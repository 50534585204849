<template>
  <router-link :class="[style.resumeListItem, {[style.resumeListItemGray]: color === 'gray', [style.resumeListItemHighlight]: highlight}]" :to="link || ''">
    <div :class="style.resumeListItemHeader">
      <div :class="style.listItemAvatar">
        <candidate-avatar :img="item.image?.url || item.image" :class="style.candidateAvatar"/>
      </div>
      <div :class="style.resumeListItemInfoHeader">
        <div :class="style.resumeListItemTitleContainer">
          <div :class="style.resumeListItemTitle" v-if="item.position">
            {{ item.position }}
          </div>
          <tag :class="style.salaryTag"
               icon="money"
               :icon_pos=" type === 'candidate' ? !item.salary ? 'start' : !item.price ? 'start' : '' : ''"
               icon_color="rgba(11,11,11, .3)"
               :text=" salaryParse(item.salary, null, $store.state.systemParams.currencies[item.currency]?.short)"
               color="var(--green)" height="37"/>
        </div>
        <div :class="[style.resumeListItemInfoHeaderBefore, {[style.hrHeaderBefore]: type==='hr'}]">
          <p :class="style.resumeListItemSecondaryInfo"
             v-if="dateParse(item.birthday) || item.age || item.location"
          >{{ item.location ? `${item.location}${getAgeByDate(item.birthday) || item.age ? ', ' : ''}` : '' }}
            {{ getAgeByDate(item.birthday) ? `${getAgeByDate(item.birthday)} ${getNoun(getAgeByDate(item.birthday), 'год', 'года', 'лет')}` : item.age ? `${item.age} ${getNoun(item.age, 'год', 'года', 'лет')}` : '' }}</p>
          <p :class="style.resumeListItemSecondaryInfo" v-if="type === 'candidate' && item.experience">Опыт работы: {{ item.experience }} {{getNoun(item.experience, 'год', 'года', 'лет')}}</p>
        </div>
        <div :class="style.prevJob" v-if="item.work_last && Object.values(item.work_last).filter(v=>v).length">
          <div :class="style.prevJobLabel">Последнее место работы:</div>
          <div :class="style.prevJobTitle" v-if="item.work_last.position">{{ item.work_last.position }}</div>
          <div :class="style.prevJobSubtitle" v-if="item.work_last?.date_from">{{ item.work_last.company_info?.title }}
            ({{ dateParse(item.work_last.date_from, 'MMMM YYYY') }} -
            {{
              item.work_last.date_to && dateParse(item.work_last.date_to, 'MMMM YYYY') ? dateParse(item.work_last.date_to, 'MMMM YYYY') : 'По настоящее время'
            }})</div>
          <div v-else :class="style.prevJobSubtitle">{{ item.work_last.company_info?.title }}</div>
        </div>
        <div :class="style.listItemTextContainer" v-if="text">
          <p :class="[style.listItemText]">"{{ expandedText ? text : collapsedText }}"</p>
          <rekroo-button
              v-if="text && text.length > 130"
              :prevent="true"
              @click="expandedText = !expandedText"
              style="margin-top: 4px;"
              type="text" text_color="var(--bright-blue)"
              :icon="`chevron-${expandedText ? 'up' : 'down'}`"
              icon_pos="end" icon_color="var(--bright-blue)" icon_style="margin-left: 4px;">
            {{expandedText ? 'Скрыть' : 'Показать полностью'}}</rekroo-button>
        </div>
      </div>
    </div>
    <div :class="style.skills" v-if="item.skills && item.skills.length">
      <tag v-for="(skill, i) in item.skills" :key="i" :text="skill" :class="style.skillTag"/>
    </div>
    <div :class="style.resumeListItemContentMobile">
      <div :class="[style.resumeListItemInfoHeaderBefore]">
        <p :class="style.resumeListItemSecondaryInfo"
           v-if="dateParse(item.birthday) || item.age || item.location"
        >{{ item.location ? `${item.location}${getAgeByDate(item.birthday) || item.age ? ', ' : ''}` : '' }}
          {{ getAgeByDate(item.birthday) ? `${getAgeByDate(item.birthday)} ${getNoun(getAgeByDate(item.birthday), 'год', 'года', 'лет')}` : item.age ? `${item.age} ${getNoun(item.age, 'год', 'года', 'лет')}` : '' }}</p>
        <p :class="style.resumeListItemSecondaryInfo" v-if="type === 'candidate' && item.experience">Опыт работы: {{ item.experience }} {{getNoun(item.experience, 'год', 'года', 'лет')}}</p>
      </div>
      <div :class="style.prevJob" v-if="item.work_last && Object.values(item.work_last).filter(v=>v).length">
        <div :class="style.prevJobLabel">Последнее место работы:</div>
        <div v-if="item.work_last.position" :class="style.prevJobTitle">{{ item.work_last.position }}</div>
        <div :class="style.prevJobSubtitle" v-if="item.work_last?.date_from">{{ item.work_last.company_info?.title }} ({{ dateParse(item.work_last.date_from, 'MMMM YYYY') }} -
          {{
            item.work_last.date_to && dateParse(item.work_last.date_to, 'MMMM YYYY') ? dateParse(item.work_last.date_to, 'MMMM YYYY') : 'По настоящее время'
          }})</div>
        <div v-else :class="style.prevJobSubtitle">{{ item.work_last.company_info?.title }}</div>
      </div>
      <tag :class="style.salaryTag"
           icon="money"
           :icon_pos=" type === 'candidate' ? !item.salary ? 'start' : !item.price ? 'start' : '' : ''"
           icon_color="rgba(11,11,11, .3)"
           :text="['candidate', 'resume'].includes(type) ?  salaryParse(item.salary, null, $store.state.systemParams.currencies[item.currency]?.short) : item.price ? `${ thousandSeparator(item.price)} ₽/ усл.` : 'по договоренности'"
           color="var(--green)" height="37"/>
    </div>
<!--    <div :class="style.cardActions" v-if="user.id && user.id == item.user_id">
      <rekroo-button color="var(&#45;&#45;yellow)" text_color="var(&#45;&#45;primary)">Поднять в поиске</rekroo-button>
    </div>-->
    <div v-if="user.id && user.id == item.user_id"
        :class="style.resumeStatus"
         :style="`background: ${$store.state.resumeStatusLabelColors[item.status]}; color: ${item.status === 0 ? 'var(--primary)' : '#FFF'}`">
      {{$store.state.resumeStatuses[item.status]}}</div>
  </router-link>
</template>

<script>
import CandidateAvatar from "@/components/CandidateAvatar";
import Tag from "@/components/Tag";
import Dot from "@/components/Dot";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
import style from "@/components/styles/PersonCardListItemStyle.module.css"
export default {
  name: "PersonCardListItem",
  props: ['item', 'type', 'link', 'cover_letter', 'color', 'highlight'],
  emits: ['add-worker-btn-click'],
  components: {RekrooButton, RekrooIcon, Dot, Tag, CandidateAvatar},
  computed: {
    user() {
      return this.$store.state.user;
    },
    text() {
      return this.type === 'hr' ? this.item.about : this.cover_letter;
    },
    collapsedText() {
      let text = this.type === 'hr' ? this.item.about : this.cover_letter;
      if (text && text.length > 130) {
        text = text.slice(0, 130);
        text += '...';
      }
      return text;
    }
  },
  data: ()=>({
    style,
    expandedText: false,
  })
}
</script>

<style module lang="css">

</style>