<template>
  <el-dialog :visible.sync="isVisible"
             custom-class="rekroo-dialog filters-hr-search-dialog gray-bg" :center="true">
    <div :class="$style.title">Фильтры</div>
    <filters-hr-search-form :filters="filters" @apply="apply"/>
    <rekroo-button :class="$style.resetButton" type="text" text_color="var(--gray-text)" width="100%"
                   @click="reset">Сбросить все</rekroo-button>
  </el-dialog>
</template>

<script>
import FiltersHrSearchForm from "@/views/company/auctions/components/FiltersHrSearchForm";
import RekrooButton from "@/components/Button";
export default {
  name: "FiltersHrSearchDialog",
  components: {RekrooButton, FiltersHrSearchForm},
  emits: ['on-change'],
  data: () => ({
    isVisible: false,
    filters: {},
  }),
  methods: {
    open(params) {
      this.filters = params.filters;
      this.isVisible = true;
    },
    apply() {
      this.$emit('on-change', this.filters);
      this.isVisible = false;
    },
    reset() {
      this.filters = {};
      this.$emit('on-change', this.filters);
      this.isVisible = false;
    }
  }
}
</script>

<style module lang="css">
.title {
  margin-bottom: 16px;
  font-size: var(--subheadline);
  font-weight: var(--body-medium-weight);
}

.resetButton {
  margin-top: 20px;
}
</style>