import {Item} from "@/api/item";

export class Auth extends Item {
    method = 'auth';
    login(data) {
        return this.request(data, `${this.method}/login`);
    }
    checkCode(data) {
        return this.request(data, `${this.method}/checkCode`);
    }
    logout() {
        return this.request({}, `${this.method}/logout`);
    }
    marketingForm(form) {
        return this.request(form, `${this.method}/marketingForm`);
    }
}