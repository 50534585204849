<template>
  <div class="page">
    <h1 :class="$style.title">{{ mailing.id ? 'Редактирование рассылки' : 'Новая рассылка' }}</h1>
    <div :class="$style.formOuter">
      <div :class="$style.formContainer">
        <el-form ref="form" :model="mailing" :rules="rules" :class="$style.form">
          <el-form-item label="Название">
            <el-input v-model="mailing.title" placeholder="Название рассылки"/>
          </el-form-item>
          <el-form-item label="Вакансия" prop="vacancy_id" style="margin-bottom: 32px;">
            <rekroo-button :icon="mailing.id ? '' : 'pencil'" icon_pos="end" icon_width="24px" color="#FFF"
                           text_color="var(--primary)"
                           :space-between="true" width="100%" height="60px" @click="openSelectVacancyDialog"
                           :disabled="!!mailing.id"
                           :class="[$style.selectVacancyButton, {[$style.selectVacancyButtonError]: vacancyIdError}]">
              {{ selectedVacancy.position || 'Выбрать вакансию' }}
            </rekroo-button>
          </el-form-item>
          <el-form-item label="Отберите кандидатов по фильтрам"
                        class="align-left"
                        style="margin-bottom: 32px">
            <rekroo-button icon="pencil" icon_pos="end" icon_width="24px" color="#FFF" text_color="var(--primary)"
                           :space-between="true" width="100%" height="60px" @click="selectResumeFilters">
              {{
                mailing.resume_filters.q || mailing.max_length ?
                    `${[mailing.resume_filters.q, `${thousandSeparator(mailing.max_length)} резюме`].filter(v => v).join(' - ')}` : 'Выбрать фильтры'
              }}
            </rekroo-button>

          </el-form-item>
          <el-form-item label="Дата и время отправки">
            <div :class="$style.datetimeContainer">
              <el-date-picker v-model="mailing.date" type="date" format="dd.MM.yyyy" lang="ru"
                              placeholder="ДД.ММ.ГГГГ" class="no-prefix" value-format="yyyy-MM-dd"
                              v-maska="'##.##.####'"/>
              <el-time-select
                  v-model="mailing.time"
                  v-maska="'##:##'"
                  :picker-options="{start: '00:00',step: '00:15',end: '23:45'}"
                  placeholder="Выберите время">
              </el-time-select>
            </div>
          </el-form-item>
        </el-form>
        <div :class="$style.actions">
          <rekroo-button height="50px" width="100%" color="var(--gray)" text_color="var(--primary)"
                         style="margin-bottom: 16px" @click="save()" :loading="loading">Сохранить
          </rekroo-button>
          <rekroo-button height="50px" width="100%" @click="save(true)" :loading="loading">Сохранить и отправить
          </rekroo-button>
        </div>
      </div>
    </div>
    <ResumeCatalogDialog ref="resume_catalog_dialog" @save-filters="onSaveFilters"/>
    <MailingListTariffDialog ref="mailing_list_tariff_dialog" @select="onSelectTariff"/>
    <VacancyFormDialog ref="vacancy_form_dialog" @create="onSelectVacancy"/>
    <SelectVacancyDialog ref="select_vacancy_dialog" :vacancy_id="mailing.vacancy_id"
                         @select="onSelectVacancy" @on-create-btn-click="openVacancyForm"/>
  </div>
</template>

<script>
import Skeleton from "@/components/skeletons/Skeleton";
import CatalogFiltersForm from "@/views/company/catalog/components/CatalogFiltersForm";
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import {Mailing_list_item} from "@/api/mailing_list_item";
import Vacancy from "@/api/vacancy";
import ResumeCatalogDialog from "@/dialogs/ResumeCatalogDialog";
import MailingListTariffDialog from "@/dialogs/MailingListTariffDialog";
import VacancyFormDialog from "@/dialogs/VacancyFormDialog";
import SelectVacancyDialog from "@/views/company/mailing_list/SelectVacancyDialog";

export default {
  name: "MailingListForm",
  components: {
    SelectVacancyDialog,
    VacancyFormDialog,
    MailingListTariffDialog, ResumeCatalogDialog, Skeleton, CatalogFiltersForm, RekrooButton, RekrooIcon
  },
  data: () => ({
    mailingListItemClass: new Mailing_list_item({}),
    mailing: {vacancy_id: '', resume_filters: {currency: 'rub', skills: [], languages: []}},
    receivedSchedule: '',
    vacancyClass: new Vacancy({}),
    vacancies: [],
    vacanciesLoading: false,
    loading: false,
    rules: {
      vacancy_id: [
        {required: true, message: 'Выберите вакансию', trigger: 'change'},
      ]
    },
    vacancyIdError: false,
    selectedVacancy: {}
  }),
  methods: {
    get() {
      this.mailingListItemClass.get(this.$route.params.id).then(data => {
        this.mailing = this.dataFromResponse(data.response);
      })
    },
    save(send = false) {
      this.$refs.form.validate(valid => {
        this.vacancyIdError = this.dataToRequest().vacancy_id === '';
        if (valid) {
          this.loading = true;
          this.mailingListItemClass.create(this.dataToRequest()).then(data => {
            this.loading = false;
            this.$notify({
              type: 'success',
              message: 'E-mail рассылка успешно сохранена'
            })
            this.$router.replace(`/${this.$route.path.split('/')[1]}/mailing_list/update/${data.response.id}`);
            if (send) this.send(data.response)
            else if (!this.mailing.tariff) this.openTariff();
            // else this.$router.replace('/company/mailing_list')
          }).catch(data => {
            this.loading = false;
            if (data.error?.type === 'tariff') {
              this.openTariff();
            }
          })
        }
      })
    },
    send(res) {
      this.loading = true;
        this.mailingListItemClass.send(res.id, this.dataToRequest().datetime_schedule).then(() => {
          this.loading = false;
          this.$notify({
            type: 'success',
            message: `E-mail рассылка успешно ${this.dataToRequest().datetime_schedule ? 'запланирована' : 'отправлена'}`
          })
          this.$router.replace('/company/mailing_list')
        }).catch(data => {
          this.loading = false;
          if (data.error?.type === 'tariff') {
            this.openTariff();
          }
        })
    },
    openTariff() {
      this.$refs.mailing_list_tariff_dialog.open();
    },
    onSelectTariff(tariff) {
      this.mailing.tariff = tariff;
      this.save();
    },
    selectResumeFilters() {
      this.$refs.resume_catalog_dialog.open(this.mailing.resume_filters);
    },
    dataToRequest() {
      let data = this.mailing;
      data.datetime_schedule = this.$moment(`${this.mailing.date}T${this.mailing.time}`).unix();
      return data;
    },
    dataFromResponse(res) {
      this.selectedVacancy = res.vacancy;
      res.vacancy_id = res.vacancy?.id;
      if (res.datetime_schedule) {
        res.date = this.dateParse(res.datetime_schedule, 'YYYY-MM-DD');
        res.time = this.dateParse(res.datetime_schedule, 'HH:mm');
        this.receivedSchedule = res.datetime_schedule;
      }
      return res;
    },
    onSaveFilters(params) {
      this.mailing.resume_filters = params.filters;
      this.mailing.max_length = params.counts;
    },
    openVacancyForm() {
      this.$refs.vacancy_form_dialog.open();
    },
    openSelectVacancyDialog() {
      this.$refs.select_vacancy_dialog.open();
    },
    onSelectVacancy(vacancy) {
      this.mailing.vacancy_id = vacancy.id;
      this.selectedVacancy = vacancy;
      this.vacancyIdError = false;
      this.$refs.form.validate(() => {
      });
    }
  },
  mounted() {
    if (this.$route.params.id) this.get();
  }
}
</script>

<style module lang="css">
.title {
  margin-bottom: 36px;
}

.formOuter {
  display: grid;
  grid-template-columns: 66% auto;
  grid-column-gap: 20px;
}

.form {
  position: relative;
  background: var(--light-gray);
  padding: 64px 48px;
  border-radius: 16px;
  margin-bottom: 40px;
}

.datetimeContainer {
  display: flex;
  column-gap: 8px;
}

.selectVacancyButton.selectVacancyButtonError {
  border: 1px solid var(--error-red);
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}

@media screen and (max-width: 1024px ) {
  .formOuter {
    grid-template-columns: 1fr;
  }

  .form {
    padding: 44px 40px;
  }

  .actions {
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 575px) {
  .form {
    padding: 32px 20px;
  }

  .actions {
    max-width: 100%;
    grid-template-columns: 1fr;
  }
}
</style>