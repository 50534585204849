<template>
  <div class="page page-with-tabs-table">
    <h1>Мои отклики</h1>
<!--    <el-select v-model="responseStatus" class="gray-bg" placeholder="Статус откликов"
               style="max-width: 300px; margin-bottom: 24px">
      <el-option v-for="status in statuses" :key="status" :value="status" :label="status"/>
    </el-select>-->
    <empty-list-plug v-if="authError" title="Доступ ограничен" subtitle="Авторизуйтесь, чтобы иметь возможность просматривать отклики на вакансии"
    button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
    style="margin: 0 auto" :show-icon="true"/>
    <responses-table :data="tablesData.active.data" :loading="tablesData.active.loading"
                     :loaded="tablesData.active.loaded" name="active"/>
  </div>
</template>
<script>
import Vacancy from "@/api/vacancy";
import ResponsesTable from "@/views/applicant/responses/components/ResponsesTable";
import Tag from "@/components/Tag";
import EmptyListPlug from "@/components/EmptyListPlug";

export default {
  name: "ResponsesList",
  components: {EmptyListPlug, Tag, ResponsesTable},
  data: () => ({
    activeTab: 'active',
    tablesData: {
      active: {
        loading: false,
        loaded: false,
        data: []
      },
      archive: {
        loading: false,
        loaded: false,
        data: []
      }
    },
    vacancyClass: new Vacancy({}),
    responseStatus: '',
    statuses: ['Не просмотрено', 'просмотрено', 'отказано', 'обменялись контактами', 'Все отклики'],
    authError: false,
  }),
  methods: {
    get(firstLoad = false, status = 'active') {
      if (firstLoad) this.tablesData[status].loading = true;
      this.vacancyClass.getResponsed().then((data) => {
        this.tablesData[status].data = data.response;
        this.tablesData[status].loaded = true;
        this.tablesData[status].loading = false;
        this.authError = false;
      }).catch((data) => {
        this.tablesData[status].loading = false;
        this.authError = data?.error?.type === '401';
      })
    },
    onTabChange(newTab) {
      this.get(false, newTab);
      for (let key in this.tablesData) {
        if (key !== newTab) this.tablesData[key].loaded = false;
      }
      this.$router.push({query: newTab === 'active' ? {} : {t: newTab}}).catch(() => {});
    }
  },
  mounted() {
    this.get(true);
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get(true);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
</style>