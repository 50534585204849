<template>
  <el-dialog :class="$style.createOrderDialog" :visible.sync="isVisible"
             custom-class="rekroo-dialog create-order-dialog" :center="true" ref="dialog">
    <div :class="$style.createOrderTitle">Найти рекрутера</div>
    <el-form :model="order" :rules="rules" ref="form">
      <el-form-item label="Должность" prop="title" required>
        <el-input class="gray-bg" v-model="order.title"
                  placeholder="Укажите должность"/>
      </el-form-item>
      <el-form-item label="Комментарий для рекрутера">
        <el-input class="gray-bg" type="textarea" v-model="order.description"
                  placeholder="Расскажите подробнее о требованиях к кандидату и о вакансии. Какие пожелания и особенности учесть специалисту?"
                  :autosize="{ minRows: 4, maxRows: 10}"
        />
      </el-form-item>
      <!--      <el-form-item>
              <el-upload :action="`${item.url}files/upload`" :class="$style.upload">
                <template #default>
                  <div :class="$style.uploadContainer">
                    <span>Добавить</span>&nbsp;фото или файлы
                  </div>
                </template>
                <div slot="file" slot-scope="{file}">
                  <div :class="$style.thumbnail">
                    <rekroo-button icon="close" icon_width="8" :class="$style.removeButton"/>
                    <img :src="file.url" alt="">
                  </div>
                </div>
              </el-upload>
            </el-form-item>-->
      <el-form-item>
        <div :class="$style.budget">
          <div :class="$style.budgetTitle">Гонорар HR'а</div>
          <div :class="$style.budgetInputsContainer">
            <div :class="$style.budgetInputs">
              <el-input v-model="order.price_from" v-maska="thousandMask"
                        type="tel"
                        class="gray-bg" placeholder="от" :class="$style.budgetFromInput" :key="key+1"/>
              <el-input v-model="order.price_to" v-maska="thousandMask"
                        type="tel"
                        class="gray-bg" placeholder="до" :class="$style.budgetToInput" :key="key+2"/>
            </div>
            <span style="font-size: 24px">{{ $store.state.systemParams.currencies['rub']?.short }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div :class="$style.budget">
          <div :class="$style.budgetTitle">Зарплата сотрудника</div>
          <div :class="$style.budgetInputsContainer">
            <div :class="$style.budgetInputs">
              <el-input v-model="order.salary_from" v-maska="thousandMask"
                        type="tel"
                        class="gray-bg" placeholder="от" :class="$style.budgetFromInput" :key="key+1"/>
              <el-input v-model="order.salary_to" v-maska="thousandMask"
                        type="tel"
                        class="gray-bg" placeholder="до" :class="$style.budgetToInput" :key="key+2"/>
            </div>
            <span style="font-size: 24px">{{ $store.state.systemParams.currencies['rub']?.short }}</span>
          </div>
        </div>
      </el-form-item>
      <div :class="$style.actions">
        <rekroo-button width="100%" color="var(--gray)" height="50px"
                       text_color="var(--primary)" @click="create(0)">Сохранить как неопубликованную
        </rekroo-button>
        <rekroo-button width="100%" height="50px" :loading="loading" @click="create(1)">Опубликовать</rekroo-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import {Item} from "@/api/item";
import {AuctionItem} from "@/api/auction_item";
import Vacancy from "@/api/vacancy";

export default {
  name: "CreateOrderDialog",
  components: {RekrooButton},
  props: ['type'],
  emits: ['created'],
  data: () => ({
    isVisible: false,
    order: {
      title: '',
      salary_from: '',
      salary_to: '',
      description: '',
      price_from: '',
      price_to: ''
    },
    auctionItemClass: new AuctionItem({}),
    vacancyClass: new Vacancy({}),
    vacancy: {},
    rules: {
      title: [
        {required: true, message: 'Укажите задачу', trigger: 'change'},
        {required: true, message: 'Укажите задачу', trigger: 'blur'},
      ]
    },
    loading: false,
    item: new Item({}),
    thousandMask: '',
    key: 0
  }),
  methods: {
    open(data) {
      this.isVisible = true;
      this.order = {
        title: '',
        salary_from: '',
        salary_to: '',
        description: '',
        price_from: '',
        price_to: ''
      };
      this.vacancy = {};
      setTimeout(() => {
        this.$refs.form.resetFields();
      })
      if (data.id) this.get(data.id);
      if (data.vacancy_id) this.getVacancy(data.vacancy_id)
      else this.thousandMask = this.$store.state.maskThousandSeparator;
    },
    get(id) {
      this.loading = true;
      this.auctionItemClass.get(id).then((data) => {
        this.order = data.response;
        this.loading = false;
        this.thousandMask = this.$store.state.maskThousandSeparator;
        this.key = Math.random();
      }).catch(() => {
        this.loading = false;
      })
    },
    getVacancy(id) {
      this.vacancyClass.get(id).then((data) => {
        this.vacancy = data.response;
        this.order.position = this.vacancy.title;
        if (this.vacancy.salary_from) this.order.salary_from = this.vacancy.salary_from;
        if (this.vacancy.salary_to) this.order.salary_to = this.vacancy.salary_to;
        this.thousandMask = this.$store.state.maskThousandSeparator;
        this.key = Math.random();
      })
    },
    create(status) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.auctionItemClass.create({...this.dataToRequest(), status}).then(() => {
            this.loading = false;
            this.$emit('created');
            this.isVisible = false;
          }).catch(() => {
            this.loading = false;
          })
        }
      })
    },
    dataToRequest() {
      let data = {...this.order};
      data.type = 0;
      data.price_from = data.price_from?.toString()?.replaceAll(' ', '');
      data.price_to = data.price_to?.toString()?.replaceAll(' ', '');
      data.salary_from = data.salary_from?.toString()?.replaceAll(' ', '');
      data.salary_to = data.salary_to?.toString()?.replaceAll(' ', '');
      return data;
    },
    onKeydown(key) {
      if (key.code === 'Enter') this.submit();
    },
  },
  computed: {
    isButtonDisabled() {
      return !this.order.title;
    }
  }
}
</script>

<style module lang="css">
.createOrderTitle {
  font-size: var(--headline_3);
  margin-bottom: 20px;
}

.upload {
  margin-bottom: 8px;
}

.uploadContainer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--primary);
  border-radius: 8px;
  font-size: var(--small-text);
}

.uploadContainer span {
  color: var(--bright-blue);
}

.thumbnail {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  position: relative;
}

.thumbnail .removeButton {
  width: 20px;
  height: 20px;
  top: 4px;
  right: 4px;
  background: #FFFFFF;
  border-radius: 4px;
}

.budget {
  margin-bottom: 12px;
}

.budget .budgetTitle {
  margin-bottom: 16px;
  font-size: var(--headline_3);
  font-weight: 600;
}

.budget .budgetInputsContainer {
  display: flex;
  column-gap: 10px;
}

.budget .budgetInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  flex-grow: 1;
}

.currencySelect {
  max-width: 60px;
}

.actions {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

@media screen and (max-width: 1024px) {
  .budget .budgetInputsContainer {
    column-gap: 4px;
  }

  .budget .budgetInputs {
    column-gap: 0;
  }

  .budget .budgetInputs .budgetFromInput input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: 1px solid rgba(172, 172, 172, .3) !important;
  }

  .budget .budgetInputs .budgetToInput input {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}
</style>