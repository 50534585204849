<template>
  <div :class="[style.landingHrPage, {[style.landingHrPageLoggedIn]: user.id}]">
    <section :class="$style.landingApplicantHeader">
      <div :class="$style.landingApplicantHeaderTitle">
        Никогда еще не было так просто найти работу <span>своей мечты
                <img src="/img/applicant_landing/stroke.svg" alt="" :class="$style.landingApplicantHeaderTitleStroke">
      </span>
      </div>
      <div :class="$style.landingApplicantHeaderSearchbarContainer">
        <el-autocomplete :class="$style.landingApplicantHeaderInput" placeholder="Работа мечты"
                  v-model="q"
                  :fetch-suggestions="fetchSuggestionsPos"
                  :trigger-on-focus="false"
                  @keydown.enter.native="$router.push(`/applicant/vacancies?q=${q}`)">
          <template #prefix>
            <rekroo-icon icon="search" color="var(--gray-text)"/>
          </template>
        </el-autocomplete>
        <router-link :to="`/applicant/vacancies?q=${q}`">
          <rekroo-button :class="$style.landingApplicantHeaderButton">Найти работу</rekroo-button>
        </router-link>
        <img src="/img/applicant_landing/stroke_2.svg" alt="" :class="$style.landingApplicantSearchbarStroke">
      </div>
      <a href="#landing-applicant-resume" :class="$style.landingApplicantHeaderNavigateToBottomButton">Как тебе поможет Rekroo
        <rekroo-icon icon="arrow-bottom" width="16"/>
      </a>
      <img src="/img/applicant_landing/curve_arrow.svg" alt="" :class="$style.landingApplicantHeaderCurveArrow">
    </section>
    <section :class="$style.landingApplicantResume" id="landing-applicant-resume">
      <h1 :class="$style.landingApplicantResumeTitle">Размести резюме и получай приглашения на собеседования</h1>
      <p :class="$style.landingApplicantResumeSubtitle">Тогда потенциальные работодатели смогут сами тебя найти, а ты
        сможешь откликаться на интересующие вакансии в один клик. </p>
      <img src="/img/applicant_landing/applicant_resume.jpg" alt="" :class="$style.landingApplicantResumeImg">
      <div :class="$style.landingApplicantResumeHint">
        А создать максимально грамотное резюме поможет наш удобный конструктор
        <img src="/img/applicant_landing/curve_dashed_gray_arrow.svg" alt="">
      </div>
    </section>
    <section :class="$style.landingApplicantHighlight">
      <div :class="$style.landingApplicantHighlightInner">
        <h1 :class="$style.landingApplicantHighlightTitle">Выделись и привлеки внимание работодателей</h1>
        <div :class="$style.landingApplicantHighlightItems">
          <div :class="$style.landingApplicantHighlightItem" v-for="(item, i) in highlights" :key="i">
            <div :class="$style.landingApplicantHighlightItemTitle">{{ item.title }}</div>
            <div :class="$style.landingApplicantHighlightItemSubtitle">{{ item.subtitle }}</div>
            <img :src="`/img/applicant_landing/highlight_${i+1}.png`" alt=""
                 :class="$style.landingApplicantHighlightItemImage">
          </div>
        </div>
        <router-link to="/applicant/resumes/create">
          <rekroo-button width="100%" color="var(--purple)" height="72px">Создать резюме</rekroo-button>
        </router-link>
      </div>
    </section>
    <section :class="$style.landingApplicantVacancies">
      <h1 :class="$style.landingApplicantVacanciesTitle">Откликайся на актуальные живые вакансии, обновляющиеся
        ежедневно</h1>
      <landing-vacancies :vacancies="vacancies" link="/applicant/vacancies/"/>
      <router-link to="/applicant/vacancies" :class="$style.landingApplicantVacanciesButton">
        <rekroo-button height="72px" color="var(--purple)" width="100%">Больше
          вакансий
        </rekroo-button>
      </router-link>
    </section>
    <section :class="$style.landingApplicantConsultation">
      <h1 :class="$style.landingApplicantConsultationTitle">Повысь свои шансы найти работу мечты на консультации с
        опытным HR</h1>
      <p :class="$style.landingApplicantConsultationSubtitle">Получи карьерную поддержку и ценные лайфхаки по поиску
        работы прямо от практикующих HR-директоров, рекрутеров и менеджеров</p>
      <div :class="$style.landingApplicantConsultationHint">
        «С чем я могу обратиться?»
        <img src="/img/applicant_landing/small_gray_dashed_curve_arrow.svg" alt="">
      </div>
      <div :class="$style.landingApplicantConsultationItems">
        <div :class="$style.landingApplicantConsultationItem" v-for="(item, i) in consultationItems" :key="i">
          <img :src="`/img/icons/stickers/sticker-${item.img}.svg`" alt=""
               :class="$style.landingApplicantConsultationItemImg">
          <div :class="$style.landingApplicantConsultationItemLabel">{{ item.label }}</div>
        </div>
      </div>
      <router-link to="/applicant/consultations" :class="$style.landingApplicantConsultationButton">
        <rekroo-button color="var(--purple)" width="100%" height="72px">Подобрать HR</rekroo-button>
      </router-link>
    </section>
    <section :class="$style.landingApplicantDream">
      <div :class="$style.landingApplicantDreamDescription">
        <h1 :class="$style.landingApplicantDreamTitle">Получи оффер мечты, составив топ-5 компаний в которых хочешь работать</h1>
        <p :class="$style.landingApplicantDreamSubtitle">Добавь компанию в список избранных и обрати внимание работодателя на себя. Так ты сразу дашь понять работодателю, что работа именно в его компании для тебе приоритетнее. </p>
      </div>
      <div :class="$style.landingApplicantDreamImageContainer">
        <img src="/img/applicant_landing/dream_section.png" alt="">
        <div :class="$style.landingApplicantDreamHint">
          Ты можешь выбрать до 5 компаний мечты
        </div>
        <img :class="$style.landingApplicantDreamHintImg" src="/img/applicant_landing/double_curve_dashed_gray_arrow.svg" alt="">
      </div>
    </section>
    <landing-reviews-section :title="`Они уже попробовали Rekroo <br> и нашли работу своей мечты`"/>
  </div>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import Vacancy from "@/api/vacancy";
import ApplicantVacanciesList from "@/views/applicant/vacancy/List";
import ApplicantLandingHeroImage from "@/views/landing/components/ApplicantLandingHeroImage";
import LandingVacancies from "@/views/landing/components/LandingVacancies";
import LandingReviewsSection from "@/views/landing/components/LandingReviewsSection";

export default {
  name: "ApplicantLanding",
  components: {
    LandingReviewsSection,
    LandingVacancies, ApplicantLandingHeroImage, ApplicantVacanciesList, RekrooIcon, RekrooButton},
  data: () => ({
    style,
    vacancyClass: new Vacancy({}),
    q: '',
    highlights: [
      {
        title: 'Поднятие резюме в топ',
        subtitle: 'Резюме будет показываться в первых строчках в откликах и результатах поиска',
      },
      {
        title: 'Яркое выделение резюме',
        subtitle: 'Резюме будет выделено  цветом и станет более заметным для работодателей',
      }
    ],
    vacancies: [],
    demoVacancies: [
      {
        company_info: {title: 'Рекламное агенство Нерв'},
        position: 'Менеджер по продажам и аккаунтингу',
        location: 'Санкт-Петербург',
        salary_from: 60000,
      },
      {
        company_info: {title: 'Яндекс Крауд'},
        position: 'Автор текстов в Поиск',
        salary_from: '',
        remote_work: 1
      },
      {
        company_info: {title: 'ИП Максим Андреюк'},
        position: 'Менеджер отдела продаж',
        location: 'Москва',
        salary_from: 40000,
        salary_to: 65000,
      },
    ],
    consultationItems: [
      {
        img: 'pencil',
        label: 'Составить резюме и сопроводительное письмо'
      },
      {
        img: 'briefcase',
        label: 'Узнать свои карьерные возможности'
      },
      {
        img: 'chat',
        label: 'Подготовиться к собеседованию'
      },
      {
        img: 'list',
        label: 'Проработать стратегию поиска работы'
      },
      {
        img: 'heart',
        label: 'Заручиться поддержкой при откликах'
      },
      {
        img: 'location',
        label: 'Найти новую работу за границей'
      },
    ]
  }),
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    fetchSuggestionsPos(q, cb) {
      this.vacancyClass.suggestions('position', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    search() {
      this.$refs.vacancies_list.filters.q = this.q;
      this.$refs.vacancies_list.get();
      window.scrollTo({
        top: this.$refs.vacancies_list.$el.offsetTop,
        behavior: 'smooth'
      })
    },
    getVacancies() {
      this.vacancyClass.search( {pageLength: 3}).then((data)=>{
        this.vacancies = data.response;
      })
    },
  },
  mounted() {
    this.getVacancies();
  }
}
</script>

<style module lang="css">
/*HEADER*/
.landingApplicantHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 113px 52px;
  border-radius: 16px;
  background: var(--light-gray);
  width: 100%;
  position: relative;
}

.landingApplicantHeaderTitle {
  margin-bottom: 60px;
  font-size: 64px;
  font-weight: 700;
  line-height: 108%;
  text-align: center;
}

.landingApplicantHeaderTitle span {
  position: relative;
  display: inline-block;
  color: var(--purple);
}

.landingApplicantHeaderTitleStroke {
  position: absolute;
  bottom: -36px;
  left: 0;
  width: 100%;
}

.landingApplicantHeaderSearchbarContainer {
  display: flex;
  column-gap: 12px;
  max-width: 914px;
  width: 100%;
  padding: 40px 52px;
  margin-bottom: 92px;
  border-radius: 16px;
  background: var(--blue);
  position: relative;
}

.landingApplicantSearchbarStroke {
  position: absolute;
  left: -36px;
  top: -36px;
}

.landingApplicantHeaderInput input,
.landingApplicantHeaderButton {
  height: 46px !important;
}

.landingApplicantHeaderButton {
  width: 227px !important;
}

.landingApplicantHeaderNavigateToBottomButton {
  font-size: var(--body);
}

.landingApplicantHeaderNavigateToBottomButton i {
  margin-left: 12px;
}

.landingApplicantHeaderCurveArrow {
  position: absolute;
  right: 70px;
  transform: translateY(-30px);
}

/*RESUME SECTION*/
.landingApplicantResume {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  position: relative;
}

.landingApplicantResumeTitle {
  margin-bottom: 28px;
  text-align: center;
  max-width: 950px;
}

.landingApplicantResumeSubtitle {
  margin-bottom: 20px;
  text-align: center;
  max-width: 574px;
}

.landingApplicantResumeImg {
  max-width: 840px;
  width: 100%;
  height: 100%;
}

.landingApplicantResumeHint {
  width: 227px;
  text-align: center;
  position: absolute;
  bottom: 44px;
  font-size: var(--body);
  color: var(--gray-text);
  transform: rotate(6deg);
  right: 0px;
}

/*HIGHLIGHTS SECTION*/
.landingApplicantHighlight {
  padding: 100px 40px;
  width: 100%;
  background: var(--light-gray);
  border-radius: 16px;
}

.landingApplicantHighlightInner {
  max-width: 874px;
  margin: 0 auto;
}

.landingApplicantHighlightTitle {
  margin-bottom: 40px;
  text-align: center;
}

.landingApplicantHighlightItems {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-bottom: 48px;
}

.landingApplicantHighlightItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 0;
  border-radius: 12px;
  background: #FFFFFF;
}

.landingApplicantHighlightItemTitle {
  margin-bottom: 12px;
  font-size: var(--headline_3);
  font-weight: 600;
  text-align: center;
}

.landingApplicantHighlightItemSubtitle {
  margin-bottom: 20px;
  text-align: center;
  line-height: 144%;
  font-size: var(--body);
}

.landingApplicantHighlightItemImage {
  max-width: 317px;
  width: 100%;
  height: 100%;
}

/*VACANCIES SECTION*/
.landingApplicantVacancies {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 100px;
  width: 100%;
}

.landingApplicantVacanciesTitle {
  margin-bottom: 44px;
  text-align: center;
  max-width: 929px;
}

.landingApplicantVacanciesButton {
  max-width: 419px !important;
  width: 100% !important;
}

/*CONSULTATION SECTION*/
.landingApplicantConsultation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 40px;
  margin-bottom: 20px;
  border-radius: 16px;
  background: var(--light-gray);
  position: relative;
}

.landingApplicantConsultationTitle {
  margin-bottom: 28px;
  max-width: 917px;
  text-align: center;
}

.landingApplicantConsultationSubtitle {
  margin-bottom: 36px;
  max-width: 616px;
  text-align: center;
}

.landingApplicantConsultationItems {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-bottom: 48px;
}

.landingApplicantConsultationItem {
  padding: 32px 36px;
  border-radius: 10px;
  background: #FFFFFF;
}

.landingApplicantConsultationItemImg {
  margin-bottom: 16px;
  width: 64px;
  height: 64px;
}

.landingApplicantConsultationItemLabel {
  font-size: var(--subheadline);
  font-weight: 500;
}

.landingApplicantConsultationButton {
  max-width: 420px;
  width: 100%;
}

.landingApplicantConsultationHint {
  position: absolute;
  left: 107px;
  top: 228px;
  font-size: var(--body);
  font-weight: 500;
  color: var(--gray-text);
  transform: rotate(-3deg);
  max-width: 200px;
  display: flex;
  text-align: center;
}

/*DREAM SECTION*/
.landingApplicantDream {
  padding: 56px 80px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 50px;
  border-radius: 16px;
  background: var(--light-gray);
}

.landingApplicantDreamDescription {
  max-width: 600px;
  width: 100%;
}
.landingApplicantDreamTitle {
  margin-bottom: 28px;
}
.landingApplicantDreamImageContainer {
  position: relative;
}
.landingApplicantDreamImageContainer img {
  max-height: 300px;
  height: 100%;
  width: 100%;
}

.landingApplicantDreamHint {
  position: absolute;
  bottom: 40px;
  left: -135px;
  font-size: var(--body);
  font-weight: 500;
  color: var(--gray-text);
  transform: rotate(8deg);
  max-width: 160px;
  text-align: center;
}

img.landingApplicantDreamHintImg {
  width: 220px;
  height: 140px;
  position: absolute;
  left: -54px;
  bottom: -72px;
  display: block;
}


@media screen and (max-width: 1360px) {
  .landingApplicantHeaderCurveArrow, .landingApplicantResumeHint, .landingApplicantConsultationHint,
  img.landingApplicantDreamHintImg {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .landingApplicantHeader {
    padding: 80px 40px 42px;
    border-radius: 12px;
  }

  .landingApplicantHeaderTitle {
    font-size: 52px;
  }

  .landingApplicantHeaderSearchbarContainer {
    max-width: 100%;
    padding: 40px;
    margin-bottom: 72px;
    border-radius: 12px;
    flex-direction: column;
    row-gap: 10px;
  }

  .landingApplicantHeaderTitleStroke {
    bottom: -28px;
  }

  .landingApplicantHeaderButton {
    height: 50px !important;
    width: 100% !important;
  }

  .landingApplicantSearchbarStroke {
    display: none;
  }

  /*RESUME SECTION*/
  .landingApplicantResume {
    padding-top: 80px;
  }

  .landingApplicantResumeTitle {
    margin-bottom: 20px;
    max-width: 592px;
  }

  .landingApplicantResumeSubtitle {
    margin-bottom: 28px;
  }

  .landingApplicantResumeImg {
    max-width: 592px;
    width: 100%;
  }

  /*HIGHLIGHT SECTION*/
  .landingApplicantHighlight {
    padding: 64px 40px;
    border-radius: 12px;
  }

  .landingApplicantHighlightInner {
    max-width: 100%;
  }

  .landingApplicantHighlightTitle {
    margin-bottom: 32px;
  }

  .landingApplicantHighlightItems {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }

  .landingApplicantHighlightItem {
    padding: 40px 0 0;
  }

  .landingApplicantHighlightItemTitle {
    max-width: 347px;
    font-size: 22px;
  }

  .landingApplicantHighlightItemSubtitle {
    max-width: 347px;
    margin-bottom: 18px;
  }

  .landingApplicantHighlightItemImage {
    max-width: 347px;
  }

  /*VACANCIES SECTION*/
  .landingApplicantVacancies {
    padding: 100px 0 80px;
  }

  .landingApplicantVacanciesTitle {
    margin-bottom: 32px;
    max-width: 458px;
  }

  .landingApplicantVacanciesButton {
    max-width: 592px !important;
  }

  /*CONSULTATION SECTION*/
  .landingApplicantConsultation {
    padding: 64px 40px;
  }

  .landingApplicantConsultationTitle {
    margin-bottom: 20px;
  }

  .landingApplicantConsultationSubtitle {
    margin-bottom: 32px;
  }

  .landingApplicantConsultationItems {
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 40px;
  }

  .landingApplicantConsultationItem {
    padding: 32px;
  }

  .landingApplicantConsultationButton {
    max-width: 100%;
  }

  /*DREAM SECTION*/
  .landingApplicantDream {
    padding: 152px 40px 22px;
    column-gap: 45px;
    position: relative;
  }

  .landingApplicantDreamDescription {
    max-width: 50%;
  }
  .landingApplicantDreamTitle {
    position: absolute;
    top: 64px;
    left: 40px;
    right: 40px;
    margin-bottom: 28px;
  }
  .landingApplicantDreamImageContainer {
    position: relative;
  }
  .landingApplicantDreamImageContainer img {
    max-height: 270px;
  }

  .landingApplicantDreamHint {
    bottom: 0;
    left: -94px;
    max-width: 138px;
  }

  img.landingApplicantDreamHintImg {
    width: 157px;
    height: 101px;
    left: -38px;
    bottom: -56px;
  }
}

@media screen and (max-width: 575px) {
  .landingApplicantHeader {
    padding: 60px 28px 40px;
  }

  .landingApplicantHeaderTitle {
    margin-bottom: 36px;
    font-size: 32px;
  }

  .landingApplicantHeaderTitleStroke {
    bottom: -18px;
  }

  .landingApplicantHeaderSearchbarContainer {
    padding: 24px 16px;
  }

  .landingApplicantResume {
    padding-top: 72px;
  }

  .landingApplicantResumeSubtitle {
    margin-bottom: 24px;
  }

  /*HIGHLIGHT SECTION*/
  .landingApplicantHighlight {
    padding: 60px 20px;
  }

  .landingApplicantHighlightItem {
    padding: 32px 24px 0;
  }

  .landingApplicantHighlightItemTitle {
    margin-bottom: 8px;
    max-width: 100%;
    font-size: 18px;
  }

  .landingApplicantHighlightItemSubtitle {
    max-width: 100%;
    margin-bottom: 16px;
  }

  .landingApplicantHighlightItemImage {
    max-width: 100%
  }

  /*VACANCIES SECTION*/
  .landingApplicantVacancies {
    padding: 72px 0;
  }

  .landingApplicantVacanciesButton {
    max-width: 100% !important;
  }
  /*CONSULTATION SECTION*/
  .landingApplicantConsultation {
    padding: 60px 20px;
    overflow: hidden;
  }

  .landingApplicantConsultationSubtitle {
    margin-bottom: 24px;
  }

  .landingApplicantConsultationItems {
    margin: 0 -20px 40px;
    padding: 0 20px;
    display: flex;
    max-width: calc(100% + 40px);
    overflow: auto;
  }

  .landingApplicantConsultationItem {
    min-width: 288px;
    width: 288px;
  }
  .landingApplicantConsultationItems::-webkit-scrollbar {
    display: none;
  }
  .landingApplicantConsultationItems {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /*DREAM SECTION*/
  .landingApplicantDream {
    display: block;
    padding: 60px 20px 82px;
  }

  .landingApplicantDreamDescription {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .landingApplicantDreamTitle {
    position: initial;
    margin-bottom: 20px;
  }
  .landingApplicantDreamImageContainer {
    position: relative;
  }
  .landingApplicantDreamImageContainer img {
    max-width: 100%;
    max-height: 100%;
  }

  .landingApplicantDreamHint {
    bottom: -55px;
    left: auto;
    font-size: 14px;
    right: 16px;
    display: none;
  }

  img.landingApplicantDreamHintImg {
    width: 176px;
    height: 112px;
    left: 41px;
    bottom: -110px;
    transform: rotateY(180deg);
  }


}
</style>