<template>
  <div class="page" :class="[$style.resumeItemPage, {[$style.activePage]: isMounted}]">
    <link-back text="Вернуться к резюме"
               :path="$route.query.back_link"/>
    <div :class="$style.resumeItemPageContent">
      <resume-skeleton v-if="loading" :window-width="windowWidth"/>
      <div v-else-if="html_only" v-html="item.html"/>
      <div v-else :class="style.resume">
        <img src="/img/icons/icon_paper_clip.svg" alt="" :class="style.resumeClip">

        <div v-if="authError" :class="$style.resume">
          <empty-list-plug title="Авторизуйтесь, чтобы посмотреть резюме" button_text="Войти"
                           @onClick="$store.state.eventBus.$emit('auth-error')" style="margin: 0 auto"/>
        </div>
        <empty-list-plug v-else-if="notFoundError" title="Резюме не существует, либо оно была снято с публикации"
                         :show-icon="true"
                         button_text="Вернуться к резюме" @onClick="$router.back()"
                         style="margin: 0 auto"/>
        <template v-else>
          <div :class="style.resumeHeader">
            <candidate-avatar :gender="item.gender"
                              :img="item.image"
                              :size=" windowWidth > 1024 ? '212' : windowWidth > 575 ? '164' : '124'"/>
            <div :class="style.resumeHeaderInfo">
              <div :class="[style.name, {[style.hidden]: item.contactsHidden}]">
                <rekroo-icon v-if="item.contactsHidden" icon="lock" color="var(--gray)" width="16"
                             style="margin-right: 12px"/>
                <span v-if="item.contactsHidden">Имя скрыто</span>
                <span v-else>{{ item.name }} {{ item.surname }}</span>
              </div>
              <div :class="style.position">{{ item.position }}</div>
              <div :class="style.age">
                {{
                  [['', 'Мужчина', 'Женщина'][item.gender],
                    getAgeByDate(item.birthday) || item.age ? `${item.age || getAgeByDate(item.birthday)} ${getNoun(item.age || getAgeByDate(item.birthday), 'год', 'года', 'лет')}` : ''].filter(v => v).join(', ')
                }}
              </div>
              <div :class="style.location">{{ item.location }}</div>
              <div :class="style.citizen" v-if="item.citizenship">Гражданство: {{ item.citizenship }}</div>
              <div :class="style.contacts" v-if="!item.contactsHidden">
                <a :href="`tel: ${item.phone}`" v-if="item.phone">
                  <tag :text="strPhoneMask(item.phone)" icon="phone" icon_pos="start" icon_color="var(--gray-text)"/>
                </a>
                <a :href="`mailto:${item.email}`" v-if="item.email">
                  <tag :text="item.email" icon="mail" icon_pos="start" icon_color="var(--gray-text)"/>
                </a>
              </div>
              <tag v-else>
                <template #custom>
                  <div :class="style.contactsHiddenIcon">
                    <rekroo-icon icon="error" color="#FFF" width="9"/>
                  </div>
                  <rekroo-button @click="openContacts"
                                 type="text" text_color="var(--bright-blue)"
                                 :class="style.subscribeTextButton">Открыть имя и контакты&nbsp;
                    {{ item.contactsLeft ? `(Осталось: ${item.contactsLeft})` : '' }}
                  </rekroo-button>
                </template>
              </tag>
            </div>
          </div>
          <div :class="style.resumeSection" v-if="item.covering_letter">
            <div :class="style.resumeSectionTitle">Сопроводительное письмо</div>
            <div :class="style.resumeSectionAbout">{{ item.covering_letter }}</div>
          </div>
          <div :class="style.resumeSection" v-if="item.about">
            <div :class="style.resumeSectionTitle">Обо мне</div>
            <div :class="style.resumeSectionAbout" v-html="item.about?.replaceAll('\n', '<br>')"></div>
          </div>
          <div :class="style.resumeSection">
            <div :class="style.resumeSectionTitle">Пожелания к работе</div>
            <div :class="style.resumeSectionWishes">
              <div :class="style.resumeSectionWishesInfo">
                <div :class="style.row" v-if="schedule">
                  <span :class="style.label">График: </span>{{ schedule }}
                </div>
                <div :class="style.row" v-if="job_type">
                  <span :class="style.label">Занятость: </span>{{ job_type }}
                </div>
                <div :class="style.row">
                  <span :class="style.label">Командировки: </span>{{
                    $store.state.systemParams?.business_trip_statuses[item.business_trip]
                  }}
                </div>
              </div>
              <tag color="var(--green)" icon="money" icon_pos="start"
                   :text="item.salary ? `${ thousandSeparator(item.salary)} ${ $store.state.systemParams.currencies[item.currency]?.short}` : 'По договоренности'"/>
            </div>
          </div>
          <div :class="style.resumeSection" v-if="item.education && item.education.length">
            <div :class="style.resumeSectionTitle">Образование</div>
            <div :class="style.resumeSectionEducation">
              <div :class="style.resumeSectionRowInfo" v-for="(university, i) in item.education" :key="i">
                <div :class="style.resumeSectionRowInfoTitle">
                <span>{{
                    university.university || ''
                  }} {{
                    university.degree && $store.state.systemParams.educational_levels ? `(${$store.state.systemParams.educational_levels[university.degree] || university.degree})` : ''
                  }}</span>
                  <div :class="style.year" v-if="university.date_to">{{ dateParse(university.date_to, 'YYYY') }}</div>
                </div>
                <div :class="style.resumeSectionRowInfoSubtitle">{{ university.speciality }}</div>
                <div v-if="university.files && university.files.length" :class="style.resumeSectionFiles">
                  <div :class="style.resumeSectionFilesTitle">Файлы</div>
                  <div :class="style.filesContainer">
                    <file-thumbnail v-for="(file, i) in university.files" :file="file" :key="i"
                                    :hide-remove-btn="true"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="style.resumeSection" v-if="item.work && item.work.length">
            <div :class="style.resumeSectionTitle">Опыт работы</div>
            <div :class="style.resumeSectionRowInfo" v-for="(job, i) in item.work" :key="i">
              <div :class="style.resumeSectionRowInfoTitle">
                <span v-if="job.company">{{ job.company }}</span>
                <div :class="style.year" v-if="job.date_from">{{ dateParse(job.date_from, 'MMMM YYYY') }} -
                  {{
                    job.date_to && dateParse(job.date_to, 'MMMM YYYY') ? dateParse(job.date_to, 'MMMM YYYY') : 'По настоящее время'
                  }}
                </div>
              </div>
              <div :class="style.jobPosition" v-if="job.position">{{ job.position }}</div>
              <div :class="style.resumeSectionRowInfoSubtitle" v-html="job.description?.replaceAll('\n', '<br>')"></div>
            </div>
          </div>
          <div :class="style.resumeSection" v-if="item.skills && item.skills.length">
            <div :class="style.resumeSectionTitle">Навыки</div>
            <div :class="style.skills">
              <tag v-for="skill in item.skills" :key="skill" :text="skill"/>
            </div>
          </div>
          <div :class="style.resumeSection"
               v-if="item.languages && item.languages.length && $store.state.systemParams.languageNames">
            <div :class="style.resumeSectionTitle">Языки</div>
            <div :class="style.languages">
              <tag v-for="(lang, i) in item.languages" :key="i" :class="style.language"
                   :text="`${$store.state.systemParams.languageNames[Object.keys(lang)[0]]} - ${$store.state.systemParams.languageLevels[lang[Object.keys(lang)[0]]]}`"
              />
            </div>
          </div>
          <div :class="style.resumeSection" v-if="item.courses && item.courses.length">
            <div :class="style.resumeSectionTitle">Курсы и сертификаты</div>
            <div :class="style.resumeSectionRowInfo" v-for="(certificate, i) in item.courses" :key="i">
              <div :class="style.resumeSectionRowInfoTitle">
                <span>{{ certificate.name }}</span>
                <div :class="style.year">{{ certificate.year }}</div>
              </div>
              <div :class="style.resumeSectionRowInfoSubtitle">{{ certificate.organization }}</div>
              <a :href="certificate.link" target="_blank" :class="style.resumeSectionItemLink">{{
                  certificate.link
                }}</a>
              <div v-if="certificate.files && certificate.files.length" :class="style.resumeSectionFiles">
                <div :class="style.resumeSectionFilesTitle">Файлы</div>
                <div :class="style.filesContainer">
                  <file-thumbnail v-for="(file, i) in certificate.files" :file="file" :key="i"
                                  :hide-remove-btn="true"/>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="!authError && !loading && !notFoundError" :class="$style.sideContent">
        <span :class="$style.updateDate" v-if="item.update_date">Резюме обновлено {{ item.update_date }}</span>
        <div :class="$style.sideContentActions">
          <div :class="$style.actionsGroup">
            <rekroo-button v-if="item.isOwner"
                           icon="pencil" icon_color="#FFF" color="var(--purple)"
                           width="100%" icon_style="margin-right: 10px"
                           @click="$router.push(`/applicant/resumes/update/${item.id}`)"
            >Редактировать
            </rekroo-button>
            <rekroo-button v-if="item.isOwner"
                           :class="$style.removeButton" icon="trash" icon_color="#FFF" color="var(--gray-text)"
                           icon_style="#FFF" icon_width="20px" width="54px"
                           @click="$refs.confirm_delete_dialog.open()"><span>Удалить</span></rekroo-button>
          </div>
          <div :class="[$style.actionsGroup, $style.actionsGroupMobileRow]">
            <a v-if="item.downloadLink" :href="item.downloadLink" style="width: 100%">
              <rekroo-button icon="share" icon_color="#FFF" color="var(--purple)"
                             width="100%" icon_style="margin-right: 10px">Скачать
              </rekroo-button>
            </a>
            <rekroo-button
                v-if="!item.isOwner"
                @click="complain"
                color="var(--gray)" icon="warning" icon_color="var(--primary)" width="42px" height="42px"
                text_color="var(--primary)"/>
          </div>
        </div>
        <rekroo-button v-if="item.contactsHidden" @click="openContacts" width="100%" style="margin-top: 8px">
          Открыть контакты
        </rekroo-button>
<!--        <rekroo-button v-if="$route.path.split('/')[1] === 'hr'"
                       @click="addToHRMVacancy"
            type="outlined" width="100%" style="margin-top: 8px">Прикрепить к вакансии в HRM</rekroo-button>-->
        <div :class="$style.remainingContainer">
          <items-remaining-block v-if="systemParams?.resumeContactsTotal"
                                 @open-resume-rates="openResumeRatesDialog"/>
        </div>
      </div>
    </div>
    <ResumeRatesDialog ref="resume_rates_dialog" :redirect_url="`${$route.path}?payed=1`"/>
    <ConfirmDialog ref="confirm_delete_dialog" title="Вы уверены, что хотите удалить резюме?"
                   subtitle="Отменить удаление будет невозможно." decline_button_text="Отменить"
                   success_button_text="Удалить"
                   @success="remove"/>
    <ConfirmDialog ref="activate_prev_order_dialog" title="У вас есть неактивированные покупки."
                   subtitle="Перейти к активации или приобрести еще одну?"
                   success_button_text="Активировать и открыть контакты" :success_btn_loading="activating"
                   decline_button_text="Приобрести еще"
                   @success="activateOrder" @decline="openResumeRatesDialog"/>
    <ComplainDialog ref="complain_dialog" @complained="get"/>
  </div>
</template>
<script>
import LinkBack from "@/components/LinkBack";
import CandidateAvatar from "@/components/CandidateAvatar";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
import Tag from "@/components/Tag";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import {Resume} from "@/api/resume";
import EmptyListPlug from "@/components/EmptyListPlug";
import ResumeSkeleton from "@/components/skeletons/ResumeSkeleton";
import style from "@/components/styles/ResumeStyles.module.css"
import ResumeRatesDialog from "@/dialogs/ResumeRatesDialog";
import {Order} from "@/api/order";
import InfoDialog from "@/dialogs/InfoDialog";
import Vacancy from "@/api/vacancy";
import ComplainDialog from "@/dialogs/ComplainDialog";

import "@/views/company/catalog/styles/resume.css";
import FileThumbnail from "@/components/FileThumbnail";
import ItemsRemainingBlock from "@/components/ItemsRemainingBlock";

export default {
  name: "ResumeItem",
  components: {
    ItemsRemainingBlock,
    FileThumbnail,
    ComplainDialog,
    InfoDialog,
    ResumeRatesDialog,
    ResumeSkeleton, EmptyListPlug, ConfirmDialog, Tag, RekrooButton, RekrooIcon, CandidateAvatar, LinkBack
  },
  data() {
    return {
      style,
      resumeClass: new Resume({}),
      orderClass: new Order({}),
      vacancyClass: new Vacancy({}),
      orders: [],
      item: new Resume({}),
      isMounted: false,
      loading: false,
      authError: false,
      response: {},
      activating: false,
      notFoundError: false,
      html_only: 1,
      openContactsListener: this.openContacts,
    }
  },
  methods: {
    get() {
      this.loading = true;
      this.resumeClass.get(this.$route.params.id, {
        rid: this.$route.query.r,
        html_only: this.html_only
      }).then((data) => {
        this.item = data.response;
        if (this.item.contactsHidden)
          this.$store.state.eventBus.$emit('get-user');
        this.loading = false;
        this.authError = false;
      }).catch((data) => {
        this.loading = false;
        this.notFoundError = data?.message === 'Not Found';
        if (data.error.type === '401') this.authError = true;
      })
    },
    remove() {
      this.resumeClass.moveToArchive(this.item.id).then(() => {
        this.$notify({
          type: 'success',
          title: 'Резюме успешно удалено',
          duration: 3000
        });
        if (this.$route.path.split('/').includes('applicant'))
          this.$router.replace('/applicant/resumes');
        else
          this.$router.replace('/hr/resumes');
      })
    },
    openResumeRatesDialog(from = 'resume') {
      this.$refs.resume_rates_dialog.open(from);
    },
    openContacts() {
      this.resumeClass.openContacts(this.item.id || this.$route.params.id).then(() => {
        this.$store.state.eventBus.$emit('get-user');
        this.get();
      }).catch((data) => {
        if (data.error.type === 'unpayed') {
          this.getOrders();
        }
      })
    },
    getOrders(activateLast = false) {
      this.orderClass.get().then((data) => {
        this.orders = data.response.filter(e => e.type).reverse();
        if (activateLast) {
          this.activateOrder();
          this.$router.push({query: {back_link: `/${this.getValue('role', 'company')}/catalog`}}).catch(()=>{});
        }
        else if (this.activeResumeOrders.length) this.$refs.activate_prev_order_dialog.open();
        else if (this.systemParams.audience_survey_passed === 0 && this.audience_survey_closes_count <=3) {
          this.$store.state.eventBus.$emit('open-survey-dialog');
        } else this.openResumeRatesDialog();
      })
    },
    activateOrder() {
      this.activating = true;
      this.orderClass.activate(this.activeResumeOrders[0].id).then(() => {
        this.activating = false;
        this.openContacts();
      }).catch(() => {
        this.activating = false;
      })
    },
    complain() {
      this.$refs.complain_dialog.open({
        type: 'resume',
        complainTarget: `${this.item.name || ''} ${this.item.surname || ''}`,
        id: this.item.id
      });
    },
    addToHRMVacancy() {
      /*TODO перенаправление в HRM на страницу списка вакансий*/
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    systemParams() {
      return this.$store.state.systemParams;
    },
    audience_survey_closes_count() {
      return this.$store.state.audience_survey_closes_count;
    },
    windowWidth() {
      return window.innerWidth;
    },
    schedule() {
      let schedule = '';
      if (this.$store.state.systemParams.schedule_options) {
        this.item.schedule.forEach((s, i) => {
          schedule += i === 0 ? this.$store.state.systemParams.schedule_options[s] : `, ${this.$store.state.systemParams.schedule_options[s]}`;
        })
      }
      return schedule;
    },
    job_type() {
      let job_type = '';
      if (!this.$store.state.systemParams.employment_types) return '';
      this.item.employment_type.forEach((s, i) => {
        job_type += i === 0 ? this.$store.state.systemParams.employment_types[s] : `, ${this.$store.state.systemParams.employment_types[s]}`;
      })
      return job_type;
    },
    activeResumeOrders() {
      let timestampNow = +new Date();
      timestampNow = Math.round(timestampNow / 1000);
      return this.orders.filter(order => order.type.includes('resume') && order.payed === 1
          && order.amount_left > 0 && (order.datetime_to ? order.datetime_to > timestampNow : true));
    },
    lastCreatedRate() {
      return this.$store.state.last_created_rate;
    },
  },
  mounted() {
    this.get();
    this.isMounted = true;
    if (+this.$route.query.payed === 1) {
      this.getOrders(true)
    }
    document.addEventListener("open_resume_contacts", this.openContactsListener);
  },
  beforeDestroy() {
    document.removeEventListener('open_resume_contacts', this.openContactsListener);
  }
}
</script>

<style module lang="css">
.resumeItemPage {
  opacity: 0;
  animation: opacity 1s ease-in-out;
}

.resumeItemPage.activePage {
  opacity: 1;
}

.resumeItemPage .resumeItemPageContent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 48px;
  align-items: flex-start;
  position: relative;
}

.sideContent {
  position: sticky;
  top: 20px;
}

.sideContent .updateDate {
  font-size: var(--small-text);
  color: var(--gray-text);
  display: block;
  margin-bottom: 16px;
}

.sideContentActions {
  position: sticky;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.actionsGroup {
  width: 100%;
  display: flex;
  gap: 8px;
}

.removeButton span {
  display: none;
  color: #FFFFFF;
  margin-left: 10px;
}

.remainingContainer {
  margin-top: 28px;
}
@media screen and (max-width: 1130px) {
  .sideContentActions {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }

  .removeButton {
    width: 100% !important;
  }

  .removeButton span {
    display: inline-block;
  }
}

@media screen and (max-width: 1024px) {
  .resumeItemPage .resumeItemPageContent {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }

  .sideContent {
    flex-basis: 100%;
    width: 100%;
  }

  .sideContentActions {
    grid-template-columns: auto auto 54px;
  }

  .removeButton {
    width: 54px !important;
  }

  .removeButton span {
    display: none;
  }
  .remainingContainer {
    margin-top: 32px;
  }
}

@media screen and (max-width: 575px) {
  .sideContentActions {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }

  .actionsGroup {
    flex-direction: column;
  }

  .actionsGroupMobileRow {
    flex-direction: row;
  }

  .removeButton {
    width: 100% !important;
  }

  .removeButton span {
    display: inline-block;
  }
  .remainingContainer {
    margin-top: 28px;
  }
}
</style>