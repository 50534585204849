<template>
  <div :class="$style.fileThumbnailContainer" @click="openFile">
    <rekroo-button v-if="!hideRemoveBtn"
        @click="$emit('on-remove-btn-click')" :class="$style.removeBtn" icon="close" height="20px" width="20px" color="#FFF"/>
    <div :class="$style.fileThumbnail" :style="`background: ${bg_color || 'var(--light-gray)'}`">
      <div :class="$style.fileBlur"/>
      <img v-if="image_types.includes(file_type)" :class="$style.fileImage" :src="file.url" alt="">
      <span v-else :class="$style.fileType">{{ file_type }}</span>
    </div>
    <div :class="$style.fileUrl">{{file.title}}</div>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
export default {
  name: "FileThumbnail",
  components: {RekrooButton},
  props: ['file', 'bg_color', 'hideRemoveBtn'],
  emits: ['on-remove-btn-click'],
  data() {
    return {
      image_types: ['png', 'jpg', 'jpeg', 'svg']
    }
  },
  computed: {
    file_type() {
      let splittedFile = this.file.url.split('.');
      return splittedFile[splittedFile.length - 1];
    }
  },
  methods: {
    openFile() {
      window.open(this.file.url, '_blank');
    }
  }
}
</script>

<style module lang="css">
.fileThumbnailContainer {
  width: 80px;
  overflow: hidden;
  position: relative;
}
.fileThumbnail {
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.removeBtn {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0;
  z-index: 2;
}

.fileBlur {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: var(--primary);
  opacity: .2;
}
.fileImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.fileType {
  text-transform: uppercase;
}

.fileUrl {
  text-align: center;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>