<template>
  <section :class="style.safetySection">
    <div :class="style.safetySectionHint">
      <div :class="style.safetySectionHintLabel">
        Что мы для этого<br>используем?
      </div>
      <img src="/img/hr_landing/curve_dashed_arrow.svg" alt="" :class="style.safetySectionHintArrow">
    </div>
    <div :class="style.safetySectionHeader">
      <div :class="style.safetyDesc">
        <h1 :class="style.safetySectionTitle"><span>Безопасность и защита.</span><br>Все данные только у вас</h1>
        <p :class="style.safetySectionSubtitle">Мы трепетно относимся к вашим персональным данным — поэтому прилагаем
          все усилия, чтобы обеспечить высокий уровень безопасности и надежности.</p>
      </div>
      <img src="/img/hr_landing/safety_tools_image.png" alt="" :class="style.safetyToolsImage">
    </div>
    <div :class="style.safetyTools">
      <div :class="style.safetyToolOuter" v-for="(tool, i) in safetyTools" :key="i">
        <img src="/img/hr_landing/folder_top.svg" alt="" :class="style.safetyToolTopFolder">
        <div :class="style.safetyTool">
          <div :class="style.safetyToolTitle">{{ tool.title }}</div>
          <div :class="style.safetyToolText">{{ tool.text }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"

export default {
  name: "SafetySection",
  data: ()=>({
    style,
    safetyTools: [
      {
        title: 'SSL-сертификат',
        text: 'Все передаваемые данные между вашим браузером и нашими серверами защищены сертификатом безопасности SSL',
      },
      {
        title: 'HTTPS-соединение',
        text: 'Обмен данных происходит по протоколу HTTPS, что обеспечивает конфиденциальность информации и ее защиту от третьих лиц',
      },
      {
        title: 'Являемся оператором персональных данных',
        text: 'Мы берём на себя ответственность за безопасность ваших данных в соответствии с законодательством',
      },
    ],
  })
}
</script>

<style scoped>

</style>