import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {router} from "@/router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import Maska from "maska";
Vue.use(Maska);

import store from "@/store";
import globalMixin from "@/globalMixin";
import moment from "moment";
Vue.use(ElementUI, {locale});
Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$moment = moment;
moment.locale('ru');
Vue.mixin(globalMixin);
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
