<template>
  <div style="width: 100%;height: 40px" v-if="true"></div>
  <section v-else :class="style.reviewsSection">
    <div :class="style.reviewsSectionHeader">
      <h1 :class="style.reviewSectionTitle" v-html="title"/>
      <swiper-arrows :current-slide="currentSlide" :item-width="itemWidth()" :offset-left="offsetLeft"
                     :max-forward="reviews.length - 3"
                     :prev-button-is-gray="true"
                     @on-click="onArrowClick"
      />
    </div>
    <div :class="style.reviews" ref="reviews">
      <div :class="style.review" v-for="(review, i) in reviews" :key="i" :ref="`review_${i}`">
        <img :src="review.img" alt="" :class="style.reviewAvatar">
        <div :class="style.reviewTitle">{{ review.title }}</div>
        <p :class="style.reviewText">{{ review.text }}</p>
        <span :class="style.reviewName">{{ review.name }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import SwiperArrows from "@/components/SwiperArrows";
export default {
  name: "LandingReviewsSection",
  components: {SwiperArrows},
  props: ['title'],
  data: () => ({
    style,
    currentSlide: 0,
    reviews: [
      {
        img: '/img/hr_landing/review_avatar_1.jpg',
        title: 'HR Director',
        text: 'Наконец все люди всегда в активе и не приходится лихорадочно кого-то искать, перелопачивая почту и записи. Все разложено по полочкам, в профайле стоят даты собеседования, видны сразу все мои комментарии, каждое действие отмечено. Это и правда оказалось очень удобно, спасибо.',
        name: 'Анна Севостьянова',
      },
      {
        img: '/img/hr_landing/review_avatar_2.jpg',
        title: 'HR Manager',
        text: 'Сначала напряглась, когда сказали, что мы будем пробовать новый инструмент, а теперь не понимаю, как жила без него раньше)) Очень систематизирует работу и никакой сложности на самом деле, очень просто и понятно все с первого раза. У нас большая компания и очень много собеседований каждый день, постоянно пользуюсь готовыми шаблонами и практически не трачу времени на подготовку. Супер!!',
        name: 'Наталья Черкашина',
      },
      {
        img: '/img/hr_landing/review_avatar_3.jpg',
        title: 'Freelancer',
        text: 'Крутая штука, заменяет пару ассистентов) Кроме телефона, по сути теперь вообще ничего не нужно, вся работа происходит не выпуская айфон из рук. Вызывать на собеседование тоже можно одним кликом прямо с геотегом и сразу запросить подтверждение, круто! Ну и главное конечно – перенес всю базу за несколько лет и теперь голова не болит, все в облаке и данные никуда не уплывут. Отдельное спасибо за напоминания о комиссионных – минус еще одна головная боль',
        name: 'Евгений Домин',
      },
      {
        img: '/img/hr_landing/review_avatar_4.jpg',
        title: 'Владелец компании',
        text: 'Сначала напряглась, когда сказали, что мы будем пробовать новый инструмент, а теперь не понимаю, как жила без него раньше)) Очень систематизирует работу и никакой сложности на самом деле, очень просто и понятно все с первого раза. У нас большая компания и очень много собеседований каждый день, постоянно пользуюсь готовыми шаблонами и практически не трачу времени на подготовку. Супер!!',
        name: 'Наталья Черкашина',
      },
      {
        img: '/img/hr_landing/review_avatar_5.jpg',
        title: 'Директор по развитию',
        text: 'Крутая штука, заменяет пару ассистентов) Кроме телефона, по сути теперь вообще ничего не нужно, вся работа происходит не выпуская айфон из рук. Вызывать на собеседование тоже можно одним кликом прямо с геотегом и сразу запросить подтверждение, круто! Ну и главное конечно – перенес всю базу за несколько лет и теперь голова не болит, все в облаке и данные никуда не уплывут. Отдельное спасибо за напоминания о комиссионных – минус еще одна головная боль',
        name: 'Евгений Домин',
      },
      {
        img: '/img/hr_landing/review_avatar_6.jpg',
        title: 'Генеральный директор',
        text: 'Наконец все люди всегда в активе и не приходится лихорадочно кого-то искать, перелопачивая почту и записи. Все разложено по полочкам, в профайле стоят даты собеседования, видны сразу все мои комментарии, каждое действие отмечено. Это и правда оказалось очень удобно, спасибо.',
        name: 'Александр Устюгов',
      },
    ],
    offsetLeft: 0
  }),
  computed: {
  },
  methods: {
    itemWidth() {
      return this.$refs.reviews?.firstChild.clientWidth + 20 || 447;
    },
    onArrowClick(params) {
      this.offsetLeft = params.offsetLeft;
      this.currentSlide = params.currentSlide;
      this.$refs.reviews.scrollTo({
            left: this.offsetLeft,
            behavior: 'smooth'
          }
      )
    }
  }
}
</script>

<style scoped>

</style>
