<template>
  <el-skeleton-item :variant="variant" :style="`min-width: ${width}; width: ${width}; height: ${height}; max-width: ${maxWidth}`"/>
</template>

<script>
export default {
  name: "Skeleton",
  props: ['variant', 'width', 'height', 'maxWidth'],
}
</script>

<style scoped>

</style>