<template>
  <div :class="style.vacancies">
    <vacancy-card  v-for="(vacancy, i) in vacancies" :key="i" :vacancy="vacancy" :link="`${link}${vacancy.id}`"/>
  </div>
</template>

<script>
import VacancyCard from "@/components/VacancyCard";
import style from "@/views/landing/styles/landingStyles.module.css"

export default {
  name: "LandingVacancies",
  props: ['vacancies', 'link'],
  components: {VacancyCard},
  data: ()=>({
    style,
  })
}
</script>

<style scoped>

</style>