<template>
  <ul :class="$style.navigation">
    <li :class="[$style.navigationEl, {[$style.active]: activeSection === el.name}]" v-for="(el, i) in navigation"
        @click="onClick(i)"
        :key="i">
      <rekroo-icon :class="$style.navigationElIcon"
                   :icon="el.icon" :color="activeSection === el.name ? 'var(--primary)' : 'var(--gray-text)'"/>
      {{ el.label }}
    </li>
  </ul>
</template>

<script>
import RekrooIcon from "@/components/Icon";
export default {
  name: "SideNavigation",
  components: {RekrooIcon},
  props: ['navigation', 'activeSection', 'sectionPositions'],
  emits: ['link-click'],
  methods: {
    onClick(i) {
      this.$emit('link-click', i);
    }
  }
}
</script>

<style module lang="css">

.navigation {
  max-width: 315px;
  width: 100%;
  padding: 32px;
  background: var(--light-gray);
  border-radius: 16px;
  position: sticky;
  top: 20px;
  z-index: 99;
}

.navigation .navigationEl {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  font-size: var(--subheadline);
  font-weight: 500;
  color: var(--gray-text);
  cursor: pointer;
}

.navigation .navigationEl .navigationElIcon {
  margin-right: 12px;
}

.navigation .navigationEl:last-child {
  margin-bottom: 0;
}

.navigation .navigationEl.active {
  color: var(--primary);
}

@media screen and (max-width: 1024px) {

  .navigation {
    top: 0;
    padding: 20px;
    background: #FFFFFF;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px,1fr));
    border-bottom: 1px solid var(--gray);
    border-radius: 0;
  }

  .navigation .navigationEl {
    flex-direction: column;
    margin-bottom: 0;
    font-size: var(--body);
    text-align: center;
  }

  .navigation .navigationEl .navigationElIcon {
    margin-right: 0;
  }
}

@media screen and (max-width: 575px) {
  .navigation {
    padding: 10px;
  }

  .navigation .navigationEl {
    font-size: var(--tiny-text);
  }
}
</style>