<template>
  <div :class="[$style.vacancySkeleton, style.vacancy]">
    <div :class="style.titleContainer">
      <el-skeleton-item variant="h1"/>
    </div>
    <div :class="style.vacancyHeader">
      <div :class="style.locationContainer">
        <skeleton variant="button" width="120px"/>
        <skeleton variant="button" width="120px"/>
        <skeleton variant="button" width="120px"/>
      </div>
    </div>
    <div :class="[style.subheader, $style.subheaderSkeleton]">
      <skeleton variant="text" width="290px"/>
      <skeleton variant="text" width="290px"/>
    </div>
    <el-skeleton-item variant="p"/>
    <div :class="style.section">
      <div class="headline-3" :class="style.title">Задачи:</div>
      <el-skeleton :rows="4" animated>
        <el-skeleton-item variant="p"/>
      </el-skeleton>
    </div>
    <div :class="style.section">
      <div class="headline-3" :class="style.title">Требования:</div>
      <el-skeleton :rows="4" animated>
        <el-skeleton-item variant="p"/>
      </el-skeleton>
    </div>
    <div :class="style.section">
      <div class="headline-3" :class="style.title">Условия:</div>
      <el-skeleton :rows="4" animated>
        <el-skeleton-item variant="p"/>
      </el-skeleton>
    </div>
  </div>
</template>

<script>
import style from "@/views/company/vacancy/styles/Vacancy.module.css";
import Skeleton from "@/components/skeletons/Skeleton";

export default {
  name: "VacancySkeleton",
  components: {Skeleton},
  data: ()=>({
    style,
  })
}
</script>

<style module lang="css">
.vacancySkeleton {

}
.subheaderSkeleton {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.subheaderSkeleton > * {
  display: block;
}
</style>