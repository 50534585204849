<template>
  <el-dialog :visible.sync="isVisible" width="650px" custom-class="rekroo-dialog apply-vacancy-dialog"
             :center="true">
    <div :class="$style.title">Отклик на вакансию</div>
    <el-form ref="form" :model="cv" :rules="rules">
      <el-form-item v-if="resumes.length" prop="covering_letter" :class="$style.clFormItem">
        <el-input v-model="cv.covering_letter" class="gray-bg" type="textarea" :autosize="{minRows: 6, maxRows: 14}"
                  :minlength="150"
                  placeholder="Расскажите вкратце о себе. Работодатели чаще рассматривают отклики с сопроводительными письмами"
        />
        <template #label>
          <div :class="$style.clCustomLabel">Сопроводительное письмо
            <div :class="[$style.clCustomLabelCounter, $style.clCustomLabelCounterSuccess]"
                 v-if="cv?.covering_letter?.length >= 150">Отлично!
            </div>
            <div :class="$style.clCustomLabelCounter" v-else>{{ cv.covering_letter?.length || 0 }} / 150</div>
          </div>
        </template>
      </el-form-item>
      <el-form-item v-if="resumesLoading" label="Прикрепить резюме" prop="resume">
        <div :class="$style.rowSkeleton">
          <skeleton variant="circle" width="32px" height="32px"/>
          <skeleton variant="text"/>
        </div>
      </el-form-item>
      <el-form-item v-else-if="resumes.length" label="Прикрепить резюме" prop="resume" :class="$style.clFormItem">
        <div :class="$style.document" v-for="resume in resumes" :key="resume.id"
             @click="cv.resume = resume.id">
          <candidate-avatar :img="resume.image" :gender="resume.gender" :circle="true" size="32"/>
          <div :class="$style.resumeTitle">{{ resume.position }}</div>
          <rekroo-icon v-if="cv.resume === resume.id" icon="check" style="margin-left: auto"/>
        </div>
      </el-form-item>
      <el-form-item v-else label="Прикрепить резюме" prop="resume">
        <empty-list-plug :class="$style.emptyResume" title="У вас нет резюме"
                         subtitle="Чтобы откликнуться на вакансию, сначала создайте и опубликуйте резюме"
                         button_text="Перейти к резюме" @onClick="$router.push('/applicant/resumes/create')"/>
      </el-form-item>
      <rekroo-button v-if="resumes.length"
                     :loading="applyLoading"
                     @click="apply" width="100%" height="50px">Откликнуться</rekroo-button>
    </el-form>
  </el-dialog>
</template>

<script>
import {Resume} from "@/api/resume";
import RekrooButton from "@/components/Button";
import Vacancy from "@/api/vacancy";
import CandidateAvatar from "@/components/CandidateAvatar";
import RekrooIcon from "@/components/Icon";
import EmptyListPlug from "@/components/EmptyListPlug";
import Skeleton from "@/components/skeletons/Skeleton";

export default {
  name: "ApplyVacancyDialog",
  components: {Skeleton, EmptyListPlug, RekrooIcon, CandidateAvatar, RekrooButton},
  emits: ['applied'],
  data() {
    let clValidator = (_, value, callback) => {
      if (!value || value.length < 150) {
        callback(new Error('Минимум 150 символов'));
      } else callback();
    };
    return {
      isVisible: false,
      cv: {
        resume: '',
        covering_letter: '',
      },
      resumeClass: new Resume({}),
      vacancyClass: new Vacancy({}),
      resumes: [],
      resumesLoading: false,
      applyLoading: false,
      vacancy_id: '',
      rules: {
        covering_letter: [
          {trigger: 'blur', validator: clValidator},
          {trigger: 'change', validator: clValidator},
        ],
        resume: [
          {required: true, message: 'Прикрепите резюме'},
        ]
      }
    }
  },
  methods: {
    open(data) {
      this.isVisible = true;
      this.cv = { resume: '', covering_letter: ''};
      this.vacancy_id = '';
      setTimeout(()=>{
        this.$refs.form.resetFields();
      })
      this.vacancy_id = data.vacancy_id;
      this.getResumes();
    },
    getResumes() {
      this.resumesLoading = true;
      this.resumeClass.get(null).then((data) => {
        this.resumes = data.response;
        this.resumesLoading = false;
      }).catch(()=>{
        this.resumesLoading = false;
      })
    },
    apply() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.applyLoading = true;
          this.vacancyClass.applyVacancy(this.vacancy_id, this.cv).then(() => {
            this.applyLoading = false;
            this.$notify({
              type: 'success',
              title: 'Отклик успешно отправлен работадателю'
            });
            this.$emit('applied');
            this.isVisible = false;
          }).catch(()=>{
            this.applyLoading = false;
          })
        }
      })
    },
  }
}
</script>

<style module lang="css">
.title {
  margin-bottom: 32px;
  font-size: var(--headline_3);
  font-weight: 500;
}

.document {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid var(--gray);
  cursor: pointer;
}

.document:last-child {
  border-bottom: 0;
}

.resumeTitle {
  margin-left: 12px;
  font-size: var(--body);
  font-weight: 500;
}

.emptyResume {
  margin: 0 auto;
}

.clFormItem {
  margin-bottom: 32px !important;
}

.clFormItem label {
  width: 100%;
  text-align: left !important;
}

.clCustomLabel {
  display: flex;
  flex-grow: 1;
}

.clCustomLabelCounter {
  margin-left: auto;
  color: var(--gray-text);
}

.clCustomLabelCounterSuccess {
  color: var(--bright-green);
}

.rowSkeleton {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
</style>