<template>
  <div :class="[$style.noInternetConnection, {[$style.noInternetConnectionVisible]: isActive}]"
  :style="`background: ${bg_color}`">
    <div :class="$style.noInternetConnectionInner" :style="`color: ${color}`">
      <rekroo-icon v-if="icon" :icon="icon" :color="color" :class="$style.icon" width="18px"/>
      <slot name="icon"/>
      {{ text }}
    </div>
  </div>
</template>

<script>
import RekrooIcon from "@/components/Icon";
export default {
  name: "BottomInfoAlert",
  props: ['text', 'icon', 'bg_color', 'color', 'isActive'],
  components: {RekrooIcon},
}
</script>

<style module lang="css">
.noInternetConnection {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(42px);
  transition: transform .3s linear;
  will-change: transform;
  z-index: 9999;
}
.noInternetConnectionInner {
  max-width: 1320px;
  padding: 0 60px;
  display: flex;
  height: 42px;
  justify-content: center;
  align-items: center;
}
.noInternetConnectionVisible {
  transform: translateY(0);
}
.icon {
  position: relative;
  margin-right: 8px;
}
</style>