<template>
<div class="page" :class="$style.pageInProgress">
  <h1 :class="$style.pageInProgressTitle" v-html="title
  || `Текущая страница находится <br> <span>в разработке</span>`"></h1>
  <p :class="$style.pageInProgressSubtitle">{{ subtitle || 'Здесь скоро появится контент. Следите за обновлениями' }}</p>
  <img src="/img/icons/icon_technical_works.svg" alt="" width="200">
</div>
</template>

<script>
export default {
  name: "PageInProgress",
  props: ['title', 'subtitle']
}
</script>

<style module lang="css">
.pageInProgress {
  margin: 0 auto;
  padding-top: 120px !important;
  padding-bottom: 120px !important;
  text-align: center;
}
.pageInProgressTitle {
  margin-bottom: 28px;
  text-align: center;
}

.pageInProgressTitle span {
  color: var(--bright-blue);
}

.pageInProgressSubtitle {
  margin-bottom: 40px;
  text-align: center;
}
</style>