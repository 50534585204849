<template>
  <el-form :class="$style.filtersForm">
    <el-form-item>
      <div :class="salaryItemStyle.salaryItem">
        <div :class="salaryItemStyle.salary">
          <el-input placeholder="Ставка от" v-model="filters.price_from" :class="salaryItemStyle.salaryFromInput"
                    v-maska="$store.state.maskThousandSeparator" clearable/>
          <el-input placeholder="Ставка до" v-model="filters.price_to" :class="salaryItemStyle.salaryToInput"
                    v-maska="$store.state.maskThousandSeparator" clearable/>
        </div>
      </div>
    </el-form-item>
    <div :class="$style.cityAndReviewsInput">
      <el-form-item>
        <el-autocomplete
            placeholder="Город" v-model="filters.location"
            :fetch-suggestions="fetchSuggestionsLocation"
            clearable
            :trigger-on-focus="false"
        />
      </el-form-item>
      <el-form-item>
        <el-select v-model="filters.scope" placeholder="Отрасль" clearable>
          <el-option
              v-for="(item, i) in $store.state.systemParams.scopes"
              :key="i"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <rekroo-button :class="$style.applyFiltersButton" height="50px" @click="$emit('apply')">Применить
    </rekroo-button>
  </el-form>

</template>

<script>
import RekrooButton from "@/components/Button";
import salaryItemStyle from "@/assets/repeated_styles/SalaryItemStyle.module.css";
import {User} from "@/api/user";

export default {
  name: "FiltersHrSearchForm",
  components: {RekrooButton},
  props: ['filters'],
  emits: ['apply'],
  data: () => ({
    salaryItemStyle,
    userClass: new User({}),
  }),
  methods: {
    fetchSuggestionsLocation(q, cb) {
      this.userClass.suggestions('location', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
  }
}
</script>

<style module lang="css">

.filtersForm {
  display: flex;
  column-gap: 8px;
}

.cityAndReviewsInput {
  display: flex;
  column-gap: 8px;
}

@media screen and (max-width: 1024px) {
  .filtersForm {
    flex-direction: column;
  }

  .filtersForm .cityAndReviewsInput {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
  }

  .filtersForm .applyFiltersButton {
    width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .filtersForm .cityAndReviewsInput {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
    margin-bottom: 16px;
  }
}
</style>