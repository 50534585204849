<template>
  <el-dialog :visible.sync="isVisible" width="650px" custom-class="rekroo-dialog add-response-auction-dialog"
             :center="true">
    <div class="dialog-title" :class="$style.title">Откликнуться на заказ</div>
    <el-form ref="form" :rules="rules" :model="response">
      <el-form-item label="Предполагаемый гонорар">
        <el-input v-model="response.salary" class="gray-bg" placeholder="Сумма в рублях"
        v-maska="$store.state.maskThousandSeparator"/>
      </el-form-item>
      <el-form-item label="Комментарий">
        <el-input v-model="response.message" type="textarea" :autosize="{ minRows: 4, maxRows: 10}"
                  class="gray-bg" placeholder="Расскажите заказчику, почему он должен выбрать именно вас в качестве исполнителя"/>
      </el-form-item>
      <rekroo-button @click="addResponse" :loading="loading"
      width="100%" height="50px">Откликнуться</rekroo-button>
    </el-form>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import {AuctionItem} from "@/api/auction_item";

export default {
  name: "AddResponseDialog",
  components: {RekrooButton},
  emits: ['success'],
  data: () => ({
    auctionClass: new AuctionItem({}),
    isVisible: false,
    rules: {},
    response: {},
    loading: false,
  }),
  methods: {
    open(id) {
      this.response = {};
      this.isVisible = true;
      this.response.id = id;
      setTimeout(() => {
        this.$refs.form.resetFields();
      });
    },
    addResponse() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.auctionClass.addResponse(this.response.id, this.dataToRequest()).then(() => {
            this.$emit('success');
            this.$notify({
              type: 'success',
              message: 'Отклик успешно отправлен!'
            });
            this.isVisible = false;
            this.loading = false;
          }).catch(() => {
            this.loading = false;
          })
        }
      })
    },
    dataToRequest() {
      let data = {...this.response};
      if (data.salary) data.salary = data.salary.replaceAll(' ', '');
      return data;
    }
  }
}
</script>

<style module>
.title {
  margin-bottom: 16px;
}
</style>