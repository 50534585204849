<template>
  <transition name="fade">
    <router-view :key="$route.params.id"/>
  </transition>
</template>

<script>
export default {
  name: "ResumeView"
}
</script>

<style scoped>

</style>