<template>
  <div :class="$style.subscriptionContainer">
    <h1 :class="$style.subscriptionTitle">Выберите тарифный план доступа&nbsp;к&nbsp;базе&nbsp;резюме</h1>
    <div :class="$style.subscriptionHint">Воспользуйтесь полным доступом к резюме соискателей, просматривайте <br> любые
      контакты без ограничений в их количестве.
    </div>
    <div :class="$style.rates" v-if="!rates.length">
      <rate-skeleton v-for="i in 3" :key="i"/>
    </div>
    <div v-else :class="$style.rates" ref="resume_rates_list">
      <div :class="$style.rate" v-for="(rate, i) in rates" :key="i">
        <div :class="$style.title">{{ rate.title }}</div>
        <div :class="$style.rateHeader">
          <div :class="$style.duration" v-if="i > 0">{{
             rate.hasOwnProperty('subtitle') ? rate.subtitle :
                 `${rate.period / 24 / 3600} ${getNoun(rate.period / 24 / 3600, 'день', 'дня', 'дней')}` }}
          </div>
          <div :class="$style.discount" v-if="i && Math.round(((rates[1].price / rates[1].amount )/ (rate.price / rate.amount)) * 100) - 100 > 0">
            -{{ Math.round(((rates[1].price / rates[1].amount )/ (rate.price / rate.amount)) * 100) - 100 }}%
          </div>
        </div>
        <h1 :class="$style.ratePrice">{{ thousandSeparator(rate.price) }} ₽</h1>
        <div :class="$style.rateTags" v-if="rate.labels">
          <tag v-for="(label, i) in rate.labels" :key="i" :text="label" :class="$style.rateAmount"/>
        </div>
        <tag v-else :text="`${rate.amount} ${getNoun(rate.amount, 'контакт', 'контакта', 'контактов')}`" :class="$style.rateAmount"/>
        <div :class="$style.ratePriceHint" v-if="rate.one_day_price">Стоимость 1 дня:
          {{ thousandSeparator(rate.one_day_price) }} ₽
        </div>
        <rekroo-button :loading="order.key === rate.key ? loading : false"
                       :disabled="order.key !== rate.key && loading"
            width="100%" :class="$style.subscribeButton" @click="createOrder(rate)">Выбрать
        </rekroo-button>
      </div>
    </div>
  </div>
</template>
<script>
import RekrooButton from "@/components/Button";
import Tag from "@/components/Tag";
import {Order} from "@/api/order";
import RateSkeleton from "@/components/skeletons/RateSkeleton";

export default {
  name: "ResumeRates",
  components: {RateSkeleton, Tag, RekrooButton},
  props: ['redirect_url', 'from'],
  emits: ['on-create', 'close'],
  data: () => ({
    orderClass: new Order({}),
    order: new Order({}),
    loading: false,
  }),
  methods: {
    createOrder(rate) {
      if ( this.systemParams.audience_survey_passed === 0 && this.audience_survey_closes_count <= 3 && this.from !== 'survey' ) {
        this.$emit('close');
        this.$store.state.eventBus.$emit('open-survey-dialog');
      } else {
        this.$store.commit('setLastCreatedRate', rate);
        this.order = {...rate};
        this.loading = true;
        this.orderClass.create({type: this.order.key,
          redirect_url:`${this.redirect_path}${this.redirect_url || `/${this.getValue('role', 'company')}/catalog`}`}).then((data) => {
          this.loading = false;
          this.$router.push({
            path: `/order/${data.response.id}`,
          });
          this.$emit('on-create');
        }).catch((data) => {
          this.loading = false;
          if (data.error && data.error.type === 'bill_email') {
            this.$store.state.eventBus.$emit('open-email-dialog');
          }
        })
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    systemParams() {
      return this.$store.state.systemParams;
    },
    audience_survey_closes_count() {
      return this.$store.state.audience_survey_closes_count;
    },
    rates() {
      let rates = [];
      if (this.$store.state.systemParams.productTypes) {
        let keys = Object.keys(this.$store.state.systemParams.productTypes)
            .filter(key => this.$store.state.systemParams.productTypes[key].type === 'resume');
        keys.forEach(key => {
          this.$store.state.systemParams.productTypes[key].key = key;
          rates.push(this.$store.state.systemParams.productTypes[key]);
        })
      }
      return rates;
    },
    lastCreatedRate() {
      return this.$store.state.last_created_rate;
    },
    redirect_path() {
      return this.$store.state.redirect_path;
    }
  },
  created() {
    this.$store.state.eventBus.$on('success-auth', ()=>{
      if (this.lastCreatedRate.type.includes('resume')) this.createOrder(this.order);
    });
    this.$store.state.eventBus.$on('user-email-added', ()=>{
      if (this.lastCreatedRate.type.includes('resume')) this.createOrder(this.order);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-email-added');
  }
}
</script>

<style module lang="css">
.subscriptionContainer {
  padding-top: 56px;
  max-width: 874px;
  width: 100%;
  margin: 0 auto;
}

.subscriptionContainer .subscriptionTitle {
  margin-bottom: 24px;
  text-align: center;
}

.subscriptionContainer .subscriptionHint {
  margin-bottom: 36px;
  font-size: var(--body);
  text-align: center;
  line-height: 144%;
}

.subscriptionContainer .rates {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 20px;
}

.subscriptionContainer .rates .rate {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 37px 32px 32px;
}

.subscriptionContainer .rates .rate .rateHeader {
  height: 42px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.subscriptionContainer .rates .rate .title {
  font-size: var(--subheadline);
  font-weight: 500;
  margin-bottom: 16px;
}

.subscriptionContainer .rates .rate .rateHeader .duration {
  font-size: 22px;
  font-weight: 600;
}

.rateAmount {
  margin-bottom: 36px;
}
.discount {
  height: 42px;
  width: fit-content;
  padding: 4px 8px;
  font-size: var(--small-text);
  font-weight: var(--body-medium-weight);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--yellow);
  border-radius: 8px;
  margin-bottom: 8px;
}

.subscriptionContainer .rates .rate .ratePrice {
  font-size: 38px;
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
}

.subscriptionContainer .rates .rate .ratePriceHint {
  margin-top: 4px;
  font-size: var(--small-text);
}

.rateTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.subscriptionContainer .rates .rate .subscribeButton {
  margin-top: auto;
}

@media screen and (max-width: 1024px) {
  .subscriptionContainer {
    padding-top: 60px;
  }

  .subscriptionContainer .subscriptionTitle {
    margin-bottom: 20px;
  }

  .subscriptionContainer .subscriptionHint {
    margin-bottom: 28px;
    font-size: var(--small-text);
  }

  .subscriptionContainer .rates {
    grid-gap: 16px;
  }

  .subscriptionContainer .rates .rate {
    padding: 26px 20px 20px;
  }

  .subscriptionContainer .rates .rate .rateHeader {
    height: 33px;
    margin-bottom: 8px;
  }

  .subscriptionContainer .rates .rate .title {
    margin-bottom: 12px;
  }

  .subscriptionContainer .rates .rate .rateHeader .duration {
    font-size: var(--subheadline);
  }

  .subscriptionContainer .rates .rate .rateHeader .discount {
    height: 33px;
    padding: 6px 10px;
  }
}

@media screen and (max-width: 575px) {
  .subscriptionContainer {
    padding-top: 44px;
  }

  .subscriptionContainer .subscriptionTitle {
    margin-bottom: 16px;
    font-size: 18px;
  }

  .subscriptionContainer .rates {
    grid-template-columns: 1fr;
  }

  .subscriptionContainer .rates .rate {
    padding: 28px;
    height: fit-content;
  }

  .subscriptionContainer .rates .rate .rateHeader {
    height: 46px;
  }

  .subscriptionContainer .rates .rate .rateHeader .duration {
    font-size: 22px;
  }

  .subscriptionContainer .rates .rate .rateHeader .discount {
    height: 46px;
    padding: 10px;
  }

  .subscriptionContainer .rates .rate .ratePrice {
    font-size: 48px;
  }

  .subscriptionContainer .rates .rate .ratePriceHint {
    font-size: var(--headline_3);
    margin-top: 8px;
  }

  .subscriptionContainer .rates .rate .subscribeButton {
    margin-top: 20px;
  }

  .rateAmount {
    margin-bottom: 20px;
  }

}
</style>