<template>
  <section :class="style.candidateListSection" id="candidateListSection">
    <slot name="before"></slot>
    <div :class="style.candidateImagesMobile">
      <img src="/img/hr_landing/candidate_1.jpg" alt="" :class="style.candidateImg">
      <img src="/img/hr_landing/candidate_2.jpg" alt="" :class="style.candidateImg">
      <img src="/img/hr_landing/candidate_3.jpg" alt="" :class="style.candidateImg">
      <img src="/img/hr_landing/candidate_4.jpg" alt="" :class="style.candidateImg">
      <div :class="style.candidatesMore">3916+</div>
    </div>
    <h1 :class="style.candidateListSectionTitle">
      <div :class="style.candidateImages">
        {{title_1}}
        <img src="/img/hr_landing/candidate_1.jpg" alt="" :class="style.candidateImg">
        <img src="/img/hr_landing/candidate_2.jpg" alt="" :class="style.candidateImg">
        <img src="/img/hr_landing/candidate_3.jpg" alt="" :class="style.candidateImg">
        <img src="/img/hr_landing/candidate_4.jpg" alt="" :class="style.candidateImg">
        <div :class="style.candidatesMore">3916+</div>
      </div>
      <span :style="`color: ${title_2_color || 'var(--primary)'}`">{{title_2}}</span>
    </h1>
    <p :class="style.candidateListSectionSubtitle" v-html="subtitle"/>
    <router-link :class="style.listImageContainer" :to="link">
      <img src="/img/hr_landing/candidate_list.jpg" alt="" :class="style.listImage">
      <img src="/img/hr_landing/icon_cursor.svg" alt="" :class="style.cursor">
    </router-link>
    <slot></slot>
  </section>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"

export default {
  name: "LandingCandidatesDatabaseSection",
  props: ['title_1', 'title_2', 'subtitle', 'title_2_color', 'link'],
  data: () =>({
    style,
  })
}
</script>

<style scoped>

</style>