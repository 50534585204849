<template>
<div :class="$style.roleSelectionContainer">
  <div :class="$style.authTitle" class="headline-3">Войти как</div>
  <div :class="$style.authOptions">
    <button v-for="(label, key) in $store.state.roles" :key="key"
        @click="selectRole(key)"
            :class="[$style.authOptionButton, {[$style.selected]: role === key}]">{{label}}</button>
  </div>
  <rekroo-button v-if="role" width="100%" :class="$style.authForwardButton" height="50px" @click="next">Далее</rekroo-button>
</div>
</template>

<script>
import RekrooButton from "@/components/Button";
export default {
  name: "RoleSelection",
  props: ['role'],
  components: {RekrooButton},
  emits: ['on-role-select', 'select-role'],
  data: ()=>({
    localRole: ''
  }),
  methods: {
    selectRole(role) {
      this.localRole = role;
      if (this.role) this.$emit('select-role', role);
      else {
        this.$emit('select-role', role);
        this.next()
      }
    },
    next() {
      if (this.role) this.localRole = this.role;
      this.$emit('on-role-select', this.localRole);
    }
  },
}
</script>

<style module lang="css">
.roleSelectionContainer {}
.authTitle {
  text-align: center;
  margin-bottom: 28px;
}

.authOptions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  margin-bottom: 24px;
}

.authOptions .authOptionButton {
  height: 83px;
  padding: 0 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DADBDD;
  border-radius: 8px;
  background: #FFFFFF;
}

.authOptions .authOptionButton.selected {
  border: 1px solid var(--green-active);
}

.authForwardButton {
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .authOptions {
    grid-template-columns: 1fr;
    row-gap: 6px;
  }
}
</style>