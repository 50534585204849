<template>
  <div :class="[$style.remainingContainer, {[$style.oneRemainingContainer]: (systemParams.resumeContactsLeft / systemParams.resumeContactsTotal) <= 0.2}]">
    <div :class="$style.remainingHeader">
      <div :class="$style.remainingHeaderLabel">Осталось контактов
        <el-tooltip content="1 открытие резюме = 1 контакт" effect="light">
        <div :class="$style.question">?</div>
      </el-tooltip>
      </div>
      <div :class="$style.remainingCount">{{systemParams.resumeContactsLeft}}</div>
    </div>
    <div :class="[$style.progressBarOuter, $style.progressBar]">
      <div :class="[$style.progressBar, $style.progressBarInner]"
      :style="`width: ${(systemParams.resumeContactsLeft / systemParams.resumeContactsTotal)*100}%`"></div>
    </div>
    <rekroo-button @click="$emit('open-resume-rates')"
        :color="(systemParams.resumeContactsLeft / systemParams.resumeContactsTotal) <= 0.2 ? 'var(--bright-yellow)' : `var(--green-active)`" width="100%">Купить еще контакты</rekroo-button>
  </div>
</template>

<script>
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
export default {
  name: "ItemsRemainingBlock",
  components: {RekrooButton, RekrooIcon},
  emits: ['open-resume-rates'],
  computed: {
    systemParams() {
      return this.$store.state.systemParams;
    }
  }
}
</script>

<style module lang="css">
.remainingContainer {
  display: block;
  padding: 28px 32px;
  background: rgba(57, 198, 113, 0.1);
  border-radius: 16px;
}

.oneRemainingContainer.remainingContainer {
  background: var(--light-gray);
}

.remainingHeader, .remainingHeaderLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remainingHeader {
  margin-bottom: 16px;
  font-size: var(--subheadline);
}

.question {
  margin-left: 6px;
  background: #FFFFFF;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.progressBar {
  height: 10px;
  width: 100%;
  border-radius: 20px;
}

.progressBarOuter {
  margin-bottom: 20px;
  background: #FFFFFF;
}

.progressBarInner {
  background: var(--green-active);
}

.oneRemainingContainer .progressBarInner {
  background: var(--bright-yellow);
}

</style>