<template>
  <div id="app">
    <TheContainer>
      <template #default>
        <TheHeader @on-success-auth="getUser" ref="header" :user-loading="userLoading"/>
        <TheSubheader v-if="['applicant', 'company', 'hr'].includes($route.path.split('/')[1])"/>
        <!--        <div v-else-if="['applicant', 'company', 'hr'].includes($route.path.split('/')[1])" :class="$style.subheaderSkeleton"/>-->
        <!--        <transition name="fade">-->
        <router-view/>
        <!--        </transition>-->
        <TheFooter/>
        <InfoDialog ref="info_dialog"/>
        <SubscribeDialog ref="subscribe_dialog"/>
        <ResumeRatesDialog ref="resume_rates_dialog" :redirect_url="redirect_url"/>
        <DialogAudienceSurvey ref="dialog_audience_survey" @open-resume-rates="openResumeRates"/>
        <div v-if="showBackTop" :class="$style.backToTop" @click="scrollToTop">
          <rekroo-icon icon="arrow-forward"/>
        </div>
      </template>
    </TheContainer>
    <bottom-info-alert :is-active="!onLine" text="Нет интернет соединения" color="#FFF"
                       bg_color="var(--bright-blue)">
      <template #icon>
        <rekroo-icon icon="remote" color="#FFF" :class="$style.iconNoConnection"/>
      </template>
    </bottom-info-alert>
    <bottom-info-alert :is-active="bad_connection" text="Плохое интернет-соединение. Повторите позже" icon="warning"
                       color="var(--primary)"
                       bg_color="var(--yellow)"/>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import TheSubheader from "@/components/TheSubheader";
import TheContainer from "@/components/TheContainer";
import {User} from "@/api/user";
import InfoDialog from "@/dialogs/InfoDialog";
import SubscribeDialog from "@/dialogs/SubscribeDialog";
import BottomInfoAlert from "@/components/BottomInfoAlert";

export default {
  name: 'App',
  components: {
    ResumeRatesDialog,
    DialogAudienceSurvey,
    RekrooIcon,
    BottomInfoAlert,
    SubscribeDialog,
    InfoDialog,
    TheSubheader,
    TheFooter,
    TheHeader,
    TheContainer
  },
  data: () => ({
    deviceWidth: '',
    activeWidth: '',
    userClass: new User(),
    onLine: navigator.onLine,
    userLoading: false,
    bad_connection: false,
    scrollTop: 0,
    redirect_url: ''
  }),
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    getUser() {
      this.userLoading = true;
      this.userClass.get('me').then((data) => {
        if (data.response.company_info && data.response.company_info.link) {
          data.response.company_info.link = data.response.company_info.link.replace('https://', '');
        }
        this.$store.commit('setUser', data.response);
        this.$store.commit('setSystemParams', data.systemParams);
        this.$store.commit('setUserCounts', data.counts);
        this.$store.state.eventBus.$emit('user-received');
        this.userLoading = false;
        if (['company', 'hr'].includes(this.getValue('role', 'company')) && this.systemParams.audience_survey_passed === 0 && this.audience_survey_closes_count <= 3) {
          this.$refs.dialog_audience_survey.open();
          setTimeout(() => {
            this.$refs.dialog_audience_survey.open();
          }, 45000);
        }
      }).catch((data) => {
        this.userLoading = false;
        this.$store.commit('setSystemParams', data.systemParams);
        this.$store.state.eventBus.$emit('user-received');
        if (['company', 'hr'].includes(this.getValue('role', 'company')))
          setTimeout(() => {
            this.$refs.dialog_audience_survey.open();
          }, 45000);
      })
    },
    updateOnlineStatus(e) {
      const {type} = e
      this.onLine = type === 'online';
    },
    showBadConnection() {
      this.bad_connection = true;
      setTimeout(() => {
        this.bad_connection = false
      }, 3000)
    },
    openResumeRates(redirect_url) {
      this.redirect_url = redirect_url;
      this.$refs.resume_rates_dialog.open('survey');
    }
  },
  computed: {
    showBackTop() {
      return this.scrollTop > 500;
    },
    user() {
      return this.$store.state.user;
    },
    audience_survey_closes_count() {
      return this.$store.state.audience_survey_closes_count;
    },
    systemParams() {
      return this.$store.state.systemParams;
    }
  },
  mounted() {
    this.deviceWidth = window.innerWidth + 'px';
    this.activeWidth = document.querySelector('.header').clientWidth + 'px';
    document.body.setAttribute('style', `--device-width: ${this.deviceWidth}; --active-width: ${this.activeWidth}`);
    this.getUser();

    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus);
    this.$store.commit('setRedirectPath', `${this.$store.state.isTest ? 'http://' : 'https://'}${window.location.host}`);
    this.$store.commit('setAudienceCloseCount', this.getValue('audience_survey_closes_count', 0));
  },
  created() {
    this.$store.state.eventBus.$on('api-error', (errorStr) => {
      this.$refs.info_dialog.open({error: errorStr, type: 'error'});
    });
    this.$store.state.eventBus.$on('auth-error', (data) => {
      this.$refs.header.openAuthDialog(data);
    })
    this.$store.state.eventBus.$on('open-email-dialog', () => {
      this.$refs.subscribe_dialog.open();
    })
    this.$store.state.eventBus.$on('get-user', () => {
      this.getUser();
    });
    this.$store.state.eventBus.$on('api-timeout', () => {
      this.showBadConnection();
    });
    this.$store.state.eventBus.$on('open-survey-dialog', () => {
        this.$refs.dialog_audience_survey.open();
    });
    document.addEventListener('scroll', () => {
      this.scrollTop = window.scrollY;
    });
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }
}
import '@/assets/styles.css';
import '@/assets/SFProDisplay/stylesheet.css';
import '@/assets/icons/style.css';
import "@/assets/repeated_styles/table_styles/table_styles.css";
import RekrooIcon from "@/components/Icon";
import DialogAudienceSurvey from "@/dialogs/survey/DialogAudienceSurvey";
import ResumeRatesDialog from "@/dialogs/ResumeRatesDialog";
</script>
<style module lang="css">
.subheaderSkeleton {
  height: 58px;
}

.iconNoConnection {
  position: relative;
  margin-right: 8px;
}

.iconNoConnection:after {
  position: absolute;
  content: ' ';
  top: 45%;
  height: 2px;
  width: 90%;
  background: #FFFFFF;
  display: block;
  transform: rotate(45deg);
}

.backToTop {
  position: fixed;
  bottom: 32px;
  right: 60px;
  z-index: 999;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--gray);
  transform: rotate(-90deg);
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .backToTop {
    right: 48px;
    width: 48px;
    height: 48px;
  }

  .subheaderSkeleton {
    height: 50px;
  }
}

@media screen and (max-width: 575px) {
  .backToTop {
    right: 16px;
  }
}
</style>
