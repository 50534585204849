<template>
  <section :class="style.hrmSection">
    <h1 :class="style.hrmSectionTitle">Автоматизация рутины<br>
      с помощью удобной <span>HRM-системы</span></h1>
    <div class="swiper swiper-buttons" :class="style.hrmSectionTabs">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(tab, i) in hrmTabs" :key="i" style="width: auto !important;">
          <rekroo-button :color="hrmActiveTab === i ? 'rgba(206, 217, 227, 0.6)' : '#FFF'"
                         :text_color="hrmActiveTab === i ? 'var(--bright-blue)' : 'var(--gray-text)'"
                         @click="changeSlide(i)"
          >{{ tab.name }}
          </rekroo-button>
        </div>
      </div>
    </div>
    <div class="swiper swiper-content">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(tab, i) in hrmTabs" :key="i">
          <div :class="style.hrmSectionTabContent" class="hrm-section-tabs">
            <div :class="style.tabDescription">
              <div :class="style.tabDescriptionTitle">{{ tab.title }}</div>
              <div :class="style.tabDescriptionText">
                <p v-for="(text, j) in tab.text" :key="j">{{ text }}</p>
              </div>
              <rekroo-button width="100%" height="50px" :class="style.tabDescriptionButton"
                             icon="arrow-bottom" icon_pos="end"
                             icon_style="transform: rotate(-90deg); margin-left: 15px;"
                             icon_color="#FFF" @click="openHRM"
              >Перейти к HRM-системе
              </rekroo-button>
            </div>
            <img :src="tab.img" alt="" :class="style.hrmImage">
          </div>
        </div>
      </div>
      <div :class="style.indicators">
        <dot v-for="(item, i) in hrmTabs" :key="i" :color="i === hrmActiveTab ? 'var(--bright-blue)' : 'var(--blue)'"
             size="12" @click="changeSlide(i)" :class="style.indicatorDot"/>
      </div>
      <rekroo-button width="100%" height="50px" :class="style.notDesktopHRMButton"
                     icon="arrow-bottom" icon_pos="end" icon_style="transform: rotate(-90deg); margin-left: 15px;"
                     icon_color="#FFF" @click="openHRM"
      >Перейти к HRM-системе
      </rekroo-button>
    </div>
    <slot></slot>
  </section>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooButton from "@/components/Button";
import Dot from "@/components/Dot";
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper.css';

export default {
  name: "HRMDescriptionSection",
  components: {Dot, RekrooButton},
  data: () => ({
    style,
    hrmActiveTab: 0,
    hrmTabs: [
      {
        name: 'Импорт резюме',
        title: 'Загружайте базы\n' +
            'резюме из папки или со сторонних сервисов с автозаполнением полей',
        text: ['За раз можно загрузить до 500 штук. с ИИ, который автозаполняет поля (имя, фамилию, должность и тд).',
          'Все резюме сохраняются, по ним работает поиск. Вся ваша база резюме в одном месте с возможностью быстро найти прошлого кандидата и посмотреть историю взаимодействия'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_1.png',
      },
      {
        name: 'Аналитика и статистика',
        title: 'Удобная аналитика\n' +
            'и статистика ',
        text: ['Система Rekroo собирает данные по вакансиям, отдельным кандидатам, финансам, эффективности рекрутинга и коэффициентах принятия офферов в удобные графики и диаграммы.',
          'Вам больше не придется сводить их вручную в Excel.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_2.png',
      },
      {
        name: 'Сценарии собеседований',
        title: 'Готовые или авторские скрипты для собеседований',
        text: ['Используйте отдельные шаблоны по всем ключевым вакансиям.',
          'Вам больше не нужно тратить время на составление вопросов перед собеседованием – просто добавьте свои комментарии к готовому сценарию.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_3.png',
      },
      {
        name: 'Воронка вакансий',
        title: 'Воронка вакансий,\n' +
            'где нельзя ничего забыть',
        text: ['Отслеживайте сколько в среднем времени ушло на закрытие вакансии, сколько было кандидатов на каждом этапе.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_4.png',
      },
      {
        name: 'Полная база кандидатов',
        title: 'Кандидаты всегда в поле зрения',
        text: ['Оставляйте оценки и комментарии и возвращайтесь к ним в любой момент.',
          'Подробные профайлы, история взаимодействия, добавление в избранное, поиск по имени и вакансии, контроль выхода на работу – все как на ладони.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_5.png',
      }
    ],
    swiper: null,
    swiperButtons: null,
  }),
  methods: {
    openHRM() {
      if (this.$store.state.user.id) window.open('https://web.rekroo.org', '_blank');
      else this.$store.state.eventBus.$emit('auth-error');
    },
    changeSlide(i) {
      this.hrmActiveTab = i;
      this.swiper.slideTo(i);
      this.swiperButtons.slideTo(i);
    }
  },
  mounted() {
    this.swiper = new Swiper('.swiper.swiper-content', {
      slidesPerView: 1,
      spaceBetween: 20,
      on: {
        activeIndexChange: (swiper) => {
          this.changeSlide(swiper.activeIndex)
        }
      }
    });
    this.swiperButtons = new Swiper('.swiper.swiper-buttons', {
      slidesPerView: 'auto',
      spaceBetween: 20,
      on: {
        activeIndexChange: (swiper) => {
          this.changeSlide(swiper.activeIndex)
        }
      }
    })
    this.swiper.init();
    this.swiperButtons.init();
  }
}
</script>

<style scoped>

</style>