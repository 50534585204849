<template>
  <el-dialog :visible.sync="isVisible" width="650px"
             custom-class="rekroo-dialog success-dialog" :center="true">
    <div :class="$style.successDialogInner">
      <div :class="$style.iconContainer">
        <rekroo-icon icon="check" color="var(--primary)"/>
      </div>
      <div :class="$style.successDialogTitle">{{ title }}</div>
      <p :class="$style.successDialogSubtitle">{{ subtitle }}</p>
      <img src="/img/icons/icon_empty_list.svg" alt="" style="margin-bottom: 40px">
      <rekroo-button v-if="actionButtonText" type="gray" width="100%" text_color="var(--primary)" height="50px"
                     @click="onAction"
      >
        {{ actionButtonText }}
      </rekroo-button>
      <rekroo-button width="100%" style="margin-top: 10px"  height="50px" @click="onConfirm"
      >{{ confirmButtonText || 'Готово' }}</rekroo-button>
    </div>
  </el-dialog>
</template>

<script>
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";

export default {
  name: "SuccessDialog",
  components: {RekrooButton, RekrooIcon},
  props: ['title', 'subtitle', 'actionButtonText', 'confirmButtonText'],
  emits: ['confirm', 'action'],
  data: () => ({
    isVisible: false,
  }),
  methods: {
    open() {
      this.isVisible = true;
    },
    onAction() {
      this.$emit('action');
      this.isVisible = false;
    },
    onConfirm() {
      this.$emit('confirm');
      this.isVisible = false;
    }
  }
}
</script>

<style module lang="css">
.successDialogInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 120px;
}

.iconContainer {
  margin-bottom: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
}

.successDialogTitle {
  margin-bottom: 14px;
  font-size: var(--headline_3);
  font-weight: 600;
}

.successDialogSubtitle {
  max-width: 325px;
  margin-bottom: 20px;
}
</style>