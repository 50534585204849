<template>
  <el-dialog width="426px" :visible.sync="isVisible" custom-class="rekroo-dialog about-hr-form-dialog" :center="true"
             @close="onClose">
    <div :class="$style.surveyGreeting" v-if="step === 0">
      <img src="/img/icons/icon_survey_hands.svg" alt="" :class="$style.surveyIcon">
      <div :class="$style.surveyGreetingTitle">Познакомимся?</div>
      <div :class="$style.surveyGreetingSubtitle">Расскажите о себе — получите <strong>5 резюме бесплатно</strong></div>
    </div>
    <div v-else :class="$style.stepsContainer">
      <div v-if="step < 5" :class="$style.stepsNavigation">
        <dot :color="i <= step ? `var(--primary)` : `var(--light-gray)`" v-for="i in 4" :key="i" size="10"/>
      </div>
      <div :class="$style.stepTitle">{{ titles[step - 1] }}</div>
      <div class="swiper survey-swiper swiper-no-swiping">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <!--            v-if="step === 1" -->
            <el-form :model="form" :rules="rules" style="width: 100%; padding: 0 1px" ref="form">
              <el-form-item label="Имя" required prop="Имя">
                <el-input v-model="form['Имя']" class="gray-bg" autocomplete="name" @input="gtmEvent('popup_formstart')"/>
              </el-form-item>
              <el-form-item label="Компания">
                <el-input v-model="form['Компания']" class="gray-bg"/>
              </el-form-item>
              <el-form-item label="Должность">
                <el-autocomplete v-model="form['Должность']" class="gray-bg"
                                 :fetch-suggestions="fetchSuggestionsPos"
                                 clearable
                                 :trigger-on-focus="false"/>
              </el-form-item>
              <!--              <rekroo-button @click="forwardToSecondStep" width="100%" height="50px">Далее
                            </rekroo-button>-->
            </el-form>
          </div>
          <div class="swiper-slide">
            <div :class="$style.rolesContainer">
              <div @click="form['Роль'] = option; tempRole = '';showRoleError = false"
                   :class="[$style.roleOption, {[$style.roleOptionSelected]: form['Роль'] === option}]"
                   v-for="(option, i) in roleOptions" :key="i">{{ option }}
                <rekroo-icon icon="check" width="16px" :style="`opacity: ${form['Роль'] === option ? 1 : 0}`"
                             color="var(--green-active)"/>
              </div>
              <el-input v-model="tempRole" placeholder="Другое" :class="tempRole.length ? 'green-bg' : 'gray-bg'"
                        @input="form['Роль'] = ''; showRoleError = false" style="padding: 0 1px">
                <template #suffix>
                  <rekroo-icon icon="check" width="16px" :style="`opacity: ${tempRole.length ? 1 : 0}`"
                               color="var(--green-active)"/>
                </template>
              </el-input>
              <div v-if="showRoleError" :class="$style.errorText">Выберите один из вариантов</div>
            </div>
          </div>
          <div class="swiper-slide">
            <!-- v-else-if="step === 3"      -->
            <el-form ref="form_third_step" :model="form" :rules="rules" style="padding: 0 1px">
              <el-form-item label="Сколько сотрудников вы обычно ищете?" required
                            prop="Сколько сотрудников вы обычно ищете?">
                <el-select v-model="form['Сколько сотрудников вы обычно ищете?']" class="gray-bg">
                  <el-option v-for="(item, i) in employee_search_count_options" :key="i" :label="item" :value="item"/>
                </el-select>
              </el-form-item>
              <el-form-item label="Как часто вы ищете сотрудников?" required prop="Как часто вы ищете сотрудников?">
                <el-select v-model="form['Как часто вы ищете сотрудников?']" class="gray-bg">
                  <el-option v-for="(item, i) in employee_frequency_search_options" :key="i" :label="item"
                             :value="item"/>
                </el-select>
              </el-form-item>
              <el-form-item v-if="form['Компания']" label="Сколько сотрудников в вашей компании?" required
                            prop="Сколько сотрудников в вашей компании?">
                <el-select v-model="form['Сколько сотрудников в вашей компании?']" class="gray-bg">
                  <el-option v-for="(item, i) in employees_in_company_options" :key="i" :label="item" :value="item"/>
                </el-select>
              </el-form-item>
              <!--              <rekroo-button :class="$style.buttonForward" :disabled="!form.role && !tempRole" width="100%" height="50px" @click="forwardToFourStep">
                              Далее</rekroo-button>-->
            </el-form>
          </div>
          <div class="swiper-slide">
            <!--      v-else-if="step === 4"-->
            <div :class="$style.lastStepContainer">
              <p :class="$style.surveyGreetingSubtitle">Пожалуйста, поделитесь дополнительными комментариями или
                предложениями, которые могли бы помочь нам улучшить наш сервис</p>
              <el-form :model="form" :rules="rules" ref="last_step_form">
                <el-form-item prop="Отзыв" required>
                  <el-input v-model="form['Отзыв']" type="textarea"
                            placeholder="Что вам нравится или не нравится в Rekroo? Что хотелось бы добавить?"
                            :autosize="{ minRows: 6, maxRows: 15}" class="gray-bg" style="padding: 0 1px"/>
                </el-form-item>
                <el-form-item style="position: relative;">
                  <div :class="$style.checkboxContainer">
                    <el-checkbox v-model="form.privacy" name="privacy"/>
                    <div :class="$style.privacy" @click="form.privacy = !form.privacy; privacyError = !form.privacy">
                      Я соглашаюсь с обработкой моих данных в соответствии с
                      <router-link to="/policy"><strong>политикой конфиденциальности</strong></router-link>
                    </div>
                  </div>
                  <div v-if="privacyError" :class="[$style.errorText, $style.errorPrivacyText]">Обязательное поле</div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="swiper-slide">
            <div :class="$style.surveyGreeting">
              <img src="/img/icons/icon_complete_survey.svg" alt="" :class="$style.surveyIcon">
              <div :class="$style.surveyGreetingTitle">5 бесплатных резюме уже у вас!</div>
              <div :class="$style.surveyGreetingSubtitle">Выбирайте лучших кандидатов в базе резюме, открывайте контакты
                и приглашайте их на собеседование
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.surveyGreetingActions">
      <rekroo-button v-if="step === 5" @click="complete" width="100%" height="50px" style="white-space: break-spaces">
        {{ user.id ? 'Отлично' : 'Авторизуйтесь, чтобы воспользоваться преимуществами' }}
      </rekroo-button>
      <rekroo-button v-else-if="step === 4" width="100%" height="50px" :type="isDisabled ? 'disabled' : 'primary'"
                     @click="forwardToComplete" :loading="loading">Получить 5 бесплатных резюме
      </rekroo-button>
      <rekroo-button v-else width="100%" height="50px" @click="forward"
                     :type="isDisabled ? 'disabled' : 'primary'">Далее
      </rekroo-button>
      <rekroo-button v-if="step === 0" @click="openRateDialog" width="100%" height="50px" color="var(--light-gray)"
                     text_color="var(--primary)">
        Купить резюме за 49р
      </rekroo-button>
    </div>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import Dot from "@/components/Dot";
import RekrooIcon from "@/components/Icon";
import {Item} from "@/api/item";
import Swiper from 'swiper';
import 'swiper/swiper.css';
import {Auth} from "@/api/auth";

export default {
  name: "DialogAudienceSurvey",
  components: {RekrooIcon, Dot, RekrooButton},
  emits: ['open-resume-rates'],
  data() {
    return {
      itemClass: new Item({}),
      authClass: new Auth({}),
      isVisible: false,
      step: 0,
      titles: ['Расскажите о себе, чтобы получить 5 резюме бесплатно', 'Какую роль вы выполняете?', '', 'Как вам Rekroo?'],
      roleOptions: ['Частный рекрутер', 'HR агентство', 'HR-специалист', 'Компания, ищем сотрудников самостоятельно'],
      form: {
        'Имя': '',
        'Компания': '',
        'Должность': '',
        'Роль': '',
        'Сколько сотрудников вы обычно ищете?': '',
        'Как часто вы ищете сотрудников?': '',
        'Сколько сотрудников в вашей компании?': '',
        'Отзыв': '',
        privacy: false,
      },
      rules: {
        'Имя': [
          {required: true, message: 'Обязательное поле', trigger: 'change'},
          {required: true, message: 'Обязательное поле', trigger: 'blur'},
        ],
        'Сколько сотрудников вы обычно ищете?': [
          {required: true, message: 'Обязательное поле', trigger: 'change'},
          {required: true, message: 'Обязательное поле', trigger: 'blur'},
        ],
        'Как часто вы ищете сотрудников?': [
          {required: true, message: 'Обязательное поле', trigger: 'change'},
          {required: true, message: 'Обязательное поле', trigger: 'blur'},
        ],
        'Сколько сотрудников в вашей компании?': [
          {required: true, message: 'Обязательное поле', trigger: 'change'},
          {required: true, message: 'Обязательное поле', trigger: 'blur'},
        ],
        'Отзыв': [
          {required: true, message: 'Обязательное поле', trigger: 'change'},
          {required: true, message: 'Обязательное поле', trigger: 'blur'},
        ],
      },
      isFirstStepValid: false,
      tempRole: '',
      employee_search_count_options: ['1-5', '6-20', '21-50', 'Более 50'],
      employee_frequency_search_options: ['Единоразово', 'Регулярно (несколько раз в год)', 'Массовый подбор'],
      employees_in_company_options: ['1-10', '11-50', '51-100', 'Более 100'],
      swiper: null,
      showRoleError: false,
      loading: false,
      privacyError: false,
    }
  },
  methods: {
    open() {
      if (!this.isVisible) {
        this.step = 0;
        this.form = {
          'Имя': '',
          'Компания': '',
          'Должность': '',
          'Роль': '',
          'Сколько сотрудников вы обычно ищете?': '',
          'Как часто вы ищете сотрудников?': '',
          'Сколько сотрудников в вашей компании?': '',
          'Отзыв': '',
          privacy: false,
        }
        this.isVisible = true;
      }
    },
    openRateDialog() {
      this.isVisible = false;
      this.$emit('open-resume-rates', this.$route.path);
    },
    forward() {
      switch (this.step) {
        case 0:
          this.forwardToFirstStep();
          break;
        case 1:
          this.forwardToSecondStep();
          break;
        case 2:
          this.forwardToThirdStep();
          break;
        case 3:
          this.forwardToFourStep();
          break;
        case 4:
          this.forwardToComplete();
          break;
        default:
          return;
      }
    },
    forwardToFirstStep() {
      this.step++;
      setTimeout(() => {
        this.swiper = new Swiper('.swiper.survey-swiper', {
          slidesPerView: 1,
          width: 346,
        });
      })
      this.form['Имя'] = this.$store.state.user?.name || '';

    },
    forwardToSecondStep() {
      this.$refs.form.validate(valid => {
        this.isFirstStepValid = valid;
        if (valid) {
          this.step++;
          this.swiper.slideTo(1);
        }
      })
    },
    forwardToThirdStep() {
      if (this.form['Роль'] || this.tempRole) {
        this.step++;
        this.swiper.slideTo(2);
        if (this.tempRole) this.form['Роль'] = this.tempRole;
      } else {
        this.showRoleError = true;
      }
    },
    forwardToFourStep() {
      this.$refs.form_third_step.validate(valid => {
        if (valid) {
          this.step++;
          this.swiper.slideTo(3);
        }
      })
    },
    forwardToComplete() {
      this.privacyError = false;
      this.$refs.last_step_form.validate(valid=>{
        if (valid && this.form.privacy) {
          this.loading = true;
          this.authClass.marketingForm({form: this.form}).then(data => {
            this.gtmEvent('popup_formsubmit');
            if (data.response) this.setValue('audience_survey_token', data.response);
            this.loading = false;
            this.step++;
            this.$store.state.eventBus.$emit('get-user');
            this.swiper.slideTo(4);
          }).catch(() => {
            this.loading = false;
          })
        }
        if (!this.form.privacy) {
          this.privacyError = true;
        }
      })
    },
    fetchSuggestionsPos(q, cb) {
      this.itemClass.suggestions('position', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    complete() {
      if (this.user.id && !this.$route.path.includes('/catalog/')) {
        this.$router.push('/hr/catalog');
      } else if (!this.user.id) this.$store.state.eventBus.$emit('auth-error');
      this.isVisible = false;
    },
    onClose() {
      let count = this.audience_survey_closes_count+1;
      this.setValue('audience_survey_closes_count', count);
      this.$store.commit('setAudienceCloseCount', count);
    }
  },
  computed: {
    audience_survey_closes_count() {
      return this.$store.state.audience_survey_closes_count;
    },
    isDisabled() {
      switch (this.step) {
        case 1:
          return !this.form['Имя'];
        case 2:
          return !this.form['Роль'] && !this.tempRole;
        case 3:
          return !this.form['Сколько сотрудников вы обычно ищете?'] || !this.form['Как часто вы ищете сотрудников?'] || this.form['Компания'] ? !this.form['Сколько сотрудников в вашей компании?'] : false;
        case 4:
          return !this.form.privacy || !this.form['Отзыв'];
        default:
          return false;
      }
    },
    user() {
      return this.$store.state.user;
    }
  }
}
</script>

<style module lang="css">
.surveyGreeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.surveyIcon {
  height: 222px;
  width: auto;
  margin-bottom: 28px;
}

.surveyGreetingTitle {
  margin-bottom: 12px;
  font-size: var(--headline_3);
  font-weight: 600;
  text-align: center;
}

.surveyGreetingSubtitle {
  margin-bottom: 24px;
  text-align: center;
  font-size: var(--body);
}

.surveyGreetingActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
}

.stepsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.stepsNavigation {
  display: flex;
  column-gap: 8px;
  margin-bottom: 24px;
}

.stepTitle {
  margin-bottom: 32px;
  font-size: var(--headline_3);
  font-weight: 600;
  text-align: center;
}

.rolesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 8px;
  margin-bottom: 28px;
}

.rolesContainer .roleOption {
  display: flex;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  background: var(--light-gray);
  justify-content: space-between;
  align-items: center;
}

.rolesContainer .roleOption:last-child {
  color: rgba(17, 17, 17, 0.3);
}

.rolesContainer .roleOption.roleOptionSelected {
  background: rgba(57, 198, 113, 0.10);
  color: var(--green-active);
}

.buttonForward {
  margin-top: 24px;
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 36px;
}

.privacy {
  margin-left: 12px;
  font-size: var(--small-text);
  line-height: 144%;
}

.errorText {
  color: var(--error-red);
  font-size: 12px;
}

.errorPrivacyText {
  position: absolute;
  bottom: 20px;
  line-height: 100%;
}
</style>