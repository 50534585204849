<template>
  <div class="page" :class="style.catalogPage">
    <h1>Клиенты</h1>
    <el-table fit table-layout="auto" :data="rows" :cell-class-name="$style.tableCell">
      <el-table-column :class-name="$style.tableCellNoWrap" prop="id" label="#"/>
      <el-table-column :class-name="$style.tableCellNoWrap" prop="phone" label="Контакты">
        <template #default="scope">
          <div>
            <div>{{ strPhoneMask(scope.row.phone) }}</div>
            <div>{{ scope.row.email }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :class-name="$style.tableCellNoWrap" prop="name" label="Имя">
        <template #default="scope">
          <div>
            <div>{{ scope.row.name }}</div>
            <div>{{ scope.row.surname }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column :class-name="$style.tableCellNoWrap" prop="registered" label="first/last action">
        <template #default="scope">
          <div>
            <div>Активных дней {{ scope.row.activeDays }}</div>
            <div>{{ scope.row.registered }}</div>
            <div>{{ scope.row.lastAction }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="orders" label="Покупки">
        <template #default="scope">
          <div>
            <el-tag style="text-transform: capitalize;margin-bottom: 4px" v-for="order in scope.row.orders"
                    :key="order.id" disable-transitions>
              {{ order.type.split('_')[0] }} - {{ order.price }}р
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="utm" label="Источник">
        <template #default="scope">
          <div v-if="scope.row.utm" style="word-break: normal">
            utm_source: {{ scope.row.utm.utm_source }}
            utm_campaign: {{ scope.row.utm.utm_campaign }}
            utm_term: {{ scope.row.utm.utm_term }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="resumeActions" label="Фильтры">
        <template #default="scope">
          <div style="word-break: normal">
            <el-tag style="margin-bottom: 4px" v-for="query in scope.row.resumeActions" :key="query">
              {{ query }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import style from "@/views/company/catalog/styles/catalog.module.css"
import {ClientCRM} from "@/api/CRM/clientCRM"
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";

export default {
  name: "Clients",
  components: {},
  data: () => ({
    clientCRM: new ClientCRM(),
    style,
    tableStyles,
    rows: []
  }),
  methods: {},
  mounted() {
    this.clientCRM.get().then(data => this.rows = data.response);
  }
}
</script>

<style module lang="css">
.tableCellNoWrap * {
  white-space: nowrap !important;
}
</style>
