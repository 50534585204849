<template>
  <div :class="$style.itemAvatar"
       :style="`width: ${size+'px' || ''}; min-width: ${size+'px' || ''}; height: ${size+'px' || ''}; border-radius: ${circle ? '50%' : ''}`">
    <img v-if="img" :src="img" alt="">
    <rekroo-icon v-else :icon="gender === 2 ? 'female' : 'male'" color="var(--gray-text)" :width="`${(size/2)+'px' || ''}`"/>
  </div>
</template>

<script>
import RekrooIcon from "@/components/Icon";
export default {
  name: "CandidateAvatar",
  props: ['size', 'circle', 'img', 'gender'],
  components: {RekrooIcon}
}
</script>

<style module lang="css">
.itemAvatar {
  min-width: 112px;
  width: 112px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-gray);
  overflow: hidden;
  border-radius: 8px;
}
.itemAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemAvatar i {
  font-size: 64px;
}

@media screen and (max-width: 1024px) {
  .itemAvatar {
    min-width: 76px;
    width: 76px;
    height: 76px;
  }
  .itemAvatar i {
    font-size: 38px;
  }

}

@media screen and (max-width: 575px) {
  .itemAvatar {
    min-width: 64px;
    width: 64px;
    height: 64px;
  }
  .itemAvatar i {
    font-size: 32px;
  }

}
</style>