<template>
  <el-dialog :visible.sync="isVisible" width="860px"
             custom-class="rekroo-dialog user-agreement-dialog" :center="true">
    <div :class="$style.dialogInner" class="agree-dialog-inner">
      <div :class="$style.header">
        <div></div>
        <div :class="$style.dataInner">
          <p>Оператору персональных данных:</p>
          <p>ООО «Техинформгрупп»</p>
          <p>адрес: 115035, г. Москва,</p>
          <p>Космодамианская наб., д 4/22, к. «Б», оф. 529</p>
          <p>от</p>
          <p>{{ applicant.name }} {{ applicant.surname }},</p>
          <p v-if="applicant.location">адрес регистрации: {{ applicant.location }}</p>
          <p v-if="applicant.phone">телефон: {{ applicant.phone }}</p>
          <p v-if="applicant.email">e-mail: {{ applicant.email }}</p>
        </div>
      </div>
      <div :class="$style.title">Согласие на обработку персональных данных</div>
      <p :class="$style.paragraph">Оператор персональный данных при предоставлении услуг по поиску работы и подбору
        персонала, в соответствии со ст.
        9 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных», настоящим в целях обработки персональных
        данных в связи с предоставлением соискателю сведений о вакансиях и сведений работодателю о соискателях получает
        согласие субъекта персональных данных на обработку следующих персональных данных:
      </p>
      <el-form :class="$style.form" ref="form" :model="personal_data">
        <el-form-item v-for="(checkbox, key) in personal_data" :key="key">
          <el-checkbox v-model="checkbox.value" :label="checkbox.label"/>
        </el-form-item>
      </el-form>
      <p :class="$style.paragraph">(проставить знак «✓», напротив персональных данных в отношении которых субъектом
        персональных данных дается согласие на обработку в соответствии с ст. 9 Федерального закона от 27.07.2006 N
        152-ФЗ «О персональных данных»), Обработка персональных данных будет осуществляться в соответствии с Политикой
        обработки персональных данных.
        Настоящее согласие действует со дня выдачи согласия до дня его отзыва в письменной форме.
      </p>
      <div :class="$style.actions">
        <rekroo-button :class="$style.sendButton" @click="send"
                       :loading="loading"
                       width="100%" height="50px">Подписать
        </rekroo-button>
        <span :class="$style.errorText" v-if="!isValid">Вам необходимо дать согласие на обработку всех персональных данных</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {Resume} from "@/api/resume";
import RekrooButton from "@/components/Button";
import {User} from "@/api/user";

export default {
  name: "UserAgreementDialog",
  components: {RekrooButton},
  data: () => ({
    isVisible: false,
    userClass: new User({}),
    applicant: new Resume({}),
    personal_data: {
      fullname: {
        value: true,
        label: 'фамилия, имя, отчество',
      },
      gender: {
        value: true,
        label: 'пол'
      },
      citizenship: {
        value: true,
        label: 'гражданство'
      },
      birthday: {
        value: true,
        label: 'дата и место рождения'
      },
      image: {
        value: true,
        label: 'изображение (фотография)'
      },
      passport: {
        value: true,
        label: 'паспортные данные'
      },
      address: {
        value: true,
        label: 'адрес регистрации по месту жительства'
      },
      address_fact: {
        value: true,
        label: 'адрес фактического проживания'
      },
      contacts: {
        value: true,
        label: 'контактные данные'
      },
      taxcode: {
        value: true,
        label: 'индивидуальный номер налогоплательщика'
      },
      snils: {
        value: true,
        label: 'страховой номер индивидуального лицевого счета (СНИЛС)'
      },
      edu: {
        value: true,
        label: 'сведения об образовании, квалификации, профессиональной подготовке и повышении квалификации'
      },
      family: {
        value: true,
        label: 'семейное положение, наличие детей, родственные связи'
      },
      work: {
        value: true,
        label: 'сведения о трудовой деятельности, в том числе наличие поощрений, награждений и (или) дисциплинарных взысканий'
      },
      marriage: {
        value: true,
        label: 'данные о регистрации брака'
      },
      military: {
        value: true,
        label: 'сведения о воинском учете'
      },
      disability: {
        value: true,
        label: 'сведения об инвалидности'
      },
      alimony: {
        value: true,
        label: 'сведения об удержании алиментов'
      },
      prev_salary: {
        value: true,
        label: 'сведения о доходе с предыдущего места работы'
      },
    },
    isValid: true,
    loading: false,
  }),
  methods: {
    open(applicant) {
      this.applicant = applicant;
      this.isVisible = true;
    },
    send() {
      this.checkValid(this.personal_data);
      if (this.isValid) {
        let agreeHtml = document.querySelector('.agree-dialog-inner').innerHTML;
        this.loading = true;
        this.userClass.agree(agreeHtml).then(() => {
          this.$notify({
            type: "success",
            title: "Согласие на обработку п. данных успешно подписано"
          })
          this.loading = false;
          this.isVisible = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
    checkValid(personal_data) {
      let errCount = 0;
      for (let key in personal_data) {
        if (!personal_data[key].value) {
          this.isValid = false;
          errCount++;
        }
      }
      if (errCount === 0) this.isValid = true;
    }
  },
  watch: {
    'personal_data': {
      deep: true,
      handler: function (val) {
        this.checkValid(val)
      }
    }
  }
}
</script>

<style module lang="css">
.dialogInner {
  position: relative;
}

.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 24px;
}

.title {
  text-align: center;
  margin-bottom: 32px;
  font-size: var(--headline_2);
  font-weight: var(--body-medium-weight);
}

.form {
  margin-bottom: 32px;
}

.actions {
  margin-left: -40px;
  margin-right: -40px;
  margin-top: 40px;
  box-shadow: 0px 0px 15px 20px #fff;
  padding: 20px 40px 40px;
  background: #FFFFFF;
  text-align: center;
  position: sticky;
  bottom: 0;
  z-index: 1;

}

.sendButton {
  margin: 0 auto;
}

.errorText {
  display: block;
  margin-top: 8px;
  color: var(--error-red);
  font-size: var(--body);
}

.paragraph {
  text-indent: 20px;
}
</style>