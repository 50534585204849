<template>
  <table-skeleton v-if="loading" :class="tableStyles.table" :key="'skelet'+name"
                  :header-titles="['','Название', 'Стоимость', 'Отклики', 'Дата создания']"/>
  <el-table v-else
            :key="'table'+name"
            :class="['table-container',tableStyles.table, {'table-container-active': loaded}, 'table-with-actions']"
            ref="multipleTable" :data="data"
            :show-header="data.length > 0"
            header-row-class-name="header-row">
    <el-table-column label="Название" class-name="table-title-col">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <div :class="tableStyles.title" @click="$emit('create', scope.row.id)">{{scope.row.title}}</div>
          <div :class="tableStyles.mobileCell">
            <div :class="$style.price">
              <span>Стоимость:</span> {{$store.state.systemParams.currencies[scope.row.currency] ?
                salaryParse(scope.row.price, 0, $store.state.systemParams.currencies[scope.row.currency].short) : ''}}
            </div>
            <div :class="$style.dateCreate">
              <span>Дата создания:</span> {{dateParse(scope.row.datetime_create, 'D MMMM, YYYY')}}
            </div>
          </div>
        </div>
        <div :class="tableStyles.mobileActions">
          <el-dropdown :hide-on-click="true" trigger="click">
            <rekroo-button icon="more" color="transparent" icon_width="30px" style="padding: 0"/>
            <el-dropdown-menu>
              <el-dropdown-item>
                <rekroo-button @click="$emit('create', scope.row.id)"
                    type="text" text_color="var(--primary)" icon="pencil" icon_style="margin-right: 6px">
                  Редактировать
                </rekroo-button>
                </el-dropdown-item>
              <el-dropdown-item>
                <rekroo-button @click="$emit('click-remove', scope.row.id)"
                               type="text" text_color="var(--error-red)" icon="trash" icon_style="margin-right: 6px">
                  Удалить
                </rekroo-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Стоимость">
      <template slot-scope="scope">
        {{$store.state.systemParams.currencies[scope.row.currency] ?
          salaryParse(scope.row.price, 0, $store.state.systemParams.currencies[scope.row.currency].short) : ''}}
      </template>
    </el-table-column>
<!--    <el-table-column label="Отклики">
      <template slot-scope="scope">
        <rekroo-icon icon="hand" color="var(&#45;&#45;gray-text)" style="margin-right: 10px"/>
        {{scope.row.responses}}
      </template>
    </el-table-column>-->
    <el-table-column label="Дата создания">
      <template slot-scope="scope">
        {{dateParse(scope.row.datetime_create, 'D MMMM, YYYY')}}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot-scope="scope">
        <div :class="tableStyles.actions">
          <rekroo-button icon="pencil" icon_color="#FFF" width="48px" height="48px" color="var(--purple)"/>
          <rekroo-button icon="trash" icon_color="#FFF" width="48px" height="48px" color="var(--gray-text)" @click="$emit('click-remove', scope.row.id)"/>
        </div>
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug :class="tableStyles.emptyTablePlug"
                       title="У вас пока нет опубликованных услуг"
                       subtitle="Опишите преимущества работы с вами и привлеките больше заказов"
                       button_text="+ Создать услугу" @onClick="$emit('create')"
      />
    </template>
  </el-table>
</template>

<script>
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import EmptyListPlug from "@/components/EmptyListPlug";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";

export default {
  name: "HRConsultationsTable",
  components: {RekrooButton, RekrooIcon, EmptyListPlug, TableSkeleton},
  props: ['loading', 'data', 'name', 'loaded'],
  emits: ['create', 'click-remove'],
  data: ()=>({
    tableStyles,
  }),
  methods: {
  }

}
</script>

<style module lang="css">
.price {
  margin-bottom: 12px;
}
.dateCreate{}
</style>
