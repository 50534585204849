<template>
  <div :class="$style.loaderDimmer">
    <i class="icon el-icon-loading" style="color: var(--gray-text); font-size: 36px"/>
  </div>
</template>

<script>
export default {
  name: "FormLoader"
}
</script>

<style module lang="css">
.loaderDimmer {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 60px;
  background: #FFFFFF;
  opacity: .7;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
</style>