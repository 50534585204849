<template>
  <div :class="$style.ratesPageContent">
    <h1 :class="$style.ratesPageTitle" v-if="showTitle">Выберите тарифный план<br>размещения вакансии</h1>
<!--    <p :class="$style.ratesPageSubtitle" v-if="showSubtitle">Воспользуйтесь полным доступом к резюме соискателей, просматривайте<br>любые
      контакты без ограничений в их количестве. </p>-->
    <div :class="$style.rates" v-if="!rates.length">
      <rate-skeleton v-for="i in 3" :key="i"/>
    </div>
    <div v-else :class="$style.rates" ref="vacancy_rates_list">
      <div :class="$style.rate" v-for="(rate, i) in rates" :key="i">
        <div :class="$style.rateName">{{ rate.title }}</div>
        <div :class="$style.ratePriceContainer">
          <div :class="[$style.ratePrice, {[$style.ratePriceSale]: rate.price < rate.full_price}]">
            {{ thousandSeparator(rate.full_price) }} ₽</div>
          <div :class="$style.ratePrice">{{rate.price}} ₽</div>
        </div>
        <div v-if="rate.date_to && rate.price === 0" :class="$style.rateHint">бесплатно до {{ dateParse(rate.date_to, 'D MMMM')}}</div>
        <div :class="$style.rateHint">Стоимость 1 вакансии</div>
        <div v-if="rate.labels" :class="$style.rateTags">
          <tag v-for="(label, i) in rate.labels" :key="i" :text="label"/>
        </div>
        <tag v-else icon="time" icon_pos="start" icon_color="var(--gray-text)" icon_width="13"
             :text="`Срок&nbsp;размещения: ${ rate.period / 24 / 3600}&nbsp;${getNoun(rate.period / 24 / 3600, 'день', 'дня', 'дней')}`"
             :class="$style.rateDuration"/>
        <ul :class="$style.rateAdvantages" v-if="rate.advantages && rate.advantages.length">
          <li :class="$style.advantage" v-for="adv in rate.advantages" :key="adv">{{ adv }}</li>
        </ul>
        <div :class="$style.rateActions">
          <el-input-number v-model="rate.amount" :min="1" :max="10"/>
          <rekroo-button :loading="order.key === rate.key ? loading : false"
                         :disabled="order.key !== rate.key && loading"
              width="100%" :class="$style.pickRateButton"
                         @click="createOrder(rate)">Выбрать
          </rekroo-button>
          <div v-if="rate.amount === 10" :class="$style.amountHint">Можно выбрать не более 10 вакансий</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from "@/components/Tag";
import RekrooButton from "@/components/Button";
import SubscribeDialog from "@/dialogs/SubscribeDialog";
import {Order} from "@/api/order";
import RateSkeleton from "@/components/skeletons/RateSkeleton";

export default {
  name: "VacancyRates",
  components: {RateSkeleton, SubscribeDialog, RekrooButton, Tag},
  props: ['redirect_url', 'showTitle', 'showSubtitle'],
  emits: ['on-create'],
  data: () => ({
    orderClass: new Order({}),
    order: new Order({}),
    loading: false,
  }),
  methods: {
    createOrder(rate) {
      this.$store.commit('setLastCreatedRate', rate);
      this.order = {...rate};
      this.loading = true;
      this.orderClass.create({type: this.order.key, amount: rate.amount,
      redirect_url: `${this.redirect_path}${this.redirect_url || `/${this.getValue('role', 'company')}/vacancies?t=not_publish`}`}).then((data) => {
        this.loading = false;
          this.$router.push({path: `/order/${data.response.id}`,});
        this.$emit('on-create')
      }).catch((data) => {
        this.loading = false;
        if (data.error && data.error.type === 'bill_email') {
          this.$store.state.eventBus.$emit('open-email-dialog');
        }
      })
    },
  },
  computed: {
    rates() {
      let rates = [];
      if (this.$store.state.systemParams.productTypes) {
        let keys = Object.keys(this.$store.state.systemParams.productTypes)
            .filter(key => this.$store.state.systemParams.productTypes[key].type === 'vacancy');
        keys.forEach(key => {
          this.$store.state.systemParams.productTypes[key].key = key;
          rates.push(this.$store.state.systemParams.productTypes[key]);
        });
      }
      return rates;
    },
    lastCreatedRate() {
      return this.$store.state.last_created_rate;
    },
    redirect_path() {
      return this.$store.state.redirect_path;
    }
  },
  mounted() {
  },
  created() {
    this.$store.state.eventBus.$on('user-email-added', ()=>{
      if (this.lastCreatedRate?.type?.includes('vacancy')) this.createOrder(this.order);
    });
    this.$store.state.eventBus.$on('success-auth', ()=>{
      if (this.lastCreatedRate?.type?.includes('vacancy')) this.createOrder(this.order);
    })
  }
}
</script>

<style module lang="css">
.ratesPageContent {
  margin: 0 auto;
  max-width: 874px;
}

.ratesPageContent .ratesPageTitle {
  text-align: center;
  margin-bottom: 24px;
}

.ratesPageContent .ratesPageSubtitle {
  text-align: center;
  font-size: var(--body);
  margin-bottom: 36px;
}

.ratesPageContent .rates {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 20px;
  justify-items: center;
}

.ratesPageContent .rates .rate {
  height: fit-content;
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 32px 28px;
  display: flex;
  background: #FFFFFF;
  max-width: 545px;
  width: 100%;
  flex-direction: column;
}

.ratesPageContent .rates .rate .rateName {
  font-size: var(--subheadline);
  font-weight: 500;
  margin-bottom: 16px;
}

.ratePriceContainer {
  display: flex;
  column-gap: 12px;
}
.ratesPageContent .rates .rate .ratePrice {
  font-size: var(--headline_1);
  font-weight: 600;
  margin-bottom: 4px;
}

.ratePriceSale {
  opacity: .3;
  text-decoration: line-through;
}

.ratesPageContent .rates .rate .rateHint {
  font-size: var(--small-text);
  color: var(--gray-text);
  margin-bottom: 20px;
}

.ratesPageContent .rates .rate .rateDuration {
  font-size: 14px !important;
  font-weight: 500;
  white-space: normal;
}

.ratesPageContent .rates .rate .rateAdvantages {
  margin-top: 16px;
  list-style-position: inside;
}

.ratesPageContent .rates .rate .rateAdvantages .advantage {
  font-size: 14px;
  margin-bottom: 9px;
  position: relative;
  padding-left: 25px;
}

.ratesPageContent .rates .rate .rateAdvantages .advantage:before {
  content: ' ';
  background: url("/img/icons/icon_green_check.svg") no-repeat;
  background-size: cover;
  width: 17px;
  height: 14px;
  top: 2px;
  position: absolute;
  left: 0;
}

.ratesPageContent .rates .rate .rateAdvantages .advantage:last-child {
  margin-bottom: 0;
}

.ratesPageContent .rates .rate .rateActions {
  margin-top: 36px;
}

.rateTags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.ratesPageContent .rates .rate .rateActions .pickRateButton {
  margin-top: 12px;
  height: 50px;
}

.amountHint {
  margin-top: 8px;
  color: var(--gray-text);
  font-size: var(--small-text);
}
@media screen and (max-width: 1024px) {
  .ratesPageContent .ratesPageTitle {
    margin-bottom: 20px;
  }

  .ratesPageContent .ratesPageSubtitle {
    font-size: var(--small-text);
    margin-bottom: 28px;
  }

  .ratesPageContent .rates {
    grid-gap: 16px;
  }

  .ratesPageContent .rates .rate {
    padding: 24px 20px;
  }

  .ratesPageContent .rates .rate .rateName {
    margin-bottom: 12px;
  }

  .ratesPageContent .rates .rate .ratePrice {
    margin-bottom: 0;
  }

  .ratesPageContent .rates .rate .rateHint {
    margin-bottom: 12px;
  }

  .ratesPageContent .rates .rate .rateDuration br {
    display: block;
  }

  .ratesPageContent .rates .rate .rateAdvantages .advantage {
    margin-bottom: 4px;
    padding-left: 20px;
  }

  .ratesPageContent .rates .rate .rateAdvantages .advantage:before {
    top: 4px;
    width: 12px;
    height: 10px;
  }

  .ratesPageContent .rates .rate .rateActions input {
    height: 50px !important;
  }

  .ratesPageContent .rates .rate .rateActions .pickRateButton {
    margin-top: 10px;
  }
}

@media screen and (max-width: 575px) {
  .ratesPageContent .ratesPageTitle {
    margin-bottom: 16px;
    font-size: 18px;
  }

  .ratesPageContent .rates {
    grid-template-columns: 1fr;
  }

  .ratesPageContent .rates .rate {
    height: fit-content;
    padding: 20px;
  }
  .ratePriceContainer {
    column-gap: 8px;
  }
  .ratesPageContent .rates .rate .ratePrice {
    font-size:38px;
    margin-bottom: 4px;
  }

  .ratesPageContent .rates .rate .rateHint {
    font-size: var(--body);
    margin-bottom: 20px;
  }

  .ratesPageContent .rates .rate .rateDuration br {
    display: none;
  }

  .ratesPageContent .rates .rate .rateActions {
    margin-top: 20px;
  }


}
</style>