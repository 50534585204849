<template>
<!--  <HrLanding v-if="show_landing"/>-->
  <transition name="fade">
    <router-view/>
  </transition>
</template>

<script>
import HrLanding from "@/views/landing/HrLanding";

export default {
  name: "HrView",
  components: {HrLanding},
  methods: {
  },
  watch: {
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    show_landing() {
      return !this.$store.state.user.id;
    }
  },
  mounted() {
  },
  created() {

    this.$eventBus.$on('logout', () => {
      this.$router.replace('/hr').catch(()=>{});
    });
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    this.$eventBus.$off('logout');
  }

}
</script>

<style scoped>

</style>