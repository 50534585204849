<template>
  <HrLanding/>
<!--<div class="landing"></div>-->
</template>

<script>
import HrLanding from "@/views/landing/HrLanding";
export default {
  name: "Landing",
  components: {HrLanding}

}
</script>

<style scoped>

</style>