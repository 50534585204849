<template>
  <div class="page" ref="page">
    <link-back text="Вернуться ко всем моим заказам"/>
    <auction-item-skeleton v-if="loading"/>
    <div v-else :class="auctionStyles.orderItemPageContent" class="page-with-navigation">
      <side-navigation v-if="item.user_id == user.id"
                       :class="auctionStyles.sideNavigation"
          :navigation="navigation" :active-section="activeSection" @link-click="scrollToEl"/>
      <div v-else :class="auctionStyles.orderCreatorContainer">
        <PageHint v-if="notFoundError" title="Страницы не существует" icon="error" color="var(--yellow)"/>
        <div v-else :class="auctionStyles.orderCreator">
          <div :class="auctionStyles.orderCreatorHeader">
            <img v-if="item.company_info?.image?.url" :src="item.company_info?.image?.url" alt="" :class="auctionStyles.image">
            <rekroo-icon v-else :icon="
          item.hasOwnProperty('company_info') ? 'image' : item.gender === 1 ? 'male' : 'female'" width="48px" color="var(--gray-text)"/>
            <div :class="auctionStyles.title">{{
                item.hasOwnProperty('resume') ? `${item.name} ${item.surname[0].toUpperCase()}.` : item.company_info?.title }}</div>
          </div>
<!--          <tag :text="`Заказ от ${item.order_from === 'applicant' ? 'соискателя' : 'компании'}`"
               :color="item.order_from === 'applicant' ? 'var(&#45;&#45;yellow)' : 'var(&#45;&#45;green)'"/>-->
        </div>
        <div :class="auctionStyles.orderCreatorActions">
          <rekroo-button v-if="item.user_id != user.id && item.response?.match_worker  == 0"
                         @click="$refs.add_response_dialog.open(item.id)"
                         color="var(--purple)" icon="hand" icon_color="#FFF" icon_style="margin-right: 10px"
                         width="100%"
          >Откликнуться</rekroo-button>
          <tag v-else-if="item.user_id != user.id && item.response?.chosen*1" text="Вы выбраны в качестве исполнителя"
               color="var(--green-active)"
               style="height: 50px; width: 100%"/>
          <tag v-else-if="item.user_id != user.id && item.response?.match_worker*1" text="Откликнулся" style="height: 50px; width: 100%"/>
        </div>
      </div>
      <div :class="auctionStyles.orderItemPageContentInner">
        <div :class="auctionStyles.orderContainer">
          <empty-list-plug v-if="notFoundError" title="Аукцион не найден" subtitle="Аукцион был удален или снят с публикации"
                           :show-icon="true" button_text="Вернуться к аукционам" @onClick="$router.back()"
          style="margin: 0 auto"/>
          <div v-else :class="auctionStyles.orderInner" ref="order" id="order">
            <div :class="auctionStyles.orderHeader">
              <span :class="auctionStyles.date">{{ dateParse(item.datetime_create, 'D MMMM в HH:mm') }}</span>
              <el-dropdown v-if="item.user_id == user.id" hide-on-click trigger="click">
              <span class="el-dropdown-link">
                <rekroo-button icon="more" width="46px" icon_color="#FFF" icon_width="30px" style="padding: 0"/>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <rekroo-button @click="openEdit"
                        type="text" icon="pencil" icon_color="var(--gray-text)" text_color="var(--primary)"
                                   icon_width="16">Редактировать
                    </rekroo-button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="item.status !== 2">
                    <rekroo-button @click="openConfirmCloseOrder"
                        type="text" icon="trash" icon_color="var(--gray-text)" text_color="var(--primary)"
                                   icon_width="16">Закрыть заказ
                    </rekroo-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <h1 :class="auctionStyles.orderTitle">{{ item.title }}
              <tag v-if="item.status === 2" text="В архиве"/>
            </h1>
            <div :class="auctionStyles.orderTags">
              <tag color="var(--green)"
                   :text="salaryParse(item.price_from, item.price_to)"/>
              <tag icon="eye" icon_pos="start" icon_color="var(--gray-text)"
                   :text="`${item.counts?.views} ${getNoun(item.counts?.views, 'просмотр', 'просмотра', 'просмотров')}`"/>
              <tag icon="hand" icon_pos="start" icon_color="var(--gray-text)"
                   :text="`${item.counts?.total} ${getNoun(item.counts?.total, 'отклик', 'отклика', 'откликов')}`"/>
            </div>
            <LabelValueRow :class="$style.employeeSalary"
                label="Предполагаемая зарплата сотрудника" :value="salaryParse(item.salary_from*1, item.salary_to*1)"/>
            <p :class="auctionStyles.orderDesc" v-html="item.description?.replaceAll('\n', '<br>')"/>
            <div :class="auctionStyles.orderFilesContainer" v-if="item.files && item?.files?.length">
              <div :class="auctionStyles.orderFilesLabel">
                <rekroo-icon icon="attach"/>
                Файлы и фотографии
              </div>
              <div :class="auctionStyles.orderFiles">
                <div :class="auctionStyles.orderFile" v-for="file in item.files" :key="file">
                  <img :src="file" alt="">
                </div>
              </div>
            </div>
            <div :class="auctionStyles.chosenOneContainer" v-if="item.responses?.length && item.responses.find(i=>i.chosen)">
              <div :class="auctionStyles.chosenOneLabel">Исполнитель заказа:</div>
              <div :class="auctionStyles.chosenOne">
                <candidate-avatar :circle="true" :img="item.responses.find(i=>i.chosen).worker?.image?.url" size="64"/>
                <div :class="auctionStyles.chosenOneContent">
                  <div :class="auctionStyles.chosenOneName">{{item.responses.find(i=>i.chosen).worker?.name}} {{item.responses.find(i=>i.chosen).worker?.surname}}</div>
                  <div :class="auctionStyles.chosenOneContacts">
                    <tag v-if="item.responses.find(i=>i.chosen).worker?.phone"
                         icon="phone" icon_color="var(--gray-text)" icon_pos="start" :text="strPhoneMask(item.responses.find(i=>i.chosen).worker?.phone)"/>
                    <tag v-if="item.responses.find(i=>i.chosen).worker?.email"
                         icon="mail" icon_color="var(--gray-text)" icon_pos="start" :text="item.responses.find(i=>i.chosen).worker?.email"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.user_id == user.id && !item.responses?.find(i=>i.chosen)"
              :class="auctionStyles.hrOffersContainer">
            <div :class="auctionStyles.hrOffersTitle">Предложения HR-специалистов</div>
            <div :class="auctionStyles.hrOffers" v-if="item.responses?.length" ref="response" id="response">
              <hr-card-response v-for="response in item.responses" :key="response.id" :item="response" :user="user"
              @add-response-btn-click="exchangeContacts(response)" @choose-btn-click="selectHr(response)" :order="item"/>
            </div>
            <EmptyListPlug
                v-else
                style="margin: 0 auto"
                title="Откликов нет"
                :subtitle="item.user_id == user.id ? 'На ваш запрос пока нет предложений. ' +
                 'Вы можете не ждать откликов и сами предложить HR\'у свой заказ' : 'На этот запрос еще никто не откликнулся. Будьте первыми!'"
                :show-icon="true"
                :button_text="item.user_id == user.id ? 'Найти исполнителя' : 'Откликнуться'"
                @onClick="scrollToEl(2)"
            />
          </div>
        </div>
        <div :class="auctionStyles.hrSearchContainer" v-if="item.user_id == user.id && !item.responses?.find(i=>i.chosen)">
          <h1 :class="auctionStyles.hrSearchTitle">Найти HR-специалиста</h1>
          <div :class="auctionStyles.hrSearchFiltersContainer">
            <div :class="auctionStyles.hrSearchFiltersTitle">
              Фильтры
              <rekroo-button type="text" :class="auctionStyles.resetButton" text_color="var(--gray-text)"
              @click="reset">Сбросить все
              </rekroo-button>
            </div>
            <filters-hr-search-form :filters="filters" @apply="search"/>
          </div>
          <div :class="auctionStyles.hrFilterMobile">
            <rekroo-button icon="filter" type="gray" icon_style="margin-right: 5px" width="100%"
                           text_color="var(--primary)" icon_color="var(--primary)"
                           @click="$refs.filters_hr_search_dialog.open({filters})"
            >Фильтры</rekroo-button>
            <div :class="auctionStyles.hrFilterTags">
              <tag v-for="filter in appliedFilters" :key="filter"
                   :text="filter"
                  color="var(--blue)" icon_pos="end" icon="close" @onIconClick="()=>{}"/>
            </div>
          </div>
          <div :class="auctionStyles.searchItems" v-if="workers?.length" ref="search" id="search" >
            <HrCardSearch v-for="worker in workers" :key="worker.id" :item="worker"
            @add-worker-btn-click="addWorker(worker.user?.id)" :user="user" :order="item"/>
          </div>
          <div v-else :class="auctionStyles.searchItems">
            <empty-list-plug style="margin: 0 auto"
                title="Похоже, все HR'ы заняты" subtitle="Попробуйте заглянуть сюда позже" :show-icon="true"/>
          </div>
        </div>
      </div>
    </div>
    <ActionWithPersonDialog ref="exchange_contacts_dialog" title="Хотите обменяться контактами с этим специалистом?"
                            :subtitle="`Вам доступны 3 бесплатных<br>обмена контактами.`"
                            accept-text="Да, продолжить" decline-text="Отмена" :loading="addWorkerLoading"
                            @accept="addWorker"
    />
    <ActionWithPersonDialog ref="select_hr_dialog" title="Хотите выбрать исполнителем  этого специалиста?"
                            :subtitle="`После выбора исполнителя мы скроем<br>ваш заказ для новых откликов`"
                            accept-text="Да, продолжить" decline-text="Отмена"
                            @accept="chooseWorker" :loading="chooseWorkerLoading"
    />
    <FiltersHrSearchDialog ref="filters_hr_search_dialog"/>
    <CreateOrderDialog ref="create_order_dialog" @created="get"/>
    <ConfirmDialog ref="confirm_close_auction_dialog"
        title="Вы уверены, что хотите закрыть аукцион?" @success="closeOrder"/>
    <AddResponseDialog ref="add_response_dialog" @success="get"/>
  </div>
</template>

<script>
import LinkBack from "@/components/LinkBack";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
import Tag from "@/components/Tag";
import CandidateAvatar from "@/components/CandidateAvatar";
import PersonCardListItem from "@/components/PersonCardListItem";
import ActionWithPersonDialog from "@/dialogs/ActionWithPersonDialog";
import Dot from "@/components/Dot";
import FiltersHrSearchForm from "@/views/company/auctions/components/FiltersHrSearchForm";
import FiltersHrSearchDialog from "@/views/company/auctions/FiltersHrSearchDialog";
import SideNavigation from "@/components/SideNavigation";
import {AuctionItem} from "@/api/auction_item";
import EmptyListPlug from "@/components/EmptyListPlug";
import CreateOrderDialog from "@/views/company/auctions/Create";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import AuctionItemSkeleton from "@/components/skeletons/AuctionItemSkeleton";
import auctionStyles from '@/components/styles/AuctionItemStyles.module.css'
import HRCard from "@/components/HRCardResponse";
import LabelValueRow from "@/components/LabelValueRow";
import AddResponseDialog from "@/views/hr/auction/AddResponseDialog";
import HrCardResponse from "@/components/HRCardResponse";
import HrCardSearch from "@/components/HrCardSearch";
import PageHint from "@/components/PageHint";

export default {
  name: "AuctionItem",
  components: {
    PageHint,
    HrCardSearch,
    HrCardResponse,
    AddResponseDialog,
    LabelValueRow,
    HRCard,
    AuctionItemSkeleton,
    ConfirmDialog,
    CreateOrderDialog,
    EmptyListPlug,
    SideNavigation,
    FiltersHrSearchDialog,
    FiltersHrSearchForm,
    Dot,
    ActionWithPersonDialog,
    PersonCardListItem,
    CandidateAvatar,
    Tag,
    RekrooButton,
    RekrooIcon,
    LinkBack
  },
  data() {
    let deviceWidth = window.innerWidth;
    return {
      auctionStyles,
      activeSection: 'order',
      auctionClass: new AuctionItem({}),
      item: new AuctionItem({}),
      offers: [],
      filters: {
        location: '',
        scope: '',
        price_from: '',
        price_to: ''
      },
      workers: [],
      deviceWidth,
      loading: false,
      addWorkerLoading: false,
      chooseWorkerLoading: false,
      notFoundError: false,
    }
  },
  methods: {
    exchangeContacts(hr) {
      this.$refs.exchange_contacts_dialog.open(hr);
    },
    selectHr(hr) {
      this.$refs.select_hr_dialog.open(hr);
    },
    reset() {
      this.filters = {
        location: '',
            scope: '',
            price_from: '',
            price_to: ''
      };
      this.search();
    },
    get() {
      this.loading = true;
      let currentPath = this.$route.path.split('/')[1];
      this.auctionClass.get(this.$route.params.id, currentPath === 'hr' ? {hr: 1} : {}).then((data)=>{
        this.loading = false;
        this.item = data.response;
        this.notFoundError = false;
      }).catch((data)=>{
        this.loading = false;
        this.notFoundError = data.error?.type == 404;
      })
    },
    search() {
      this.auctionClass.workerSearch({...this.filtersToRequest(), order_id: this.$route.params.id}).then((data)=>{
        this.workers = data.response;
      })
    },
    filtersToRequest() {
      let filters = {...this.filters};
      if (filters.price_from) filters.price_from = filters.price_from.replaceAll(' ', '');
      if (filters.price_to) filters.price_to = filters.price_to.replaceAll(' ', '');
      return filters;
    },
    openEdit() {
      this.$refs.create_order_dialog.open(this.item.id);
    },
    addWorker(worker_id) {
      this.addWorkerLoading = true;
      this.auctionClass.addWorker(this.item.id, worker_id).then(()=>{
        this.get();
        this.addWorkerLoading = false;
        this.$refs.exchange_contacts_dialog.close();
      }).catch(()=>{
        this.addWorkerLoading = false;
      })
    },
    chooseWorker(worker_id) {
      this.chooseWorkerLoading = true;
      this.auctionClass.choose(this.item.id, worker_id).then(()=>{
        this.chooseWorkerLoading = false;
        this.get();
        this.$refs.select_hr_dialog.close();
      }).catch(()=>{
        this.chooseWorkerLoading = false;
      })
    },
    openConfirmCloseOrder() {
      this.$refs.confirm_close_auction_dialog.open();
    },
    closeOrder() {
      this.auctionClass.create({id: this.item.id, status: 2}).then(()=>{
        this.get();
        this.$notify({
          type: 'success',
          message: 'Заказ закрыт. Вы его сможете найти во вкладке Архив'
        })
      })
    },
    scrollToEl(i) {
      if (!this.navigation[i]) return;
      this.$router.push({hash: this.navigation[i].name}).catch(()=>{});
    },
    setActiveSectionLink() {
      window.addEventListener('scroll', () => {
        this.navigation.forEach((section)=>{
          if (this.$refs[section.name] && this.isInViewport(this.$refs[section.name] )) this.activeSection = section.name;
        })
      })
    },
    isInViewport(element) {
      if (!element) return false;
      const { top, left, bottom, right }  = element.getBoundingClientRect();
      return ((top > 0 && top < innerHeight) ||
              (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    appliedFilters() {
      return [];
    },
    navigation() {
      return [
        {
          label: 'Заказ',
          name: 'order',
          icon: 'filing',
        },
        {
          label: `Отклики (${this.item?.responses?.length || 0})`,
          name: 'response',
          icon: 'files',
        },
        {
          label: this.deviceWidth > 575 ? 'Найти HR-специалиста' : 'Специалисты',
          name: 'search',
          icon: 'info',
        },
      ];
    }
  },
  mounted() {
    this.setActiveSectionLink();
    this.get();
    if (this.$route.path.split('/')[1] === 'company') this.search();
  }
}
</script>

<style module lang="css">
.employeeSalary {
  margin-bottom: 16px !important;
}
</style>