<template>
  <el-dialog :visible.sync="isVisible" width="335px" custom-class="rekroo-dialog resume-filters-dialog" :center="true">
    <div :class="$style.resumeFiltersTitle">Фильтры</div>
    <catalog-filters-form :filters="filters" @apply="apply" :type="type"/>
    <div :class="$style.formActions">
      <rekroo-button width="100%" type="text" text_color="var(--gray-text)" @click="reset">Сбросить все</rekroo-button>
    </div>
  </el-dialog>
</template>

<script>
import CatalogFiltersForm from "@/views/company/catalog/components/CatalogFiltersForm";
import RekrooButton from "@/components/Button";
export default {
  name: "ResumeFiltersDialog",
  components: {RekrooButton, CatalogFiltersForm},
  props: ['filters', 'type'],
  emits: ['apply'],
  data: () => ({
    isVisible: false,
  }),
  methods: {
    open() {
      this.isVisible = true;
    },
    apply() {
      this.$emit('apply', this.filters);
      this.isVisible = false;
    },
    reset() {
      this.$emit('reset');
    }
  }
}
</script>

<style module lang="css">
.resumeFiltersTitle {
  padding-top: 20px;
  margin-bottom: 12px;
  font-size: var(--subheadline);
  font-weight: var(--body-medium-weight);
}

.formActions {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media screen and (max-width: 575px) {
  .resumeFiltersTitle {
    padding-top: 0;
  }
}
</style>