<template>
  <div class="page" :class="$style.updateResumePage" ref="page" :key="pageKey">
    <h1 :class="$style.updateResumePageTitle">
      {{ item.id ? item.position : 'Создание резюме' }}</h1>
    <el-form :model="item" :rules="rules" ref="form" autocomplete="on">
      <el-collapse v-model="collapseSections" accordion @change="onCollapseChange">
        <div v-if="!item.id" :class="$style.formContainer" ref="import">
          <div :class="$style.formInner">
            <el-collapse-item class="resume-collapse" name="import">
              <template #title>
                <div :class="$style.formHeader">Импортируйте своё резюме с hh.ru</div>
              </template>
              <div :class="[$style.formContent]" style="padding-top: 2px">
                <div :class="$style.importContainer">
                  <el-form-item label="" style="flex-grow: 1; margin-bottom: 4px !important" :error="parseError ? ' ' : ''">
                    <el-input v-model="parseLink" placeholder="Ссылка на резюме hh.ru" @input="parseError = false"/>
                  </el-form-item>
                  <rekroo-button @click="parseResume" :loading="importLoading" height="50px">Импортировать
                  </rekroo-button>
                </div>
                <div :class="$style.formSubtitle"
                :style="parseError ? 'color: var(--error-red)' : ''">Убедитесь, что открыт доступ по ссылке</div>
              </div>
            </el-collapse-item>
          </div>
        </div>
        <div :class="$style.formContainer" ref="personal">
          <div :class="$style.formInner">
            <form-loader v-if="getLoading"/>
            <el-collapse-item class="resume-collapse" name="personal">
              <template #title>
                <div :class="$style.formHeader">
                  Персональные данные
                  <div :class="$style.iconHeaderContainer">
                    <rekroo-icon :icon=" collapseSections.includes('personal') ? 'chevron-up' : 'chevron-down'"
                                 color="#fff" width="14"/>
                  </div>
                </div>
              </template>
              <div :class="$style.formContent">
                <div :class="$style.mainInfoRow">
                  <image-upload title="Добавить фото" icon="profile" :item-image="item.image"
                                @on-upload-success="onUploadImageSuccess" @on-remove-btn-click="onRemoveImage"/>
                  <div :class="$style.nameSurnameContainer">
                    <el-form-item label="Имя" prop="name">
                      <el-input v-model="item.name"/>
                    </el-form-item>
                    <el-form-item label="Фамилия" prop="surname">
                      <el-input v-model="item.surname"/>
                    </el-form-item>
                  </div>
                </div>
                <div :class="$style.inputsRow">
                  <el-form-item label="Телефон" prop="phone">
                    <el-input v-model="item.phone" placeholder="+7 (   )" v-maska="phoneMask"
                              @input="onPhoneInput"
                              name="phone_input"
                              type="tel"
                              autocomplete="tel"
                              ref="phoneInput"/>
                  </el-form-item>
                  <el-form-item label="Email" prop="email">
                    <el-input v-model="item.email" @input="v=>v.length === 1 ? $refs.form.validate(()=>{}):null"
                              type="email" autocomplete="email"/>
                  </el-form-item>
                </div>
                <div :class="$style.inputsRow">
                  <el-form-item label="Город проживания">
                    <el-autocomplete
                        placeholder="" v-model="item.location"
                        :fetch-suggestions="fetchSuggestionsLocation"
                        :trigger-on-focus="false"/>
                    <el-checkbox label="Готов(а) к переезду" v-model="item.relocation"/>
                  </el-form-item>
                </div>
                <el-form-item label="О себе">
                  <el-input type="textarea"
                            placeholder="Расскажите кратко о себе.  Помните, что эта часть вашего резюме должна захватить внимание рекрутера — будьте честными, но в то же время уверенными в себе."
                            v-model="item.about" :autosize="{minRows: 5, maxRows: 10}"
                  />
                </el-form-item>
                <rekroo-button @click="toggleSection('additional_info')"
                               :class="$style.additionalInfoButton"
                               color="var(--gray)" width="100%" text_color="var(--primary)">
                  {{ formSections.additional_info ? '-' : '+' }} Дополнительная информация
                </rekroo-button>
                <div
                    :class="[$style.additionalInfoContainer, {[$style.formContentCollapsed]: !formSections.additional_info }]">
                  <div :class="$style.inputsRow">
                    <el-form-item label="Дата рождения" prop="birthday">
                      <div :class="$style.birthdayContainer">
                        <el-input v-model="tempBirthday.day" v-maska="'##'" placeholder="ДД"
                                  :class="$style.birthdayInput"
                                  @input="v=>{v.length === 2 ? $refs.monthSelect.$el.click() : ''}"/>
                        <el-select ref="monthSelect" v-model="tempBirthday.month" placeholder="ММ"
                                   :class="$style.birthdayInput"
                                   @change="onMonthSelect">
                          <el-option v-for="(month, i) in $store.state.months" :key="i" :value="i+1" :label="month"/>
                        </el-select>
                        <el-input ref="yearInput" v-model="tempBirthday.year" v-maska="'####'" placeholder="ГГГГ"
                                  :class="$style.birthdayInput">
                          <template #suffix v-if="Object.values(tempBirthday).filter(v=>v).length">
                            <div @click="tempBirthday = {}" class="el-icon-circle-close"/>
                          </template>
                        </el-input>
                      </div>
                      <!--                      <el-date-picker v-model="item.birthday" type="date" format="dd.MM.yyyy" lang="ru"
                                                            placeholder="ДД.ММ.ГГГГ" class="no-prefix" value-format="yyyy-MM-dd"
                                                            v-maska="'##.##.####'"
                                            />-->
                    </el-form-item>
                    <el-form-item label="Пол">
                      <el-select v-model="item.gender" placeholder="Выберите из списка">
                        <el-option v-for="(item, key) in $store.state.systemParams.genders" :key="key" :label="item"
                                   :value="key*1"/>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div :class="$style.inputsRow">
                    <el-form-item label="Гражданство">
                      <el-input v-model="item.citizenship"/>
                    </el-form-item>
                    <el-form-item label="Категория прав (при наличии)">
                      <el-select v-model="item.driver_license" placeholder="Выберите из списка" multiple>
                        <el-option v-for="(item, i) in $store.state.systemParams.driver_license_types" :key="i"
                                   :label="item" :value="i*1"/>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </div>
          <PageHint :class="[{[$style.formContentCollapsed]: !collapseSections.includes('personal') }, $style.pageHint]"
                    title="Фотография"
                    subtitle="Отличный способ выделить резюме. Подойдет деловое или нейтральное фото, где хорошо видно ваше лицо.">
            <template #custom>
              <div :class="$style.pictureExampleContainer">
                <div :class="[$style.pictureExampleLabel, $style.good]">
                  <rekroo-icon icon="check" color="var(--active-green)" style="margin-right: 10px"/>
                  Хорошо
                </div>
                <div :class="$style.pictureExamples">
                  <img v-for="i in 3" :key="i" :src="`/img/resume_photo_examples/example_${i}_good.jpg`" alt="">
                </div>
                <div :class="[$style.pictureExampleLabel, $style.bad]">
                  <rekroo-icon icon="check" color="var(--error-red)" style="margin-right: 10px;"/>
                  Плохо
                </div>
                <div :class="$style.pictureExamples">
                  <img v-for="i in 3" :key="i" :src="`/img/resume_photo_examples/example_${i}_bad.jpg`" alt="">
                </div>
              </div>
            </template>
          </PageHint>
        </div> <!--Персональные данные-->
        <template v-if="!getLoading">
          <div :class="$style.formContainer" ref="position">
            <div :class="$style.formInner">
              <el-collapse-item class="resume-collapse" name="position">
                <template #title>
                  <div :class="$style.formHeader">
                    Желаемая должность
                    <div :class="$style.iconHeaderContainer">
                      <rekroo-icon :icon="collapseSections.includes('position') ? 'chevron-up' : 'chevron-down'"
                                   color="#fff" width="14"/>
                    </div>
                  </div>
                  <div :class="$style.formItemCompleted" v-if="!collapseSections.includes('position') && item.position">
                    <div :class="$style.formItemCompletedContent">
                      <div :class="$style.formItemCompletedTitle">{{ item.position }}</div>
                      <div :class="$style.formItemCompletedSubtitle">
                        {{ salaryParse(item.salary, null, $store.state.systemParams.currencies[item.currency]?.short) }}
                      </div>
                    </div>
                    <div :class="$style.formItemCompletedActions">
                      <rekroo-button @click="toggleSection('position')"
                                     color="transparent" icon="pencil" icon_color="var(--primary)" icon_width="24px"
                                     style="padding: 0"/>
                    </div>
                  </div>
                </template>
                <div :class="[$style.formContent, $style.formContentWithPB]">
                  <el-form-item label="Должность" prop="position">
                    <el-autocomplete
                        placeholder="Например, продуктовый дизайнер" v-model="item.position"
                        :fetch-suggestions="fetchSuggestionsPos"
                        :trigger-on-focus="false"
                    />
                  </el-form-item>
                  <div :class="$style.inputsRow">
                    <el-form-item label="Зарплата">
                      <div :class="$style.salaryContainer">
                        <el-input v-model="item.salary" v-maska="thousandMask" @input="item.salary_by_agr = false"
                                  type="tel"/>
                        <el-select placeholder="" v-model="item.currency" style="max-width: 75px;">
                          <el-option v-for="(item, key) in $store.state.systemParams.currencies" :key="key"
                                     :label="item.short" :value="key"/>
                        </el-select>
                      </div>
                      <el-checkbox label="по договоренности" v-model="item.salary_by_agr" @change="item.salary = ''"/>
                    </el-form-item>
                    <el-form-item label="Готовность к командировкам">
                      <el-select placeholder="Выберите из списка" v-model="item.business_trip">
                        <el-option v-for="(item, i) in $store.state.systemParams?.business_trip_statuses" :key="i"
                                   :label="item" :value="i"/>
                      </el-select>
                      <div :class="$style.scheduleCheckboxesContainer">
                        <el-form-item label="График">
                          <div :class="$style.checkboxInner">
                            <el-checkbox :class="$style.employmentAndScheduleCheckbox"
                                         v-for="(option, i) in $store.state.systemParams.schedule_options" :key="i"
                                         :value="item.schedule.includes(i*1)" :label="option"
                                         @change="onScheduleSelect(i*1)"/>
                          </div>
                        </el-form-item>
                        <el-form-item label="Занятость">
                          <div :class="$style.checkboxInner">
                            <el-checkbox
                                :class="$style.employmentAndScheduleCheckbox"
                                v-for="(option, i) in $store.state.systemParams.employment_types" :key="i"
                                :value="item.employment_type.includes(i*1)" :label="option"
                                @change="onJobTypeSelect(i*1)"/>
                          </div>
                        </el-form-item>
                      </div>
                    </el-form-item>
                  </div>
                </div>
              </el-collapse-item>
            </div>
            <PageHint :class="[{[$style.formContentCollapsed]: !formSections.position }, $style.pageHint]"
                      title="Должность"
                      subtitle="Если только начинаете карьеру и не знаете какую должностью выбрать, пишите «специалист»."/>
          </div> <!--Желаемая должность-->
          <div :class="$style.formContainer" ref="education">
            <div :class="$style.formInner">
              <el-collapse-item class="resume-collapse" name="education">
                <template #title>
                  <div :class="$style.formHeader">
                    Образование
                    <div :class="$style.iconHeaderContainer">
                      <rekroo-icon :icon="collapseSections.includes('education') ? 'chevron-up' : 'chevron-down'"
                                   color="#fff" width="14"/>
                    </div>
                  </div>
                  <template>
                    <div :class="[$style.formItemCompleted]"
                         v-for="(edu_item, i) in item.education" :key="i">
                      <div :class="$style.formItemCompletedContent">
                        <div :class="$style.formItemCompletedTitle">{{ edu_item.university }}</div>
                        <div :class="$style.formItemCompletedSubtitle">{{ edu_item.speciality }}
                          {{
                            edu_item.degree && $store.state.systemParams.educational_levels
                                ? `(${$store.state.systemParams.educational_levels[edu_item.degree]})` : ''
                          }}
                        </div>
                      </div>
                      <div :class="$style.formItemCompletedActions">
                        <rekroo-button @click="removeItem('education', i)"
                                       color="transparent" icon="close" icon_color="var(--primary)" icon_width="24px"
                                       style="padding: 0"/>
                        <rekroo-button color="transparent" icon="pencil" icon_color="var(--primary)" icon_width="24px"
                                       style="padding: 0" @click="editItem('education', i)"/>
                      </div>
                    </div>
                  </template>
                  <rekroo-button v-if="!collapseSections.includes('education') && item.education.length"
                                 :class="$style.addItemButton"
                                 color="var(--gray)" width="100%" text_color="var(--primary)"
                                 @click="saveItem('education')">+ Еще одно место обучения
                  </rekroo-button>
                </template>
                <div :class="[$style.formContent]">
                  <div :class="$style.educationItem">
                    <div :class="$style.inputsRow">
                      <el-form-item label="Уровень образования">
                        <el-select placeholder="Выберите из списка" v-model="temporaryItems.education.degree"
                                   clearable
                                   @change="eduError = false">
                          <el-option v-for="(lvl, j) in $store.state.systemParams.educational_levels" :key="j"
                                     :value="j"
                                     :label="lvl"/>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="Учебное заведение" :error="eduError ? `Укажите учебное заведение` : ''"
                                    prop="university"
                                    :rules="[{ trigger: 'blur', validator:  universityValidator},
                                { trigger: 'change', validator:  universityValidator},
                                ]"
                      >
                        <el-input placeholder="Наименование или аббревиатура"
                                  v-model="temporaryItems.education.university"
                                  @input="eduError = false"/>
                      </el-form-item>
                    </div>
                    <div :class="$style.inputsRow">
                      <el-form-item label="Специальность">
                        <el-input v-model="temporaryItems.education.speciality" @input="eduError = false"/>
                      </el-form-item>
                      <el-form-item label="Год окончания"
                                    prop="date_to"
                                    type="tel"
                                    :rules="{ trigger: 'blur', validator: eduYearEndValidator}"
                                    :error="eduYearEndError ? 'Неверно указан год окончания' : ''">
                        <el-input v-model="temporaryItems.education.date_to" v-maska="'####'"
                                  @input="eduError = false"/>
                      </el-form-item>
                    </div>
                    <el-form-item label="Файлы">
                      <el-upload
                          ref="education_upload"
                          :action="`${itemClass.url}files/upload`"
                          :with-credentials="true"
                          :show-file-list="false"
                          accept="application/msword, application/pdf, image/*"
                          multiple
                          :before-upload="file=>beforeUpload(file, 'education')"
                          :on-success="data=>onSuccess(data, 'education')"
                          :on-error="()=>onError('education')">
                        <rekroo-button type="outlined" width="100%" height="60px"
                                       :loading="fileUploading.education"
                                       :class="$style.uploadButton">
                          Добавить фото или файлы <br>
                          <span>Допустимый размер файла 5 МБ</span>
                        </rekroo-button>
                      </el-upload>
                      <div v-if="temporaryItems.education.files" :class="$style.filesContainer">
                        <file-thumbnail v-for="(file, i) in temporaryItems.education.files" :key="i"
                                        :file="file" bg_color="#FFF" @on-remove-btn-click="removeFile(i, 'education')"/>
                      </div>
                    </el-form-item>
                    <rekroo-button style="margin-top: 16px"
                                   v-if="hasItemValues(temporaryItems.education)" type="text"
                                   text_color="var(--purple)"
                                   height="fit-content"
                                   @click="saveItem('education')">Сохранить
                    </rekroo-button>
                  </div>
                  <rekroo-button v-if="collapseSections.includes('education') || item.education.length"
                                 :class="$style.addItemButton"
                                 color="var(--gray)" width="100%" text_color="var(--primary)"
                                 @click="saveItem('education')">+ Еще одно место обучения
                  </rekroo-button>
                </div>
              </el-collapse-item>
            </div>
            <PageHint
                :class="[{[$style.formContentCollapsed]: !collapseSections.includes('education') }, $style.pageHint]"
                title="Образование"
                subtitle="Не указывайте начальные или средние школы, если они не были вашим последним образованием"/>
          </div> <!--Образование-->
          <div :class="$style.formContainer" ref="work">
            <div :class="$style.formInner">
              <el-collapse-item class="resume-collapse" name="work">
                <template #title>
                  <div :class="$style.formHeader">
                    Опыт работы
                    <div :class="$style.iconHeaderContainer">
                      <rekroo-icon :icon=" collapseSections.includes('work') ? 'chevron-up' : 'chevron-down'"
                                   color="#fff"
                                   width="14"/>
                    </div>
                  </div>
                  <template>
                    <div :class="$style.formItemCompleted" v-for="(exp_item, i) in item.work" :key="i">
                      <div :class="$style.formItemCompletedContent">
                        <div :class="$style.formItemCompletedTitle">{{ exp_item.position }}</div>
                        <div :class="$style.formItemCompletedSubtitle">{{ exp_item.company }}</div>
                      </div>
                      <div :class="$style.formItemCompletedActions">
                        <rekroo-button @click="removeItem('work', i)"
                                       color="transparent" icon="close" icon_color="var(--primary)" icon_width="24px"
                                       style="padding: 0"/>
                        <rekroo-button @click="editItem('work', i)"
                                       color="transparent" icon="pencil" icon_color="var(--primary)" icon_width="24px"
                                       style="padding: 0"/>
                      </div>
                    </div>
                  </template>
                  <rekroo-button
                      v-if="!collapseSections.includes('work') && item.work.length"
                      :class="$style.addItemButton"
                      color="var(--gray)" width="100%" text_color="var(--primary)"
                      @click="saveItem('work')">+ Еще одно место работы
                  </rekroo-button>
                </template>
                <div :class="[$style.formContent]">
                  <el-form-item v-if="!item.work.length">
                    <el-checkbox label="Без опыта работы" v-model="item.no_experience"/>
                  </el-form-item>
                  <el-form-item v-if="item.no_experience">
                    <p :class="$style.noExpHint">90% работодателей приглашают на собеседование кандидатов, у которых
                      есть
                      информация об опыте работы или указана причина его отсутствия.</p>
                    <el-input type="textarea" placeholder="Расскажите почему не было опыта работы."
                              v-model="item.experience_text"/>
                  </el-form-item>
                  <div :class="$style.experienceItems">
                    <div :class="$style.experienceItem">
                      <div :class="$style.inputsRow">
                        <el-form-item label="Должность" :error="workError ? 'Укажите должность': ''"
                                      prop="position"
                                      :rules="[{ trigger: 'blur', validator: workValidator},
                                  { trigger: 'change', validator: workValidator}]">
                          <el-autocomplete v-model="temporaryItems.work.position" @input="workError = false"
                                           :fetch-suggestions="fetchSuggestionsPos"
                                           :trigger-on-focus="false"/>
                        </el-form-item>
                        <el-form-item label="Компания">
                          <el-input v-model="temporaryItems.work.company" @input="workError = false"/>
                        </el-form-item>
                      </div>
                      <div :class="$style.inputsRow">
                        <el-form-item label="Дата начала работы"
                                      :error="
                                  workDatesErrors.fromIsAfterTo ? 'Дата начала позже окончания' :
                                  workDatesErrors.from ? 'Неверно указана дата начала' : ''">
                          <div :class="$style.experienceDateRow">
                            <el-select placeholder="Месяц" v-model="temporaryItems.work.month_start"
                                       @change="workDatesErrors.from = false; workDatesErrors.fromIsAfterTo = false"
                                       clearable>
                              <el-option v-for="(month, j) in $store.state.months" :key="j" :label="month"
                                         :value="j+1"/>
                            </el-select>
                            <el-input placeholder="Год" style="max-width: 100px;"
                                      type="tel"
                                      v-model="temporaryItems.work.year_start"
                                      v-maska="'####'"
                                      @input="workDatesErrors.from = false; workDatesErrors.fromIsAfterTo = false"/>
                          </div>
                        </el-form-item>
                        <el-form-item label="Дата окончания работы"
                                      :error="workDatesErrors.to ? 'Неверно указана дата окончания' : ''">
                          <div :class="$style.experienceDateRow">
                            <el-select placeholder="Месяц" v-model="temporaryItems.work.month_end"
                                       :disabled="temporaryItems.work.present"
                                       @change="workDatesErrors.to = false; workDatesErrors.fromIsAfterTo = false"
                                       clearable>
                              <el-option v-for="(month, j) in $store.state.months" :key="month" :label="month"
                                         :value="j+1"/>
                            </el-select>
                            <el-input placeholder="Год" style="max-width: 100px;" v-model="temporaryItems.work.year_end"
                                      type="tel"
                                      :disabled="temporaryItems.work.present" v-maska="'####'"
                                      @input="workDatesErrors.to = false; workDatesErrors.fromIsAfterTo = false"/>
                          </div>
                          <el-checkbox label="По настоящее время" v-model="temporaryItems.work.present"
                                       @change="temporaryItems.work.month_end = ''; temporaryItems.work.year_end = ''; workDatesErrors.to = false;
                                   workDatesErrors.fromIsAfterTo = false;"/>
                        </el-form-item>
                      </div>
                      <el-form-item label="Обязанности и достижения">
                        <el-input type="textarea" v-model="temporaryItems.work.description"
                                  :autosize="{minRows: 5, maxRows: 10}"
                                  placeholder="Расскажите кратко о себе. Максимально подробно опишите опыт работы, если он у вас есть. Помните, что эта часть вашего резюме должна захватить внимание рекрутера — будьте честными, но в то же время уверенными в себе"
                                  @input="workError = false"/>
                      </el-form-item>
                      <rekroo-button
                          v-if="hasItemValues(temporaryItems.work)"
                          type="text" text_color="var(--purple)" height="fit-content"
                          @click="saveItem('work')">Сохранить
                      </rekroo-button>
                    </div>
                  </div>
                  <rekroo-button
                      :class="$style.addItemButton"
                      color="var(--gray)" width="100%" text_color="var(--primary)"
                      @click="saveItem('work')">+ Еще одно место работы
                  </rekroo-button>
                </div>
              </el-collapse-item>
            </div>
            <PageHint
                :class="[{[$style.formContentCollapsed]: !formSections.experience }, $style.experiencePageHint, $style.pageHint]"
                title="Обязанности и достижения"
                subtitle="Составьте свой текст с учетом потребностей работодателя и дополните его собственным опытом.">
              <template #custom>
                <div :class="$style.experiencePageHintLabel">Например</div>
                <ul :class="$style.experiencePageHintList">
                  <li>работа за кассой</li>
                  <li>расчет заработной платы сотрудников в программе 1С</li>
                  <li>разработка плана для отдела продаж</li>
                </ul>
              </template>
            </PageHint>
          </div> <!--Опыт работы-->
          <div :class="$style.formContainer" ref="skills">
            <div :class="$style.formInner">
              <el-collapse-item class="resume-collapse" name="skills"
                                :disabled="disabledSection.includes('skills')">
                <template #title>
                  <div :class="$style.formHeader">
                    Навыки и языки
                    <div :class="$style.iconHeaderContainer">
                      <rekroo-icon :icon=" collapseSections.includes('skills') ? 'chevron-up' : 'chevron-down'"
                                   color="#fff"
                                   width="14"/>
                    </div>
                  </div>
                  <div v-if="item.skills.length" :class="$style.skillTags">
                    <tag v-for="(skill, i) in item.skills" :key="skill" :text="skill" color="rgba(206, 217, 227, 0.6)"
                         icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                         @onIconClick="removeItem('skills', i)"
                    />
                    <tag v-if="!collapseSections.includes('skills')" text="+ Добавить"
                         color="var(--purple)" text_color="#FFF"/>
                  </div>
                  <div v-if="item.languages.length" :class="$style.skillTags">
                    <tag v-for="(lang, i) in item.languages" :key="i"
                         :text="$store.state.systemParams.languageNames ?
                   `${$store.state.systemParams.languageNames[Object.keys(lang)[0]]} - ${$store.state.systemParams.languageLevels[lang[Object.keys(lang)[0]]]}` : ''"
                         color="rgba(206, 217, 227, 0.6)"
                         icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                         @onIconClick="removeLang(i)"
                    />
                    <tag v-if="!collapseSections.includes('skills')" text="+ Добавить" color="var(--purple)"
                         text_color="#FFFFFF"/>
                  </div>
                </template>
                <div :class="[$style.formContent]">
                  <el-form-item label="Профессиональный навык" style="margin-bottom: 36px">
                    <div :class="$style.skillInputRow">
                      <el-autocomplete
                          ref="skill_input"
                          placeholder="Начните вводить" v-model="tempSkill"
                          :fetch-suggestions="fetchSuggestionsSkills"
                          :trigger-on-focus="false"
                          @select="addSkill"
                          @keydown.native="onKeydownSkillInput"
                      />
                      <rekroo-button @click="onKeydownSkillInput" color="var(--purple)" width="187px"
                                     :class="$style.addSkillButton">Добавить навык
                      </rekroo-button>
                    </div>
                  </el-form-item>
                  <div :class="$style.skillInputRow">
                    <el-form-item label="Язык" style="flex-grow: 1">
                      <el-select placeholder="Выберите из списка" v-model="tempLang.lang">
                        <el-option v-for="(lang, key) in $store.state.systemParams.languageNames"
                                   :disabled="item.languages && item.languages.map(_lang=>Object.keys(_lang)[0]).includes(key)"
                                   :key="key" :value="key" :label="lang"/>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Уровень владения" prop="lang"
                                  style="flex-grow: 1" :error="showLangError ? 'Укажите уровень владения' : ''">
                      <el-select placeholder="Выберите из списка" v-model="tempLang.lvl"
                                 @change="showLangError = false">
                        <el-option v-for="(lang, key) in $store.state.systemParams.languageLevels" :key="key"
                                   :value="key"
                                   :label="lang"/>
                      </el-select>
                    </el-form-item>
                    <rekroo-button @click="addLang"
                                   color="var(--purple)" width="187px" :class="$style.addSkillButton"
                                   style="margin-bottom: 16px;">
                      Добавить язык
                    </rekroo-button>
                  </div>
                </div>
              </el-collapse-item>
            </div>
            <PageHint
                :class="[{[$style.formContentCollapsed]: !formSections.skills }, $style.experiencePageHint, $style.pageHint]"
                title="Навыки"
                subtitle="Указывайте профессиональные навыки, личные качества не подойдут">
              <template #custom>
                <div :class="$style.experiencePageHintLabel">Например</div>
                <p style="font-size: var(--small-text)">Документальное сопровождение, ведение отчетности, кассовые
                  операции.</p>
              </template>
            </PageHint>
          </div> <!--Навыки и языки-->
          <div :class="$style.formContainer" ref="courses">
            <div :class="$style.formInner">
              <el-collapse-item class="resume-collapse" name="courses">
                <template #title>
                  <div :class="$style.formHeader">
                    Курсы и сертификаты
                    <div :class="$style.iconHeaderContainer">
                      <rekroo-icon :icon="collapseSections.includes('courses') ? 'chevron-up' : 'chevron-down'"
                                   color="#fff"
                                   width="14"/>
                    </div>
                  </div>
                  <template>
                    <div :class="$style.formItemCompleted" v-for="(course, i) in item.courses" :key="i">
                      <div :class="$style.formItemCompletedContent">
                        <div :class="$style.formItemCompletedTitle">{{ course.name }}</div>
                        <div :class="$style.formItemCompletedSubtitle">{{ course.organization }}</div>
                      </div>
                      <div :class="$style.formItemCompletedActions">
                        <rekroo-button @click="removeItem('courses', i)"
                                       color="transparent" icon="close" icon_color="var(--primary)" icon_width="24px"
                                       style="padding: 0"/>
                        <rekroo-button @click="editItem('courses',i)"
                                       color="transparent" icon="pencil" icon_color="var(--primary)" icon_width="24px"
                                       style="padding: 0"/>
                      </div>
                    </div>
                  </template>
                  <rekroo-button
                      v-if="!collapseSections.includes('courses') && item.courses.length"
                      :class="$style.addItemButton"
                      color="var(--gray)" width="100%" text_color="var(--primary)"
                      @click="saveItem('courses')">+ Еще один сертификат
                  </rekroo-button>
                </template>
                <div :class="[$style.formContent]">
                  <div :class="$style.courseItem">
                    <div :class="$style.inputsRow">
                      <el-form-item label="Название курса" :error="courseError ? 'Укажите название': ''">
                        <el-input placeholder="Наименование или аббревиатура" v-model="temporaryItems.courses.name"
                                  prop="temporaryItems.courses.name"
                                  :rules="[{ trigger: 'blur', validator: courseValidator},
                                  { trigger: 'change', validator: courseValidator}]"
                                  @input="courseError = false"/>
                      </el-form-item>
                      <el-form-item label="Организация, проводившая обучение">
                        <el-input placeholder="Наименование или аббревиатура"
                                  v-model="temporaryItems.courses.organization"/>
                      </el-form-item>
                    </div>
                    <el-form-item label="Ссылка на сертификат">
                      <el-input v-model="temporaryItems.courses.link">
                        <template #prepend>https://</template>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="Файлы">
                      <el-upload
                          ref="education_upload"
                          :action="`${itemClass.url}files/upload`"
                          :with-credentials="true"
                          :show-file-list="false"
                          accept="application/msword, application/pdf, image/*"
                          multiple
                          :before-upload="file=>beforeUpload(file, 'courses')"
                          :on-success="data=>onSuccess(data, 'courses')"
                          :on-error="()=>onError('courses')">
                        <rekroo-button type="outlined" width="100%" height="60px"
                                       :loading="fileUploading.education"
                                       :class="$style.uploadButton">
                          Добавить фото или файлы <br>
                          <span>Допустимый размер файла 5 МБ</span>
                        </rekroo-button>
                      </el-upload>
                      <div v-if="temporaryItems.courses.files" :class="$style.filesContainer">
                        <file-thumbnail v-for="(file, i) in temporaryItems.courses.files" :key="i"
                                        :file="file" bg_color="#FFF" @on-remove-btn-click="removeFile(i, 'courses')"/>
                      </div>
                    </el-form-item>
                    <rekroo-button v-if="hasItemValues(temporaryItems.courses)"
                                   type="text" text_color="var(--purple)" height="fit-content"
                                   @click="saveItem('courses')">Сохранить
                    </rekroo-button>
                  </div>
                  <rekroo-button
                      :class="$style.addItemButton"
                      color="var(--gray)" width="100%" text_color="var(--primary)"
                      @click="saveItem('courses')">+ Еще один сертификат
                  </rekroo-button>
                </div>
              </el-collapse-item>
            </div>
          </div> <!--Курсы и сертификаты-->
          <div :class="$style.formActions">
            <rekroo-button :loading="loading" width="100%" height="50px" @click="save(0)" type="gray"
                           text_color="var(--primary)">Сохранить как
              неопубликованное
            </rekroo-button>
            <rekroo-button :loading="loading" width="100%" @click="save(1)" height="50px">Опубликовать резюме
            </rekroo-button>
          </div>
        </template>
      </el-collapse>
    </el-form>
    <UserAgreementDialog ref="user_agreement_dialog"/>
    <SuccessDialog ref="success_dialog" title="Отлично! Резюме сохранено"
                   subtitle="Все резюме можно посмотреть в списке резюме в личном кабинете"
                   action-button-text="Перейти к резюме" @action="onSave"/>
  </div>
</template>

<script>
import PageHint from "@/components/PageHint";
import RekrooIcon from "@/components/Icon";
import ImageUpload from "@/components/ImageUpload";
import RekrooButton from "@/components/Button";
import Tag from "@/components/Tag";
import SuccessDialog from "@/dialogs/SuccessDialog";
import {Resume} from "@/api/resume";
import UserAgreementDialog from "@/views/applicant/resume/components/UserAgreementDialog";
import FormLoader from "@/components/FormLoader";
import {Item} from "@/api/item";
import FileThumbnail from "@/components/FileThumbnail";

// import MaskInput from 'maska';

export default {
  name: "UpdateCandidateResume",
  components: {
    FileThumbnail,
    FormLoader, UserAgreementDialog, SuccessDialog, Tag, RekrooButton, ImageUpload, RekrooIcon, PageHint
  },
  data() {
    let birthdayValidator = (_, value, callback) => {
      if ((Object.values(this.tempBirthday).filter(v => v).length && Object.values(this.tempBirthday).filter(v => v).length < 3) ||
          +this.tempBirthday.day > 31 || +this.tempBirthday.day <= 0 || +this.tempBirthday.year < 1900) {
        callback(new Error('Дата указана неверно'));
      } else if (Object.values(this.tempBirthday).filter(v => v).length &&
          this.$moment().diff(this.$moment(`${this.tempBirthday.day}.${this.tempBirthday.month}.${this.tempBirthday.year}`, 'DD.MM.YYYY'), 'years') < 14) {
        callback(new Error('Вам должно быть больше 14 лет'));
      } else callback();
    };
    let eduYearEndValidator = (_, value, callback) => {
      if (!this.temporaryItems.education.date_to) {
        callback();
        return;
      }
      if (this.temporaryItems.education.date_to * 1 > 2100 || this.temporaryItems.education.date_to * 1 < 1900) {
        callback(new Error('Неверно указан год окончания'));
      } else callback();
    };
    let universityValidator = (_, value, callback) => {
      if (this.hasItemValues(this.temporaryItems.education)
          && !this.temporaryItems.education.university)
        callback(new Error('Укажите учебное заведение'));
      else callback()
    };
    let workValidator = (_, value, callback) => {
      if (this.hasItemValues(this.temporaryItems.work) && !this.temporaryItems.work.position)
        callback(new Error('Укажите должность'));
      else callback()
    };
    let courseValidator = (_, value, callback) => {
      if (this.hasItemValues(this.temporaryItems.courses) && !this.temporaryItems.courses.name)
        callback(new Error('Укажите название'));
      else callback()
    };
    let contactsValidator = (_, value, callback) => {
      if (!this.item.phone && !this.item.email)
        callback(new Error('Укажите телефон или e-mail'));
      else callback()
    };
    let langValidator = (_, value, callback) => {
      if (this.tempLang.lang && !this.tempLang.lvl)
        callback(new Error('Укажите уровень владения'));
      else callback()
    };
    return {
      eduYearEndValidator,
      universityValidator,
      workValidator,
      courseValidator,
      itemClass: new Item({}),
      formSections: {
        personal: true,
        position: false,
        education: false,
        experience: false,
        skills: false,
        courses: false,
        portfolio: false,
        additional_info: false,
      },
      resumeClass: new Resume({}),
      item: new Resume({
        education: [],
        work: [],
        courses: [],
      }),
      temporaryItems: {
        education: {},
        work: {},
        courses: {},
      },
      tempSkill: '',
      tempLang: {
        lang: '',
        lvl: ''
      },
      rules: {
        name: [
          {required: true, message: 'Укажите имя', trigger: 'change'},
          {required: true, message: 'Укажите имя', trigger: 'blur'},
        ],
        surname: [
          {required: true, message: 'Укажите фамилию', trigger: 'change'},
          {required: true, message: 'Укажите фамилию', trigger: 'blur'},
        ],
        phone: [
          {trigger: 'blur', validator: contactsValidator},
          {trigger: 'change', validator: contactsValidator},
        ],
        email: [
          {type: 'email', message: 'E-mail указан неверно', trigger: 'blur'},
          {trigger: 'blur', validator: contactsValidator},
          {trigger: 'change', validator: contactsValidator},
        ],
        birthday: [
          {trigger: 'change', validator: birthdayValidator},
        ],
        lang: [
          {trigger: 'blur', validator: langValidator},
          {trigger: 'change', validator: langValidator},
        ],
        position: [
          {required: true, message: 'Укажите желаемую должность', trigger: 'change'},
          {required: true, message: 'Укажите желаемую должность', trigger: 'blur'},
        ],
      },
      eduError: false,
      eduYearEndError: false,
      workError: false,
      workDatesErrors: {
        from: false,
        to: false,
        fromIsAfterTo: false,
      },
      courseError: false,
      loading: false,
      getLoading: false,
      phoneMask: '',
      thousandMask: '',
      collapseSections: ['import', 'personal'],
      disabledSection: [],
      parseLink: '',
      fileUploading: {
        education: false,
        courses: false,
      },
      showLangError: false,
      tempBirthday: {
        day: '',
        month: '',
        year: ''
      },
      importLoading: false,
      parseError: false,
      pageKey: 0,
    }
  },
  methods: {
    onMonthSelect() {
      this.$nextTick(() => {
        this.$refs.yearInput.$el.children[0].focus();
      })
    },
    onPhoneInput(v) {
      v.length === 1 ? this.$refs.form.validate(() => {
      }) : null;
      if (this.ignore8onPhoneInput(v)) this.item.phone = this.ignore8onPhoneInput(v);
    },
    save(status) {
      ['education', 'work', 'courses'].forEach(section => {
        this.saveItem(section);
      })
      this.$refs.form.validate((valid) => {
        if (valid && !this.eduError && !this.workError && !this.courseError
            && !this.workDatesErrors.from && !this.workDatesErrors.to && !this.workDatesErrors.fromIsAfterTo) {
          this.loading = true;
          this.resumeClass.create(this.dataToRequest()).then((data) => {
            this.item = data.response;
            this.loading = false;
            if (this.item.id) {
              if (!status) this.$notify({
                type: 'success',
                title: 'Резюме успешно сохранено'
              })
            } else {
              this.$refs.success_dialog.open();
            }
            if (status === 1) this.publish();
            else this.$router.push('/applicant/resumes');
            // else if (!this.$route.params.id) this.$router.replace(`/applicant/resumes/update/${data.response.id}`);
          }).catch((data) => {
            this.loading = false;
            if (data.error?.type === 'agreement') {
              this.$refs.user_agreement_dialog.open(this.dataToRequest());
            }
          })
        } else {
          this.scrollToError(this.$refs.page);
          if (!this.item.name || !this.item.surname || (!this.item.email && !this.item.phone)) {
            this.collapseSections = 'personal';
          } else if (!this.item.title) {
            this.collapseSections = 'position';
          }
        }
      })
    },
    publish() {
      this.resumeClass.create({status: 1, id: this.item.id}).then(() => {
        this.$notify({
          type: 'success',
          title: 'Резюме успешно опубликовано',
          duration: 3000
        });
        this.gtmEvent('resume_success_public');
        this.$router.push('/applicant/resumes');
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    onKeydown(key) {
      if (key.code === 'Enter') this.save();
    },
    get() {
      if (this.$route.params.id) {
        this.getLoading = true;
        this.resumeClass.get(this.$route.params.id).then((data) => {
          this.getLoading = false;
          this.item = this.parseResponse(data.response);
          this.phoneMask = this.$store.state.phone_mask;
          this.thousandMask = this.$store.state.maskThousandSeparator;
        }).catch(() => {
          this.getLoading = false;
        })
      }
    },
    dataToRequest() {
      this.addLang();
      let data = {...this.item};
      data.relocation = this.item.relocation ? 1 : 0;
      data.salary = this.item.salary ? this.item.salary.replaceAll(' ', '') : '';
      if (this.item.image && this.item.image.id) data.image = this.item.image.id;
      else delete data.image;
      if (this.item.education.length) {
        data.education = data.education.filter(i => Object.keys(i).length &&
            Object.values(i).filter(val => val).length);
        data.education.forEach(edu => {
          if (edu.files && edu.files.length) {
            edu.files = edu.files.map(file => file.id);
          }
        })
      }
      if (this.item.work.length) {
        data.work = data.work.filter(i => !Array.isArray(i) && Object.keys(i).length &&
            Object.values(i).filter(val => val).length);
        data.work.forEach(job => {
          if (job.year_start && job.month_start)
            job.date_from = `${job.year_start}-${job.month_start}`;
          if (job.year_end && job.month_end)
            job.date_to = `${job.year_end}-${job.month_end}`;
        })
      }
      if (this.item.courses.length) {
        data.courses = data.courses.filter(i => !Array.isArray(i) && Object.keys(i).length);
        this.item.courses.forEach(course => {
          if (course.link && !course.link.includes('http')) {
            course.link = 'https://' + course.link;
          }
        });
        data.courses.forEach(course => {
          if (course.files && course.files.length) {
            course.files = course.files.map(file => file.id);
          }
        })
      }
      if (this.tempSkill) {
        data.push(this.tempSkill);
        this.tempSkill = '';
      }
      if (data.languages.length) {
        let langs = data.languages;
        data.languages = {};
        langs.forEach(lang => {
          Object.assign(data.languages, lang);
        })
      }
      if (Object.values(this.tempBirthday).filter(v => v).length) {
        if (+this.tempBirthday.month < 10) {
          this.tempBirthday.month = `0${+this.tempBirthday.month}`;
        }
        if (+this.tempBirthday.day < 10) {
          this.tempBirthday.day = `0${+this.tempBirthday.day}`;
        }
        data.birthday = Object.values(this.tempBirthday).reverse().join('-');
      }
      data.status = 0;
      return data;
    },
    parseResponse(res) {
      let data = {...res};
      data.relocation = !!res.relocation;
      data.salary = res.salary ? res.salary.toString() : '';
      if (data.work && data.work.length) {
        data.work.forEach((job) => {
          if (job.date_from) {
            job.month_start = job.date_from.split('-')[1] * 1;
            job.year_start = job.date_from.split('-')[0];
          }
          if (job.date_to && job.date_to !== '---') {
            job.month_end = job.date_to.split('-')[1] * 1;
            job.year_end = job.date_to.split('-')[0];
          } else if (job.date_from) job.present = true;
        })
      }
      if (data.courses && data.courses.length) {
        data.courses.forEach(course => {
          if (course.link) {
            course.link = course.link.replace('https://', '');
          }
        })
      }
      if (data.birthday) {
        this.tempBirthday.day = +data.birthday.split('-')[2];
        this.tempBirthday.month = +data.birthday.split('-')[1];
        this.tempBirthday.year = +data.birthday.split('-')[0];
      }
      if (!data.gender || data.gender === '0') data.gender = '';
      if (!data.schedule) data.schedule = [];
      if (!data.employment_type) data.employment_type = [];
      if (!data.skills) data.skills = [];
      if (!data.languages) data.languages = [];
      let langs = [];
      for (let key in data.languages) {
        let obj = {};
        obj[key] = data.languages[key];
        langs.push(obj);
      }
      data.languages = langs;
      return data;
    },
    toggleSection(section) {
      this.formSections[section] = !this.formSections[section];
    },
    onScheduleSelect(val) {
      this.item.schedule = this.updateArray(this.item.schedule, val);
    },
    onJobTypeSelect(val) {
      this.item.employment_type = this.updateArray(this.item.employment_type, val);
    },
    fetchSuggestionsSkills(q, cb) {
      this.resumeClass.suggestions('skill', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    addSkill(skill) {
      if (!skill.value) return;
      this.item.skills.push(skill.value);
      this.tempSkill = '';
    },
    onKeydownSkillInput(e) {
      if (!e || e.code === 'Enter') {
        this.addSkill({value: this.tempSkill})
      }
    },
    addLang() {
      this.showLangError = false;
      if (this.tempLang.lang && this.tempLang.lvl) {
        let lang = {};
        lang[this.tempLang.lang] = this.tempLang.lvl;
        this.item.languages.push(lang);
        this.tempLang = {
          lang: '',
          lvl: ''
        }
      } else if (this.tempLang.lang && !this.tempLang.lvl) {
        this.showLangError = true;
      }
    },
    removeLang(i) {
      this.disabledSection.push('skills');
      this.item.languages.splice(i, 1);
      setTimeout(() => {
        this.disabledSection.pop();
      })
      if (!this.item.languages.length && this.collapseSections !== 'skills') this.collapseSections = 'skills';
    },
    fetchSuggestionsLocation(q, cb) {
      this.resumeClass.suggestions('location', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    fetchSuggestionsPos(q, cb) {
      this.resumeClass.suggestions('position', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    onSave() {
      this.$router.replace('/applicant/resumes')
    },
    onUploadImageSuccess(image) {
      this.item.image = image;
    },
    onRemoveImage() {
      this.$nextTick(() => {
        this.item.image = null;
      })
      this.$forceUpdate();
    },
    hasItemValues: obj => Object.values(obj).filter(val => val).length > 0,
    removeItem(section, i) {
      this.disabledSection.push(section);
      this.item[section].splice(i, 1);
      setTimeout(() => {
        this.disabledSection.pop();
      })
      if (!this.item[section].length && this.collapseSections !== section) this.collapseSections = section;
    },
    editItem(section, i) {
      let currentEl = {...this.item[section][i]};
      if (this.hasItemValues(this.temporaryItems[section])) this.saveItem(section);
      if (this.checkItemForm(section) || !this.hasItemValues(this.temporaryItems[section])) {
        this.item[section].splice(i, 1);
        this.temporaryItems[section] = {...currentEl};
        /*setTimeout(()=>{
          if (this.collapseSections !== section) this.collapseSections = section;
        }, 100);*/
      }
    },
    saveItem(section) {
      if (this.checkItemForm(section)) {
        this.item[section].push(this.temporaryItems[section]);
        this.temporaryItems[section] = {};
      }
    },
    checkItemForm(section) {
      switch (section) {
        case 'education':
          return this.checkEduForm();
        case 'work':
          return this.checkWorkForm();
        case 'courses':
          return this.checkCourseForm();
        default:
          return false;
      }
    },
    checkEduForm() {
      this.eduError = false;
      this.eduYearEndError = false;
      if (this.temporaryItems.education.university &&
          ((this.temporaryItems.education.date_to && this.temporaryItems.education.date_to * 1 <= 2100 &&
              this.temporaryItems.education.date_to * 1 >= 1900) || !this.temporaryItems.education.date_to)) {
        return true;
      } else if (!this.temporaryItems.education.university && this.hasItemValues(this.temporaryItems.education)) {
        this.eduError = true;
        return false;
      } else if (this.temporaryItems.education.date_to && (this.temporaryItems.education.date_to * 1 > 2100 ||
          this.temporaryItems.education.date_to * 1 < 1900)) {
        this.eduYearEndError = true;
        return false;
      } else return false;
    },
    /*Проверка дат опыта работы
    *  если поле год не пустое, то должно быть 4 цифры
    *   дата должна быть не позже "сегодня"
    *   дата начала должна быть до даты окончания
    *   должны быть указаны месяц и год
    *   */

    checkWorkDate(month, year, type) {
      if (!year && !month) {
        this.workDatesErrors[type] = false;
        return;
      }
      this.workDatesErrors[type] = year && year.length !== 4 /*ошибка года*/ ||
          this.$moment(`${year}-${month}-01`).isAfter(this.$moment()) /*дата позже сегодня*/ ||
          (!month && year) || (month && !year) /*не указан месяц/год*/;
    },
    checkWorkForm() {
      this.workError = false;
      this.checkWorkDate(this.temporaryItems.work.month_start, this.temporaryItems.work.year_start, 'from');
      this.checkWorkDate(this.temporaryItems.work.month_end, this.temporaryItems.work.year_end, 'to');
      if (this.temporaryItems.work.year_start && this.temporaryItems.work.year_end && this.temporaryItems.work.month_start && this.temporaryItems.work.month_end) {
        this.workDatesErrors.fromIsAfterTo =
            this.$moment(`${this.temporaryItems.work.year_start}-${this.temporaryItems.work.month_start}-01`)
                .isAfter(this.$moment(`${this.temporaryItems.work.year_end}-${this.temporaryItems.work.month_end}-01`));
        return !this.workDatesErrors.fromIsAfterTo;
      }

      if (this.temporaryItems.work.position && !this.workDatesErrors.from && !this.workDatesErrors.to
          && !this.workDatesErrors.fromIsAfterTo) {
        return true;
      } else if (!this.temporaryItems.work.position && this.hasItemValues(this.temporaryItems.work)) {
        this.workError = true;
        return false;
      } else return false;
    },
    checkCourseForm() {
      this.courseError = false;
      if (this.temporaryItems.courses.name) {
        return true;
      } else if (!this.temporaryItems.courses.name && this.hasItemValues(this.temporaryItems.courses)) {
        this.courseError = true;
        return false;
      } else return false;
    },
    parseQuery(query) {
      if (query && query.info) {
        this.item = this.parseResponse(query.info);
        this.$forceUpdate();
      }
    },
    parseResume() {
      if (!this.parseLink) {
        this.parseError = true;
      } else {
        this.importLoading = true;
        this.resumeClass.request({link: this.parseLink}, 'resume/hhLink').then(data => {
          this.importLoading = false;
          this.$router.replace(`/applicant/resumes/update/${data.response}`);
        }).catch((data)=>{
          this.importLoading = false;
          if (data.error?.type === 'agreement') {
            this.$refs.user_agreement_dialog.open(this.dataToRequest());
          }
        })
      }
    },
    onCollapseChange(openedSection) {
      let sections = ['education', 'courses', 'work'];
      sections.forEach(section => {
        if (openedSection !== section) this.saveItem(section);
      })
      if (openedSection && this.$refs[openedSection])
        setTimeout(() => {
          this.$refs[openedSection].scrollIntoView({
            behavior: "smooth"
          })
        }, 280)

    },
    onSuccess(data, section) {
      if (data.response) {
        this.fileUploading[section] = false;
        if (!this.temporaryItems[section].files) this.temporaryItems[section].files = [];
        this.temporaryItems[section].files.push(data.response);
        this.$forceUpdate();
      } else if (data.error && data.error?.type === '401') {
        this.$store.state.eventBus.$emit('auth-error');
      }
    },
    onError(section) {
      this.fileUploading[section] = false;
    },
    removeFile(i, section) {
      this.temporaryItems[section].files.splice(i, 1);
      this.$forceUpdate();
    },
    beforeUpload(file, section) {
      if (this.checkFileSize(file.size)) {
        this.$notify({
          message: `Размер файла ${file.name} больше 5МБ`,
          type: 'warning'
        })
        return false;
      } else {
        this.fileUploading[section] = true;
      }
    },
  },
  mounted() {
    this.parseQuery(this.$route.query);
    if (this.$route.params.id) {
      this.get();
    } else {
      this.phoneMask = this.$store.state.phone_mask;
      this.thousandMask = this.$store.state.maskThousandSeparator;
    }
    this.pageKey = Math.random();
  },
  beforeDestroy() {
    localStorage.setItem('resume', '');
  }
}
</script>

<style module lang="css">
.updateResumePage {
}

.updateResumePage .updateResumePageTitle {
  margin-bottom: 40px;
}

.birthdayContainer {
  display: flex;
  column-gap: 2px;
}

.birthdayInput {
  width: fit-content !important;
}

.birthdayInput:first-child input {
  width: 70px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.birthdayInput:nth-child(2) input {
  border-radius: 0 !important;
}

.birthdayInput:last-child input {
  width: 90px;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.updateResumePage .formContainer {
  display: grid;
  grid-template-columns: 66% auto;
  grid-column-gap: 32px;
  align-items: flex-start;
  justify-content: space-between;
  /*column-gap: 32px;*/
  margin-bottom: 20px;
}

.updateResumePage .formContainer:last-child {
  margin-bottom: 0;
}

.updateResumePage .formContainer .formInner {
  background: var(--light-gray);
  border-radius: 16px;
  flex-basis: 66%;
  max-width: 860px;
  width: 100%;
  position: relative;
}

.updateResumePage .formContainer .formInner .formHeader {
  padding: 36px 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  font-size: var(--headline_3);
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;
}

.updateResumePage .formContainer .formInner .formHeader .iconHeaderContainer {
  background: var(--primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateResumePage .formContainer .formInner .formContent {
  max-height: 500vh;
  height: 100%;
  padding: 0 48px;
  /*transition: all .4s linear;*/
}

.formSubtitle {
  font-size: var(--small-text);
  font-weight: 500;
  color: var(--gray-text);
}

.importContainer {
  display: flex;
  column-gap: 12px;
}

.updateResumePage .formContainer .formInner .formContent.formContentWithPB {
  padding-bottom: 40px;
}

.formContentCollapsed {
  max-height: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.mainInfoRow {
  display: flex;
  row-gap: 20px;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 28px;
  margin-bottom: 16px;
}

.additionalInfoButton {
  margin-bottom: 36px;
}

.inputsRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.formItemCompleted {
  margin-bottom: 36px;
  padding: 20px 24px;
  background: rgba(206, 217, 227, 0.6);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formItemCompleted .formItemCompletedContent {
}

.formItemCompleted .formItemCompletedContent .formItemCompletedTitle {
  font-size: var(--body);
  font-weight: var(--body-medium-weight);
  margin-bottom: 2px;
  line-height: 144%;
}

.formItemCompleted .formItemCompletedContent .formItemCompletedSubtitle {
  font-size: var(--small-text);
  line-height: 120%;
}

.formItemCompleted .formItemCompletedActions {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.nameSurnameContainer {
  flex-grow: 1;
  width: 100%;
}

.additionalInfoContainer {
  height: 100%;
  padding-bottom: 24px;
  transition: height 100ms ease-in;
}

.additionalInfoContainer.formContentCollapsed {
  height: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.salaryContainer {
  display: flex;
  column-gap: 6px;
}

.scheduleCheckboxesContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkboxInner {
  display: flex;
  flex-direction: column;
}

.employmentAndScheduleCheckbox {
  line-height: 100%;
  margin-bottom: 10px;
  margin-right: 0 !important;
}

.educationItem {
  margin-bottom: 36px;
}

.noExpHint {
  margin-bottom: 24px;
}

.experienceItems .experienceItem {
  margin-bottom: 36px;
}

.experienceDateRow {
  display: flex;
  column-gap: 6px;
}

.experiencePageHint {
  align-self: flex-end;
  margin-bottom: -40px;
}

.experiencePageHintLabel {
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: var(--small-text);
  font-weight: var(--body-medium-weight);
  color: var(--green-active);
}

.experiencePageHintList {
  list-style-type: disc;
  list-style-position: inside;
  font-size: var(--small-text);
  line-height: 144%;
}

.skillInputRow {
  display: flex;
  align-items: flex-end;
  column-gap: 8px;
}

.skillTags {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.addSkillButton {
  min-width: 187px;
  height: 50px !important;
}

.courseItem {
  margin-bottom: 36px;
}

.pictureExampleContainer {
  margin-top: 20px;
}

.pictureExampleLabel {
  display: flex;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: var(--body);
  line-height: 144%;
}

.pictureExampleLabel.good {
  color: var(--green-active);
}

.pictureExampleLabel.bad {
  margin-top: 16px;
  color: var(--error-red);
}

.pictureExamples {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
  grid-gap: 12px;
}

.pictureExamples img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.formActions {
  max-width: 66%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-gap: 12px;
}


.addItemButton {
  margin-bottom: 48px;
}

.filesContainer {
  margin-top: 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.uploadButton {
  flex-direction: column;
  font-weight: normal;
  font-size: var(--body);
  line-height: 144%;
  border: 1px dashed var(--primary) !important;
}

.uploadButton span {
  color: var(--gray-text);
  font-size: var(--small-text);
}

@media screen and (max-width: 1024px) {
  .pageHint {
    display: none;
  }

  .updateResumePage .formContainer .formInner .formContent.formContentWithPB {
    padding-bottom: 32px;
  }

  .updateResumePage .formContainer {
    grid-template-columns: 1fr;
  }

  .updateResumePage .formContainer .formInner {
    max-width: 100%;
  }

  .updateResumePage .formContainer .formInner .formContent {
    padding: 0 40px;
  }

  .updateResumePage .formContainer .formInner .formHeader {
    padding: 24px 0 20px;
    font-size: var(--subheadline);
  }

  .mainInfoRow {
    column-gap: 24px;
    margin-bottom: 24px;
  }

  .inputsRow {
    grid-column-gap: 16px;
  }

  .additionalInfoContainer {
    padding-bottom: 32px;
  }

  .employmentAndScheduleCheckbox {
    margin-bottom: 8px;
    display: block !important;
  }

  .scheduleCheckboxesContainer {
    justify-content: flex-start;
    column-gap: 40px;
  }

  .educationItem {
    margin-bottom: 16px;
  }

  .formItemCompleted {
    margin-bottom: 28px;
    padding: 20px;
  }

  .formItemCompleted .formItemCompletedContent .formItemCompletedSubtitle {
    font-size: var(--tiny-text);
  }

  .addSkillButton {
    height: 45px !important;
  }

  .formActions {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    grid-gap: 12px;
  }

  .addItemButton {
    margin-bottom: 40px;
  }

}

@media screen and (max-width: 575px) {

  .updateResumePage .formContainer .formInner {
  }

  .updateResumePage .formContainer .formInner .formHeader {
    padding: 24px 0 20px;
    font-size: var(--subheadline);
  }

  .updateResumePage .formContainer .formInner .formHeader .iconHeaderContainer {
    width: 32px;
    height: 32px;
  }

  .updateResumePage .formContainer .formInner .formContent {
    padding: 0 20px;
  }

  .mainInfoRow {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
  }

  .inputsRow {
    grid-template-columns: 1fr;
    margin-bottom: 12px;
  }

  .inputsRow:last-child {
    margin-bottom: 0;
  }

  .additionalInfoContainer {
    padding-bottom: 28px;
  }

  .additionalInfoButton {
    margin-top: 28px;
    white-space: pre-wrap;
  }

  .scheduleCheckboxesContainer {
    flex-direction: column;
  }

  .formItemCompleted {
    padding: 16px;
  }

  .formItemCompleted .formItemCompletedContent .formItemCompletedTitle {
    font-size: var(--small-text);
  }

  .noExpHint {
    font-size: var(--small-text);
    margin-bottom: 16px;
  }

  .skillInputRow {
    flex-direction: column;
    row-gap: 12px;
  }

  .addSkillButton {
    width: 100% !important;
    height: 45px !important;
  }

  .skillInputRow > * {
    width: 100%;
  }

  .addItemButton {
    margin-bottom: 32px;
  }
}
</style>