<template>
  <div :class="$style.hrOfferOuter">
    <div :class="$style.hrOffer">
      <div :class="$style.hrOffer_before">
        <div :class="$style.hrAvatar">
          <candidate-avatar :class="$style.hrAvatarComponent" :img="item.worker?.image?.url" :circle="true" size="72"/>
          <!--                    <div :class="$style.hrAvatarRate"
                                   :style="`background: ${item.rate > 4 ? 'var(&#45;&#45;bright-green)' : item.rate > 3 ? 'var(&#45;&#45;bright-yellow)' : 'var(&#45;&#45;bright-red)'}`">
                                {{ item.rate }}
                              </div>-->
        </div>
        <!--                  <span :class="$style.reviewsCount">{{ item.reviewsCount }} отзывов</span>-->
      </div>
      <div :class="$style.hrOffer_content">
        <div :class="$style.hrOfferContentHeader">
          <div :class="$style.hrOffer_contentRow">
            <div :class="$style.hrOfferName">{{ item?.worker?.name }} {{ item?.worker?.surname }}</div>
            <tag :class="$style.hrOfferPriceTag" color="var(--green)"
                 :text="salaryParse(item.salary *1)" height="37"/>
          </div>
          <div :class="$style.hrOfferContentHeaderActions" v-if="order.user_id == user.id">
            <rekroo-button v-if="!item?.match_client"
                           type="gray" style="margin-bottom: 10px" width="100%"
                           @click="$emit('add-response-btn-click')" text_color="var(--primary)">Обменяться контактами
            </rekroo-button>
            <rekroo-button v-if="item.match_client && item.match_worker" color="var(--purple)" width="100%" @click="$emit('choose-btn-click')">Выбрать специалиста
            </rekroo-button>
            <tag v-else-if="item.match_client" text="Ожидается ответ со стороны HR'а"/>

          </div>
        </div>
        <div :class="$style.hrOfferTextContainer">
          <p :class="$style.hrOfferText">{{ expandedText ? text : collapsedText }}</p>
          <rekroo-button
              v-if="text && text.length > 130"
              :prevent="true"
              @click="expandedText = !expandedText"
              style="margin-top: 4px;"
              type="text" text_color="var(--bright-blue)"
              :icon="`chevron-${expandedText ? 'up' : 'down'}`"
              icon_pos="end" icon_color="var(--bright-blue)" icon_style="margin-left: 4px;">
            {{ expandedText ? 'Скрыть' : 'Показать полностью' }}
          </rekroo-button>
        </div>
        <div :class="$style.hrContacts" v-if="item.match_client && item.match_worker">
          <tag v-if="item?.worker?.phone"
               icon="phone" icon_color="var(--gray-text)" icon_pos="start" :text="strPhoneMask(item.worker?.phone)"/>
          <tag v-if="item?.worker?.email"
               icon="mail" icon_color="var(--gray-text)" icon_pos="start" :text="item.worker?.email"/>
        </div>
      </div>
    </div>
    <div :class="$style.hrOffer_after" v-if="order.user_id == user.id">
      <rekroo-button v-if="!item?.match_client"
                     type="gray" style="margin-bottom: 10px" width="100%"
                     @click="$emit('add-response-btn-click')" text_color="var(--primary)">Обменяться контактами
      </rekroo-button>
      <rekroo-button v-if="item.match_client && item.match_worker" color="var(--purple)" width="100%" @click="$emit('choose-btn-click')">Выбрать специалиста
      </rekroo-button>
      <tag v-else-if="item.match_client" text="Ожидается ответ со стороны HR'а"/>
    </div>
  </div>
</template>

<script>
import CandidateAvatar from "@/components/CandidateAvatar";
import Tag from "@/components/Tag";
import RekrooButton from "@/components/Button";

export default {
  name: "HrCardResponse",
  components: {RekrooButton, Tag, CandidateAvatar},
  props: ['item', 'user', 'order'],
  emits: ['add-response-btn-click', 'choose-btn-click', 'add-worker-btn-click'],
  data: () => ({
    expandedText: false,
  }),
  computed: {
    text() {
      return this.item.about || this.item.message;
    },
    collapsedText() {
      let text = this.item.about || this.item.message;
      if (text && text.length > 130) {
        text = text.slice(0, 130);
        text += '...';
      }
      return text;
    }
  }
}
</script>

<style module lang="css">

.hrOfferOuter {
  border-bottom: 1px solid var(--gray);
  padding: 32px 0;
}

.hrOffer {
  display: flex;
  align-items: flex-start;
}

.hrOfferOuter:first-child {
  padding-top: 0;
}

.hrOfferOuter:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.hrOffer_before {
  margin-right: 24px;
}

.hrOfferContentHeader {
  display: flex;
}

.hrOfferContentHeaderActions {
  margin-left: auto;
}

.hrOffer_before .hrAvatar {
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.hrOffer_before .hrAvatarRate {
  position: absolute;
  bottom: -2px;
  width: 38px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  font-size: 14px;
  color: #FFF;
  font-weight: 600;
}

.hrOffer_before .reviewsCount {
  font-size: 14px;
  color: var(--gray-text);
  font-weight: 500;
}

.hrOffer_content .hrOfferName {
  margin-bottom: 6px;
  font-size: var(--subheadline);
  font-weight: 500;
}

.hrOfferText {
  margin-top: 12px;
  font-size: var(--body);
  line-height: 144%;
}

.hrOffer_content {
  width: 100%;
}

.hrOffer_content .hrContacts {
  display: flex;
  column-gap: 8px;
  margin-top: 8px;
}

.hrOffer_after {
  display: none
}

.hrOffer.search .hrOffer_content .hrOfferTextContainer {
  margin-bottom: 16px;
}

@media screen and (max-width: 1024px) {
  .hrOffer_before {
    margin-right: 12px;
  }

  .hrOfferContentHeader .hrOffer_contentRow {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .hrOfferContentHeader .hrOffer_contentRow .hrOfferName {
    flex-grow: 1;
  }
  .hrOffer .hrOffer_content {
    grid-area: b;
    margin-right: 0;
  }

  .hrOfferContentHeaderActions {
    display: none;
  }

  .hrOffer .hrOffer_content .hrOfferPriceTag {
    margin-left: auto;
  }

  .hrOffer .hrOffer_content .hrOfferTextContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: -60px;
  }

  .hrContacts {
    margin-left: -60px;
  }

  .hrOffer_after {
    width: 100%;
    display: grid;
    margin-top: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-column-gap: 8px;
  }

  .hrOffer_after button {
    margin-bottom: 0;
  }

  .hrOffer .hrAvatarComponent {
    width: 48px !important;
    min-width: 48px !important;
    height: 48px !important;
  }
}

@media screen and (max-width: 575px) {
  .hrOfferContentHeader .hrOffer_contentRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .hrOfferContentHeader .hrOffer_contentRow .hrOfferPriceTag {
    margin-left: 0;
  }
  .hrOffer .hrOffer_content .hrOfferTextContainer {
    margin-top: 16px;
    margin-bottom: 20px;
    margin-left: -60px;
  }
}
</style>