<template>
  <div :class="$style.hrOfferOuter">
    <div :class="$style.hrOffer">
      <div :class="$style.hrAvatar">
        <candidate-avatar :class="$style.hrAvatarComponent" :img="item.image?.url" size="72"/>
        <!--                    <div :class="$style.hrAvatarRate"
                                 :style="`background: ${item.rate > 4 ? 'var(&#45;&#45;bright-green)' : item.rate > 3 ? 'var(&#45;&#45;bright-yellow)' : 'var(&#45;&#45;bright-red)'}`">
                              {{ item.rate }}
                            </div>-->
      </div>
      <!--                  <span :class="$style.reviewsCount">{{ item.reviewsCount }} отзывов</span>-->
      <div :class="$style.hrOffer_content">
        <div :class="$style.hrOffer_contentRow">
          <div :class="$style.hrOffer_contentCol">
            <div :class="$style.hrOfferName">{{ item?.user?.name }} {{ item?.user?.surname }}</div>
            <div :class="$style.hrLocation">
              {{
                [item.user?.location, `${item.age || getAgeByDate(item.user?.birthday) ? `${item.age || getAgeByDate(item.user?.birthday)} ${getNoun(item.age || getAgeByDate(item.user?.birthday), 'год', 'года', 'лет')}` : ''}`].filter(v => v).join(', ')
              }}
            </div>
          </div>
          <tag :class="$style.hrOfferPriceTag" color="var(--green)"
               :text="salaryParse(item.salary *1 || item.price_from *1, item.price_to*1)" height="37"/>
        </div>

        <div :class="$style.hrOfferTextContainer">
          <p :class="$style.hrOfferText">{{ expandedText ? text : collapsedText }}</p>
          <rekroo-button
              v-if="text && text.length > 130"
              :prevent="true"
              @click="expandedText = !expandedText"
              style="margin-top: 4px;"
              type="text" text_color="var(--bright-blue)"
              :icon="`chevron-${expandedText ? 'up' : 'down'}`"
              icon_pos="end" icon_color="var(--bright-blue)" icon_style="margin-left: 4px;">
            {{ expandedText ? 'Скрыть' : 'Показать полностью' }}
          </rekroo-button>
        </div>
        <rekroo-button v-if="!item.response" @click="$emit('add-worker-btn-click')"
                       type="gray" text_color="var(--primary)" :class="$style.addWorkerBtn">Предложить заказ
        </rekroo-button>
        <tag v-else-if="item.response" color="var(--light-gray)" icon="check" icon_color="var(--gray-text)"
             :class="$style.addWorkerTag"
             icon_pos="start"
             icon_style="margin-right: 13px" text_color="var(--gray-text)"
             text="Вы уже предложили заказ этому специалисту"/>
      </div>
    </div>
    <div :class="$style.hrOfferContentMobile">
      <tag :class="$style.hrOfferPriceTag" color="var(--green)"
           :text="salaryParse(item.salary *1 || item.price_from *1, item.price_to*1)" height="37"/>
      <div :class="$style.hrOfferTextContainer">
        <p :class="$style.hrOfferText">{{ expandedText ? text : collapsedText }}</p>
        <rekroo-button
            v-if="text && text.length > 130"
            :prevent="true"
            @click="expandedText = !expandedText"
            style="margin-top: 4px;"
            type="text" text_color="var(--bright-blue)"
            :icon="`chevron-${expandedText ? 'up' : 'down'}`"
            icon_pos="end" icon_color="var(--bright-blue)" icon_style="margin-left: 4px;">
          {{ expandedText ? 'Скрыть' : 'Показать полностью' }}
        </rekroo-button>
      </div>
      <rekroo-button v-if="!item.response" @click="$emit('add-worker-btn-click')"
                     type="gray" text_color="var(--primary)" :class="$style.addWorkerBtn">Предложить заказ
      </rekroo-button>
      <tag v-else-if="item.response" color="var(--light-gray)" icon="check" icon_color="var(--gray-text)"
           :class="$style.addWorkerTag"
           icon_pos="start"
           icon_style="margin-right: 13px" text_color="var(--gray-text)"
           text="Вы уже предложили заказ этому специалисту"/>
    </div>
  </div>
</template>

<script>
import CandidateAvatar from "@/components/CandidateAvatar";
import Tag from "@/components/Tag";
import RekrooButton from "@/components/Button";

export default {
  name: "HrCardSearch",
  components: {RekrooButton, Tag, CandidateAvatar},
  props: ['item', 'user', 'order'],
  emits: ['add-response-btn-click', 'choose-btn-click', 'add-worker-btn-click'],
  data: () => ({
    expandedText: false,
  }),
  computed: {
    text() {
      return this.item.about || this.item.message;
    },
    collapsedText() {
      let text = this.item.about || this.item.message;
      if (text && text.length > 130) {
        text = text.slice(0, 130);
        text += '...';
      }
      return text;
    }
  }
}
</script>

<style module lang="css">
.hrOffer {
  padding: 36px;
  display: flex;
  column-gap: 20px;
  background: #FFFFFF;
  border-radius: 16px;
}

.hrOffer .hrAvatarComponent {
  width: 112px !important;
  height: 112px !important;
}

.hrOffer .hrOffer_contentRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.hrOffer .hrOffer_contentRow .hrOfferPriceTag {
  margin-left: auto;
}

.hrOffer_content .hrOfferName {
  margin-bottom: 6px;
  font-size: var(--subheadline);
  font-weight: 500;
}

.hrOffer_content {
  flex-grow: 1;
}

.hrOfferText {
  margin-top: 12px;
  font-size: var(--body);
  line-height: 144%;
}

.hrOffer .hrOffer_content .hrOfferTextContainer {
  margin-bottom: 16px;
}

.addWorkerTag {
  white-space: normal;
}

.hrOfferContentMobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .hrOffer {
    padding: 32px;
    border-radius: 12px;
  }
}

@media screen and (max-width: 575px) {
  .hrOfferOuter {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px 16px;
  }
  .hrOffer {
    padding: 0;
    margin-bottom: 16px;
    column-gap: 12px;
  }

  .hrOffer .hrAvatarComponent {
    width: 64px !important;
    min-width: 64px !important;
    height: 64px !important;
  }
  .hrOffer_content .hrOfferPriceTag, .hrOffer_content .hrOfferTextContainer,
  .hrOffer_content .addWorkerBtn, .hrOffer_content .addWorkerTag {
    display: none;
  }
  .hrOfferContentMobile {
    display: block;
  }
  .hrOfferContentMobile .addWorkerBtn {
    width: 100% !important;
  }
  .hrOfferContentMobile .hrOfferText {
    margin-top: 16px;
  }
  .hrOfferContentMobile .hrOfferTextContainer {
    margin-bottom: 20px;
  }
}
</style>