import {Item} from "@/api/item";

export class Mailing_list_item extends Item {
    method = 'mailingList';
    send(id, datetime_schedule) {
        return this.request({datetime_schedule}, `${this.method}/send/${id}`);
    }
    moveToArchive(id) {
        return this.request({deleted: 1}, `${this.method}/update/${id}`);
    }
    cancel(id) {
        return this.request({}, `${this.method}/cancel/${id}`);
    }
}