import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        user: {},
        userCounts: {},
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        phone_mask: '+7 (###) ### ## ##',
        maskThousandSeparator: ['#', '##', '###', '# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###'],
        systemParams: {
            currencies: {}
        },
        educational_levels: ['Среднее', 'Среднее Специальное', 'Неоконченное высшее', 'Высшее', 'Бакалавр', 'Магистр', 'Кандидат наук', 'Доктор наук'],
        vacancyStatuses: ['Не опубликована', 'Опубликована', 'Архив'],
        resumeStatuses: ['Не опубликовано', 'Опубликовано', 'Архив'],
        experience_options: ['Не указан', 'Без опыта', 'От 1 года до 3 лет', 'От 4 до 6 лет', 'Более 6 лет'],
        resumeStatusLabelColors: ['var(--gray)', 'var(--purple)', 'var(--bright-red)'],
        vacancyStatusColors: ['var(--gray-text)', 'var(--green-active)', 'var(--error-red)'],
        eventBus: new Vue(),
        last_created_rate: '',
        order_payed_successful: false,
        roles: {
            applicant: 'Соискатель',
            company: 'Работодатель',
            hr: 'Рекрутер'
        },
        isTest: window.location.host.indexOf('localhost:') === 0,
        redirect_path: '',
        audience_survey_closes_count: 0,
    },
    mutations: {
        setUser(state, newUser) {
            newUser.subscribe = 1;
            state.user = {...newUser};
        },
        setUserCounts(state, counts) {
          state.userCounts = counts;
        },
        setSystemParams(state, newParams) {
            state.systemParams = {...newParams};
        },
        setLastCreatedRate(state, newType) {
            state.last_created_rate = newType;
        },
        setOrderPayedSuccessful(state, isPayed) {
            state.order_payed_successful = isPayed;
        },
        setRedirectPath(state, path) {
            state.redirect_path = path;
        },
        setAudienceCloseCount(state, count) {
            state.audience_survey_closes_count = count;
        }
    }
})

export default store;