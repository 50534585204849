<template>
  <div :class="style.landingHrPage">
    <section :class="$style.hrLandingHeader">
      <div :class="$style.hrLandingHeaderDescription">
        <img :class="$style.hrLandingHeaderArrow" src="/img/icons/icon_curve_arrow.svg" alt="">
        <div :class="$style.hrLandingHeaderTitle">Никогда еще рекрутинг не был таким удобным</div>
        <div :class="$style.hrLandingHeaderSubtitle">Комплексное решение для HR. Управляйте всеми процессами в одном
          месте.
        </div>
        <a href="https://web.rekroo.org" target="_blank" :class="$style.hrLandingHeaderButton">
          <rekroo-button width="100%" height="50px">Попробовать бесплатно
          </rekroo-button>
        </a>
        <a href="#candidateListSection" :class="$style.hrLandingHeaderNavigateToBottomButton">Как работает Rekroo
          <rekroo-icon icon="arrow-bottom" style="margin-left: 12px" width="14px"/>
        </a>
      </div>
      <div :class="$style.hrLandingHeaderImageContainer">
        <img src="/img/hr_landing/hr_landing_header_image.png" alt="" :class="$style.hrLandingHeaderImage">
        <img src="/img/hr_landing/hrm_landing_tablet.png" alt="" :class="$style.hrLandingHeaderImageTablet">
        <a href="https://web.rekroo.org" target="_blank" :class="$style.hrLandingHeaderButton">
          <rekroo-button width="100%" height="50px">Попробовать бесплатно
          </rekroo-button>
        </a>
      </div>
    </section>
    <section :class="$style.toolsSection">
      <h1 :class="$style.toolsSectionTitle">Rekroo — комплексная система управления и подбора персонала</h1>
      <div :class="$style.systemItems">
        <div :class="$style.systemItem" v-for="(item, i) in systemItems" :key="i">
          <div :class="$style.systemItemInner">
            <div :class="$style.systemItemIcon" :style="`background: ${item.iconColor}`">
              <rekroo-icon :icon="item.icon"/>
              <div v-if="item.plusIcon" :class="$style.toolIconAddIcon">
                <rekroo-icon icon="plus" width="12px"/>
              </div>
            </div>
            <div :class="$style.systemItemTitle">{{ item.title }}</div>
            <div :class="$style.systemItemText">{{ item.text }}</div>
            <router-link :to="`#${item.link}`" :class="$style.systemItemLink">Подробнее
              <rekroo-icon icon="chevron-right" width="10px"/>
            </router-link>
          </div>
        </div>
        <div :class="[$style.systemItem, $style.systemItemCTA]">
          <div :class="$style.systemItemInner">
            <div :class="$style.systemItemCTA_Title">Попробовать первые 3 месяца бесплатно</div>
            <a href="https://web.rekroo.org" target="_blank" :class="$style.systemItemCTA_Button">
              <rekroo-button width="100%">Попробовать</rekroo-button>
            </a>
          </div>
        </div>
      </div>
      <!--      <div :class="$style.toolsSectionSubtitle">База данных по кандидатам, календарь, напоминания, push-уведомления,
              приглашения на собеседования с запросом подтверждения и геолокацией – любое действие в один клик.
            </div>
            <div :class="$style.toolsSectionIcons">
              <div v-for="icon in icons" :key="icon" :class="[$style[`${icon}Icon`], $style.toolIcon]">
                <rekroo-icon :icon="icon"/>
                <div :class="$style.toolIconAddIcon">
                  <rekroo-icon icon="plus" width="8px"/>
                </div>
              </div>
            </div>-->
    </section>
    <section :class="$style.resumeDatabaseSection" id="resume_database">
      <div :class="$style.resumeDatabaseSectionHint">«Чем мне поможет Rekroo?»
        <img src="/img/hr_landing/curve_dashed_arrow.svg" alt="">
      </div>
      <div :class="$style.resumeDatabaseSectionInner">
        <div :class="$style.resumeDatabaseSectionContent">
          <h1 :class="$style.resumeDatabaseSectionTitle">
            <span>Загружайте свою базу</span>
            резюме из папки с автозаполнением полей</h1>
          <p>За раз можно загрузить до 500 штук с ИИ, который автозаполняет поля (имя, фамилию, должность и тд)</p>
          <p>Вся база резюме хранится в одном месте, имеется система поиска и история взаимодействий с кандидатами</p>
        </div>
        <div :class="$style.resumeDatabaseSectionImage">
          <img src="/img/icons/icon_redhead_woman_sandglass.svg" alt="">
        </div>
      </div>
      <img :class="$style.resumeSectionImgMobile" src="/img/hr_landing/resume_section_mobile.png" alt="">
      <upload-resume-block :custom-class="$style.uploadResumeBlock" icon-color="var(--gray)"/>
      <a href="https://web.rekroo.org/candidates" :class="$style.hrmCandidatesListImage">
        <img src="/img/hr_landing/hrm_candidates_list.jpg" alt="">
      </a>
    </section>
    <!--    <section :class="$style.resourcesSection">
          <div :class="$style.resourcesIcons">
            <img v-for="icon in iconsResources" :key="icon"
                 :src="`/img/icons/icon_${icon}.png`" alt="">
          </div>
          <h1 :class="$style.resourcesTitle">
            <span>Импорт резюме</span>
            со сторонних ресурсов</h1>
        </section>-->
    <section :class="$style.resumeCatalogSection" id="catalog_resume">
      <h1 :class="$style.resumeCatalogTitle">
        Работайте с нашей
        <span>актуальной базой резюме</span>
      </h1>
      <p :class="$style.resumeCatalogSubtitle">Огромная база талантов в ваших руках! Откройте доступ и изучайте профили
        тысяч квалифицированных специалистов
        <span>от 29₽ за контакт</span></p>
      <img :class="$style.resumeCatalogImage" src="/img/hr_landing/candidate_list.jpg" alt="">
      <router-link to="/hr/catalog" :class="$style.resumeCatalogButton">
        <rekroo-button height="50px" width="100%">Открыть базу резюме</rekroo-button>
      </router-link>
    </section>
    <section :class="$style.browserExtensionSection" id="extension">
      <h1 :class="$style.browserExtensionTitle"><span>Расширение для браузера.</span>
        Управляйте кандидатами со сторонних ресурсов</h1>
      <div :class="$style.browserExtensionList">
        <div :class="$style.browserExtensionItem" v-for="(item, i) in browserExtensionItems" :key="i">
          <div :class="$style.browserExtensionItemTitle">{{ item.title }}</div>
          <div :class="$style.browserExtensionItemSubtitle">{{ item.subtitle }}</div>
          <img :class="$style.browserExtensionItemImage" :src="`/img/hr_landing/browser_extension_${i+1}.png`" alt="">
        </div>
      </div>
      <a :class="$style.browserExtensionLink"
         href="https://chrome.google.com/webstore/detail/rekroo/gggelkilfdbhicgookaepphnojcmdanj?hl=en">
        <rekroo-button width="100%" height="50px">Установить расширение</rekroo-button>
      </a>
    </section>
    <section :class="$style.funnelSection" id="funnel">
      <div :class="$style.funnelContent">
        <h1 :class="$style.funnelTitle">
          <span>Воронка подбора, </span>как эффективный инструмент найма
        </h1>
        <p :class="$style.funnelSubtitle">Удобный трекер показателей каждого этапа</p>
      </div>
      <div :class="$style.funnelImage">
        <img src="/img/hr_landing/funnel.png" alt="">
      </div>
    </section>
    <section :class="$style.commentSection">
      <div :class="$style.commentContent">
        <h1 :class="$style.commentTitle">
          <span>Оставляйте оценки и комментарии</span> и&nbsp;возвращайтесь к ним в любой момент
        </h1>
        <p :class="$style.commentSubtitle">Подробные профайлы, история взаимодействия, добавление в избранное, поиск по
          имени и вакансии, контроль финансов и выхода на работу – все как на ладони.</p>
        <p :class="$style.commentSubtitle">Все взаимодействие с кандидатом остаётся только с вами, комментарии оценки и
          прочее видите только вы.</p>
        <img src="/img/hr_landing/comment_hint.png" alt="" :class="$style.commentHint">
      </div>
      <div :class="$style.commentImage">
        <img :class="$style.commentImageDesktop" src="/img/hr_landing/comments.png" alt="">
        <img :class="$style.commentImageMobile" src="/img/hr_landing/comment_mobile.png" alt="">
        <img :class="$style.commentHintTablet" src="/img/hr_landing/comment_hint_tablet.png" alt="">
        <img :class="$style.commentHintMobile" src="/img/hr_landing/comment_hint_mobile.png" alt="">
      </div>
    </section>
    <section :class="$style.statisticSection" id="analytics">
      <h1 :class="$style.statisticTitle">
        <span>Аналитика и статистика</span> по&nbsp;вакансиям, кандидатам и финансам
      </h1>
      <p :class="$style.statisticSubtitle">Детальная аналитика по отдельным месяцам позволяет отслеживать тенденции и
        эффективность работы</p>
      <div :class="$style.statisticItems">
        <img v-for="i in 4" :key="i" :src="`/img/hr_landing/statistics_${i}.png`" alt="">
      </div>
    </section>
    <section :class="$style.scriptSection" id="scripts">
      <div :class="$style.scriptContent">
        <h1 :class="$style.scriptTitle">
          <span>Сценарии собеседований.</span>
          Экономьте время на подготовку, пользуйтесь готовыми шаблонами</h1>
        <p :class="$style.scriptSubtitle">Отдельные шаблоны по всем ключевым вакансиям. Вам больше не нужно тратить
          время на составление вопросов перед собеседованием – просто добавьте свои комментарии к готовому сценарию.</p>
      </div>
      <img :class="$style.scriptImage" src="/img/hr_landing/script.png" alt="">
    </section>
    <section :class="$style.moreFeaturesSection" id="more_features">
      <img :class="$style.moreFeaturesHint" src="/img/hr_landing/more_features_hint.png" alt="">
      <h1 :class="$style.moreFeaturesTitle">Также Rekroo — это:</h1>
      <div :class="$style.moreFeaturesList">
        <div :class="$style.moreFeaturesItem" v-for="(item, i) in featuresItems" :key="i">
          <div :class="$style.moreFeaturesItemTitle">{{ item.title }}</div>
          <div :class="$style.moreFeaturesItemSubtitle">{{ item.text }}</div>
          <img v-if="i === featuresItems.length - 1" src="/img/hr_landing/notifications.png" alt="">
        </div>
      </div>
      <a href="https://web.rekroo.org" :class="$style.moreFeaturesButton">
        <rekroo-button height="50px" width="100%">Попробовать бесплатно</rekroo-button>
      </a>
    </section>
    <landing-reviews-section title="Они уже попробовали Rekroo и нашли своих сотрудников"/>
    <section :class="$style.promoSection" id="trial">
      <h1 :class="$style.promoTitle">Начните прямо сейчас. Попробуйте первые 3 месяца бесплатно
        <img src="/img/icons/icon_empty_list.svg" alt="">
      </h1>
      <div :class="$style.promoContent">
        <p :class="$style.promoText">Сегодня вы можете войти в круг первых пользователей и получить бесплатный доступ, а
          мы от вас хотим только обратную связь, что понравилось или не понравилось, чтобы сделать Rekroo еще лучше.</p>
        <a :class="$style.promoButton" href="https://web.rekroo.org">
          <rekroo-button width="100%" height="50px">Попробовать бесплатно</rekroo-button>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import UploadResumeBlock from "@/components/UploadResumeBlock";
import LandingReviewsSection from "@/views/landing/components/LandingReviewsSection";

export default {
  name: "HrmLanding",
  components: {LandingReviewsSection, UploadResumeBlock, RekrooIcon, RekrooButton},
  data() {
    return {
      style,
      systemItems: [
        {
          title: 'Экономия времени!',
          icon: 'time',
          iconColor: 'var(--green)',
          text: 'Автоматическое заполнение резюме искусственным интеллектом',
          link: 'scripts'
        },
        {
          title: 'Эффективная работа с большим объемом данных',
          icon: 'files',
          iconColor: 'var(--red)',
          text: 'Обработка до 500 резюме одновременно',
          plusIcon: true,
          link: 'resume_database'
        },
        {
          title: 'Все в одном месте',
          icon: 'filing',
          iconColor: 'var(--orange)',
          text: 'Быстрый доступ и удобное хранение и поиск резюме',
          link: 'resume_database'
        },
        {
          title: 'Доступ к актуальным резюме всего за 29 ₽*',
          icon: 'info',
          iconColor: 'var(--blue)',
          text: '*Стоимость указана за 1 кандидата',
          link: 'catalog_resume'
        },
        {
          title: 'Детальная аналитика и статистика для контроля эффективности работы',
          icon: 'graph',
          iconColor: 'var(--green)',
          text: '',
          link: 'analytics'
        },
        {
          title: 'Интеграция с популярными сайтами по поиску работы',
          icon: 'hh',
          iconColor: 'var(--orange)',
          text: 'HeadHunter, Habr Карьера, Superjob, Github, Avito, Linkedin и Facebook',
          link: 'extension'
        },
        {
          title: 'Управление коммуникацией с кандидатами с помощью удобной воронки найма',
          icon: 'filter',
          iconColor: 'var(--blue)',
          text: '',
          link: 'funnel'
        },
        {
          title: 'Инструменты эффективных собеседований',
          icon: 'list',
          iconColor: 'var(--red)',
          text: 'Готовый шаблоны скриптов и сценариев',
          link: 'scripts'
        },
        {
          title: 'Создание напоминаний о собеседованиях и других важных событиях',
          icon: 'notification',
          iconColor: 'var(--orange)',
          text: '',
          link: 'more_features'
        },
        {
          title: 'Интеграция с WhatsApp и Telegram для удобной связи с кандидатами',
          icon: 'chatbubbles',
          iconColor: 'var(--green)',
          text: '',
          link: 'more_features'
        },
        {
          title: 'Первые 3 месяца бесплатного доступа для новых пользователей',
          icon: 'star',
          iconColor: 'var(--blue)',
          text: '',
          link: 'trial'
        },
      ],
      icons: ['profile', 'notification', 'files', 'geo', 'calendar'],
      iconsResources: ['hh', 'habr', 'linkedin', 'github', 'stackoverflow'],
      browserExtensionItems: [
        {
          title: 'Экспорт резюме с популярных сайтов по поиску работы',
          subtitle: 'Добавляйте кандидтов из HeadHunter, Habr Карьера, Superjob, Github, Avito, Linkedin и Facebook',
        },
        {
          title: 'Сбор и хранение истории взаимодействия с кандидатом со сторонних ресурсов',
          subtitle: 'Работайте с резюме даже если доступ на его источнике уже закончился',
        },
        {
          title: 'Проверка наличия резюме с нашего сайта в вашей базе',
          subtitle: 'Расширение автоматически определит и покажет, если кандидат уже добавлен в HRM',
        },
      ],
      featuresItems: [
        {
          title: 'Интеграция с WhatsApp/Telegram',
          text: 'Rekroo дает возможность контактировать с кандидатами через привычные и удобные вам мессенджеры WhatsApp и Telegram — вы ничего не пропустите.'
        },
        {
          title: 'Страница проведения собеседования',
          text: 'Отдельные шаблоны по всем ключевым вакансиям. Вам больше не нужно тратить время на составление вопросов перед собеседованием.'
        },
        {
          title: 'Напоминания о собеседованиях',
          text: 'Напоминания о собеседованиях и просто можно создать напоминание (позвонить кандидату, запросить какой-то документ и тд)',
        }
      ]
    }
  }
}
</script>

<style module lang="css">
/*CTA SECTION*/
.hrLandingHeader {
  position: relative;
  margin-bottom: 120px;
  display: flex;
  padding: 100px 0 56px 80px;
  border-radius: 16px;
  background: var(--blue);
  overflow: visible;
}

.hrLandingHeaderDescription {
  max-width: 690px;
  background: transparent;
  position: relative;
}

.hrLandingHeaderTitle {
  margin-bottom: 24px;
  font-size: 64px;
  font-weight: 700;
}

.hrLandingHeaderSubtitle {
  max-width: 520px;
  margin-bottom: 64px;
  font-size: 24px;
  line-height: 140%;
}

.hrLandingHeaderButton {
  display: block;
  max-width: 424px !important;
  margin-bottom: 76px;
}

.hrLandingHeaderArrow {
  position: absolute;
  bottom: 60px;
  right: 80px;
}

.hrLandingHeaderImageContainer {
  min-width: 300px;
  position: absolute;
  bottom: -70px;
  right: -60px;
}

.hrLandingHeaderImage {
  max-width: 580px;
  width: 100%;
}

.hrLandingHeaderImageContainer .hrLandingHeaderNavigateToBottomButton, .hrLandingHeaderImageContainer .hrLandingHeaderButton {
  display: none;
}

.hrLandingHeaderImageTablet {
  display: none;
}

/*TOOLS SECTION*/
.toolsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.toolsSectionTitle {
  margin-bottom: 52px;
  max-width: 810px;
  text-align: left;
}

.systemItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.systemItem {
  padding: 12px;
  border-radius: 20px;
  background: var(--light-gray);
}

.systemItemInner {
  height: 100%;
  padding: 20px;
  border: 1px dashed var(--primary);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.systemItemIcon {
  width: 44px;
  height: 44px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}

.systemItemTitle {
  font-size: var(--body);
  font-weight: 500;
}

.systemItemText {
  margin-top: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  opacity: .6;
}

.systemItemLink {
  margin-top: auto;
  display: flex;
  align-items: center;
  font-size: var(--small-text);
}

.systemItemCTA {
  background-image: url("/img/hr_landing/w_shape_arrow.png");
  background-color: var(--yellow);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}

.systemItemCTA_Title {
  padding-top: 8px;
  font-size: var(--headline_3);
  font-weight: 600;
}

.systemItemCTA_Button {
  width: 100%;
  margin-top: auto;
}

.toolsSectionSubtitle {
  margin-bottom: 20px;
  max-width: 630px;
  font-size: var(--body);
  text-align: center;
}

.toolsSectionIcons {
  display: flex;
}

.toolIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 4px solid #FFF;
  position: relative;
}

.toolIcon:not(:first-child) {
  margin-left: -10px;
}

.profileIcon {
  background: var(--orange);
}

.notificationIcon {
  background: var(--blue);
}

.filesIcon {
  background: var(--red);
}

.geoIcon {
  background: var(--yellow);
}

.calendarIcon {
  background: var(--green);
}

.toolIconAddIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  right: 7px;
  bottom: 7px;
}

/*HRM RESUME LIST*/
.resumeDatabaseSection {
  margin-bottom: 400px;
  padding: 100px 80px 260px;
  border-radius: 16px;
  background: var(--light-gray);
  position: relative;
}

.resumeDatabaseSectionInner {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
}

.resumeDatabaseSectionContent {
  max-width: 610px;
}

.resumeDatabaseSectionTitle {
  margin-bottom: 28px;
}

.resumeDatabaseSectionTitle span {
  color: var(--bright-blue);
}

.resumeDatabaseSectionContent p {
  max-width: 502px;
}

.resumeDatabaseSectionContent p:not(:last-child) {
  margin-bottom: 16px;
}

.resumeDatabaseSectionImage {
  position: relative;
}


.resumeSectionImgMobile {
  display: none;
}

.uploadResumeBlock {
  width: 460px;
  position: absolute;
  right: 120px;
  top: 276px;
  left: auto;
  height: fit-content;
}

.hrmCandidatesListImage {
  display: block;
  position: absolute;
  bottom: -284px;
  left: 80px;
  right: 80px;
}

.hrmCandidatesListImage img {
  max-height: 501px;
  width: 100%;
}

.resumeDatabaseSectionHint {
  position: absolute;
  top: 48px;
  left: 630px;
  width: 190px;
  transform: rotate(15deg);
  color: var(--gray-text);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.resumeDatabaseSectionHint img {
  width: 55px;
  margin-top: 8px;
  transform: rotate(90deg);
}

/*CATALOG SECTION*/
.resumeCatalogSection {
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resumeCatalogTitle {
  margin-bottom: 24px;
  max-width: 646px;
  text-align: center;
}

.resumeCatalogSubtitle {
  max-width: 624px;
  margin-bottom: 36px;
  text-align: center;
  font-size: var(--body);
}

.resumeCatalogSubtitle span {
  background: var(--green);
  border-radius: 4px;
}

.resumeCatalogTitle span {
  color: var(--purple)
}

.resumeCatalogImage {
  margin-bottom: 48px;
  max-height: 490px;
  height: 100%;
  width: auto;
}

.resumeCatalogButton {
  max-width: 428px;
  width: 100%;
}

/*BROWSER EXTENSION*/
.browserExtensionSection {
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.browserExtensionTitle {
  margin-bottom: 40px;
  max-width: 720px;
  text-align: center;
}

.browserExtensionTitle span {
  color: var(--bright-blue);
}

.browserExtensionList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 48px;
}

.browserExtensionItem {
  padding-top: 40px;
  background: var(--light-gray);
  border-radius: 12px;
  max-width: 427px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.browserExtensionItemTitle {
  padding: 0 28px;
  margin-bottom: 12px;
  font-size: var(--headline_3);
  font-weight: 600;
}

.browserExtensionItemSubtitle {
  padding: 0 28px;
  margin-bottom: 12px;
  font-size: var(--body);
}

.browserExtensionItemImage {
  height: auto;
  width: 100%;
  margin: auto auto 0;
}

.browserExtensionItem:last-child .browserExtensionItemImage {
  margin-bottom: -32px;
}

.browserExtensionLink {
  max-width: 426px;
  width: 100%;
}

/*FUNNEL SECTION*/
.funnelSection {
  display: flex;
  margin-bottom: 20px;
  padding: 44px 75px 44px 80px;
  align-items: center;
  justify-content: space-between;
  background: var(--light-gray);
  border-radius: 16px;
}

.funnelContent {
  margin-right: 30px;
}

.funnelTitle {
  margin-bottom: 28px;
  max-width: 502px;
}

.funnelTitle span {
  color: var(--bright-yellow);
}

.funnelSubtitle {
  font-size: var(--body);
}

.funnelImage img {
  max-height: 320px;
  height: 100%;
  width: 100%;
}

/*COMMENT SECTION*/
.commentSection {
  display: flex;
  margin-bottom: 120px;
  padding: 80px;
  padding-right: 75px;
  align-items: flex-start;
  justify-content: space-between;
  background: var(--light-gray);
  border-radius: 16px;
}

.commentContent {
  order: 2;
}

.commentTitle {
  margin-bottom: 28px;
}

.commentTitle span {
  color: var(--bright-red);
}

.commentSubtitle {
  font-size: var(--body);
  margin-bottom: 12px;
}

.commentSubtitle:last-child {
  margin-bottom: 0;
}

.commentImage {
  order: 1;
  margin-right: 30px;
  width: 100%;
  position: relative;
}

.commentImage img {
  max-width: 527px;
  width: 100%;
}

.commentImageMobile {
  display: none;
}

.commentHint {
  max-width: 410px;
  margin-left: -50px;
}

.commentHintTablet, .commentHintMobile {
  display: none;
}

/*STATISTIC SECTION*/
.statisticSection {
  margin-bottom: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.statisticTitle {
  max-width: 620px;
  text-align: center;
  margin-bottom: 28px;
}

.statisticTitle span {
  color: var(--purple);
}

.statisticSubtitle {
  max-width: 528px;
  text-align: center;
  margin-bottom: 44px;
}

.statisticItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
}

.statisticItems img {
  max-width: 315px;
  width: 100%;
}

/*SCRIPT SECTION*/
.scriptSection {
  margin-bottom: 192px;
  padding: 30px 60px 0;
  display: flex;
  align-items: center;
  background: var(--light-gray);
  border-radius: 16px;
}

.scriptTitle {
  margin-bottom: 28px;
}

.scriptTitle span {
  color: var(--bright-blue);
}

.scriptSubtitle {
  font-size: var(--body);
  padding-bottom: 60px;
}

.scriptImage {
  max-width: 530px;
  width: 100%;
  margin-bottom: -92px;
}

/*MORE FEATURES SECTION*/
.moreFeaturesSection {
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}


.moreFeaturesHint {
  position: absolute;
  top: 16px;
  left: 72px;
  max-height: 60px;
}

.moreFeaturesTitle {
  margin-bottom: 55px;
  text-align: center;
}

.moreFeaturesList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 60px;
}

.moreFeaturesItem {
  padding: 36px 36px 74px;
  background: var(--green);
  border-radius: 16px;
  position: relative;
}

.moreFeaturesItemTitle {
  margin-bottom: 16px;
  font-size: var(--headline_2);
  font-weight: 700;
}

.moreFeaturesItemSubtitle {
  font-size: var(--body);
}

.moreFeaturesItem img {
  position: absolute;
  bottom: -24px;
  right: 36px;
  max-height: 87px;
}

.moreFeaturesButton {
  max-width: 426px;
  width: 100%;
}

/*PROMO SECTION*/
.promoSection {
  display: flex;
  align-items: flex-start;
  margin-bottom: 36px;
  padding: 100px 80px;
  background: var(--yellow);
  border-radius: 16px;
}

.promoContent {
  max-width: 570px;
}

.promoText {
  margin-bottom: 28px;
  font-size: var(--body);
}

.promoButton {
  max-width: 541px;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .hrLandingHeader {
    padding-top: 60px;
    padding-left: 48px;
  }

  .hrLandingHeaderTitle {
    max-width: 520px;
    font-size: 56px;
  }

  .hrLandingHeaderArrow {
    display: none;
  }

  .hrLandingHeaderImageContainer {
    bottom: 0;
  }

  .hrLandingHeaderImage {
    max-width: 480px;
  }

  .hrmCandidatesListImage {
    bottom: -200px;
  }

  .uploadResumeBlock {
    right: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .hrLandingHeader {
    flex-direction: column;
    padding: 56px 40px;
    margin-bottom: 80px;
  }

  .hrLandingHeaderDescription {
    max-width: 100%;
  }

  .hrLandingHeaderDescription .hrLandingHeaderButton {
    display: none;
  }

  .hrLandingHeaderImageContainer .hrLandingHeaderButton {
    display: block;
    width: calc(100% - 40px);
  }

  .hrLandingHeaderTitle {
    margin-bottom: 24px;
    max-width: 100%;
  }

  .hrLandingHeaderSubtitle {
    max-width: 100%;
    margin-bottom: 32px;
  }

  .hrLandingHeaderImageContainer {
    min-width: 0;
    position: initial;
    margin-right: -40px;
  }

  .hrLandingHeaderImage {
    display: none;
  }

  .hrLandingHeaderImageTablet {
    display: block;
    width: 100%;
    border-radius: 12px;
    margin-bottom: 48px;
  }

  .hrLandingHeaderButton {
    max-width: 100% !important;
    width: 100%;
    display: block;
    margin-bottom: 48px;
  }

  .hrLandingHeaderNavigateToBottomButton {
    display: none;
  }

  .hrLandingHeaderImageContainer .hrLandingHeaderNavigateToBottomButton {
    display: flex;
  }

  .toolsSection {
    margin-bottom: 80px;
  }

  .toolsSectionTitle {
    margin-bottom: 20px;
  }


  .systemItems {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .toolsSectionSubtitle {
    margin-bottom: 32px;
  }

  .resumeDatabaseSection {
    padding: 60px 40px;
    margin-bottom: 280px;
  }

  .resumeDatabaseSectionInner {
    margin-bottom: 0;
  }

  .resumeDatabaseSectionHint {
    left: auto;
    right: 40px;
  }

  .resumeDatabaseSectionContent, .resumeDatabaseSectionContent p {
    max-width: 100%;
  }

  .resumeDatabaseSectionTitle {
    max-width: 408px;
  }

  .resumeDatabaseSectionImage {
    display: none;
  }

  .uploadResumeBlock {
    display: none;
  }

  .hrmCandidatesListImage {
    position: initial;
    margin-bottom: -181px
  }

  .hrmCandidatesListImage img {
    max-height: 460px;
  }

  /*CATALOG SECTION*/
  .resumeCatalogSection {
    margin-bottom: 100px;
  }

  .resumeCatalogTitle {
    margin-bottom: 20px;
    max-width: 404px;
  }

  .resumeCatalogSubtitle {
    max-width: 592px;
    margin-bottom: 28px;
  }

  .resumeCatalogImage {
    margin-bottom: 40px;
    max-height: 315px;
  }

  /*BROWSER EXTENSION*/
  .browserExtensionSection {
    margin-bottom: 100px;
  }

  .browserExtensionTitle {
    max-width: 592px;
  }

  .browserExtensionList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
    margin-bottom: 75px;
  }

  .browserExtensionItem {
    padding-top: 40px;
    max-width: 100%;
    align-items: center;
  }

  .browserExtensionItemTitle {
    text-align: center;
  }

  .browserExtensionItemSubtitle {
    margin-bottom: 24px;
    text-align: center;
  }

  .browserExtensionItemImage {
    max-height: 200px;
    width: auto;
  }

  /*FUNNEL SECTION*/
  .funnelSection {
    padding: 42px 50px;
    flex-direction: column;
    justify-content: center;
  }

  .funnelContent {
    margin-right: 0;
    margin-bottom: 28px;
  }

  .funnelTitle {
    margin-bottom: 20px;
    max-width: 558px;
  }

  .funnelImage img {
    max-height: 320px;
    height: 100%;
  }

  /*COMMENT SECTION*/
  .commentSection {
    margin-bottom: 100px;
    padding: 64px 42px 52px;
    justify-content: center;
    flex-direction: column;
  }

  .commentContent {
    order: 1;
    margin-bottom: 30px;
  }

  .commentTitle {
    margin-bottom: 20px;
  }

  .commentSubtitle {
    margin-bottom: 16px;
  }

  .commentImage {
    order: 2;
    margin-right: 0;
    display: flex;
    align-items: center;
  }

  .commentImage img {
    padding-left: 34px;
    width: 70%;
  }

  .commentHint {
    display: none;
  }

  img.commentHintTablet {
    display: block;
    max-width: 208px;
    width: 30%;
    height: 100%;
    padding-left: 0;
  }

  /*STATISTIC SECTION*/
  .statisticSection {
    margin-bottom: 100px;
  }

  .statisticTitle {
    max-width: 431px;
    margin-bottom: 20px;
  }

  .statisticSubtitle {
    max-width: 474px;
    margin-bottom: 28px;
  }

  .statisticItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .statisticItems img {
    max-width: 315px;
    width: 100%;
  }

  /*SCRIPT SECTION*/
  .scriptSection {
    margin-bottom: 174px;
    padding: 64px 42px 0;
    flex-direction: column;
  }

  .scriptTitle {
    margin-bottom: 20px;
  }

  .scriptSubtitle {
    padding-bottom: 28px;
  }

  .scriptImage {
    margin-bottom: -94px;
  }

  .moreFeaturesHint {
    display: none;
  }

  /*MORE FEATURES SECTION*/
  .moreFeaturesSection {
    margin-bottom: 120px;
  }

  .moreFeaturesHint {
    display: none;
  }

  .moreFeaturesTitle {
    margin-bottom: 40px;
  }

  .moreFeaturesList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 12px;
    margin-bottom: 90px;
  }

  .moreFeaturesItem {
    padding: 36px;
  }

  .moreFeaturesItemTitle {
    margin-bottom: 12px;
  }

  .moreFeaturesItem img {
    position: absolute;
    bottom: -54px;
  }

  .moreFeaturesButton {
    max-width: 426px;
    width: 100%;
  }

  /*PROMO SECTION*/
  .promoSection {
    flex-direction: column;
    margin-bottom: 20px;
    padding: 64px 40px;
  }

  .promoTitle {
    margin-bottom: 8px;
  }

  .promoTitle img {
    display: block;
  }

  .promoContent {
    max-width: 100%;
  }

  .promoButton {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .resumeDatabaseSectionInner {
    flex-direction: column;
  }

  .resumeDatabaseSectionContent {
    margin-bottom: 32px;
  }

  .resumeDatabaseSectionContent p {
    max-width: 100%;
  }

  .resumeDatabaseSectionInner {
    margin-bottom: 0;
  }

  .uploadResumeBlock {
    position: relative;
    left: 0;
    top: -150px;
  }
}

@media screen and (max-width: 575px) {
  .hrLandingHeader {
    padding: 48px 20px;
    margin-bottom: 74px;
  }

  .hrLandingHeaderImageContainer {
    margin-right: -20px;
  }

  .hrLandingHeaderImageContainer .hrLandingHeaderButton {
    width: calc(100% - 20px);
  }

  .hrLandingHeaderTitle {
    margin-bottom: 20px;
    font-size: 32px;
  }

  .hrLandingHeaderSubtitle {
    font-size: 18px;
  }

  .hrLandingHeaderButton {
    margin-bottom: 28px;
  }

  .hrLandingHeaderImageTablet {
    margin-bottom: 32px;
  }

  .toolsSection {
    margin-bottom: 50px;
  }


  .systemItems {
    display: flex;
    grid-gap: 20px;
    margin: 0 -16px;
    padding: 0 16px;
    width: calc(100% + 32px);
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .systemItems::-webkit-scrollbar {
    display: none;
  }

  .systemItem {
    min-width: 224px;
    width: 224px;
    padding: 10px;
    border-radius: 16px;
  }

  .systemItemInner {
    padding: 16px;
    border-radius: 10px;
  }

  .systemItemIcon {
    margin-bottom: 8px;
  }

  .systemItemCTA_Title {
    font-size: 20px;
    line-height: 144%;
    font-weight: 500;
  }

  .toolsSectionSubtitle {
    margin-bottom: 16px;
  }

  .resumeDatabaseSection {
    margin-bottom: 100px;
    padding: 48px 20px 20px;
  }

  .resumeDatabaseSectionTitle {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .hrmCandidatesListImage, .resumeDatabaseSectionHint {
    display: none;
  }

  .resumeSectionImgMobile {
    display: block;
    width: 100%;
  }

  /*CATALOG SECTION*/
  .resumeCatalogTitle {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .resumeCatalogSubtitle {
    max-width: 100%;
    margin-bottom: 28px;
  }

  .resumeCatalogImage {
    margin-bottom: 40px;
    height: auto;
    width: 100%;
  }

  .resumeCatalogButton {
    max-width: 100%;
  }

  /*BROWSER EXTENSION*/
  .browserExtensionTitle {
    margin-bottom: 40px;
    max-width: 100%;
  }

  .browserExtensionList {
    margin-bottom: 70px;
  }

  .browserExtensionItem {
    padding: 40px 20px 0;
    max-width: 100%;
    align-items: center;
  }

  .browserExtensionItemTitle {
    text-align: left;
    padding: 0;
  }

  .browserExtensionItemSubtitle {
    padding: 0;
    margin-bottom: 12px;
    text-align: left;
  }

  .browserExtensionItemImage {
    width: 100%;
  }

  /*FUNNEL SECTION*/
  .funnelSection {
    padding: 60px 20px;
  }

  .funnelImage img {
    max-height: 168px;
    height: 100%;
  }

  /*COMMENT SECTION*/
  .commentSection {
    margin-bottom: 245px;
    padding: 60px 20px 0;
  }

  .commentContent {
    margin-bottom: 20px;
  }

  .commentSubtitle {
    margin-bottom: 12px;
  }

  .commentImage {
    position: relative;
  }

  .commentImage img {
    padding-left: 0;
    max-width: 100%;
    margin-bottom: -67px;
  }

  .commentImageDesktop {
    display: none;
  }

  img.commentImageMobile {
    display: block;
    width: 100%;
  }

  img.commentHintTablet {
    display: none;
  }

  img.commentHintMobile {
    display: block;
    position: absolute;
    bottom: -70px;
  }

  /*STATISTIC SECTION*/
  .statisticTitle {
    max-width: 100%;
  }

  .statisticItems {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }

  .statisticItems img {
    max-width: 100%;
    width: 100%;
  }

  /*SCRIPT SECTION*/
  .scriptSection {
    margin-bottom: 152px;
    padding: 60px 20px 0;
  }

  .scriptSubtitle {
    padding-bottom: 32px;
  }

  .scriptImage {
    margin-bottom: -72px;
  }

  /*MORE FEATURES SECTION*/
  .moreFeaturesSection {
    margin-bottom: 90px;
  }

  .moreFeaturesItem {
    padding: 32px 28px;
  }

  .moreFeaturesItem:last-child {
    padding-bottom: 48px;
  }

  .moreFeaturesItemTitle {
    margin-bottom: 16px;
  }

  .moreFeaturesItem img {
    position: absolute;
    bottom: -51px;
  }

  .moreFeaturesButton {
    max-width: 426px;
    width: 100%;
  }

  /*PROMO SECTION*/
  .promoSection {
    padding: 60px 20px;
  }

  .promoTitle {
    margin-bottom: 16px;
  }
}
</style>