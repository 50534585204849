<template>
  <div class="page page-with-tabs-table" :class="$style.profilePage">
    <h1 :class="$style.pageTitle">Мой профиль
      <rekroo-button v-if="user.id"
          @click="$refs.confirm_logout_dialog.open()"
                     :class="$style.exitButton"
                     color="var(--gray)"
      text_color="var(--primary)">Выйти</rekroo-button>
    </h1>
    <div v-if="authError" :class="$style.personalInfoContainer">
      <empty-list-plug title="Авторизуйтесь, чтобы просмотреть свой профиль" button_text="Войти"
                       @onClick="$store.state.eventBus.$emit('auth-error')" :show-icon="true"
                       style="margin: 40px auto"/>
    </div>
    <div  v-else :class="$style.userInfoContainer">
      <div :class="$style.personalInfoContainer">
        <div :class="$style.avatarContainer">
          <image-upload :class="$style.avatar" :item-image="user.image ? user.image.url : ''" :on-remove-btn-click="onRemoveImage"
                        @on-upload-success="onImageUpload" bg_color="var(--light-gray)"/>
          <rekroo-button @click="$refs.profile_edit_dialog.open()"
                         :class="$style.editProfileButton" type="text" text_color="var(--bright-blue)">Редактировать
          </rekroo-button>
        </div>
        <div :class="$style.personalInfoInner">
          <div :class="$style.personalInfoName">{{ user.name }} {{ user.surname }}</div>
          <div :class="$style.personalInfoAge" v-if="user.birthday">{{ getAgeByDate(user.birthday) }}
            {{ getNoun(getAgeByDate(user.birthday), 'год', 'года', 'лет') }}
          </div>
          <div :class="$style.contacts">
            <tag :class="$style.contactTag" v-if="user.phone" icon="phone" icon_color="var(--gray-text)" icon_pos="start" :text="strPhoneMask(user.phone)"/>
            <tag :class="$style.contactTag" v-if="user.email" icon="mail" icon_color="var(--gray-text)" icon_pos="start" :text="user.email"/>
          </div>
          <rekroo-button @click="$refs.profile_edit_dialog.open()"
                         :class="$style.editProfileButton" type="text" text_color="var(--bright-blue)">Редактировать
          </rekroo-button>
          <rekroo-button @click="$refs.confirm_logout_dialog.open()"
                         :class="$style.exitButton"
                         color="var(--gray)"
                         text_color="var(--primary)">Выйти</rekroo-button>
        </div>
      </div>
      <h1>Аукционы</h1>
      <div :class="$style.userCheckboxes">
        <el-checkbox :value="user.woinfo1?.status === 1" label="Получать заказы от компаний"
                     @change="onChangeReceiveOrders"/>
        <rekroo-button :class="$style.hrUpdateButton"
            type="outlined" @click="$refs.hr_form_dialog.open()">Информация о себе</rekroo-button>
      </div>
      <h1 :class="$style.ordersTitle">Покупки</h1>
      <table-skeleton v-if="loading" :show-selection="false"
                      :header-titles="['Название', 'Стоимость', 'Что включено', '']"/>
      <el-tabs v-model="activeTab" v-else>
        <el-tab-pane name="active" label="Активные">
          <el-table
              :class="['table-container',tableStyles.table, {'table-container-active': loaded}]"
              :data="activePurchases"
              header-row-class-name="header-row"
              :show-header="activePurchases.length > 0 && deviceWidth > 575">
            <el-table-column label="Название" class-name="table-title-col">
              <template slot-scope="scope">
                <div :class="tableStyles.titleContainer">
                  <router-link :to="`/order/${scope.row.id}`" :class="tableStyles.title">{{ scope.row.title }}
                  </router-link>
                  <div :class="tableStyles.mobileCell">
                    <div :class="$style.activePurchaseTotal">
                      <span>Осталось:</span>{{ scope.row.amount_left }} / {{ scope.row.amount_total }}
                      {{
                        scope.row.type.includes('vacancy') ? getNoun(scope.row.amount_left, 'вакансия', 'вакансии', 'вакансий')
                            : getNoun(scope.row.amount_left, 'открытие', 'открытия', 'открытий')
                      }}
                    </div>
                    <div :class="$style.activePurchaseDateTo" v-if="scope.row.datetime_to">
                      <span>Активно до:</span> {{ dateParse(scope.row.datetime_to, 'D MMMM, YYYY') }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Осталось">
              <template slot-scope="scope">
                <div :class="$style.activePurchaseTotal">{{ scope.row.amount_left }} / {{ scope.row.amount_total }}
                  {{
                    scope.row.type.includes('vacancy') ? getNoun(scope.row.amount_left, 'вакансия', 'вакансии', 'вакансий')
                        : getNoun(scope.row.amount_left, 'открытие', 'открытия', 'открытий')
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Дата окончания действия" width="170">
              <template slot-scope="scope">
                <div :class="$style.activePurchaseDateTo" v-if="scope.row.datetime_to">
                  {{ dateParse(scope.row.datetime_to, 'D MMMM, YYYY') }}
                </div>
              </template>
            </el-table-column>
            <template #empty>
              <empty-list-plug title="У вас нет активных подписок" subtitle="Перейдите в раздел Тарифы, чтобы увеличить свои возможности"
              button_text="К тарифам" @onClick="$router.push('/rates')" :show-icon="true"/>
            </template>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="all" label="Все">
          <el-table
              :class="['table-container',tableStyles.table, {'table-container-active': loaded}, 'table-with-actions']"
              :data="orders" :show-header="orders.length > 0 && deviceWidth > 575"
              header-row-class-name="header-row">
            <el-table-column label="Название" class-name="table-title-col">
              <template slot-scope="scope">
                <div :class="tableStyles.titleContainer">
                  <router-link :to="`/order/${scope.row.id}`" :class="tableStyles.title">
                    {{ scope.row.title }}
                  </router-link>
                  <div :class="tableStyles.mobileCell">
                    <div :class="$style.price">
                    <span>Стоимость:</span>  {{ thousandSeparator(scope.row.price) }}
                      {{
                        $store.state.systemParams.currencies ?
                            $store.state.systemParams.currencies.rub ? $store.state.systemParams.currencies.rub.short : '' : ''
                      }}
                    </div>
                    <div :class="$style.options">
                      <span>Что включено:</span>{{
                        scope.row.type.includes('vacancy') ?
                            `${thousandSeparator(scope.row.amount_total)} ${getNoun(scope.row.amount_total, 'вакансия', 'вакансии', 'вакансий')}`
                            : `${thousandSeparator(scope.row.amount_total)} ${getNoun(scope.row.amount_total, 'открытие', 'открытия', 'открытий')} контактов`
                      }}
                    </div>
                  </div>
                </div>
                <div :class="tableStyles.mobileActions">
                  <el-dropdown :hide-on-click="true" trigger="click">
                    <rekroo-button icon="more" color="transparent" icon_width="30px" style="padding: 0"/>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <rekroo-button type="text" text_color="var(--primary)" v-if="scope.row.payed === 0" @click="pay(scope.row.paymentLink)">Оплатить
                        </rekroo-button>
                        <rekroo-button type="text" text_color="var(--primary)" v-else-if="scope.row.datetime_to === 0  && scope.row.type.includes('resume')"
                                       @click="activate(scope.row.id)">Активировать
                        </rekroo-button>
                        <rekroo-button type="text" text_color="var(--primary)" v-else :disabled="true" icon_style="margin-left: 10px"
                                       icon_color="var(--green-active)" icon="check" icon_pos="end">Активировано
                        </rekroo-button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Стоимость">
              <template slot-scope="scope">
                {{ thousandSeparator(scope.row.price) }}
                {{
                  $store.state.systemParams.currencies ?
                      $store.state.systemParams.currencies.rub ? $store.state.systemParams.currencies.rub.short : '' : ''
                }}
              </template>
            </el-table-column>
            <el-table-column label="Что включено">
              <template slot-scope="scope">
                {{
                  scope.row.type.includes('vacancy') ?
                      `${thousandSeparator(scope.row.amount_total)} ${getNoun(scope.row.amount_total, 'вакансия', 'вакансии', 'вакансий')}`
                      : `${thousandSeparator(scope.row.amount_total)} ${getNoun(scope.row.amount_total, 'открытие', 'открытия', 'открытий')} контактов`
                }}
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div :class="[tableStyles.actions, $style.allPurchasesActions]">
                  <rekroo-button v-if="scope.row.payed === 0" @click="pay(scope.row.paymentLink)">Оплатить</rekroo-button>
                  <rekroo-button v-else-if="scope.row.datetime_to === 0 && scope.row.type.includes('resume')"
                                 @click="activate(scope.row.id)">Активировать
                  </rekroo-button>
                  <rekroo-button v-else :disabled="true" icon_style="margin-left: 10px"
                                 icon_color="var(--green-active)" icon="check" icon_pos="end">Активировано
                  </rekroo-button>
                </div>
                <el-dropdown :class="$style.allPurchasesActionsTablet" :hide-on-click="true" trigger="click">
                  <rekroo-button icon="more" icon_color="#FFF" color="var(--gray-text)"
                                 width="50px" height="48px"
                                 icon_width="30px"/>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <rekroo-button type="text" text_color="var(--primary)" v-if="scope.row.payed === 0" @click="pay(scope.row.paymentLink)">Оплатить
                      </rekroo-button>
                      <rekroo-button type="text" text_color="var(--primary)" v-else-if="scope.row.datetime_to === 0  && scope.row.type.includes('resume')"
                                     @click="activate(scope.row.id)">Активировать
                      </rekroo-button>
                      <rekroo-button type="text" text_color="var(--primary)" v-else :disabled="true" icon_style="margin-left: 10px"
                                     icon_color="var(--green-active)" icon="check" icon_pos="end">Активировано
                      </rekroo-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <template #empty>
              <empty-list-plug title="Вы не приобретали подписки" subtitle="Перейдите в раздел Тарифы, чтобы увеличить свои возможности"
                               button_text="К тарифам" @onClick="$router.push('/rates')" :show-icon="true"/>
            </template>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <PaymentDialog ref="payment_dialog" @csuccess="get"/>
    <ProfileEditDialog ref="profile_edit_dialog"/>
    <AboutHrFormDialog ref="hr_form_dialog" @updated="$store.state.eventBus.$emit('get-user')"/>
    <ConfirmDialog ref="confirm_logout_dialog" @success="logout"
                   :success_btn_loading="logoutLoading"
                   :manual-close="true"
                   title="Вы уверены, что хотите выйти?" decline_button_text="Отмена" success_button_text="Да, выйти"/>
  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload";
import {User} from "@/api/user";
import {Order} from "@/api/order";
import RekrooButton from "@/components/Button";
import PaymentDialog from "@/dialogs/PaymentDialog";
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import Tag from "@/components/Tag";
import ProfileEditDialog from "@/dialogs/ProfileEditDialog";
import EmptyListPlug from "@/components/EmptyListPlug";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import AboutHrFormDialog from "@/dialogs/AboutHrFormDialog";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import {Auth} from "@/api/auth";

export default {
  name: "Profile",
  components: {
    ConfirmDialog,
    AboutHrFormDialog,
    EmptyListPlug, ProfileEditDialog, Tag, TableSkeleton, PaymentDialog, RekrooButton, ImageUpload},
  data: () => ({
    tableStyles,
    orderClass: new Order({}),
    userClass: new User({}),
    orders: [],
    loading: true,
    loaded: false,
    activeTab: 'active',
    authError: false,
    receiveOrders: false,
    logoutLoading: false,
    auth: new Auth(),
  }),
  methods: {
    get() {
      this.loading = true;
      this.orderClass.get().then((data) => {
        this.loading = false;
        this.loaded = true;
        this.orders = data.response.filter(e => e.type).reverse();
        this.authError = false;
      }).catch((data) => {
        this.loading = false;
        if (data.error.type === '401') this.authError = true;
      })
    },
    onImageUpload(res) {
      this.userClass.create({id: this.user.id, image: res.id}).then((data) => {
        this.$store.commit('setUser', data.response);
      })
    },
    onRemoveImage() {
      this.userClass.create({id: this.user.id, image: null}).then((data) => {
        this.$store.commit('setUser', data.response);
      })
    },
    pay(link) {
      this.$refs.payment_dialog.open(link);
    },
    activate(id) {
      this.orderClass.activate(id).then(() => {
        this.get();
      })
    },
    updateWOUser(status) {
      this.userClass.setWOType({status}).then(()=>{
        this.$store.state.eventBus.$emit('get-user');
      })
    },
    onChangeReceiveOrders(val) {
      if (this.$store.state.user?.woinfo1) this.updateWOUser(val ? 1 : 0);
      else this.$refs.hr_form_dialog.open();
    },
    logout() {
      this.logoutLoading = true;
      this.auth.logout().then(() => {
        this.logoutLoading = false;
        this.$store.commit('setUser', {});
        localStorage.clear();
        this.$eventBus.$emit('logout');
        this.$refs.confirm_logout_dialog.close();
        this.$router.replace('/');
        this.$store.commit('setAudienceCloseCount', 0);
      }).catch(() => {
        this.logoutLoading = false;
      })
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    deviceWidth() {
      return window.innerWidth;
    },
    activePurchases() {
      let timestampNow = +new Date();
      timestampNow = Math.round(timestampNow / 1000);
      return this.orders.filter(order => order.payed === 1
          && order.amount_left > 0 && (order.datetime_to ? order.datetime_to > timestampNow : true));
    }
  },
  mounted() {
    this.get();
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get();
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }

}
</script>

<style module lang="css">

.pageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.pageTitle button {
  font-size: var(--button);
}

.settingsTitle {
  font-size: var(--headline_3);
  font-weight: 500;
  margin-bottom: 24px;
}

.personalInfoContainer {
  display: flex;
  column-gap: 20px;
  margin-bottom: 80px;
}
.avatarContainer .editProfileButton {
  display: none;
}

.personalInfoName {
  font-size: var(--headline_3);
  font-weight: 500;
  margin-bottom: 12px;
}

.personalInfoAge {
  margin-bottom: 12px;
}

.contacts {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.orderInfoMobile {
  display: none;
}

.personalInfoInner {
  display: flex;
  flex-direction: column;
}

.personalInfoInner .exitButton {
  display: none;
}

.editProfileButton {
  margin-top: auto;
}

.userInfoContainer {
  margin-bottom: 40px;
}

.ordersTitle {
  margin-bottom: 32px;
}

.allPurchasesActions {
  display: block;
}
.allPurchasesActionsTablet {
  display: none !important;
}

.userCheckboxes {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  margin: 0 0 80px;
}

.hrUpdateButton {
  font-size: 14px;
}
@media screen and (max-width: 1024px) {
  .userCheckboxes {
    padding: 0 48px;
  }
  .allPurchasesActions {
    display: none;
  }
  .allPurchasesActionsTablet {
    display: block !important;
  }
  .personalInfoContainer {
    padding: 0 48px;
  }
}

@media screen and (max-width: 575px) {

  .pageTitle {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .pageTitle .exitButton {
    display: none;
  }
  .personalInfoContainer {
    padding: 0 20px;
    margin-bottom: 60px;
    flex-direction: column;
    gap: 8px;
  }

  .personalInfoName {
    font-size: var(--headline_2);
    margin-bottom: 4px;
  }

  .contactTag {
    width: 100%;
  }

  .userCheckboxes {
    padding: 0 20px;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 60px;
  }

  .personalInfoInner {
    width: 100%;
  }

  .orderTitle {
    display: block;
  }

  .orderInfoMobile {
    display: block;
  }

  .options {
    margin-bottom: 16px;
  }

  .activePurchaseTitle {
    display: none;
  }

  .activePurchaseMobile {
    display: block;
  }

  .activePurchaseMobile .activePurchaseTitle {
    font-weight: 500;
    display: block;
  }
  .editProfileButton {
    margin-top: 8px;
  }

  .personalInfoInner .exitButton {
    display: flex;
    margin-top: 16px;
  }
}


</style>