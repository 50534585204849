<template>
  <el-dialog width="500px" :visible.sync="isVisible" custom-class="rekroo-dialog select-vacancy-dialog" :center="true">
    <div class="dialog-title" :class="$style.dialogTitle">Выбрать вакансию
      <rekroo-button type="text" text_color="var(--bright-blue)" @click="createVacancy">+ Новая</rekroo-button>
    </div>
    <el-input v-model="q" placeholder="Например, frontend-разработчик" class="gray-bg" clearable @input="search"/>
    <ul v-if="vacanciesLoading" :class="$style.vacanciesList">
      <li v-for="i in 5" :key="i" style="padding: 20px 0">
        <skeleton variant="text" width="300" height="18px"/>
      </li>
    </ul>
    <ul v-else-if="q && !searchedVacancies.length" :class="[$style.vacanciesList]"
        style="display: flex;align-items: center; justify-content: center">
      <empty-list-plug title="Вакансия не найдена" :show-icon="true"/>
    </ul>
    <ul v-else-if="q" :class="[$style.vacanciesList]">
      <li v-for="vacancy in searchedVacancies" :key="vacancy.id" @click="select(vacancy)">
        <div :class="$style.vacancyTitle">{{ vacancy.position }}</div>
        <rekroo-icon icon="check" color="var(&#45;&#45;bright-blue)" v-if="vacancy.id == vacancy_id"/>
      </li>
    </ul>
    <ul v-else-if="vacancies.length" :class="[$style.vacanciesList]">
      <li v-for="vacancy in vacancies" :key="vacancy.id" @click="select(vacancy)">
        <div :class="$style.vacancyTitle">{{ vacancy.position }}</div>
        <rekroo-icon icon="check" color="var(&#45;&#45;bright-blue)" v-if="vacancy.id == vacancy_id"/>
      </li>
    </ul>
    <ul v-else :class="[$style.vacanciesList]"
        style="display: flex;align-items: center; justify-content: center">
      <empty-list-plug title="Вакансий нет" :show-icon="true"/>
    </ul>
  </el-dialog>
</template>

<script>
import Skeleton from "@/components/skeletons/Skeleton";
import RekrooIcon from "@/components/Icon";
import Vacancy from "@/api/vacancy";
import RekrooButton from "@/components/Button";
import EmptyListPlug from "@/components/EmptyListPlug";

export default {
  name: "SelectVacancyDialog",
  components: {EmptyListPlug, RekrooButton, RekrooIcon, Skeleton},
  props: ['vacancy_id'],
  emits: ['select', 'on-create-btn-click'],
  data: () => ({
    vacancyClass: new Vacancy({}),
    isVisible: false,
    q: '',
    vacanciesLoading: false,
    vacancies: [],
    searchedVacancies: [],
  }),
  methods: {
    open() {
      this.vacancies = [];
      this.q = '';
      this.isVisible = true;
      this.getVacancies()
    },
    getVacancies(fromDialog = false) {
      this.vacanciesLoading = true;
      this.vacancyClass.get().then(data => {
        this.vacancies = data.response.filter(vacancy => vacancy.status !== 2);
        this.vacanciesLoading = false;
        if (fromDialog) this.$emit('select', data.response[data.response.length - 1].id);
      }).catch(() => {
        this.vacanciesLoading = false;
      })
    },
    select(vacancy) {
      this.$emit('select', vacancy);
      this.isVisible = false;
    },
    search(q) {
      this.searchedVacancies = this.vacancies.filter(vacancy => vacancy.position.toLowerCase().includes(q));
    },
    createVacancy() {
      this.$emit('on-create-btn-click');
      this.isVisible = false;
    },

  }
}
</script>

<style module lang="css">
.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
}

.vacanciesList {
  height: 230px;
  overflow: auto;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 0 16px;
}

.vacanciesList li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--light-gray);
  cursor: pointer;
  line-height: 52px;
}

.vacanciesList .vacancyTitle {
  font-size: var(--body);
}

</style>