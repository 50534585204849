import {Item} from "@/api/item";

export class User extends Item {
    id;
    name;
    surname;
    phone;
    email;
    company_info;
    method = 'user';

    agree(html) {
        return this.request({html}, `${this.method}/agreement`);
    }
    setWOType(data) {
        return this.request(data, `${this.method}/setWOType1`);
    }
}