<template>
<div :class="$style.appContainer">
  <slot name="default"/>
</div>
</template>

<script>
export default {
  name: "TheContainer"
}
</script>

<style lang="css" module>
.appContainer {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 60px;
  box-sizing: content-box;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .appContainer {
    padding: 0 48px;
  }
}

@media screen and (max-width: 575px) {
  .appContainer {
    padding: 0 16px;
  }
}
</style>