<template>
  <div :class="$style.companyAboutPageContent">
    <el-form :class="$style.form" ref="about_company_form" :model="company" :rules="rules">
      <div :class="$style.formInner">
        <div :class="$style.mainInfo">
          <image-upload title="Добавить логотип" icon="image"
                        @on-upload-success="onUploadSuccess" @on-remove-btn-click="$emit('onRemoveImage')"
                        :item-image="company.image ? company.image.url : null"/>
          <div :class="$style.mainInfoInputs">
            <el-form-item label="Название компании" prop="title">
              <el-input v-model="company.title"></el-input>
            </el-form-item>
            <el-form-item label="Сайт или соцсеть компании">
              <el-input v-model="company.link">
                <template #prepend>https://</template>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div :class="$style.rowInputs">
          <el-form-item label="Направление деятельности">
            <el-input v-model="company.type"></el-input>
          </el-form-item>
          <el-form-item label="Город">
            <el-autocomplete
                v-model="company.city" placeholder=""
                :fetch-suggestions="fetchSuggestionsLocation"
                :trigger-on-focus="false"
            />
          </el-form-item>
        </div>
        <el-form-item label="Описание компании">
          <el-input v-model="company.desc" type="textarea"  :autosize="{ minRows: 5, maxRows: 16}"></el-input>
        </el-form-item>
      </div>
      <rekroo-button v-if="$route.path.includes('/company/about')" :loading="loading"
                     :disabled="updateInVacanciesLoading"
                     width="100%" height="50px" @click="$emit('submit')">Сохранить изменения</rekroo-button>
      <rekroo-button v-if="$route.path.includes('/company/about')" :loading="updateInVacanciesLoading"
                     type="outlined"
                     style="margin-top: 12px"
                     :disabled="!user.company_info || loading"
                     width="100%" height="50px" @click="$emit('update-in-vacancies')">Обновить в вакансиях</rekroo-button>
    </el-form>
    <PageHint v-if="!isFullInfo" icon="error" color="var(--yellow)" title="Информация о компании заполнена частично"
              :subtitle="infoHintSubtitle"/>
  </div>
</template>

<script>
import PageHint from "@/components/PageHint";
import RekrooButton from "@/components/Button";
import ImageUpload from "@/components/ImageUpload";
import {User} from "@/api/user";
import FormLoader from "@/components/FormLoader";
export default {
  name: "CompanyForm",
  components: {FormLoader, ImageUpload, RekrooButton, PageHint},
  props: ['isFullInfo', 'infoHintSubtitle', 'company', 'loading', 'role', 'updateInVacanciesLoading'],
  emits: ['onUploadImageSuccess', 'onRemoveImage', 'submit', 'update-in-vacancies'],
  data: ()=>({
    userClass: new User({}),
    rules: {
      title: [
        { required: true, message: 'Укажите название компании', trigger: 'change' },
        { required: true, message: 'Укажите название компании', trigger: 'blur' },
      ],
    },
  }),
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    onUploadSuccess(data) {
      this.$emit('onUploadImageSuccess', data);
    },
    fetchSuggestionsLocation(q, cb) {
      this.userClass.suggestions('location', q).then((data)=>{
        let suggestions = data.response.map(s=>({value: s}));
        cb(suggestions);
      })
    },
  }
}
</script>

<style module lang="css">

.companyAboutPageContent {
  display: grid;
  grid-template-columns: 66% 33%;
  grid-column-gap: 32px;
}
.companyAboutPageContent .form {
  flex-basis: 66%;
}
.companyAboutPageContent .form .formInner {
  position: relative;
  background: var(--light-gray);
  padding: 60px 48px;
  border-radius: 16px;
  margin-bottom: 40px;
}
.companyAboutPageContent .form .formInner .mainInfo {
  display: flex;
  column-gap: 28px;
  margin-bottom: 28px;
}

.companyAboutPageContent .form .formInner .mainInfo .mainInfoInputs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.companyAboutPageContent .form .formInner .rowInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 28px;
}

@media screen and (max-width: 1024px) {
  .companyAboutPageContent {
    flex-direction: column;
    display: flex;
  }
  .companyAboutPageContent .form {
    order: 2;
    flex-basis: 100%;
  }
  .companyAboutPageContent .form .formInner {
    padding: 40px;
  }
}

@media screen and (max-width: 575px) {
  .companyAboutPageContent .form .formInner {
    padding: 32px 20px;
  }
  .companyAboutPageContent .form .formInner .mainInfo {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    margin-bottom: 12px;
  }
  .companyAboutPageContent .form .formInner .mainInfo .mainInfoInputs {
    width: 100%;
  }
  .companyAboutPageContent .form .formInner .rowInputs {
    grid-template-columns: 1fr;
    margin-bottom: 12px;
  }
}
</style>