<template>
  <div class="page page-with-tabs-table">
    <page-in-progress :title="`Раздел <span>Мои консультации</span> находится <br> на стадии <span>разработки</span>`"
                      subtitle="Здесь вы сможете зарабатывать на консультациях по составлению резюме и прохождению собеседования"/>
<!--    <h1>Мои услуги</h1>
    <el-tabs v-model="activeTab" :before-leave="onTabChange">
      <el-tab-pane name="search" label="В поиске">
        <HRConsultationsTable :data="searchItems" :loading="searchLoading" :loaded="searchLoaded" name="search"
        @create="create" @click-remove="openRemoveDialog"/>
        <div :class="tableStyles.tablePageActions">
          <rekroo-button @click="create" v-if="searchItems.length"
                         height="50px"
                         :class="$style.createButton">+ Создать услугу</rekroo-button>
        </div>
      </el-tab-pane>
      <el-tab-pane name="active" label="В работе">
        <HRConsultationsTable :data="activeItems" :loading="activeLoading" :loaded="activeLoaded" name="active"
                              @create="create"/>
      </el-tab-pane>
      <el-tab-pane name="archive" label="Архив">
        <HRConsultationsTable :data="archiveItems" :loading="archiveLoading" :loaded="archiveLoaded" name="archive"
                              @create="create"/>
      </el-tab-pane>
    </el-tabs>-->
    <ConfirmDialog ref="confirm_remove_dialog" title="Вы действительно хотите удалить эту услугу?"
                   @success="removeService"
    />
    <HRConsultationCreateDialog ref="hr_consultation_create_dialog"/>
  </div>
</template>

<script>
import HRConsultationsTable from "@/views/hr/consultations/components/HRConsultationsTable";
import HRConsultationCreateDialog from "@/views/hr/consultations/HRConsultationCreateDialog";
import RekrooButton from "@/components/Button";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import {AuctionItem} from "@/api/auction_item";
import PageInProgress from "@/views/PageInProgress";

export default {
  name: "HRConsultationsList",
  components: {PageInProgress, ConfirmDialog, RekrooButton, HRConsultationCreateDialog, HRConsultationsTable},
  data: () => ({
    tableStyles,
    activeTab: 'search',
    itemClass: new AuctionItem({}),
    tablesData: {
      active: {
        data: [],
        loading: false,
        loaded: false,
      },
      inactive: {
        data: [],
        loading: false,
        loaded: false,
      },
      archive: {
        data: [],
        loading: false,
        loaded: false,
      },
    },
    demoServices: [],
    statuses: ['inactive', 'active', 'archive']
  }),
  methods: {
    get(status) {
      this.itemClass.get(null, {type: this.statuses.indexOf(status)}).then((data)=>{
        this.tablesData[status].data = data.response;
        this.tablesData[status].loading = false;
        this.tablesData[status].loaded = true;
      }).catch(()=>{
        this.tablesData[status].loading = false;
        this.tablesData[status].loaded = true;
      })
    },
    onTabChange(newTab) {
      this.get(newTab);
      for (let key in this.tablesData) {
        if (key !== newTab) this.tablesData[key].loaded = false;
      }
      this.$router.push({query: newTab === 'active' ? {} : {t: newTab}}).catch(() => {});
    },
    create(id = null) {
      this.$refs.hr_consultation_create_dialog.open(id);
    },
    openRemoveDialog(id) {
      this.$refs.confirm_remove_dialog.open({id});
    },
    removeService() {

    },
  },
  mounted() {
    if (this.$route.query.t) this.activeTab = this.$route.query.t
    else this.get();
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get(this.activeTab);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
.createButton {
  min-width: 240px !important;
}
</style>