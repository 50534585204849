<template>
  <button
      :style="`width:${width || 'fit-content'}; background: ${color}; height: ${height || ''}; color: ${text_color};
      justify-content: ${spaceBetween ? 'space-between' : ''}`"
      @click="click"
      :class="{
      [$style.outlined]: type === 'outlined',
      [$style.primary]: type === 'primary',
      [$style.text]: type === 'text',
      [$style.active]: type === 'active',
      [$style.subheader]: type === 'subheader',
      [$style.disabled]: type === 'disabled'
       }"
      :disabled="disabled || loading"
      type="button"
  >
    <i class="icon el-icon-loading" :class="$style.loading" v-if="loading"></i>
    <template v-else>
      <rekroo-icon v-if="icon && icon_pos==='start'" :icon="icon" :color="icon_color" :width="icon_width || 'var(--button)'" :style="icon_style"/>
      <slot></slot>
      <rekroo-icon  v-if="icon && icon_pos==='end'" :icon="icon" :color="icon_color" :width="icon_width || 'var(--button)'" :style="icon_style"/>
    </template>
  </button>
</template>

<script>
import RekrooIcon from "@/components/Icon";
export default {
  name: "rekroo-button",
  components: {RekrooIcon},
  emits: ['click'],
  props: {
    prevent: {default: false},
    type: {
      type: String,
      default: 'primary',
    },
    color: {
      type: String,
      default: ''
    },
    text_color: {
      type: String,
      default: '#FFF'
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    icon: String,
    icon_color: String,
    icon_width: String,
    icon_pos: {
      type: String,
      default: 'start'
    },
    icon_style: String,
    loading: {
      type: Boolean,
      default: false,
    },
    spaceBetween: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    click(e) {
      if (this.prevent) {
        e.preventDefault();
      }
      this.$emit('click');
    }
  }
}
</script>

<style lang="css" module>
button {
  padding: 14px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--button);
  font-weight: var(--button-weight);
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  height: 42px;
}

button.outlined {
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary) !important;
}

button.primary {
  background: #111111;
  color: #FFFFFF;
}

button:disabled, .disabled {
  background: var(--gray);
  color: var(--gray-text) !important;
}
button.text {
  height: fit-content;
  padding: 0;
  border: none;
  background: transparent ;
}
button.active {
  background: rgba(57, 198, 113, 0.1);
  color: var(--green-active) !important;
  padding: 10px 36px;
  letter-spacing: 0.8px;
  line-height: 130%;
  height: 41px;
}

button.subheader {
  background: rgba(244, 243, 242, 0.6);
  color: var(--gray-text) !important;
  padding: 10px 36px;
  font-style: var(--small-text);
  letter-spacing: 0.8px;
  line-height: 130%;
  height: 41px;
}

.loading {
  font-size: 30px;
  color: inherit;
  object-fit: cover;
}

@media screen and (max-width: 1024px) {
  button.subheader, button.active {
    padding: 8px 24px;
    line-height: 148%;
    height: 37px;
  }
}

@media screen and (max-width: 575px) {
  button {
    font-size: var(--small-text);
  }
  button.subheader, button.active {
    padding: 8px 16px;
  }
}
</style>