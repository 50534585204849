<template>
  <el-dialog :visible.sync="isVisible" width="427px" custom-class="rekroo-dialog confirm-dialog" :center="true">
    <div class="headline-3" :class="$style.confirmDialogTitle" v-html="title"/>
    <div :class="$style.confirmDialogSubtitle">{{ subtitle }}</div>
    <rekroo-button type="outlined" @click="onAction('decline')" width="100%"
                   style="margin-bottom: 10px"
    >{{ decline_button_text || 'Нет' }}
    </rekroo-button>
    <rekroo-button @click="onAction('success')" width="100%"
                   :loading="success_btn_loading"
    >{{ success_button_text || 'Да' }}</rekroo-button>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";

export default {
  name: "ConfirmDialog",
  components: {RekrooButton},
  props: ['title', 'subtitle', 'success_button_text', 'decline_button_text', 'success_btn_loading', 'manualClose'],
  emits: ['success', 'decline'],
  data: () => ({
    isVisible: false,
    params: {},
  }),
  methods: {
    open(params) {
      this.params = {};
      this.params = {...params};
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    onAction(type) {
      if (this.params.id) this.$emit(type, this.params.id);
      else if (this.params.ids) this.$emit(type, this.params.ids);
      else if (typeof this.params.status === 'number') this.$emit(type, this.params.status);
      else this.$emit(type);
      if (!this.manualClose) this.isVisible = false;
    },
  }
}
</script>

<style module lang="css">
.confirmDialogTitle {
  margin-bottom: 12px;
  text-align: center;
}

.confirmDialogSubtitle {
  margin-bottom: 24px;
  text-align: center;
}
</style>