<template>
  <table-skeleton v-if="loading" :class="$style.responsesTable" :key="'skelet'+name"
                  :header-titles="['', 'Тема', 'HR', 'Гонорар', '']"/>
  <el-table v-else :data="data" :key="'table'+name"
            :class="[$style.consultationTable, {'table-container-active': loaded}]"
            :show-header="data.length > 0"
            class="consultation-table table-container"
            header-row-class-name="header-row"
  >
    <el-table-column label="Тема" prop="title"/>
    <el-table-column label="HR">
      <template slot-scope="scope">
        <div :class="$style.hrName">
          <candidate-avatar :img="scope.row.hr.img" :circle="true" size="40"/>
          {{ scope.row.hr.name }}<br>{{ scope.row.hr.surname }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Гонорар">
      <template slot-scope="scope">
        {{ salaryParse(scope.row.salary_from, scope.row.salary_to, scope.row.currency) }}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot-scope="scope">
        <div :class="$style.actions">
        </div>
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug :class="$style.emptyListPlug"
                       title="У вас пока нет консультаций"
                       subtitle="Если у вас есть вопросы к HR-ам, создавайте консультацию и выберайте понравившегося"
                       button_text="Создать" @onClick="create"
                       :show-icon="true"
      />
    </template>
  </el-table>
</template>

<script>
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import CandidateAvatar from "@/components/CandidateAvatar";
import EmptyListPlug from "@/components/EmptyListPlug";

export default {
  name: "ConsultationTable",
  props: ['loading', 'loaded', 'name', 'data'],
  emits: ['create'],
  components: {EmptyListPlug, CandidateAvatar, TableSkeleton},
  methods: {
    create() {
      this.$emit('create');
    }
  }
}
</script>

<style module lang="css">
.hrName {
  display: flex;
  column-gap: 10px;
}

.actions {
  display: flex;
  column-gap: 8px;
}

.emptyListPlug {
  margin: 120px auto;
}
</style>