<template>
  <table-skeleton v-if="loading" :class="$style.responsesTable" :key="'skelet'+name"
                  :header-titles="['', 'Вакансия', 'Работодатель', 'Статус', '']"/>
  <el-table v-else :key="'table'+name"
            :class="['table-container', tableStyles.table, {'table-container-active': loaded},]"
            ref="multipleTable" :data="data"
            :show-header="data.length > 0"
            class="responses-table table-container"
            header-row-class-name="header-row">
    <el-table-column label="Вакансия">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <router-link :to="`/applicant/vacancies/${scope.row.id}`" :class="tableStyles.title">
            {{ scope.row.position }}
          </router-link>
          <div :class="tableStyles.mobileCell">
            <div :class="$style.salaryMobile">
              <span>Зарплата:</span> {{ salaryParse(scope.row.salary_from, scope.row.salary_to, $store.state.systemParams.currencies[scope.row.currency]?.short) }}
            </div>
            <div :class="$style.companyInfo">
              <candidate-avatar :img="scope.row.company_info.image?.url" :circle="true" size="32"/>
              {{scope.row.company_info.title}}
            </div>
            <tag v-if="scope.row.my_response.seen" text="Просмотрено" icon="dot" color="var(--green)" icon_color="var(--gray-text)"/>
            <tag v-else text="Не просмотрено" icon="dot" icon_color="var(--grey-text)"/>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Зарплата">
      <template slot-scope="scope">
          {{ salaryParse(scope.row.salary_from, scope.row.salary_to, $store.state.systemParams.currencies[scope.row.currency]?.short)}}
      </template>
    </el-table-column>
    <el-table-column label="Работодатель">
      <template slot-scope="scope">
        <div :class="$style.companyInfo">
          <candidate-avatar :img="scope.row.company_info.image?.url" :circle="true" size="32"/>
          {{scope.row.company_info.title}}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Статус">
      <template slot-scope="scope">
        <tag v-if="scope.row.my_response.seen" text="Просмотрено" icon="dot" color="var(--green)" icon_color="var(--gray-text)"/>
        <tag v-else text="Не просмотрено" icon="dot" icon_color="var(--grey-text)"/>
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug :class="$style.emptyListPlug"
                       title="У вас пока нет откликов"
                       subtitle="Перейдите в базу вакансий и найдите вакансию своей мечты"
                       button_text="Перейти" @onClick="goToVacancies"
                       :show-icon="true"
      />
    </template>
  </el-table>
</template>

<script>
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import CandidateAvatar from "@/components/CandidateAvatar";
import Tag from "@/components/Tag";
import EmptyListPlug from "@/components/EmptyListPlug";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";

export default {
  name: "ResponsesTable",
  components: {EmptyListPlug, Tag, CandidateAvatar, TableSkeleton},
  props: ['loading', 'loaded', 'name', 'data'],
  data: ()=>({
    tableStyles,
  }),
  methods: {
    goToVacancies() {
      this.$router.push('/applicant/vacancies')
    }
  }
}
</script>

<style module lang="css">
.responsesTable {
  margin-top: 40px;
}

.emptyListPlug {
  margin: 120px auto;
}

.companyInfo {
  display: flex;
  align-items: center;
  column-gap: 12px;
  word-break: break-word;
}

@media screen and (max-width: 575px) {
  .salaryMobile {
    margin-bottom: 4px;
  }
  .companyInfo {
    margin-bottom: 8px;
  }
}
</style>