<template>
  <el-dialog :visible.sync="isVisible" width="375px" custom-class="rekroo-dialog info-dialog" :center="true">
    <div :class="$style.successDialogInner">
      <div :class="$style.iconContainer" :style="`background-color: ${backgroundColor}`">
        <rekroo-icon :icon="icon" color="var(--primary)"/>
      </div>
<!--      <div :class="$style.successDialogTitle">{{ error ? error.type || error : '' }}</div>-->
      <p :class="$style.successDialogSubtitle" v-if="error">{{ error.text || error }}</p>
      <rekroo-button width="100%" height="50px" @click="isVisible = false">Ок</rekroo-button>
    </div>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";

export default {
  name: "InfoDialog",
  components: {RekrooIcon, RekrooButton},
  data: () => ({
    isVisible: false,
    error: null,
    type: '',

  }),
  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return 'check';
        case 'error':
          return 'close';
        default:
          return 'mark';
      }
    },
    backgroundColor() {
      switch (this.type) {
        case 'success':
          return 'var(--green)';
        case 'error':
          return 'var(--red)';
        default:
          return 'var(--bright-blue)';
      }
    }
  },
  methods: {
    open(params) {
      for (let i in params) {
        this[i] = params[i];
      }
      this.isVisible = true;
    }
  }
}
</script>

<style module lang="css">
.successDialogInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.iconContainer {
  margin-bottom: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successDialogTitle {
  margin-bottom: 14px;
  font-size: var(--headline_3);
  font-weight: 600;
}

.successDialogSubtitle {
  max-width: 325px;
  margin-bottom: 20px;
}
</style>