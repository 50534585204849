<template>
<div class="page" :class="$style.notFoundPage">
  <div :class="$style.notFoundPageTitle">Ваша карьера - наш приоритет, даже если страница временно недоступна</div>
  <p :class="$style.notFoundPageSubtitle">Мы готовы помочь вам достичь ваших карьерных целей!</p>
  <img src="/img/icons/icon_empty_list.svg" alt="">
  <div :class="$style.notFoundPageActions">
    <router-link to="/company/vacancies/create">
      <rekroo-button type="outlined" height="50px">Разместить вакансию</rekroo-button>
    </router-link>
    <router-link to="/applicant/resumes/create">
      <rekroo-button height="50px">Создать резюме</rekroo-button>
    </router-link>
  </div>
</div>
</template>

<script>
import RekrooButton from "@/components/Button";
export default {
  name: "NotFound",
  components: {RekrooButton}

}
</script>

<style module lang="css">
.notFoundPage {
  margin: 0 auto;
  padding-top: 120px !important;
  padding-bottom: 120px !important;
  text-align: center;
}

.notFoundPageTitle {
  margin: 0 auto;
  margin-bottom: 28px;
  text-align: center;
  max-width: 800px;
  font-size: var(--headline_2);
  font-weight: 500;
}

.notFoundPageSubtitle {
  margin: 0 auto;
  margin-bottom: 24px;
  text-align: center;
  max-width: 1000px;
}

.notFoundPageActions {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 40px;
}

@media screen and (max-width: 575px) {
  .notFoundPageActions {
    flex-direction: column;
  }
  .notFoundPageActions a {
    width: 100%;
  }
  .notFoundPageActions a button {
    width: 100% !important;
  }
}
</style>