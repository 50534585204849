<template>
  <div class="page page-with-navigation">
    <side-navigation :navigation="navigation" :active-section="activeSection" @link-click="scrollToEl"/>
    <div :class="$style.rateListContainer">
      <div :class="$style.rateSection" id="resume">
        <resume-rates ref="resume_rates"/>
      </div>
      <div :class="$style.rateSection" id="vacancy">
        <vacancy-rates :show-title="true" :show-subtitle="true" ref="vacancy_rates"/>
      </div>
      <div :class="$style.conditions">
        <h3>ОБЩИЕ УСЛОВИЯ И ПОРЯДОК ОКАЗАНИЯ УСЛУГ</h3>
        <p>1. Для получения Услуг по Договору Заказчик должен зарегистрироваться на Сайте. После регистрации Исполнитель
          предоставляет Заказчику, логин и пароль для доступа к Сайту и получения Услуг (далее – «Учетная информация»).</p>
        <p>2. Для начала работы с Сайтом Заказчик должен ввести номер телефона на странице авторизации.</p>
        <p>3. Для возможности активации тарифа необходимо внести на лицевой счет денежную сумму в размере предполагаемого
          объема Услуг.</p>
        <p>4. В случае, если сумма на Лицевом счете Заказчика больше или равна суммарной стоимости выбранных при Активации
          Услуг, Заказчик имеет возможность активировать Услуги.</p>
        <p>5. Стороны вправе определить иной порядок Активации путем заключения дополнительного соглашения к настоящему
          Договору на условиях такого соглашения.</p>
        <p>6. Порядок и сроки оказания Услуг определяются в Заказе и/или в Условиях оказания Услуг для данного вида Услуг.</p>
        <h3>Условиях и сроках отказа от услуги и возврата денежных средств</h3>
        <p>Договор может быть расторгнут по инициативе любой из Сторон в любое время, с уведомлением другой Стороны за 30
        (тридцать) календарных дней до предполагаемой даты расторжения. В случае расторжения Договора по инициативе любой
        из Сторон Исполнитель возвращает денежные средства, уплаченные Заказчиком Исполнителю по Договору, за вычетом
        стоимости фактически оказанных на дату расторжения Договора Услуг. В случае, если расторжение Договора происходит
        по инициативе Заказчика, последний обязан указать в тексте уведомления о расторжении Договора платежные реквизиты,
        по которым нужно Исполнителю произвести возврат денежных средств, если на момент расторжения Договора будет
        необходимость у Исполнителя произвести такой возврат.</p>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavigation from "@/components/SideNavigation";
import VacancyRates from "@/views/rates/VacancyRates";
import ResumeRates from "@/views/rates/ResumeRates";
import {Order} from "@/api/order";

export default {
  name: "RatesList",
  components: {ResumeRates, VacancyRates, SideNavigation},
  data: () => ({
    orderClass: new Order({}),
    activeSection: 'resume',
    navigation: [
      {
        ref: 'resume_rates',
        label: 'Резюме',
        name: 'resume',
        icon: 'filing',
      },
      {
        ref: 'vacancy_rates',
        label: 'Вакансии',
        name: 'vacancy',
        icon: 'filing',
      },
    ],
  }),
  methods: {
    scrollToEl(i) {
      if (!this.navigation[i]) return;
      this.$router.push({hash: this.navigation[i].name}).catch(()=>{});
    },
    setActiveSectionLink() {
      window.addEventListener('scroll', () => {
        this.navigation.forEach((section)=>{
         if (this.$refs[section.ref] && this.$refs[section.ref].$refs && this.isInViewport(this.$refs[section.ref].$refs[`${section.ref}_list`] )) this.activeSection = section.name;
        })
      })
    },
    isInViewport(element) {
      if (!element) return false;
      const { top, left, bottom, right }  = element.getBoundingClientRect();
      return ((top > 0 && top < innerHeight) ||
              (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth));
    }
  },
  mounted() {
    this.setActiveSectionLink();
  },
  created() {
    this.$store.state.eventBus.$on('user-received', ()=>{
      if (this.$route.query) {
        let currentSection = this.$route.query.rates;
        let currentSectionIndex = this.navigation.findIndex(e=>e.name === currentSection);
        setTimeout(()=>{
          this.scrollToEl(currentSectionIndex);
        })
      }
    })
  }
}
</script>

<style module lang="css">
.rateListContainer {
  width: 100%;
  padding: 36px;
  background: var(--light-gray);
  border-radius: 16px;
}

.rateSection {
  margin-bottom: 48px;
  padding: 36px 36px 60px;
  background: #FFFFFF;
  border-radius: 12px;
}

.conditions {}
.conditions h3  {
  margin-top: 16px;
  margin-bottom: 16px;
}
.conditions p {
  line-height: 156%;
}
@media screen and (max-width: 1024px) {
  .rateListContainer {
    padding: 32px;
    margin-bottom: 40px;
  }

  .rateSection {
    padding: 32px 32px 40px;
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 575px) {
  .rateListContainer {
    margin: 0 -16px;
    width: calc(100% + 32px);
    padding: 16px;
    margin-bottom: 52px;
  }

  .rateSection {
    padding: 20px;
    margin-bottom: 12px;
  }
}
</style>