<template>
<el-dialog width="427px" :visible.sync="isVisible" custom-class="rekroo-dialog action-with-person-dialog" :center="true">
  <div :class="$style.dialogTitle">{{title}}</div>
<!--  <div :class="$style.dialogSubtitle" v-html="subtitle"/>-->
  <div :class="$style.itemContent">
    <candidate-avatar :img="item.worker?.image?.url" :circle="true" size="64"/>
    <div :class="$style.itemName">{{item.worker?.name}} {{item.worker?.surname}}</div>
    <div v-if="item.worker?.phone" :class="$style.itemContact">{{ strPhoneMask(item.worker?.phone)}}</div>
    <div v-if="item.worker?.email" :class="$style.itemContact">{{item.worker?.email}}</div>
  </div>
  <div :class="$style.dialogActions">
    <rekroo-button type="gray" text_color="var(--primary)" @click="cancel" width="100%">Отмена</rekroo-button>
    <rekroo-button @click="accept" width="100%" :loading="loading">Да, продолжить</rekroo-button>
  </div>
</el-dialog>
</template>

<script>
import CandidateAvatar from "@/components/CandidateAvatar";
import RekrooButton from "@/components/Button";
export default {
  name: "ActionWithPersonDialog",
  components: {RekrooButton, CandidateAvatar},
  props: ['title', 'subtitle', 'acceptText', 'declineText', 'loading'],
  emits: ['decline', 'accept'],
  data: ()=>({
    isVisible: false,
    item: {},
  }),
  methods: {
    open(item) {
      this.item = {};
      this.isVisible = true;
      this.item = {...item};
    },
    cancel() {
      this.$emit('decline');
      this.isVisible = false;
    },
    accept() {
      this.$emit('accept', this.item?.worker?.id);
    },
    close() {
      this.isVisible = false;
    }
  }
}
</script>

<style module lang="css">
.dialogTitle {
  font-size: 24px;
  text-align: center;
  margin-bottom: 12px;
  font-weight: 600;
}

.dialogSubtitle {
  font-size: var(--body);
  text-align: center;
  margin-bottom: 32px;
}

.itemContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.itemName {
  font-size: var(--subheadline);
  font-weight: 500;
  margin-top: 4px;
}

.itemContact {
  margin-top: 4px;
  font-size: var(--body);
  font-weight: var(--body-medium-weight);
  color: var(--gray-text);
}

.dialogActions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 6px;
}
</style>