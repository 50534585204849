<template>
  <el-dialog :visible.sync="isVisible" width="720px" custom-class="rekroo-dialog company-about-dialog gray-bg" :center="true">
    <div :class="$style.companyInner">
      <div :class="style.companyHeader">
        <img v-if="company?.image?.url" :src="company.image.url" alt="" :class="$style.companyImg">
        <rekroo-icon v-else icon="image" width="72px" color="var(--gray-text)"/>
        <div :class="$style.companyInfo">
          <div :class="$style.companyTitle">{{ company.title }}</div>
          <a v-if="company.link" :href="company.link" target="_blank" :class="style.companyLink">{{ company.link }}</a>
        </div>
      </div>
      <div v-if="company.desc" :class="style.companyAboutContainer">
        <div :class="style.companyAbout">{{ company.desc }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import style from "@/views/company/vacancy/styles/Vacancy.module.css"
import RekrooIcon from "@/components/Icon";

export default {
  name: "CompanyAboutDialog",
  components: {RekrooIcon},
  data: () => ({
    style,
    isVisible: false,
    company: {},
  }),
  methods: {
    open(company) {
      this.isVisible = true;
      this.company = company;
    }
  }
}
</script>

<style module lang="css">
.companyInner {}
.companyImg {
  width: 72px;
  border-radius: 12px;
}

.companyTitle {
  margin-bottom: 6px;
  font-size: var(--headline_2);
  font-weight: 500;
}
</style>