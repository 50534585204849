import {Item} from "@/api/item";

export class Resume extends Item {
    id;
    name;
    surname;
    gender;
    position;
    salary;
    location;
    age;
    experience;
    skills;
    work;
    citizenship;
    about;
    education;
    languages;
    update_date;
    courses;
    phone;
    email;
    relocation;
    birthday;
    driver_license;
    business_trip;
    currency;
    schedule;
    employment_type;
    experience_text;
    image;
    contactsLeft;
    method = 'resume';
    constructor(props) {
        super(props);
        this.currency = props.currency || 'rub';
        this.schedule = props.schedule || [];
        this.employment_type = props.employment_type || [];
        this.education = props.education || [];
        this.work = props.work || [];
        this.skills = props.skills || [];
        this.languages = props.languages || [];
        this.courses = props.courses || [];
        this.driver_license = props.driver_license || [];
    }
    openContacts(id) {
        return this.request({}, `${this.method}/openContacts/${id}`);
    }
    complain(id, message) {
        return this.request({message}, `${this.method}/complain/${id}`);
    }
}