<template>
  <div :class="$style.orderItem">
    <div :class="$style.orderItemBefore">
      <candidate-avatar size="48"/>
      <skeleton variant="text"/>
    </div>
    <div :class="$style.orderItemBody">
      <div :class="$style.orderItemHeader">
        <skeleton variant="text" height="var(--headline-3)" max-width="300px"/>
        <skeleton :class="$style.priceTag" variant="button" height="42px" width="170px"/>
      </div>
      <div :class="$style.orderItemContent">
        <div :class="$style.orderItemTags">
          <skeleton :class="$style.priceTag" variant="button" height="42px" width="140px"/>
        </div>
        <el-skeleton/>
      </div>
      <div :class="$style.orderItemActions">
        <skeleton :class="$style.priceTag" variant="button" height="50px" width="100%"/>
      </div>
    </div>
  </div>
</template>

<script>
import CandidateAvatar from "@/components/CandidateAvatar";
import Skeleton from "@/components/skeletons/Skeleton";
export default {
  name: "AuctionOrderSkeleton",
  components: {Skeleton, CandidateAvatar}
}
</script>

<style module lang="css">

.orderItem {
  display: block;
  margin-bottom: 20px;
  padding: 36px;
  background: #FFFFFF;
  border-radius: 16px;
}

.orderItem:last-child {
  margin-bottom: 0;
}

.orderItem .orderItemBefore {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: var(--headline_3);
  font-weight: 500;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light-gray);
}

.orderItem .orderItemBody {
}

.orderItem .orderItemHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.orderItem .priceTag {
  margin-left: auto;
}

.orderItemTags {
  display: none;
}

/*.orderItem .orderItemTags {*/
/*  display: flex;*/
/*  gap: 6px;*/
/*  flex-wrap: wrap;*/
/*  margin-bottom: 10px;*/
/*}*/

.orderItem .orderItemContent {
}

.orderItem .orderItemActions {
  margin-top: 16px;
}

</style>