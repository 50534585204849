<template>
  <a :class="$style.linkBack" @click="path ? $router.push(path) : $router.back()">
    <rekroo-icon icon="chevron-left" color="var(--gray-text)" width="var(--small-text)" style="margin-right: 10px"/>
    {{text}}</a>
</template>

<script>
import RekrooIcon from "@/components/Icon";
export default {
  name: "LinkBack",
  components: {RekrooIcon},
  props: ['text', 'path']
}
</script>

<style module lang="css">
.linkBack {
  width: fit-content;
  display: flex;
  align-items: center;
  color: var(--gray-text);
  font-size: var(--small-text);
  margin-bottom: 20px;
}

@media screen and (max-width: 575px) {
  .linkBack {
    margin-bottom: 12px;
  }

}
</style>