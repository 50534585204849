<template>
  <div class="page page-with-tabs-table">
    <h1 :class="$style.auctionPageTitle">Мои заказы
      <rekroo-button :class="$style.createOrderButton" @click="openOrderDialog"
                     v-if="orders[activeTab]?.length">+ Новый заказ
      </rekroo-button>
    </h1>
    <empty-list-plug v-if="authError" title="Доступ ограничен" subtitle="Авторизуйтесь, чтобы иметь возможность размещать заказы"
                     button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
                     style="margin: 0 auto" :show-icon="true"/>
    <el-tabs v-else v-model="activeTab" :before-leave="onTabChange">
      <el-tab-pane label="Опубликованные" name="active">
        <auction-table :data="orders.active" name="active" :loading="loading.active" :loaded="loaded.active"
                       @open-order-dialog="id=>openOrderDialog({id})"
        @on-remove-btn-click="openConfirmRemove"/>
      </el-tab-pane>
      <el-tab-pane label="В работе" name="work">
        <auction-table :data="orders.work" name="work" :loading="loading.work" :loaded="loaded.work"
                       @open-order-dialog="id=>openOrderDialog({id})"
                       @on-remove-btn-click="openConfirmRemove"/>
      </el-tab-pane>
      <el-tab-pane label="Неопубликованные" name="not_published">
        <auction-table :data="orders.not_published" name="not_published" :loading="loading.not_published"
                       :loaded="loaded.not_published"
                       @open-order-dialog="id=>openOrderDialog({id})"
                       @on-remove-btn-click="openConfirmRemove"/>
      </el-tab-pane>
      <el-tab-pane label="Архив" name="archive">
        <auction-table :data="orders.archive" name="archive" :loading="loading.archive" :loaded="loaded.archive"
                       @open-order-dialog="id=>openOrderDialog({id})"
                       @on-remove-btn-click="openConfirmRemove"/>
      </el-tab-pane>
    </el-tabs>
    <CreateOrderDialog ref="create_order_dialog" @created="get(activeTab)"/>
    <ConfirmDialog ref="remove_confirm_dialog" title="Вы уверены, что хотите удалить аукцион?" @success="remove"/>
  </div>
</template>

<script>
import EmptyListPlug from "@/components/EmptyListPlug";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
import CreateOrderDialog from "@/views/company/auctions/Create";
import CandidateAvatar from "@/components/CandidateAvatar";
import LabelValueRow from "@/components/LabelValueRow";
import {AuctionItem} from "@/api/auction_item";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import AuctionTable from "@/views/company/auctions/components/AuctionTable";
import ConfirmDialog from "@/dialogs/ConfirmDialog";

export default {
  name: "AuctionList",
  components: {
    ConfirmDialog,
    AuctionTable,
    LabelValueRow,
    CandidateAvatar,
    CreateOrderDialog,
    RekrooButton,
    RekrooIcon,
    EmptyListPlug
  },
  data: () => ({
    tableStyles,
    activeTab: 'active',
    auctionItemClass: new AuctionItem({}),
    orders: {
      active: [],
      not_published: [],
      archive: [],
      work: [],
    },
    loading: {
      active: false,
      not_published: false,
      archive: false,
      work: false,
    },
    loaded: {
      active: false,
      not_published: false,
      archive: false,
      work: false,
    },
    statuses: {
      active: 1,
      work: 1,
      not_published: 0,
      archive: 2
    },
    authError: false
  }),
  methods: {
    openOrderDialog(data) {
      this.$refs.create_order_dialog.open(data);
    },
    get(tab = 'active') {
      this.loading[tab] = true;
      this.auctionItemClass.get(null, {type: 0, status: this.statuses[tab],
        worker_chosen: tab === 'work' ? 1 : tab === 'active' ? 0 : ''
      }).then((data) => {
        this.orders[tab] = data.response;
        this.loading[tab] = false;
        this.loaded[tab] = true;
        this.authError = false;
      }).catch((data) => {
        this.loading[tab] = false;
        if (data.error?.type === '401') this.authError = true;
      })
    },
    onTabChange(newTab) {
      this.get(newTab);
      for (let key in this.loaded) {
        if (key !== newTab) this.loaded[key] = false;
      }
      this.$router.push({query: newTab === 'active' ? {} : {t: newTab}}).catch(() => {
      });
    },
    openConfirmRemove(id) {
      this.$refs.remove_confirm_dialog.open({id});
    },
    remove(id) {
      this.auctionItemClass.moveToArchive(id).then(()=>{
        this.$notify({
          type: "success",
          message: 'Аукцион успешно удален'
        })
        this.get(this.activeTab);
      })
    }
  },
  mounted() {
    if (this.$route.query.create) this.openOrderDialog();
    if (this.$route.query.v_id) this.openOrderDialog({vacancy_id: this.$route.query.v_id});
    if (this.$route.query.t) this.activeTab = this.$route.query.t;
    else this.get('active');
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get(this.activeTab);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
.auctionPageTitle {
  display: flex;
  flex-grow: 1;
}

.createOrderButton {
  margin-left: auto;
  font-size: var(--button) !important;
}
</style>