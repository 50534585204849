<template>
  <table-skeleton v-if="loading" :class="tableStyles.table" :key="'skelet'+name"
                  :header-titles="['Название', 'Зарплата', 'Опыт работы', '']"/>
  <el-table v-else
            :class="['table-container',tableStyles.table, {'table-container-active': loaded}, 'table-with-actions']"
            :data="data"
            :show-header="data.length > 0"
            header-row-class-name="header-row">
    <el-table-column label="Название" property="position" class-name="table-row-title">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <router-link :to="`/applicant/resumes/${scope.row.id}`" :class="tableStyles.title">
            <candidate-avatar :img="scope.row.image" :circle="true" size="32" :gender="scope.row.gender"/>
            {{ scope.row.position }}
          </router-link>
          <div :class="tableStyles.mobileCell">
            <div :class="$style.resumeTableStats">
              <div :class="$style.rowItemWithIcon">
                <span>Желаемая зарплата:</span>{{ salaryParse(scope.row.salary, 0,
                  $store.state.systemParams.currencies[scope.row.currency] ? $store.state.systemParams.currencies[scope.row.currency].short : '') }}
              </div>
              <div :class="$style.rowItemWithIcon" v-if="scope.row.experience > 0">
                <span>Опыт:</span>{{ scope.row.experience }} {{ getNoun(scope.row.experience, 'год', 'года', 'лет') }}
              </div>
              <div v-else :class="$style.rowItemWithIcon">Без опыта</div>
            </div>
          </div>
        </div>
        <div :class="tableStyles.mobileActions">
          <el-dropdown :hide-on-click="true" trigger="click">
            <rekroo-button icon="more" color="transparent" icon_width="30px" style="padding: 0"/>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link :to="`/applicant/resumes/update/${scope.row.id}`">
                  <rekroo-button @click="$emit('create', scope.row.id)"
                                 type="text" text_color="var(--primary)" icon="pencil" icon_style="margin-right: 6px">
                    Редактировать
                  </rekroo-button>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <rekroo-button @click="$emit('on-remove-btn-click', scope.row.id)"
                               type="text" text_color="var(--error-red)" icon="trash" icon_style="margin-right: 6px">
                  Удалить
                </rekroo-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-table-column>
    <el-table-column property="views" label="Зарплата">
      <template slot-scope="scope">
        <div :class="$style.rowItemWithIcon" v-if="scope.row.salary*1">
          {{ thousandSeparator(scope.row.salary) }}
          {{
            $store.state.systemParams.currencies[scope.row.currency] ? $store.state.systemParams.currencies[scope.row.currency].short : ''
          }}
        </div>
        <div v-else :class="$style.rowItemWithIcon">По договоренности</div>
      </template>
    </el-table-column>
    <el-table-column property="response" label="Опыт работы">
      <template slot-scope="scope">
        <div :class="$style.rowItemWithIcon" v-if="scope.row.experience > 0">
          {{ scope.row.experience }} {{ getNoun(scope.row.experience, 'год', 'года', 'лет') }}
        </div>
        <div :class="$style.rowItemWithIcon" v-else>Без опыта</div>
      </template>
    </el-table-column>
    <el-table-column class-name="table-row__actions">
      <template slot-scope="scope">
        <div :class="tableStyles.actions">
          <router-link :to="`/applicant/resumes/update/${scope.row.id}`">
            <rekroo-button width="45px" color="var(--purple)" icon="pencil" icon_color="#FFF"/>
          </router-link>
          <rekroo-button @click="$emit('on-remove-btn-click', scope.row.id)"
                         width="45px" color="var(--gray-text)" icon="trash" icon_color="#FFF"/>
        </div>
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug :class="$style.emptyResumesListPlug"
                       title="У вас пока нет опубликованных резюме"
                       subtitle="Составьте подробное резюме и вас пригласят на собеседование"
                       button_text="+ Новое резюме" @onClick="createResume"
      />
    </template>
  </el-table>
</template>

<script>
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import RekrooButton from "@/components/Button";
import EmptyListPlug from "@/components/EmptyListPlug";
import CandidateAvatar from "@/components/CandidateAvatar";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";

export default {
  name: "ResumeListTab",
  components: {CandidateAvatar, EmptyListPlug, RekrooButton, TableSkeleton},
  props: ['data', 'loading', 'loaded', 'name'],
  emits: ['on-selection-change', 'on-remove-btn-click'],
  data: () => ({
    tableStyles,
  }),
  methods: {
    createResume() {
      this.$router.push('/applicant/resumes/create');
    },
  }
}
</script>

<style module lang="css">
.resumeTableContainer {
  opacity: 0;
  transition: opacity 1.5s;
}

.resumeTableContainer.resumeTableActive {
  opacity: 1;
}

.resumesTable {
  margin-top: 40px;
  margin-bottom: 56px;
}

.resumesTable .resumesTableTitle {
  font-size: var(--body);
  font-weight: 500;
  line-height: 144%;
  color: var(--bright-blue);
  display: flex;
  column-gap: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emptyResumesListPlug {
  margin: 120px auto;
}

.rowItemWithIcon {
  display: flex;
  align-items: center;
  font-size: var(--small-text);
}

.tableActions {
  display: flex;
  column-gap: 12px;
}

.resumeTableTitleMobile {
  display: none;
}

@media screen and (max-width: 575px) {

  .resumesTable {
    margin-bottom: 32px;
  }

  .resumeTableTitleMobile {
    display: block;
  }

  .resumesTable .resumesTableTitle {
    display: none;
  }

  .resumesTable .resumeTableTitleMobile .resumesTableTitle {
    display: block;
    margin-bottom: 12px;
    font-size: var(--small-text);
  }

  .resumesTable .resumeTableTitleMobile .resumeTableStats {
    display: flex;
    column-gap: 12px;
  }
}
</style>