<template>
<div class="page page-with-tabs-table">
<!--  <h1>Мои консультации</h1>-->
  <page-in-progress :title="`Раздел <span>Мои консультации</span> находится <br> на стадии <span>разработки</span>`"
  subtitle="Здесь вы сможете получить консультации по составлению резюме и прохождению собеседования от профессиональных рекрутеров"/>
<!--  <el-tabs v-model="activeTab">
    <el-tab-pane name="active" label="Активные">
      <consultation-table :loading="loading" :loaded="loaded" :data="consultations" name="active" @create="create"/>
    </el-tab-pane>
    <el-tab-pane name="past" label="Прошедшие">
      <consultation-table :loading="loadingArchive" :loaded="loadedArchive" :data="consultationsArchive" name="archive"
                          @create="create"/>
    </el-tab-pane>
  </el-tabs>-->
  <CreateOrderDialog ref="create_consultation_dialog" type="consultation"/>
<!--  <CreateConsultationDialog ref="create_consultation_dialog"/>-->
</div>
</template>

<script>
import ConsultationTable from "@/views/applicant/consultation/components/ConsultationTable";
import CreateConsultationDialog from "@/dialogs/CreateConsultationDialog";
import CreateOrderDialog from "@/views/company/auctions/Create";
import PageInProgress from "@/views/PageInProgress";
export default {
  name: "ApplicantConsultationList",
  components: {PageInProgress, CreateOrderDialog, CreateConsultationDialog, ConsultationTable},
  data: ()=>({
    activeTab: 'active',
    loading: false,
    loaded: true,
    loadingArchive: false,
    loadedArchive: true,
    consultations: [],
    consultationsArchive: [],
  }),
  methods: {
    get() {

    },
    create() {
      this.$refs.create_consultation_dialog.open();
    }
  },
  mounted() {
    this.get();
  }
}
</script>

<style module lang="css">

</style>