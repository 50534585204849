import store from "@/store";


export class Item {
    url = 'https://api.rekroo.org/pa/';
    hrm_url = 'https://api.rekroo.org/api/';
    timeout = 5000;
    method;

    constructor(props = {}) {
        for (let i in props) this[i] = props[i];
    }
    create(data, ids = null) {
        let method = ids !== null ? `${this.method}/update` : `${this.method}/${data.id ? `update/${data.id}` : 'create'}`;
        return this.request({...data, ids}, method);
    }

    get(param, filters = {}) {
        return this.request(filters, `${this.method}/get${param ? `/${param}` : ''}`);
    }
    search(filters) {
        return this.request(filters, `${this.method}/search`);
    }
    moveToArchive(id) {
        let data = {};
        if (Array.isArray(id)) data.ids = id;
        data.status = 2;
        return this.request(data, `${this.method}/update${Array.isArray(id) ? '' : `/${id}`}`);
    }
    remove(id) {
        let data = {};
        if (Array.isArray(id)) data.ids = id;
        data.deleted = 1;
        return this.request(data, `${this.method}/update${Array.isArray(id) ? '' : `/${id}`}`);
    }

    suggestions(type, q) {
        return this.request({type, q}, 'resume/suggestions');
    }

    complain(method, id, message) {
        return this.request({message}, `${method}/complain/${id}`);
    }

    request(_data = {}, method = this.method, isHrm = false) {
        let _method = method.includes('upload') ? `files/${method}` : `${method}`;
        _data = {..._data};
        // if (window.location.host.indexOf('localhost:') === 0) _data.token = this.getToken();
        let formData = JSON.stringify(_data);
        // let formData = new FormData();
        /*for (let i in _data)
            if (_data[i]) formData.set(i, _data[i]);*/
        const timeout = this.timeout;
        const controller = new AbortController();
        const id = setTimeout(() => {
            controller.abort();
            store.state.eventBus.$emit('api-timeout');
        }, timeout);
        return fetch(`${ isHrm ? this.hrm_url : this.url}${_method}`, {
            method: "POST",
            body: formData,
            credentials: "include",
            signal: controller.signal
        })
            .then(response => {
                if (!response.ok) throw new Error(response.statusText)
                clearTimeout(id);
                return response.json()
            })
            .then(data => {
                if (data.success) return data;
                if (['unpayed', 'agreement', 'bill_email', 'phone', 'code', 'tariff', '404', 'company_info'].includes(data.error.type) ||
                    (data.request.r.includes('user/get/me') && data.error.type === '401'))
                    return Promise.reject(data);
                else if (data.error.type == '401') {
                    store.state.eventBus.$emit('auth-error', {request: method, data: _data});
                    return Promise.reject(data);
                }
                store.state.eventBus.$emit('api-error', data.error);
                return Promise.reject(data);
            })
    }
}
