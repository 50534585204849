<template>
  <el-dialog :visible.sync="isVisible" width="475px" custom-class="rekroo-dialog consultation-create-dialog"
             :center="true">
    <div class="dialog-title" :class="$style.title">Новая услуга</div>
    <el-form :model="service">
      <el-form-item label="Название" required prop="title">
        <el-input v-model="service.title" class="gray-bg"
        placeholder="Например, консультация"/>
      </el-form-item>
      <el-form-item label="Описание">
        <el-input type="textarea"  :autosize="{ minRows: 5, maxRows: 16}" class="gray-bg"
        placeholder="Опишите подробно свою услугу и в чем ваше преимущество перед другими HR'ами"/>
      </el-form-item>
      <el-form-item label="Стоимость">
        <div :class="$style.priceContainer">
          <el-input v-model="service.price" v-maska="thousandMaska" placeholder="От 2 000" class="gray-bg"/>
          <el-select v-model="service.currency" class="gray-bg" style="max-width: 72px;">
            <el-option v-for="(value, key) in $store.state.systemParams.currencies" :key="key"
            :label="value.short" :value="key"/>
          </el-select>
        </div>
      </el-form-item>
      <rekroo-button :loading="loading" height="50px" width="100%"
      @click="create">{{service.id ? 'Сохранить' : 'Создать'}}</rekroo-button>
    </el-form>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
export default {
  name: "HRConsultationCreateDialog",
  components: {RekrooButton},

  data: () => ({
    isVisible: false,
    service: {
      currency: 'rub'
    },
    thousandMaska: '',
    loading: false,
  }),
  methods: {
    open(id) {
      this.loading = false;
      this.service = {currency: 'rub'};
      this.isVisible = true;
      if (id) this.get(id);
      else
        this.thousandMaska = this.$store.state.maskThousandSeparator;
    },
    create() {
      this.loading = true;
    },
    get(id) {
      console.log(id);
      this.thousandMaska = this.$store.state.maskThousandSeparator;
    }
  }
}
</script>

<style module lang="css">
.priceContainer {
  display: flex;
  column-gap: 6px;
}
.title {
  margin-bottom: 24px;
}
</style>