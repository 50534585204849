<template>
  <div :class="style.landingHrPage">
    <section :class="$style.employerLandingHeader">
      <div :class="$style.employerLandingHeaderIntro">
        <div :class="$style.employerLandingHeaderIntroTitle">Найти лучших сотрудников с нами просто</div>
        <p :class="$style.employerLandingHeaderIntroSubtitle">Лучшие кандидаты. Актуальные обновляемые базы резюме. Все
          в одном месте</p>
        <rekroo-button @click="joinToRekroo"
                       :class="$style.employerLandingHeaderIntroButton" color="var(--purple)" height="72px"
                       width="100%">Присоединиться к Rekroo
        </rekroo-button>
        <a href="#candidates" :class="$style.employerLandingHeaderNavigateToBottomButton">Как вам поможет
          Rekroo
          <rekroo-icon icon="arrow-bottom" width="16"/>
        </a>
      </div>
      <div :class="$style.employerLandingHeaderImage">
        <img src="/img/employer_landing/employer_landing_header.png" alt=""
             :class="$style.employerLandingHeaderImageDesktop">
        <img src="/img/employer_landing/employer_landing_header_tablet.png" alt=""
             :class="$style.employerLandingHeaderImageTablet">
      </div>
      <rekroo-button @click="joinToRekroo"
                     :class="$style.employerLandingHeaderButton" color="var(--purple)" height="72px"
                     width="100%">Присоединиться к Rekroo
      </rekroo-button>
    </section>
    <landing-candidates-database-section id="candidates" title_1="Актуальная" title_2="живая база резюме"
                                         subtitle="Огромная база талантов в ваших руках! Откройте доступ и изучайте профили тысяч квалифицированных специалистов"
                                         link="/company/catalog"

    >
      <template #default>
        <router-link to="/company/catalog" :class="$style.employerLandingCandidatesButton">
        <rekroo-button color="var(--purple)"
                       width="100%" height="72px">Открыть базу резюме
        </rekroo-button>
        </router-link>
      </template>
    </landing-candidates-database-section>
    <section :class="$style.employerLandingVacancyRates">
      <h1 :class="$style.employerLandingVacancyRatesTitle">Размещение вакансий
        по тарифам. Выберите решение, подходящее под ваши цели</h1>
      <VacancyRates/>
      <div :class="$style.employerLandingVacancyRatesHint_1">
        Если сотрудник нужен не срочно
        <img src="/img/applicant_landing/small_gray_dashed_curve_arrow.svg" alt="">
      </div>
      <div :class="$style.employerLandingVacancyRatesHint_2">
        Если сотрудник нужен срочно / узкой специализации
        <img src="/img/applicant_landing/small_gray_dashed_curve_arrow.svg" alt="">
      </div>
    </section>
<!--    <section :class="$style.employerLandingAI">
      <div :class="$style.employerLandingAIDescription">
        <h1 :class="$style.employerLandingAITitle">Автоматизируйте подбор кандидатов, доверив AI первый скрининг</h1>
        <div :class="$style.employerLandingAITabsContainer">
          <div :class="$style.employerLandingAITabs">
            <div :class="[$style.employerLandingAITab, {[$style.employerLandingAITabActive]: activeAITab === i}]"
                 v-for="(tab, i) in aiItems" :key="i" @click="activeAITab = i">{{ tab.tab }}
            </div>
          </div>
        </div>
        <div :class="$style.employerLandingAIItemTitle">{{ aiItems[activeAITab].title }}</div>
        <p :class="$style.employerLandingAIItemSubtitle">{{ aiItems[activeAITab].subtitle }}</p>
        <rekroo-button :class="$style.employerLandingAIItemButton" color="var(&#45;&#45;purple)" height="72px" width="100%"
                       @click="$store.state.eventBus.$emit('auth-error')"
        >
          Попробовать AI
        </rekroo-button>
      </div>
      <div :class="$style.employerLandingAIImage">
        <img :src="`/img/employer_landing/ai_${activeAITab+1}.png`" alt="">
      </div>
      <rekroo-button :class="$style.employerLandingAIButton" color="var(&#45;&#45;purple)" height="72px" width="100%"
                     @click="$store.state.eventBus.$emit('auth-error')">
        Попробовать AI
      </rekroo-button>
    </section>-->
    <section :class="$style.employerLandingAuction">
      <h1 :class="$style.employerLandingAuctionTitle">Аукцион с HR.<br>Ищите рекрутера проектно</h1>
      <p :class="$style.employerLandingAuctionSubtitle">Освободите себя от забот по поиску сотрудников, поручив эти
        задачи проверенным HR-специалистам</p>
      <div :class="$style.employerLandingAuctionItems">
        <div :class="$style.employerLandingAuctionItem" v-for="(item, i) in auctionSteps" :key="i">
          <div :class="$style.employerLandingAuctionItemTitle">{{item.title}}</div>
          <p :class="$style.employerLandingAuctionItemSubtitle">{{item.subtitle}}</p>
          <img :src="`/img/employer_landing/auction_order_step_${i+1}.png`" alt="" :class="$style.employerLandingAuctionItemImage">
        </div>
      </div>
      <router-link v-if="user.id" to="/company/auction?create=1" :class="$style.employerLandingAuctionButton">
        <rekroo-button
            width="100%" height="72px" color="var(--purple)">Создать заказ
        </rekroo-button>
      </router-link>
      <rekroo-button v-else @click="$store.state.eventBus.$emit('auth-error')"
                     :class="$style.employerLandingAuctionButton"
          width="100%" height="72px" color="var(--purple)">Создать заказ
      </rekroo-button>
    </section>
    <section :class="$style.employerLandingTips">
      <div :class="$style.employerLandingTip" v-for="(item, i) in tips" :key="i">
        <div :class="$style.employerLandingTipDescription">
          <h1 :class="$style.employerLandingTipTitle">{{item.title}}</h1>
          <p :class="$style.employerLandingTipSubtitle">{{item.subtitle}}</p>
        </div>
        <div :class="$style.employerLandingTipImage">
          <img :src="`/img/employer_landing/tip_${i+1}.png`" alt="">
        </div>
      </div>
    </section>
    <landing-reviews-section :title="`Они уже попробовали Rekroo <br> и нашли своих сотрудников`"/>
  </div>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import LandingCandidatesDatabaseSection from "@/views/landing/components/LandingCandidatesDatabaseSection";
import VacancyRates from "@/views/rates/VacancyRates";
import LandingReviewsSection from "@/views/landing/components/LandingReviewsSection";

export default {
  name: "EmployerLanding",
  components: {LandingReviewsSection, VacancyRates, LandingCandidatesDatabaseSection, RekrooIcon, RekrooButton},

  data: () => ({
    style,
    activeAITab: 0,
    aiItems: [
      {
        tab: 'Без рутины',
        title: 'Оставьте рутину роботу',
        subtitle: 'Робот-рекрутер возьмет на себя холодный поиск кандидатов, обработку откликов, проведение первичных собеседований, проведет оценку резюме и распределит кандидатов по релевантности',
      },
      {
        tab: 'Точность отбора',
        title: 'Повысьте точность отбора',
        subtitle: 'Алгоритмы ИИ способны анализировать большое количество данных и сопоставлять их с вашими требованиями, учитывая даже скрытые связи и паттерны. Так вы получаете доступ к наиболее подходящим и квалифицированным кандидатам',
      },
      {
        tab: 'Экономия ресурсов',
        title: 'Сократите время и затраты на подбор',
        subtitle: 'Сосредоточитесь на более важных задачах, пока ИИ эффективно обрабатывает и анализирует профили кандидатов за вас',
      },
    ],
    auctionSteps: [
      {
        title: 'Создайте заказ',
        subtitle: 'Опишите свою задачу, детали и условия. Это бесплатно и займет 2-3 минуты',
      },
      {
        title: 'Получите отклики',
        subtitle: 'HR-специалисты, готовые выполнить ваши задачи, сами откликнутся на ваш заказ',
      },
      {
        title: 'Выберите исполнителя',
        subtitle: 'Оцените репутацию, отзывы и предложения исполнителей и выберите лучшего ',
      }
    ],
    tips: [
      {
        title: 'Откройте двери кандидатам, желающим работать именно у вас',
        subtitle: 'Бесплатно просматривайте потенциальных кандидатов, которые выбрали вашу компанию в качестве компании мечты, и сократите время на поиск и привлечение талантов, сфокусировавшись на самых лояльных кандидатах'
      },
      {
        title: 'Рассылка по базе резюме. Сократите время и усилия, затрачиваемые на поиск кандидатов',
        subtitle: 'Отправляйте предложения о вакансиях тысячам кандидатов одновременно.\n' +
            'Вы можете выбрать нужные критерии, такие как профессиональные навыки, опыт работы, уровень образования, чтобы предложение о вакансии было доставлено только квалифицированным кандидатам.'
      },
    ]
  }),
  methods: {
    joinToRekroo() {
      if (this.user.id) this.$router.push('/company/about');
      else this.$store.state.eventBus.$emit('auth-error');
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
}
</script>

<style module lang="css">
.employerLandingHeader {
  display: grid;
  grid-template-columns: 55% 44%;
  grid-gap: 20px;
}

.employerLandingHeaderIntro {
  padding: 80px 80px 56px;
  border-radius: 16px;
  background: var(--light-gray);
}

.employerLandingHeaderIntroTitle {
  margin-bottom: 32px;
  font-size: 64px;
  font-weight: 700;
}

.employerLandingHeaderIntroSubtitle {
  margin-bottom: 44px;
  max-width: 497px;
  font-size: 22px;
  line-height: 140%;
}

.employerLandingHeaderIntroButton {
  max-width: 495px !important;
  margin-bottom: 72px;
}

.employerLandingHeaderNavigateToBottomButton {
  font-size: 16px;
}

.employerLandingHeaderImage {
  display: flex;
  border-radius: 16px;
  background: var(--blue);
}

.employerLandingHeaderImageDesktop {
  margin-top: auto;
  width: 100%;
  max-height: 647px;
}

.employerLandingHeaderImageTablet {
  display: none;
}

.employerLandingHeaderButton {
  display: none;
}

/*CANDIDATES SECTION*/
.employerLandingCandidatesButton {
  margin-top: 48px;
  max-width: 420px;
}

/*VACANCY RATES*/
.employerLandingVacancyRates {
  margin-bottom: 20px;
  padding: 100px 0;
  border-radius: 16px;
  background: var(--light-gray);
  position: relative;
}

.employerLandingVacancyRatesTitle {
  margin: 0 auto 40px;
  max-width: 770px;
  text-align: center;
}

.employerLandingVacancyRatesHint_1, .employerLandingVacancyRatesHint_2 {
  position: absolute;
  max-width: 170px;
  color: var(--gray-text);
  font-size: var(--body);
  font-weight: 500;
  text-align: center;
}

.employerLandingVacancyRatesHint_1 {
  top: 240px;
  left: 50px;
}

.employerLandingVacancyRatesHint_1 img {
  transform: rotateZ(90deg) rotateX(180deg);
}

.employerLandingVacancyRatesHint_2 {
  right: 36px;
  bottom: 214px;
}

.employerLandingVacancyRatesHint_2 img {
  transform: rotateZ(90deg);
}

/*AI SECTION*/
.employerLandingAI {
  display: flex;
  column-gap: 60px;
  padding: 100px 0 100px 80px;
  background: var(--light-gray);
  border-radius: 16px;
}

.employerLandingAIDescription {
  max-width: 590px;
  width: 100%;
}

.employerLandingAITitle {
  margin-bottom: 32px;
}

.employerLandingAITabs {
  margin-bottom: 28px;
  width: fit-content;
  padding: 4px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
}

.employerLandingAITab {
  display: flex;
  padding: 10px 36px;
  color: var(--gray-text);
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  border-radius: 8px;
  cursor: pointer;
}

.employerLandingAITabActive {
  color: var(--green-active);
  background: rgba(57, 198, 113, .1);
}

.employerLandingAIItemTitle {
  margin-bottom: 12px;
  font-size: var(--headline_3);
  font-weight: 600;
}

.employerLandingAIItemSubtitle {
  margin-bottom: 32px;
}

.employerLandingAIImage img {
  width: 100%;
}

.employerLandingAIButton {
  display: none;
}
/*AUCTION SECTION*/
.employerLandingAuction {
  padding: 120px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employerLandingAuctionTitle {
  margin-bottom: 28px;
  text-align: center;
}
.employerLandingAuctionSubtitle {
  margin-bottom: 40px;
  max-width: 635px;
  text-align: center;
}

.employerLandingAuctionItems {
  margin-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
}

.employerLandingAuctionItem {
  padding: 44px 40px 0;
  background: var(--light-gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
}

.employerLandingAuctionItemTitle {
  margin-bottom: 12px;
  font-size: var(--headline_3);
  font-weight: 600;
  text-align: center;
}

.employerLandingAuctionItemSubtitle {
  margin-bottom: 20px;
  text-align: center;
}

.employerLandingAuctionItemImage {
  width: 100%;
}

.employerLandingAuctionButton {
  max-width: 420px;
  width: 100%;
}

/*TIPS SECTION*/
.employerLandingTips {}
.employerLandingTip {
  padding: 40px 80px;
  display: flex;
  column-gap: 48px;
  border-radius: 16px;
  background: var(--light-gray);
  align-items: center;
}

.employerLandingTip:first-child {
  margin-bottom: 20px;
}
.employerLandingTip:last-child .employerLandingTipDescription {
  order: 2;
}
.employerLandingTip:last-child .employerLandingTipImage {
  order: 1;
}
.employerLandingTipDescription {
  max-width: 606px;
  width: 100%;
}

.employerLandingTipTitle {
  margin-bottom: 28px;
}

.employerLandingTipSubtitle {

}
.employerLandingTipImage {
  max-width: 525px;
}
.employerLandingTipImage img {
  width: 100%;
}
@media screen and (max-width: 1360px) {
  .employerLandingVacancyRatesHint_1, .employerLandingVacancyRatesHint_2 {
    display: none;
  }
}

@media screen and (max-width: 1210px) {
  .employerLandingHeaderIntroTitle {
    font-size: 46px;
  }
}

@media screen and (max-width: 1024px) {
  .employerLandingHeader {
    padding: 56px 40px;
    display: block;
    background: var(--light-gray);
    border-radius: 12px;
  }

  .employerLandingHeaderIntroButton, .employerLandingHeaderNavigateToBottomButton {
    display: none;
  }

  .employerLandingHeaderButton {
    display: flex;
  }

  .employerLandingHeaderIntro {
    padding: 0;
    margin-bottom: 56px;
  }

  .employerLandingHeaderIntroTitle {
    margin-bottom: 24px;
  }

  .employerLandingHeaderImageDesktop {
    display: none;
  }

  .employerLandingHeaderImageTablet {
    display: block;
    margin: 0 auto;
    max-width: 518px;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .employerLandingHeaderImage {
    margin-bottom: 40px;
    border-radius: 8px;
  }

  .employerLandingCandidatesButton {
    margin-top: 40px;
    max-width: calc(100% - 80px);
  }

  /*VACANCY RATES*/
  .employerLandingVacancyRates {
    margin-bottom: 0;
    padding: 40px 0 80px;
    background: #FFFFFF;
  }

  .employerLandingVacancyRatesTitle {
    margin: 0 auto 36px;
  }


  /*AI SECTION*/
  .employerLandingAI {
    display: block;
    padding: 64px 40px;
  }

  .employerLandingAIDescription {
    max-width: 100%;
  }

  .employerLandingAITitle {
    margin-bottom: 24px;
  }

  .employerLandingAITabsContainer {
    margin: 0 -40px;
    padding: 0 40px;
    max-width: calc(100% + 80px);
    overflow: auto;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .employerLandingAITabsContainer::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .employerLandingAITabsContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .employerLandingAITabs {
    margin-bottom: 28px;
  }

  .employerLandingAIItemTitle {
    font-size: 22px;
  }

  .employerLandingAIItemSubtitle {
    margin-bottom: 16px;
  }

  .employerLandingAIImage {
    margin-bottom: 28px;
  }

  .employerLandingAIImage img {
    width: 100%;
  }

  .employerLandingAIItemButton {
    display: none;
  }

  .employerLandingAIButton {
    display: flex;
  }

  /*AUCTION SECTION*/
  .employerLandingAuction {
    padding: 80px 40px;
  }

  .employerLandingAuctionTitle {
    margin-bottom: 20px;
    text-align: center;
  }
  .employerLandingAuctionSubtitle {
    margin-bottom: 36px;
    max-width: 425px;
  }

  .employerLandingAuctionItems {
    margin-bottom: 40px;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }

  .employerLandingAuctionItem {
    padding: 40px 28px 0;
  }

  .employerLandingAuctionItemSubtitle {
    margin-bottom: 22px;
  }

  .employerLandingAuctionButton {
    max-width: 100%;
  }

  /*TIPS SECTION*/
  .employerLandingTip {
    padding: 64px 40px;
    flex-direction: column;
    row-gap: 24px;
  }
  .employerLandingTip:last-child .employerLandingTipDescription {
    order: 1;
  }
  .employerLandingTip:last-child .employerLandingTipImage {
    order: 2;
  }
  .employerLandingTipDescription {
    max-width: 100%;
  }

  .employerLandingTipTitle {
    text-align: center;
    margin-bottom: 20px;
  }

  .employerLandingTipSubtitle {
    text-align: center;
  }
  .employerLandingTipImage {
    max-width: 525px;
  }
}

@media screen and (max-width: 575px) {
  .employerLandingHeader {
    padding: 48px 20px 28px;
  }

  .employerLandingHeaderIntroTitle {
    margin-bottom: 16px;
    font-size: 32px;
  }

  .employerLandingHeaderIntro {
    margin-bottom: 16px;
  }

  .employerLandingHeaderImage {
    margin-bottom: 24px;
  }

  .employerLandingCandidatesButton {
    margin-top: 24px;
    max-width: 100%;
  }

  /*VACANCY RATES*/
  .employerLandingVacancyRates {
    margin-bottom: 0;
    padding: 32px 0 72px;
    background: #FFFFFF;
  }

  .employerLandingVacancyRatesTitle {
    margin: 0 auto 32px;
  }


  /*AI SECTION*/
  .employerLandingAI {
    padding: 60px 20px;
  }

  .employerLandingAITitle {
    margin-bottom: 20px;
  }

  .employerLandingAITabsContainer {
    margin: 0 -20px;
    padding: 0 20px;
    max-width: calc(100% + 40px);
  }

  .employerLandingAITabs {
    margin-bottom: 24px;
  }

  .employerLandingAIItemTitle {
    font-size: 18px;
  }

  .employerLandingAIItemSubtitle {
    margin-bottom: 20px;
  }

  /*AUCTION SECTION*/
  .employerLandingAuction {
    padding: 72px 0;
  }

  .employerLandingAuctionSubtitle {
    margin-bottom: 24px;
  }

  .employerLandingAuctionItems {
    grid-row-gap: 16px;
  }

  .employerLandingAuctionItem {
    padding: 32px 28px 0;
  }

  .employerLandingAuctionItemTitle {
    margin-bottom: 8px;
    font-size: 18px;
  }

  .employerLandingAuctionItemSubtitle {
    margin-bottom: 16px;
  }

  .employerLandingAuctionButton {
    max-width: 100%;
  }

  /*TIPS SECTION*/
  .employerLandingTip {
    padding: 60px 20px;
    row-gap: 12px;
  }

  .employerLandingTipTitle {
    text-align: left;
  }

  .employerLandingTipSubtitle {
    text-align: left;
  }
  .employerLandingTipImage {
    max-width: 100%;
  }
}
</style>