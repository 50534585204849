<template>
  <el-dialog :visible.sync="isVisible" :width="dialogWidth" custom-class="rekroo-dialog auth-dialog" :center="true">
    <role-selection v-if="step === 'role'" @on-role-select="onRoleSelect" @select-role="roleSelect" :role="role"/>
    <login v-else-if="step === 'login'" ref="login" @on-login="onLogin" :role="role"/>
    <login-code ref="login_code" :role="role" :login-data="loginData" v-else-if="step === 'code'" @back="backFromCode"
                @success-auth="onSuccessAuth"/>
  </el-dialog>
</template>
<script>
import RoleSelection from "@/dialogs/auth/RoleSelection";
import Login from "@/dialogs/auth/Login";
import LoginCode from "@/dialogs/auth/Code";
import {User} from "@/api/user";
import {Auth} from "@/api/auth";

export default {
  name: "AuthDialog",
  components: {LoginCode, Login, RoleSelection,},
  emits: ['on-success-auth'],
  data: () => ({
    isVisible: false,
    step: 'role',
    role: '',
    loginData: {},
    userClass: new User({}),
    authClass: new Auth({}),
    requestData: {},
  }),
  methods: {
    open(data) {
      this.requestData = {};
      this.step = 'role';
      this.role = '';
      this.isVisible = true;
      this.requestData = {...data};
      this.setCurrentRole();
    },
    setCurrentRole() {
      this.role = this.$route.path.split('/')[1] || '';
    },
    roleSelect(role) {
      this.role = role;
    },
    onRoleSelect(role) {
      this.role = role;
      this.step = 'login';
    },
    onLogin(data) {
      this.step = 'code';
      this.loginData = data;
    },
    onSuccessAuth() {
      this.isVisible = false;
      this.$emit('on-success-auth');
      this.gtmEvent('login_' + this.role);
      this.sendMarketingToken();
      if (['/', '/company', '/hr'].some(v => v === this.$route.path) && ['company', 'hr'].some(v => v === this.role)) {
        this.$router.replace(`/${this.role}/catalog`);
      }
    },
    sendMarketingToken() {
      if (this.getValue('audience_survey_token'))
        this.authClass.marketingForm({form_token: this.getValue('audience_survey_token')});
    },
    backFromCode() {
      this.step = 'login';
      setTimeout(()=>{
        this.$refs.login.data[this.loginData.type] = this.loginData.login;
        this.$refs.login.loginType = this.loginData.type;
        this.$refs.login.random = Math.random();
      })
    }
  },
  watch: {
    step(val) {
      if (val === 'code')
        this.$nextTick(() => {
          this.$refs.login_code.$refs.otp_input_0[0].focus()
        });
    }
  },
  computed: {
    dialogWidth() {
      return this.step === 'role' ? '650px' : '427px';
    }
  }
}
</script>

<style lang="css" module>

</style>
