<template>
  <div class="page" :class="$style.updateVacancyPage" ref="page">
    <h1 :class="$style.updateVacancyPageTitle">{{ vacancy.id ? vacancy.position : 'Новая вакансия' }}</h1>
    <div :class="[$style.updateVacancyPageContent, {[$style.updateVacancyPageContentNoHint]: hideHint}]">
      <el-form :class="$style.form" :model="vacancy" :rules="rules" ref="form" @keydown.enter.native="enterKeydown">
        <div :class="$style.formInner">
          <form-loader v-if="getLoading"/>
          <div :class="$style.inputRow">
            <el-form-item label="Должность" prop="position" required>
              <el-autocomplete
                  placeholder="Например, продуктовый дизайнер" v-model="vacancy.position"
                  :fetch-suggestions="fetchSuggestionsPos"
                  :trigger-on-focus="false"
              />
            </el-form-item>
            <el-form-item label="Диапазон зарплаты" :class="$style.salaryRowItem">
              <div :class="$style.salaryItem">
                <div :class="$style.salary">
                  <el-input placeholder="от" v-model="vacancy.salary_from" :class="$style.salaryFromInput"
                            type="tel"
                            v-maska="thousandMask" :key="vacancy.salary_from"/>
                  <el-input placeholder="до" v-model="vacancy.salary_to" :class="$style.salaryToInput"
                            type="tel"
                            v-maska="thousandMask" :key="vacancy.salary_to"/>
                </div>
                <el-select v-model="vacancy.currency" placeholder="" style="max-width: 132px">
                  <el-option
                      v-for="(item, key) in $store.state.systemParams.currencies"
                      :key="key"
                      :label="item.short"
                      :value="key">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <div :class="[$style.inputRow, $style.inputRow3Col]">
            <el-form-item label="Тип занятости">
              <el-select v-model="vacancy.employment_type" placeholder="Выберите из списка">
                <el-option
                    v-for="(item, key) in $store.state.systemParams.employment_types"
                    :key="key"
                    :label="item"
                    :value="Number(key)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="График">
              <el-select v-model="vacancy.schedule" placeholder="Выберите из списка">
                <el-option
                    v-for="(item, key) in $store.state.systemParams.schedule_options"
                    :key="key"
                    :label="item"
                    :value="Number(key)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Требуемый опыт">
              <el-select v-model="vacancy.experience" placeholder="Например, От 1 до 3 лет">
                <el-option v-for="(option, i) in $store.state.experience_options" :key="i" :value="i" :label="option"/>
              </el-select>
            </el-form-item>
          </div>
          <div :class="$style.inputRow">
            <el-form-item label="Город">
              <el-autocomplete
                  v-model="vacancy.location" placeholder=""
                  :fetch-suggestions="fetchSuggestionsLocation"
                  :trigger-on-focus="false"
              />
              <el-checkbox label="Удаленная работа" v-model="vacancy.remote_work"/>
            </el-form-item>
            <el-form-item label="Дата окончания">
              <el-date-picker v-model="vacancy.date_end" placeholder="ДД.ММ.ГГГГ" format="dd.MM.yyyy"
                              type="tel"
                              value-format="yyyy-MM-dd" v-maska="'##.##.####'" :key="vacancy.date_end"/>
            </el-form-item>
          </div>
          <el-form-item label="Описание вакансии">
            <el-input v-model="vacancy.description" type="textarea" :autosize="{ minRows: 6, maxRows: 16}"
                      placeholder="Расскажите кратко о вакансии."/>
          </el-form-item>
          <el-form-item label="Задачи">
            <el-input v-model="vacancy.tasks" type="textarea" :autosize="{ minRows: 6, maxRows: 16}"
                      placeholder="Активные продажи Самостоятельный поиск клиентов"/>
          </el-form-item>
          <el-form-item label="Требования">
            <el-input v-model="vacancy.requirements" type="textarea" :autosize="{ minRows: 6, maxRows: 16}"
                      placeholder="Активные продажи Самостоятельный поиск клиентов"/>
          </el-form-item>
          <el-form-item label="Условия">
            <el-input v-model="vacancy.conditions" type="textarea" :autosize="{ minRows: 6, maxRows: 16}"
                      placeholder="Активные продажи Самостоятельный поиск клиентов"/>
          </el-form-item>
        </div>
      </el-form>
      <PageHint v-if="!vacancy.salary_from && !vacancy.salary_to && !hideHint"
                icon="error" color="var(--yellow)" title="Зарплата"
                subtitle="Если вы не&nbsp;укажете зарплату, то по умолчанию в&nbsp;вакансии будет указано «По&nbsp;договоренности»."/>
    </div>
    <h1 v-if="showCompanyInfo" :class="$style.updateVacancyPageTitle">Информация о компании</h1>
    <company-form v-if="showCompanyInfo" id="company_form" :company="vacancy.company_info" :role="$route.path.split('/')[1]" ref="company_form"/>
    <div :class="[$style.formActions, {[$style.formActionsNoHint]: hideHint}]">
      <rekroo-button :loading="loading" width="100%" height="50px" @click="submit(0)" type="gray"
                     text_color="var(--primary)">Сохранить как
        неопубликованную
      </rekroo-button>
      <rekroo-button :loading="loading" width="100%" @click="submit(1)" height="50px">Опубликовать вакансию
      </rekroo-button>
    </div>
    <VacancyRatesDialog ref="rates_dialog" :redirect_url="`${$route.path.split('/')[1]}/vacancies/update/${vacancy.id}?paid=1`"/>
  </div>
</template>

<script>
import Vacancy from "@/api/vacancy";
import PageHint from "@/components/PageHint";
import RekrooButton from "@/components/Button";
import VacancyRatesDialog from "@/dialogs/VacancyRatesDialog";
import CompanyForm from "@/components/CompanyForm";
import FormLoader from "@/components/FormLoader";
import {User} from "@/api/user";
import {Item} from "@/api/item";

export default {
  name: "UpdateVacancy",
  components: {FormLoader, CompanyForm, VacancyRatesDialog, RekrooButton, PageHint},
  props: ['hideHint', 'fromDialog'],
  emits: ['create'],
  data: () => ({
    showCompanyInfo: false,
    itemClass: new Item({}),
    vacancyClass: new Vacancy({}),
    vacancy: new Vacancy({}),
    userClass: new User({}),
    rules: {
      position: [
        {required: true, message: 'Укажите должность', trigger: 'change'},
        {required: true, message: 'Укажите должность', trigger: 'blur'},
      ]
    },
    loading: false,
    getLoading: false,
    thousandMask: '',
  }),
  methods: {
    get() {
      if (this.$route.params.id) {
        this.getLoading = true;
        this.vacancyClass.get(this.$route.params.id).then((data) => {
          this.getLoading = false;
          this.vacancy = this.parseResponse(data.response);
          this.thousandMask = this.$store.state.maskThousandSeparator;
          if (+this.$route.query.paid === 1) {
            this.publish();
            this.$router.push({query: {}}).catch(()=>{});
          }
        }).catch(() => {
          this.getLoading = false;
        })
      } else
        this.thousandMask = this.$store.state.maskThousandSeparator;
    },
    submit(status) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.vacancyClass.create(this.toRequest()).then((data) => {
            if (!this.vacancy.id && this.$route.path.split('/')[1] === 'company') {
              this.saveCompanyToUser(this.toRequest().company_info);
            }
            this.vacancy = this.parseResponse(data.response);
            if (status) {
              this.publish();
            } else {
              this.loading = false;
              this.$notify({
                type: 'success',
                title: 'Вакансия успешно сохранена',
                duration: 3000
              });
              if (this.fromDialog) this.$emit('create', data.response);
              else
                this.$router.replace(`/${this.$route.path.split('/')[1]}/vacancies/${this.vacancy.id}`);
            }
          }).catch((data) => {
            this.loading = false;
            if (data.error && data.error.type === 'company_info') {
              this.showCompanyInfo = true;
              this.$refs.company_form.$refs.about_company_form.validate(()=>{});
              this.$router.push({hash: 'company_form'}).catch(()=>{});
            }
          });
        } else {
          this.scrollToError(this.$refs.page);
          return false;
        }
      })
    },
    publish() {
      this.vacancyClass.create({status: 1, id: this.vacancy.id, company_info: this.vacancy.company_info}).then(() => {
        this.loading = false;
        this.$router.replace(`/${this.$route.path.split('/')[1]}/vacancies/${this.vacancy.id}`);
        this.gtmEvent('vacancies_success_public');
        this.$notify({
          type: 'success',
          title: 'Вакансия успешно опубликована',
          duration: 3000
        });
      }).catch((data) => {
        this.loading = false;
        if (data.error && data.error.type === 'unpayed') {
          this.$router.replace(`/${this.$route.path.split('/')[1]}/vacancies/update/${this.vacancy.id}`);
          this.$refs.rates_dialog.open();
        }
      })
    },
    parseResponse(res) {
      let response = {...res};
      response.salary_from = res.salary_from.toString();
      response.salary_to = res.salary_to.toString();
      response.remote_work = !!res.remote_work;
      for (let key in res) {
        if (res[key] === 0) response[key] = null;
      }
      return response;
    },
    toRequest() {
      let data = {...this.vacancy};
      data.salary_from = this.vacancy.salary_from ? this.vacancy.salary_from.replaceAll(' ', '') : '';
      data.salary_to = this.vacancy.salary_to ? this.vacancy.salary_to.replaceAll(' ', '') : '';
      data.remote_work = this.vacancy.remote_work ? 1 : 0;
      data.role = this.$route.path.split('/')[1];
      if (this.$route.path.split('/')[1] === 'company') {
        data.company_info = this.$store.state.user.company_info?.title ? {...this.$store.state.user.company_info} : data.company_info;
      }
      if (data.company_info.image && data.company_info.image.id) {
        data.company_info.image = data.company_info.image.id;
      }
      return data;
    },
    saveCompanyToUser(company_info) {
      this.userClass.create({id: this.$store.state.user.id, company_info}).then((data) => {
        this.$store.commit('setUser', data.response);
      })
    },
    fetchSuggestionsPos(q, cb) {
      this.vacancyClass.suggestions('position', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    fetchSuggestionsLocation(q, cb) {
      this.vacancyClass.suggestions('location', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    parseVacancyFromHRM(res) {
      this.vacancy.position = res.title;
      this.vacancy.salary_from = res.salary_from;
      this.vacancy.salary_to = res.salary_to;
      this.vacancy.experience = +res.experience;
      /*TODO плохо парсится дата*/
      // this.vacancy.date_end = res.date_to;
      this.vacancy.description = res.additional;
    }
  },
  computed: {},
  mounted() {
    if (this.$route.query.hrm_id) {
      this.itemClass.request(null, `vacancy/get2/${this.$route.query.hrm_id}`, true).then(data=>{
        this.parseVacancyFromHRM(data.response);
        delete this.vacancy.id;
      })
    }
    if (this.$route.path.split('/')[1] === 'hr') this.showCompanyInfo = true;
    this.get();
  }
}
</script>

<style module lang="css">
.updateVacancyPage {

}

.updateVacancyPage .updateVacancyPageTitle {
  margin-bottom: 36px;
}

.updateVacancyPageContent {
  display: grid;
  grid-template-columns: 66% 33%;
  grid-column-gap: 20px;
}

.updateVacancyPageContentNoHint {
  grid-template-columns: 100%;
}

.updateVacancyPageContent .form .formInner {
  position: relative;
  background: var(--light-gray);
  padding: 64px 48px;
  border-radius: 16px;
  margin-bottom: 40px;
}

.updateVacancyPageContent .form .formInner .inputRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
}

.updateVacancyPageContent .form .formInner .inputRow.inputRow3Col {
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
}

.updateVacancyPageContent .form .formInner .inputRow:last-child {
  margin-bottom: 8px;
}

.salaryRowItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.salaryItem {
  display: flex;
  column-gap: 4px;
}

.salaryItem .salary {
  display: flex;
}

.salaryItem .salary .salaryFromInput {
  margin-right: 2px;
}

.salaryItem .salary .salaryFromInput input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.salaryItem .salary .salaryToInput input {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.formActions {
  max-width: 66%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  grid-gap: 12px;
}

.formActionsNoHint {
  max-width: 100%;
  grid-template-columns: 100%;
}

@media screen and (max-width: 1024px) {
  .updateVacancyPage .updateVacancyPageTitle {
    margin-bottom: 20px;
  }

  .updateVacancyPageContent {
    grid-template-columns: 1fr;
  }

  .updateVacancyPageContent .form {
    flex-basis: 100%;
    order: 2;
  }

  .updateVacancyPageContent .form .formInner {
    padding: 44px 40px;
  }

  .formActions {
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .formActions button {
    white-space: pre-wrap;
  }
}

@media screen and (max-width: 575px) {
  .updateVacancyPageContent .form .formInner {
    padding: 32px 20px;
  }

  .updateVacancyPageContent .form .formInner .inputRow {
    grid-template-columns: 1fr;
    margin-bottom: 12px;
  }

  .updateVacancyPageContent .form .formInner .inputRow.inputRow3Col {
    grid-template-columns: 1fr;
  }

  .salaryItem .salary {
    flex-grow: 1;
  }

  .formActions {
    grid-template-columns: 1fr;
  }
}
</style>