import {Item} from "./item";
export default class Vacancy extends Item {
    id;
    user_id;
    position;
    employment_type;
    location;
    remote_work;
    date_end;
    salary_from;
    salary_to;
    currency;
    experience;
    description;
    tasks;
    requirements;
    conditions;
    schedule;
    status;
    datetime_publish;
    company_info;
    datetime_create;
    my_response;
    experience_from;
    response; //пока не используем

    method = 'vacancy';

    constructor(props) {
        super(props);
        this.currency = props.currency || 'rub';
        this.company_info = props.company_info || {};
        this.my_response = props.my_response || {};
    }
    search(filters) {
        return this.request(filters, `${this.method}/search`);
    }
    applyVacancy(vacancy_id, data) {
        return this.request(data, `${this.method}/addResponse/${vacancy_id}`);
    }
    getResponsed(filters, id = null) {
        return this.request(filters, `${this.method}/responsed${id ? `/${id}` : ''}`);
    }
}