<template>
  <el-dialog width="570px" :visible.sync="isVisible" custom-class="rekroo-dialog about-hr-form-dialog" :center="true">
    <div class="dialog-title" :class="$style.title">Заполните информацию о себе</div>
    <el-form ref="form" :model="formData" :rules="rules">
      <div :class="$style.headerForm">
        <image-upload title="Выбрать фото" icon="profile" :item-image="formData.image"
                      @on-upload-success="onUploadImageSuccess" @on-remove-btn-click="onRemoveImage"
                      bg_color="var(--light-gray)"/>
        <div :class="$style.headerFormInputs">
          <el-form-item label="Имя">
            <el-input v-model="formData.name" placeholder="Ваше имя" class="gray-bg"/>
          </el-form-item>
          <el-form-item label="Фамилия">
            <el-input v-model="formData.surname" placeholder="Ваша фамилия" class="gray-bg"/>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="Город">
        <el-autocomplete
            v-model="formData.location" placeholder=""
            class="gray-bg"
            :fetch-suggestions="fetchSuggestionsLocation"
            :trigger-on-focus="false"/>
      </el-form-item>
      <el-form-item label="Контактный номер для заказчика">
        <el-input v-model="formData.phone" class="gray-bg" v-maska="phoneMask" :key="randomNumber"
                  @input="onPhoneInput"
                  type="tel"
                  autocomplete="tel"
                  placeholder="Ваш номер телефона"/>
      </el-form-item>
      <el-form-item label="Контактный e-mail для заказчика" prop="email">
        <el-input v-model="formData.email" class="gray-bg" placeholder="Ваш email"
        type="email" autocomplete="email"/>
      </el-form-item>
      <el-form-item label="Профильные отрасли">
        <el-select v-model="formData.scopes" multiple class="gray-bg" placeholder="Выберите из списка">
          <el-option v-for="(option, i) in $store.state.systemParams.scopes" :key="i" :value="option" :label="option"/>
        </el-select>
        <div :class="$style.inputTooltip">В каких сферах вы планируете осуществлять подбор персонала заказчикам.</div>
      </el-form-item>
      <el-form-item label="Желаемый гонорар за поиск сотрудника">
        <div :class="salaryItemStyle.salaryItem">
          <div :class="[salaryItemStyle.salary, $style.salary]">
            <el-input placeholder="от" v-model="formData.price_from" :class="salaryItemStyle.salaryFromInput"
                      type="tel"
                      v-maska="$store.state.maskThousandSeparator" class="gray-bg" :key="randomNumber+1"/>
            <el-input placeholder="до" v-model="formData.price_to" :class="salaryItemStyle.salaryToInput"
                      type="tel"
                      v-maska="$store.state.maskThousandSeparator" class="gray-bg" :key="randomNumber+2"/>
          </div>
          <span style="font-size: 24px">{{ $store.state.systemParams.currencies['rub']?.short }}</span>
        </div>
      </el-form-item>
      <el-form-item label="Обо мне">
        <el-input v-model="formData.about" type="textarea" :autosize="{ minRows: 4, maxRows: 10}"
                  placeholder="Расскажите о себе, почему заказчик должен выбрать именно вас" class="gray-bg"/>
      </el-form-item>
      <el-form-item label="День рождения">
        <el-date-picker v-model="formData.birthday" type="date" format="dd.MM.yyyy" lang="ru"
                        placeholder="ДД.ММ.ГГГГ" class="no-prefix gray-bg" value-format="yyyy-MM-dd"
                        v-maska="'##.##.####'"
        />
      </el-form-item>
      <rekroo-button @click="update" :loading="loading" width="100%" height="50px">Сохранить</rekroo-button>
    </el-form>
  </el-dialog>
</template>

<script>
import {User} from "@/api/user";
import salaryItemStyle from "@/assets/repeated_styles/SalaryItemStyle.module.css";
import RekrooButton from "@/components/Button";
import ImageUpload from "@/components/ImageUpload";

export default {
  name: "AboutHrFormDialog",
  components: {ImageUpload, RekrooButton},
  emits:['updated'],
  data: () => ({
    salaryItemStyle,
    isVisible: false,
    userClass: new User({}),
    formData: {
      name: '',
      surname: '',
      image: {},
      location: '',
      phone: '',
      email: '',
      scopes: [],
      price_from: '',
      price_to: '',
      about: '',
      birthday: '',
    },
    loading: false,
    randomNumber: 0,
    rules: {
      email: [
        {type: 'email', message: 'Email указан неверно', trigger: 'blur'},
      ],
    },
    phoneMask: ''
  }),
  methods: {
    open() {
      this.randomNumber = 0;
      this.formData = {name: '',
        surname: '',
        image: {},
        location: '',
        phone: '',
        email: '',
        scopes: [],
        price_from: '',
        price_to: '',
        about: '',
        birthday: ''};
      this.isVisible = true;
      this.receiveData();
    },
    update() {
      this.loading = true;
      this.userClass.setWOType(this.dataToRequest()).then(() => {
        this.loading = false;
        this.$emit('updated');
        this.isVisible = false;
        if (this.dataToRequest().status === 1) {
          this.$notify({
            type: 'success',
            message: 'Теперь ваш профиль будет виден заказчикам'
          })
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    receiveData() {
      if (this.$store.state.user.woinfo1) this.formData = this.$store.state.user.woinfo1;
      if (!this.$store.state.user.woinfo1?.phone) this.formData.phone = this.$store.state.user.phone;
      if (!this.$store.state.user.woinfo1?.email) this.formData.email = this.$store.state.user.email;
      if (this.formData?.price_from === 0) this.formData.price_from = '';
      if (this.formData?.price_to === 0) this.formData.price_to = '';
      this.formData.name = this.$store.state.user.name;
      this.formData.surname = this.$store.state.user.surname;
      this.formData.image = this.$store.state.user.image;
      this.formData.birthday = this.$store.state.user.birthday;
      this.formData.location = this.$store.state.user.location;
      this.phoneMask = this.$store.state.phone_mask;
      this.randomNumber = Math.random();
    },
    dataToRequest() {
      let data = {...this.formData};
      if (data.price_from) data.price_from = data.price_from?.replaceAll(' ', '');
      if (data.price_to) data.price_to = data.price_to?.replaceAll(' ', '');
      if (data.image?.id) data.image = data.image.id;
      if (!this.$store.state.user.woinfo1) data.status = 1;
      return data;
    },
    onUploadImageSuccess(image) {
      this.formData.image = image;
    },
    onRemoveImage() {
      this.$nextTick(() => {
        this.formData.image = null;
      })
      this.$forceUpdate();
    },
    fetchSuggestionsLocation(q, cb) {
      this.userClass.suggestions('location', q).then((data)=>{
        let suggestions = data.response.map(s=>({value: s}));
        cb(suggestions);
      })
    },
    onPhoneInput(v) {
      if (this.ignore8onPhoneInput(v)) this.formData.phone = this.ignore8onPhoneInput(v);
    }
  },
}
</script>

<style module lang="css">
.title {
  margin-bottom: 32px;
}
.headerForm {
  display: flex;
  column-gap: 12px;
  align-items: flex-start;
  margin-bottom: 16px;
}

.headerFormInputs {
  flex-grow: 1;
}

.salary {
  max-width: 100% !important;
}

.inputTooltip {
  margin-top: 2px;
  color: var(--gray-text);
  line-height: 144%;
}
</style>