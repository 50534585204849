<template>
  <i :class="`icon icon-${icon}`" :style="`color: ${color || 'var(--primary)'}; font-size: ${width || '24px'}`" @click="$emit('click')"></i>
</template>

<script>
export default {
  name: "RekrooIcon",
  emits: ['click'],
  props: ['icon', 'color', 'width']
}
</script>

<style scoped>

</style>