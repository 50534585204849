<template>
  <div :class="$style.loginContainer">
    <div class="dialog-title" :class="$style.loginTitle">{{loginType === 'phone' ? 'Введите ваш номер телефона' : 'Введите ваш email'}}</div>
    <div class="dialog-subtitle small" :class="$style.loginSubtitle">Нужен для кода подтверждения</div>
    <el-form autocomplete="on" :rules="rules" ref="form" :model="data"
    @keydown.enter.native.prevent="onSubmitLogin">
      <el-form-item v-if="loginType === 'phone'"
          :error="error.text" prop="phone">
        <el-input autofocus placeholder="+7 (___) ___ __ __" v-maska="$store.state.phone_mask"
                  :class="$style.loginInput" v-model="data.phone" :key="'phone'+random" class="no-outline"
                  type="tel"
                  autocomplete="tel"
                  @input="onPhoneInput"/>
      </el-form-item>
      <el-form-item v-else :error="error.text" prop="email">
        <el-input autofocus placeholder="mail@mail.ru" :class="$style.loginInput" v-model="data.email" :key="'email'+random" class="no-outline"
                  @input="error = {}" type="email"
        />
      </el-form-item>
      <rekroo-button height="50px" width="100%" :class="$style.nextButton" @click="onSubmitLogin" :loading="loading">Далее</rekroo-button>
      <rekroo-button type="text" text_color="var(--bright-blue)" @click="changeInput" width="100%"
                     :class="$style.changeLoginMethodBtn">Войти через
        {{ loginType === 'email' ? 'телефон' : 'email' }}</rekroo-button>
    </el-form>
    <license-agreement button-text="Далее"/>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
import LicenseAgreement from "@/dialogs/auth/LicenseAgreement";
import {Auth} from "@/api/auth";
export default {
  name: "Login",
  props: ['role'],
  components: {LicenseAgreement, RekrooButton},
  emits: ['on-login'],
  data: ()=>({
    loginType: 'phone',
    data: {},
    loading: false,
    error: {},
    auth: new Auth(),
    rules: {
      email: [
        {required: true, type: 'email', message: 'Email указан неверно', trigger: 'blur'},
      ]
    },
    random: Math.random()
  }),
  methods: {
    onSubmitLogin() {
      this.data.type = this.role;
      if (this.data.email)
        this.data.email = this.data.email.trim();
      this.$refs.form.validate((valid)=>{
        if (valid) {
          this.loading = true;
          this.auth.login(this.data).then((data)=>{
            this.loading = false;
            this.$emit('on-login', {login: this.data.phone || this.data.email, type: this.loginType, secretCode: data.response});
          }).catch(data=>{
            this.error = data.error;
            this.loading = false;
          });
        }
      })
    },
    changeInput() {
      this.loginType = this.loginType === 'email' ? 'phone' : 'email';
      this.$refs.form.resetFields();
    },
    onPhoneInput(v) {
      this.error = {};
      if (this.ignore8onPhoneInput(v)) this.data.phone = this.ignore8onPhoneInput(v);
    }
  }
}
</script>

<style module lang="css">
.loginContainer {}
.loginTitle {
  margin-bottom: 12px;
}

.loginSubtitle {
  margin-bottom: 32px;
}

.loginInput input {
 border-bottom: 1px solid var(--gray) !important;
  border-radius: 0 !important;
}

.loginInput input:focus {
  border-bottom: 1px solid var(--purple) !important;
}

.loginInput input {
  padding-left: 0;
}

.nextButton {
  margin-top: 36px;
  margin-bottom: 20px;
}
.changeLoginMethodBtn {
  margin-bottom: 16px;
}
</style>