<template>
  <el-upload
      ref="upload"
      :show-file-list="false" :with-credentials="true"
      :before-upload="beforeUpload"
      :on-success="onSuccess"
      :on-error="onError"
      :class="$style.logoUploader"
      :action="`${item.url}files/upload`"
      :accept="accept || 'image/*'"
  :style="`background: ${bg_color}`">
    <template #default>
      <div :class="$style.loader" v-if="loading">
        <i class="icon el-icon-loading"></i>
      </div>
      <div v-if="image">
        <div :class="$style.uploadedImage">
          <img :src="image" alt="">
          <div :class="$style.imageActions">
            <rekroo-button icon="pencil" icon_color="#FFF" icon_width="18px" height="50px" width="54px"/>
            <rekroo-button icon="trash" icon_color="#FFF" icon_width="18px" height="50px" width="54px"
                           @click="remove"/>
          </div>
        </div>
      </div>
      <template v-else>
        <div :class="$style.thumbnail">
          <rekroo-icon :icon="icon || 'image'" color="var(--gray-text)" width="58px"/>
          <span :class="$style.uploaderTitle">{{ title }}</span>
          <span :class="$style.uploaderHint">{{ subtitle || 'JPG / PNG, не более 5МБ' }}</span>
        </div>
      </template>
    </template>
  </el-upload>
</template>

<script>
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
import {Item} from "@/api/item";

export default {
  name: "ImageUpload",
  props: ['title', 'subtitle', 'icon', 'accept', 'itemImage', 'on-remove-btn-click', 'bg_color'],
  emits: ['on-upload-success'],
  components: {RekrooButton, RekrooIcon},
  data: () => ({
    item: new Item(),
    uploadedImage: {},
    loading: false,
  }),
  methods: {
    onSuccess(data) {
      this.loading = false;
      if (data.response) {
        this.$emit('on-upload-success', data.response);
        this.$nextTick(()=>{
          this.uploadedImage = data.response;
        })
      } else if (data.error && data.error.type === '401') {
        this.$store.state.eventBus.$emit('auth-error');
      }
    },
    onError() {
      this.loading = false;
    },
    beforeUpload(file) {
      if (this.checkFileSize(file.size)) {
        this.$notify({
          message: `Размер файла ${file.name} больше 5МБ`,
          type: 'warning'
        });
        return false;
      }
      this.loading = true;
    },
    remove() {
      this.$emit('on-remove-btn-click');
      this.uploadedImage = {};
    }
  },
  computed: {
    image() {
      if (this.uploadedImage.url) return this.uploadedImage.url;
      else if (this.itemImage && this.itemImage.url) return this.itemImage.url;
      else if (this.itemImage) return this.itemImage;
      else return null;
    }
  },
}
</script>

<style module lang="css">

.logoUploader {
  position: relative;
  min-width: 166px;
  width: 166px;
  height: 166px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}

.logoUploader .uploaderTitle {
  margin-top: 12px;
  margin-bottom: 6px;
  font-size: 13px;
  opacity: .5;
  display: block;
}

.logoUploader .uploaderHint {
  font-size: 10px;
  opacity: .3;
  display: block;
}

.logoUploader > * {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadedImage {
  width: 166px;
  height: 166px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.uploadedImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.uploadedImage .imageActions {
  display: none;
}

.uploadedImage:hover .imageActions {
  padding: 10px;
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.5);
  display: flex;
  column-gap: 4px;
  justify-content: flex-end;
  align-items: flex-end;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255, .6);
  z-index: 1;
  cursor: not-allowed;
}

@media screen and (max-width: 1024px) {
  .logoUploader, .uploadedImage {
    min-width: 152px;
    width: 152px;
    height: 152px;
  }
}

@media screen and (max-width: 575px) {
  .logoUploader, .uploadedImage {
    min-width: 136px;
    width: 136px;
    height: 136px;
  }
}
</style>