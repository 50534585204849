<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">

      <div :class="$style.footerSocial">
        <router-link to="/">
          <img src="/img/logo_white.svg" alt="" :class="$style.footerLogo">
        </router-link>
        <div :class="$style.downloadAppButtons">
          <a href="https://apps.apple.com/it/app/rekroo/id1527455770?l=en">
            <rekroo-button type="outlined" color="transparent" style="border-color: #fff">
              <img src="/img/app_store.svg" alt="">
            </rekroo-button>
          </a>
          <a href="https://play.google.com/store/apps/details?id=su.khromov.rekroo">
            <rekroo-button type="outlined" color="transparent" style="border-color: #fff">
              <img src="/img/google_play.svg" alt="">
            </rekroo-button>
          </a>
        </div>
        <a target="_blank" href="https://fasie.ru/">
          <img width="100" style="background: #fff;padding: 10px;border-radius: 5px" src="/img/bortnik.png"/>
        </a>
        <div :class="[$style.helpCenterContainer]">
          <div :class="$style.helpCenterTitle">Служба поддержки</div>
          <div v-if="$store.state.systemParams.contacts" :class="$style.helpCenterContacts">
            <a :href="$store.state.systemParams.contacts?.telegram" target="_blank">
              <img src="/img/icons/icon_telegram.svg" alt="" width="16" height="16" :class="$style.tgIcon">
              Telegram
            </a>
            <a :href="`tel: ${$store.state.systemParams.contacts?.phone}`">
              <rekroo-icon icon="phone" color="#FFF" width="16px"/>
              {{ $store.state.systemParams.contacts?.phone }}
            </a>
          </div>
        </div>
      </div>
      <div :class="$style.links">
        <div :class="$style.footerMenus">
          <ul :class="$style.menu">
            <div :class="$style.menuTitle">Соискателям</div>
            <li>
              <router-link to="/applicant/resumes/create">Создать резюме</router-link>
            </li>
            <li>
              <router-link to="/applicant/vacancies">Вакансии</router-link>
            </li>
          </ul>
          <ul :class="$style.menu">
            <div :class="$style.menuTitle">Работодателям</div>
            <li>
              <router-link to="/company/vacancies/create">Добавить вакансию</router-link>
            </li>
            <li>
              <router-link to="/rates">Тарифы</router-link>
            </li>
          </ul>
          <ul :class="$style.menu">
            <div :class="$style.menuTitle">Рекрутерам</div>
            <li>
              <router-link to="/hr">Добавить вакансию</router-link>
            </li>
            <li>
              <router-link to="/rates">Тарифы</router-link>
            </li>
          </ul>
        </div>
        <div :class="$style.privacyLinks">
          <span :class="$style.copyright">© {{ currentYear.getFullYear() }} Rekroo</span>
          <dot :class="$style.dot"/>
          <router-link to="/policy">Политика конфиденциальности</router-link>
          <dot :class="$style.dot"/>
          <router-link to="/offer">Оферта</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dot from "@/components/Dot";
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";

export default {
  name: "TheFooter",
  components: {RekrooIcon, RekrooButton, Dot},
  data: () => ({
    currentYear: new Date(),
  }),
}
</script>

<style lang="css" module>
.copyrightAndContacts {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.copyrightAndContacts a {
  display: flex;
  align-items: center;
  text-align: center;
  column-gap: 4px;
}

.footer {
  background: #111111;
  border-radius: 16px;
  padding: 48px 56px;
  margin-bottom: 40px;
}

.footerInner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.footerInner * {
  color: #FFFFFF;
}

.footer .footerSocial {

}

.footer .footerSocial .footerLogo {
  margin-bottom: 30px;
}

.footer .footerSocial .downloadAppButtons {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.footer .footerSocial .downloadAppButtons button {
  min-width: 160px;
}

.footer .footerSocial .downloadAppButtons img {
  height: 26px;
  width: 100%;
}

.footer .footerSocial span {
  font-size: var(--small-text);
}

.footer .links {
  padding-left: 60px;
  display: flex;
  flex-direction: column;
}

.footer .links .footerMenus {
  display: flex;
  align-items: flex-start;
  column-gap: 40px;
}

.footer .links .footerMenus .menu li:not(:last-child) {
  margin-bottom: 4px;
}

.footer .links .footerMenus .menu li a {
  color: var(--gray-text);
  font-size: var(--body);
}

.footer .links .footerMenus .menu .menuTitle {
  font-size: var(--body);
  font-weight: var(--body-medium-weight);
  margin-bottom: 12px;
}

.footer .links .privacyLinks {
  margin-top: auto;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: var(--small-text);
}

.footer .links .privacyLinks .copyright {
}

.helpCenterContainer {
  margin-top: 16px;
}

.helpCenterTitle {
  margin-bottom: 4px;
  color: var(--gray-text);
}

.helpCenterContacts {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.helpCenterContacts a {
  display: flex;
  align-items: center;
  column-gap: 4px;
  color: #FFF;
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 28px;
  }

  .footerInner {
    display: flex;
  }

  .footer .footerSocial .footerLogo {
    display: none;
  }

  .footer .footerSocial .downloadAppButtons {
    flex-direction: column;
    padding-right: 10px;
  }

  .footer .footerSocial span {
    font-size: var(--tiny-text);
  }

  .footer .links {
    margin-left: auto;
    padding-left: 0;
  }

  .footer .links .footerMenus {
    column-gap: 18px;
  }

  .footer .links .footerMenus .menu li a {
    font-size: var(--small-text);
    white-space: nowrap;
  }

  .footer .links .privacyLinks {
    font-size: var(--tiny-text);
  }

  .helpCenterContacts {
    row-gap: 8px;
  }
}

@media screen and (max-width: 690px) {
  .footer {
    padding: 32px 28px;
  }

  .footerInner {
    flex-direction: column;
  }

  .footer .footerSocial .downloadAppButtons {
    column-gap: 8px;
    flex-direction: row;
  }

  .footer .footerSocial .downloadAppButtons a, .footer .footerSocial .downloadAppButtons a button {
    width: 100% !important;
    min-width: 0;
  }

  .footer .footerSocial span {
    display: none;
  }

  .footer .links {
    margin-left: 0;
  }

  .footer .links .footerMenus {
    flex-direction: column;
    row-gap: 12px;
    padding-bottom: 20px;
  }

  .footer .links .footerMenus .menu .menuTitle {
    font-size: var(--small-text);
    margin-bottom: 4px;
  }

  .footer .links .footerMenus .menu li a {
    font-size: var(--tiny-text);
  }

  .footer .links .privacyLinks {
    padding-top: 20px;
    border-top: 1px solid #FFFFFF;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer .links .privacyLinks .dot {
    display: none;
    row-gap: 2px;
  }

  .footer .links .privacyLinks .copyright {
    display: block;
    margin-bottom: 8px;
  }

  .helpCenterContainer {
    margin-bottom: 16px;
  }
}

</style>
