<template>
  <page-in-progress v-if="!$store.state.isTest"
                    :title="`Раздел <span>Рассылки</span> находится <br> на стадии <span>разработки</span>`"
                    subtitle="Здесь вы сможете делать email-рассылки по базам резюме"/>
  <div v-else class="page page-with-tabs-table">
    <h1 :class="$style.mailingListTitle">E-mail рассылки
      <rekroo-button v-if="!authError"
                     :class="$style.createListButton" @click="createMailingList">+ Создать рассылку
      </rekroo-button>
    </h1>
    <empty-list-plug v-if="authError" title="Доступ ограничен"
                     subtitle="Авторизуйтесь, чтобы иметь возможность создавать e-mail рассылки"
                     button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
                     style="margin: 0 auto" :show-icon="true"/>
    <el-tabs v-else v-model="activeTab" :before-leave="onTabChange">
      <el-tab-pane label="Отправленные" name="sent">
        <MailingListTable name="sent" :data="tablesData.sent.data" :loading="tablesData.sent.loading"
                          :loaded="tablesData.sent.loaded" @on-create-btn-click="createMailingList"
                          @on-remove-btn-click="id=>$refs.confirm_to_archive_dialog.open({id})"/>
      </el-tab-pane>
      <el-tab-pane label="Неотправленные" name="new">
        <MailingListTable name="new" :data="tablesData.new.data" :loading="tablesData.new.loading"
                          :loaded="tablesData.new.loaded" @on-create-btn-click="createMailingList"
                          @on-remove-btn-click="id=>$refs.confirm_to_archive_dialog.open({id})"/>
      </el-tab-pane>
      <el-tab-pane label="Архив" name="archive">
        <MailingListTable name="archive" :data="tablesData.archive.data" :loading="tablesData.archive.loading"
                          :loaded="tablesData.archive.loaded" @on-create-btn-click="createMailingList"
                          @on-remove-btn-click="id=>$refs.confirm_to_archive_dialog.open({id})"/>
      </el-tab-pane>
    </el-tabs>
    <ConfirmDialog ref="confirm_to_archive_dialog" @success="toArchive"
                   title="Вы уверены, что хотите удалить рассылку?"/>
  </div>
</template>

<script>
import PageInProgress from "@/views/PageInProgress";
import RekrooButton from "@/components/Button";
import {Mailing_list_item} from "@/api/mailing_list_item";
import MailingListTable from "@/views/company/mailing_list/components/MailingListTable";
import EmptyListPlug from "@/components/EmptyListPlug";
import ConfirmDialog from "@/dialogs/ConfirmDialog";

export default {
  name: "MailingList",
  components: {ConfirmDialog, EmptyListPlug, MailingListTable, RekrooButton, PageInProgress},
  data: () => ({
    mailingListClass: new Mailing_list_item({}),
    activeTab: 'sent',
    tablesData: {
      sent: {
        loading: false,
        loaded: false,
        data: []
      },
      new: {
        loading: false,
        loaded: false,
        data: []
      },
      archive: {
        loading: false,
        loaded: false,
        data: []
      },
    },
    statuses: ['new', 'sent', 'archive'],
    authError: false
  }),
  methods: {
    onTabChange(newTab) {
      this.get(newTab);
      for (let key in this.tablesData) {
        if (key !== newTab) this.tablesData[key].loaded = false;
      }
      this.$router.push({query: newTab === 'sent' ? {} : {t: newTab}}).catch(() => {
      });
    },
    get(tab) {
      this.tablesData[tab].loading = true;
      this.mailingListClass.get(null, tab === 'archive' ? {deleted: 1}
          : {status: tab === 'sent' ? [1, 2, 3] : this.statuses.indexOf(tab)}).then((data) => {
        this.tablesData[tab].data = data.response;
        this.tablesData[tab].loading = false;
        this.tablesData[tab].loaded = true;
        this.authError = false;
      }).catch((data) => {
        this.tablesData[tab].loading = false;
        if (data.error?.type === '401') this.authError = true;
      });
    },
    createMailingList() {
      this.$router.push({name: 'MailingListCreateCompany'});
    },
    toArchive(id) {
      this.mailingListClass.moveToArchive(id).then(() => {
        this.get(this.activeTab);
      })
    }
  },
  mounted() {
    if (this.$route.query.t) this.activeTab = this.$route.query.t
    else this.get('sent');
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get(this.activeTab);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
.mailingListTitle {
  width: 100%;
  display: flex;
  align-items: center;
}

.createListButton {
  margin-left: auto;
  font-size: var(--button);
}
</style>