<template>
  <div :class="$style.arrows">
    <rekroo-button :class="$style.arrowButton" :color="currentSlide === 0 ? prevButtonIsGray ? 'var(--light-gray)' : '#FFF'
    : 'var(--primary)'" icon="arrow-bottom" :icon_color="currentSlide === 0 ? 'var(--primary)' : '#FFF'" height="54px" width="54px"
                   icon_style="transform: rotate(90deg)" icon_width="22px" @click="prevSlide"/>
    <rekroo-button :class="$style.arrowButton" :color="currentSlide === maxForward ? prevButtonIsGray ? 'var(--light-gray)'
     : '#FFF' : 'var(--primary)'" icon="arrow-bottom" :icon_color="currentSlide === maxForward ? 'var(--primary)' : '#FFF'" height="54px" width="54px"
                   icon_style="transform: rotate(-90deg)" icon_width="22px" @click="nextSlide"/>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
export default {
  name: "SwiperArrows",
  components: {RekrooButton},
  props: ['prevButtonIsGray', 'currentSlide', 'itemWidth', 'offsetLeft', 'maxForward'],
  emits: ['on-click'],
  data:()=>({
    current_slide: 0,
    offset_left: 0
  }),
  computed: {

  },
  methods: {
    setParams() {
      this.current_slide = this.currentSlide;
      this.offset_left = this.offsetLeft;
    },
    prevSlide() {
      this.setParams();
      if (this.current_slide > 0) {
        this.current_slide--;
        this.offset_left -= this.itemWidth;
      }
      this.$emit('on-click', {currentSlide: this.current_slide, offsetLeft: this.offset_left});
    },
    nextSlide() {
      this.setParams();
      if (this.current_slide < this.maxForward) {
        this.current_slide++;
        this.offset_left += this.itemWidth;
      }
      this.$emit('on-click', {currentSlide: this.current_slide, offsetLeft: this.offset_left});
    }
  }
}
</script>

<style module lang="css">

.arrows {
  display: flex;
  column-gap: 12px;
}

.arrows .arrowButton {
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .arrows {
    display: none;
  }
}

</style>