<template>
<p :class="$style.labelValueRow">
  <span>{{label}}:</span>{{value}}
</p>
</template>

<script>
export default {
  name: "LabelValueRow",
  props: ['label', 'value']
}
</script>

<style module lang="css">
.labelValueRow:not(:last-child) {
  margin-bottom: 2px;
}
.labelValueRow span {
  display: inline-block;
  color: var(--gray-text);
  margin-right: 8px;
}
</style>