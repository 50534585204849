<template>
  <div :class="[style.resumeListItem, $style.cardListItemSkeleton]">
    <div :class="style.resumeListItemHeader">
      <div :class="style.listItemAvatar">
        <skeleton variant="image"/>
        <div :class="style.listItemRate" v-if="type === 'hr'">
          <div :class="style.rate">
            <rekroo-icon icon="star" color="var(--bright-yellow)" width="14px"/>
            <skeleton variant="text" width="50px" height="14px"/>
          </div>
          <dot color="var(--primary)"/>
          <skeleton variant="text" width="50px" height="14px"/>
        </div>
      </div>
      <div :class="style.resumeListItemInfoHeader">
        <div :class="[style.resumeListItemInfoHeaderBefore, {[style.hrHeaderBefore]: type==='hr'}]">
          <div :class="style.resumeListItemTitle">
            <skeleton variant="h3" width="100%" max-width="250px" height="var(--headline_3)" style="margin-bottom: 4px"/>
          </div>
          <skeleton variant="p" width="175px" style="display: block; margin-bottom: 4px"/>
          <div :class="[style.listItemRate, style.listItemRateMobile]" v-if="type === 'hr'">
            <div :class="style.rate">
              <rekroo-icon icon="star" color="var(--bright-yellow)" width="14px"/>
              <skeleton variant="text" width="50px" height="14px"/>
            </div>
            <dot color="var(--primary)"/>
            <skeleton variant="text" width="50px" height="14px"/>
          </div>
          <skeleton variant="p" width="175px"/>
        </div>
        <skeleton :class="style.salaryTag" variant="button" width="175px" height="40px"/>
      </div>
    </div>
    <div :class="style.resumeListItemInfo">
      <div :class="style.prevJob" v-if="type === 'candidate'">
        <div :class="style.prevJobLabel">Последнее место работы:</div>
        <div :class="style.prevJobTitle">
          <skeleton variant="text" width="100%" height="var(--body)"/>
        </div>
        <div :class="style.prevJobSubtitle">
          <skeleton variant="text" width="200px" height="var(--body)"/>
        </div>
      </div>
      <p :class="style.listItemText" v-if="type === 'hr'">
        <skeleton variant="p" width="100%"/>
      </p>
    </div>
    <div :class="style.skills">
      <skeleton :class="style.salaryTag" variant="button" width="175px" height="40px"/>
      <skeleton :class="style.salaryTag" variant="button" width="175px" height="40px"/>
      <skeleton :class="style.salaryTag" variant="button" width="175px" height="40px"/>
    </div>
  </div>
</template>

<script>
import style from "@/components/styles/PersonCardListItemStyle.module.css"
import RekrooIcon from "@/components/Icon";
import Dot from "@/components/Dot";
import Skeleton from "@/components/skeletons/Skeleton";

export default {
  name: "CardListItemSkeleton",
  components: {Skeleton, Dot, RekrooIcon},
  props: ['type'],
  data: ()=>({
    style,
  })
}
</script>

<style module lang="css">
.cardListItemSkeleton {}
</style>