<template>
  <div :class="$style.header" class="header">
    <router-link to="/">
      <img src="/img/logo.svg" alt="main-logo" :class="$style.headerLogo">
    </router-link>
    <ul :class="$style.menu">
      <li :class="[{[$style.active]: $route.path.split('/').includes('applicant')}]">
        <router-link to="/applicant">Соискателям</router-link>
      </li>
      <li :class="[{[$style.active]: $route.path.split('/').includes('company')}]">
        <router-link to="/company">Работодателям</router-link>
      </li>
      <li :class="[{[$style.active]: $route.path.split('/').includes('hr')}]">
        <router-link to="/hr">Рекрутерам</router-link>
      </li>
      <li :class="[{[$style.active]: $route.path.split('/').includes('hrm')}]">
        <router-link to="/hrm">HRM</router-link>
      </li>
      <li :class="[{[$style.active]: $route.path.split('/').includes('rates')}]">
        <router-link to="/rates">Тарифы</router-link>
      </li>
      <li v-if="+$store.state.user.admin" :class="[{[$style.active]: $route.path.split('/').includes('crm')}]">
        <router-link to="/crm">Админка</router-link>
      </li>
    </ul>
    <div :class="$style.headerButtons">
      <div v-if="$store.state.systemParams.contacts" :class="$style.headerContactsButtons">
        <a :href="$store.state.systemParams.contacts?.telegram" target="_blank">
          <rekroo-button type="text" text_color="var(--primary)">
            <img src="/img/icons/icon_telegram_black.svg" alt="" width="16" style="margin-right: 8px;">
          </rekroo-button>
        </a>
        <a :href="`tel: ${$store.state.systemParams.contacts?.phone}`">
          <rekroo-button type="text" text_color="var(--primary)">{{
              $store.state.systemParams.contacts?.phone
            }}
          </rekroo-button>
        </a>
      </div>
      <!--      <div :class="$style.actionButtons">
              <router-link :to="`/${['company'].includes($route.path.split('/')[1]) ? 'company' : 'hr'}/vacancies/create`"
                           v-if="['company', 'hr'].includes($route.path.split('/')[1]) || $route.path === '/'">
                <rekroo-button type="outlined"
                               @click=" user.id ? '' : $refs.auth_dialog.open()">Добавить вакансию
                </rekroo-button>
              </router-link>
              <router-link to="/applicant/resumes/create"
                           v-if="$route.path.split('/').includes('applicant') || $route.path === '/'">
                <rekroo-button @click=" user.id ? '' : $refs.auth_dialog.open()">
                  Создать резюме
                </rekroo-button>
              </router-link>
            </div>-->
      <div :class="$style.profileButtons">
        <el-dropdown trigger="click" :class="$style.menuDropdown">
          <rekroo-button :class="$style.profileButton"
                         icon="menu" icon_color="var(--primary)" color="var(--light-gray)" width="46px" height="46px"
                         icon_width="24px"/>
          <el-dropdown-menu slot="dropdown">
            <div :class="$style.menuLinks">
              <el-dropdown-item v-for="(label, key) in links" :key="key">
                <router-link :to="`/${key}`">
                  <rekroo-button type="text" text_color="var(--primary)" width="100%"
                                 icon_pos="end" icon="chevron-right" icon_color="var(--gray-text)"
                                 icon_style="margin-left: auto"
                  >{{ label }}
                  </rekroo-button>
                </router-link>
              </el-dropdown-item>
            </div>
            <el-dropdown-item :class="$style.menuDropdownActions">
              <router-link
                  v-if="user.id && (['company', 'hr'].includes($route.path.split('/')[1]) || $route.path === '/')"
                  :to="`/${['company'].includes($route.path.split('/')[1]) ? 'company' : 'hr'}/vacancies/create`">
                <rekroo-button color="var(--blue)" width="100%" text_color="var(--primary)"
                >Добавить вакансию
                </rekroo-button>
              </router-link>
              <rekroo-button v-else-if="['company', 'hr'].includes($route.path.split('/')[1]) || $route.path === '/'"
                             color="var(--blue)" width="100%" text_color="var(--primary)"
                             @click="$store.state.eventBus.$emit('auth-error')">Добавить вакансию
              </rekroo-button>
              <router-link to="/applicant/resumes/create"
                           v-if="user.id && ($route.path.split('/').includes('applicant') || $route.path === '/')">
                <rekroo-button color="var(--blue)" width="100%" text_color="var(--primary)">Создать резюме
                </rekroo-button>
              </router-link>
              <rekroo-button v-else-if="$route.path.split('/').includes('applicant') || $route.path === '/'"
                             @click="$store.state.eventBus.$emit('auth-error')"
                             color="var(--blue)" width="100%" text_color="var(--primary)">Создать резюме
              </rekroo-button>
            </el-dropdown-item>
            <el-dropdown-item>
              <div :class="$style.headerMenuContactsLabel">Тех. поддержка</div>
              <a :href="$store.state.systemParams.contacts?.telegram" target="_blank"
                 style="display: block;margin-bottom: 8px">
                <rekroo-button type="outlined" width="100%">
                  <img src="/img/icons/icon_telegram_black.svg" alt="" width="16" style="margin-right: 8px;">
                  Telegram
                </rekroo-button>
              </a>
              <a :href="`tel: ${$store.state.systemParams.contacts?.phone}`">
                <rekroo-button type="outlined" width="100%">{{
                    $store.state.systemParams.contacts?.phone
                  }}
                </rekroo-button>
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <router-link v-if="user.id" to="/profile">
          <candidate-avatar v-if="user.image && user.image.url" :img="user.image.url" size="46" :circle="true"
                            style="cursor: pointer;"/>
          <rekroo-button v-else :class="$style.profileButton"
                         icon="profile" icon_color="var(--primary)" color="var(--light-gray)" width="46px" height="46px"
                         icon_width="24px"/>
        </router-link>
        <rekroo-button v-else-if="!userLoading" @click="openAuthDialog">Войти</rekroo-button>
      </div>
    </div>
    <AuthDialog ref="auth_dialog" @on-success-auth="$emit('on-success-auth')"/>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
import AuthDialog from "@/dialogs/auth/AuthDialog";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import CandidateAvatar from "@/components/CandidateAvatar";
import RekrooIcon from "@/components/Icon";

export default {
  name: "TheHeader",
  props: ['userLoading'],
  emits: ['on-success-auth'],
  components: {RekrooIcon, CandidateAvatar, ConfirmDialog, AuthDialog, RekrooButton},
  data: () => ({
    links: {
      applicant: 'Соискателям',
      company: 'Работодателям',
      hr: 'Рекрутерам',
      hrm: 'HRM',
      rates: 'Тарифы',
    },
  }),
  methods: {
    openAuthDialog(data) {
      this.$refs.auth_dialog.open(data);
    },
  },
  watch: {},
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
  },
  created() {
    this.$eventBus.$on('open-auth-modal', () => {
      this.$refs.auth_dialog.open();
    })
  }
}
</script>

<style lang="css" module>
.header {
  display: flex;
  align-items: center;
  height: 82px;
}

.header .headerLogo {
  margin-right: 60px;
}

.header .menu {
  display: flex;
  align-items: center;
  list-style: none;
  column-gap: 36px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.header .menu li.active {
  color: var(--bright-blue);
  border-bottom: 1px solid var(--bright-blue);
}

.header .headerButtons {
  display: flex;
  margin-left: auto;
}

.header .headerButtons .actionButtons, .headerContactsButtons {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-right: 16px;
}

.header .headerButtons .profileButtons {
  display: flex;
  align-items: center;
}

.header .headerButtons .profileButtons .profileButton {
  border-radius: 50%;
}

.header .headerButtons .profileButtons .menuDropdown {
  display: none;
}

.menuProfileLinks {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light-gray);
}

@media screen and (max-width: 1270px) {
  .header .headerButtons .actionButtons, .headerContactsButtons {
    display: none;
  }

  .headerMenuContactsLabel {
    margin-bottom: 8px;
  }

  .header .headerButtons .profileButtons {
    column-gap: 8px;
  }

  .header .headerButtons .profileButtons .menuDropdown {
    display: block;
  }

  .menuLinks {
    display: none;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--light-gray);
  }

  .menuDropdownActions {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    height: 74px;
  }

  .headerLogo {
    height: 30px;
  }

  .header .menu {
    display: none;
  }

  .header .headerButtons .actionButtons {
    display: none;
  }

  .header .headerButtons .profileButtons .menuDropdown {
    display: block;
  }

  .menuLinks {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .header {
    height: 76px;
  }
}
</style>
