<template>
  <div class="page" :class="style.orderPage">
    <h1 :class="style.orderTitle">Ваш заказ</h1>
    <link-back v-if="order.id && redirect_url"
               :text="order.type.includes('resume') ? 'Вернуться к резюме' : 'Вернуться к вакансии'"
               :path="redirect_url"/>
    <link-back v-else text="Назад" :path="redirect_url"/>

    <order-skeleton v-if="loading"/>
    <div v-else :class="style.orderOuter">
      <div :class="style.orderInner">
        <div :class="style.orderType">{{ order.title }}</div>
        <div :class="[style.orderParam]" v-if="order.type && order.type.includes('vacancy')">
          {{ thousandSeparator(order.price) }}
          {{ rub }} x {{ order.amount_total }}
          <span>&nbsp;{{ getNoun(order.amount_total, 'вакансия', 'вакансии', 'вакансий') }}</span>
          <div :class="style.totalPrice">{{ thousandSeparator(order.price * order.amount_total) }} {{ rub }}</div>
        </div>
        <div :class="[style.orderParam]" v-else>
          {{ thousandSeparator(order.price) }} {{ rub }} x 1
          <div :class="style.totalPrice">{{ thousandSeparator(order.price) }} {{ rub }}</div>
        </div>
        <div :class="style.orderPrice">К оплате:
          {{
            order.type && order.type.includes('vacancy') ? thousandSeparator(order.price * order.amount_total) : thousandSeparator(order.price)
          }}
          {{ rub }}
          <tag v-if="order.datetime_to" :class="style.payedTag" text="Активировано" icon="check" icon_pos="start"
               icon_color="var(--green)" color="#FFF"/>
          <tag v-else-if="order.payed" :class="style.payedTag" text="Оплачено" icon="check" icon_pos="start"
               icon_color="var(--green)" color="#FFF"/>
        </div>
        <a v-if="order.payed === 0" :href="order.paymentLink">
          <rekroo-button width="100%">Оплатить</rekroo-button>
        </a>
        <rekroo-button v-else-if="order.datetime_to === 0 && order.type.includes('resume')"
                       :loading="activating"
                       @click="activate" width="100%">Активировать
        </rekroo-button>
        <span :class="style.datePayExpire"
              v-if="!order.payed">Можно оплатить до {{ dateParse(order.datetime_expire, 'D MMMM, YYYY') }}</span>
      </div>
    </div>
    <PaymentDialog ref="payment_dialog" @success="get(true)"/>
  </div>
</template>

<script>
import {Order} from "@/api/order";
import RekrooButton from "@/components/Button";
import PaymentDialog from "@/dialogs/PaymentDialog";
import Tag from "@/components/Tag";
import style from "@/views/rates/styles/OrderStyle.module.css"
import OrderSkeleton from "@/components/skeletons/OrderSkeleton";
import LinkBack from "@/components/LinkBack";

export default {
  name: "Order",
  components: {LinkBack, OrderSkeleton, Tag, PaymentDialog, RekrooButton},

  data: () => ({
    style,
    orderClass: new Order({}),
    order: new Order({}),
    loading: false,
    activating: false,
  }),
  methods: {
    get(after_success_payment = false) {
      this.loading = true;
      this.orderClass.get(this.$route.params.id).then((data) => {
        this.order = data.response;
        this.loading = false;
        if (after_success_payment) {
          this.$store.commit('setOrderPayedSuccessful', true);
          /*let query = this.$route.query;
          if (query.redirect_url) delete query.redirect_url;
          let path = `/${this.getValue('role', 'company')}/${this.order.type.includes('resume') ? 'catalog' : 'vacancies?t=not_publish'}`
          this.$router.push({
            path: this.redirect_url || path,
            query
          });*/
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    /*pay() {
      this.$refs.payment_dialog.open(this.order.paymentLink);
    },*/
    activate() {
      this.activating = true;
      this.orderClass.activate(this.order.id).then(() => {
        this.activating = false;
        this.get();
      }).catch(() => {
        this.activating = false;
      })
    }
  },
  computed: {
    rub() {
      return this.$store.state.systemParams.currencies && this.$store.state.systemParams.currencies.rub ? this.$store.state.systemParams.currencies.rub.short : '';
    },
    redirect_url() {
      return this.$route.query?.redirect_url;
    }
  },
  mounted() {
    this.get()
  }
}
</script>

<style module lang="css">
</style>