<template>
  <el-dialog width="500px" :visible.sync="isVisible" custom-class="rekroo-dialog schedule-send-dialog" :center="true">
    <div class="dialog-title" :class="$style.title">Отправить e-mail рассылку</div>
    <div :class="$style.datetimeContainer">
      <el-date-picker v-model="schedule.date" type="date" format="dd.MM.yyyy" lang="ru"
                      placeholder="ДД.ММ.ГГГГ" class="no-prefix gray-bg" value-format="yyyy-MM-dd"
                      v-maska="'##.##.####'"/>
      <el-time-select
          v-model="schedule.time"
          v-maska="'##:##'"
          :picker-options="{start: '00:00',step: '00:15',end: '23:45'}"
          placeholder="Выберите время"
          class="gray-bg">
      </el-time-select>
    </div>
    <div :class="$style.actions">
      <rekroo-button :loading="loading && !scheduleClick" height="50px" width="100%" @click="send()">Отправить сразу</rekroo-button>
      <rekroo-button :loading="loading && scheduleClick" height="50px" width="100%" type="outlined" @click="send(true)">
        Запланировать отправку
      </rekroo-button>
    </div>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import {Mailing_list_item} from "@/api/mailing_list_item";

export default {
  name: "ScheduleSendDialog",
  components: {RekrooButton},
  emits: ['open-tariff', 'sent'],
  data: () => ({
    mailingListClass: new Mailing_list_item({}),
    isVisible: false,
    schedule: {},
    id: null,
    loading: false,
    scheduleClick: false,
  }),
  methods: {
    open(id) {
      this.id = null;
      this.schedule = {};
      this.isVisible = true;
      this.id = id;
    },
    send(schedule = false) {
      this.scheduleClick = schedule;
      this.loading = true;
      let datetime_schedule = '';
      if (schedule) {
        datetime_schedule = this.$moment(`${this.schedule.date}T${this.schedule.time}`).unix();
      }
      this.mailingListClass.send(this.id, datetime_schedule).then(()=>{
        this.loading = false;
        this.isVisible = false;
        this.$notify({
          type: 'success',
          message: schedule ? 'Отправка успешно запланирована' : 'Рассылка успешно отправлена'
        });
        this.$emit('sent');
      }).catch(data=>{
        this.loading = false;
        if (data.error?.type === 'tariff') {
          this.openTariff();
        }
      })
    },
    openTariff() {
      this.$emit('open-tariff')
    },
    save(tariff) {
      this.mailingListClass.create({tariff, id: this.id}).then(()=>{
        this.send(this.schedule.date && this.schedule.time);
      })
    }
  }
}
</script>

<style module lang="css">
.title {
  margin-bottom: 32px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.datetimeContainer {
  display: flex;
  column-gap: 8px;
  margin-bottom: 32px;
}
</style>