<template>
  <el-upload  multiple
              :show-file-list="false" :with-credentials="true"
              :on-success="onSuccess"
              :action="`${itemClass.url}files/upload`" :data="{parse: 1, type: 'candidate'}"
              accept="application/pdf, application/doc">
    <template>
      <div :class="[$style.uploadResume, customClass]">
        <div :class="$style.uploadResumeContent">
          <div :class="$style.uploadResumeTitle">Загрузить резюме</div>
          <div :class="$style.uploadResumeSubtitle"><span>Выберите</span> или перетащите сюда файлы резюме в формате pdf или doc</div>
        </div>
        <div :class="$style.iconContainer" :style="`background: ${iconColor || 'var(--yellow)'}`">
          <rekroo-icon icon="share"/>
        </div>
      </div>
    </template>
  </el-upload>
</template>

<script>
import {Item} from "@/api/item";
import RekrooIcon from "@/components/Icon";

export default {
  name: "UploadResumeBlock",
  props: ['iconColor', 'customClass'],
  components: {RekrooIcon},
  data() {
    return {
      itemClass: new Item({}),
    }
  },
  methods: {
    onSuccess(data) {
      this.$router.push({path: '/applicant/resumes/create', query: {...data.response}})
    }
  }
}
</script>

<style module>

.uploadResume {
  position: absolute;
  bottom: 90px;
  right: 60px;
  left: 55px;
  background: #FFFFFF;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  width: 100%;
  column-gap: 8px;
}

.uploadResume .uploadResumeTitle {
  font-size: var(--body);
  font-weight: 500;
  margin-bottom: 4px;
  text-align: left;
}

.uploadResume .uploadResumeSubtitle {
  font-size: var(--small-text);
  color: var(--gray-text);
  text-align: left;
}

.uploadResume .uploadResumeSubtitle span {
  color: var(--bright-blue);
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .uploadResume {
    left: 50px;
    bottom: 32px;
    max-width: 350px;
    width: 100%;
  }
}

@media screen and (max-width: 575px ) {
  .uploadResume {
    left: 32px;
    right: 22px;
    bottom: 38px;
    max-width: 100%;
    width: auto;
  }
  .uploadResume .uploadResumeSubtitle {
    font-size: var(--tiny-text);
  }
  .iconContainer {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }
}
</style>