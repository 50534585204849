<template>
  <el-dialog :visible.sync="isVisible" width="427px" custom-class="rekroo-dialog subscribe-dialog" :center="true">
    <div class="headline-3 text-center" :class="$style.subscribeDialogTitle">Введите email
      для&nbsp;получения&nbsp;чека
    </div>
    <el-form :model="data" :rules="rules" ref="form">
      <el-form-item prop="email">
        <el-input placeholder="Email" v-model="data.email" :class="$style.emailInput" auto-complete="email"/>
      </el-form-item>
    </el-form>
    <rekroo-button :class="$style.primaryButton" width="100%" @click="update" :loading="loading">К оплате</rekroo-button>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import {User} from "@/api/user";

export default {
  name: "SubscribeDialog",
  components: {RekrooButton},
  data: () => ({
    userClass: new User({}),
    isVisible: false,
    data: {
      email: ''
    },
    loading: false,
    rules: {
      email: [
        {required: true, type: 'email', message: 'Email указан неверно', trigger: 'blur'},
      ],
    }
  }),
  methods: {
    open() {
      this.isVisible = true;
      this.data = {email: ''};
      setTimeout(()=>{
        this.$refs.form.resetFields();
      })
    },
    update() {
      this.data.id = this.$store.state.user.id;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.userClass.create(this.data).then((data) => {
            this.loading = false;
            this.$store.commit('setUser', data.response);
            this.isVisible = false;
            this.$store.state.eventBus.$emit('user-email-added');
          }).catch(() => {
            this.loading = false;
          })
        }
      })
    }
  }
}
</script>

<style module lang="css">
.subscribeDialogTitle {
  margin-bottom: 24px;
}

.emailInput {
}

.emailInput input {
  background: var(--light-gray);
}
.primaryButton {
  margin-top: 28px;
}
</style>