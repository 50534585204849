<template>
  <div :class="style.landingHrPage">
    <section :class="$style.hrLandingHeader">
      <div :class="$style.hrLandingHeaderDescription">
        <img :class="$style.hrLandingHeaderArrow" src="/img/icons/icon_curve_arrow.svg" alt="">
        <div :class="$style.hrLandingHeaderTitle">Закрыть потребность работодателя быстро с&nbsp;нами просто</div>
        <div :class="$style.hrLandingHeaderSubtitle">Лучшие кандидаты. Актуальные обновляемые базы резюме. Все в одном
          месте
        </div>
        <rekroo-button :class="$style.hrLandingHeaderButton" width="100%" height="50px"
                       @click="$store.state.eventBus.$emit('auth-error')">
          Присоединиться к Rekroo
        </rekroo-button>
        <a href="#candidateListSection" :class="$style.hrLandingHeaderNavigateToBottomButton">Как тебе поможет Rekroo
          <rekroo-icon icon="arrow-bottom" style="margin-left: 12px" width="14px"/>
        </a>
      </div>
      <div :class="$style.hrLandingHeaderImageContainer">
        <img src="/img/hr_landing/hr_landing_header_image.png" alt="" :class="$style.hrLandingHeaderImage">
        <a href="#candidateListSection" :class="$style.hrLandingHeaderNavigateToBottomButton">Как тебе поможет Rekroo
          <rekroo-icon icon="arrow-bottom" style="margin-left: 12px" width="14px"/>
        </a>
      </div>
    </section>

    <intro-block title="Как с нами зарабатывать больше?" class="hrm_intro"/>
    <section :class="[style.hrmSection, $style.hrmSection]" class="hrm_section">
      <!--      <tag text="Как с нами зарабатывать больше?" color="var(&#45;&#45;green-active)" text_color="var(&#45;&#45;primary)"
                 :class="$style.sectionTag"/>-->
      <h1 :class="[style.hrmSectionTitle, $style.hrmSectionTitle]">1. Своя удобная <span>HRM-система</span></h1>
      <div :class="style.hrmSectionTabs">
        <rekroo-button v-for="(tab, i) in hrmTabs" :key="i"
                       :color="hrmActiveTab === i ? 'rgba(206, 217, 227, 0.6)' : '#FFF'"
                       :text_color="hrmActiveTab === i ? 'var(--bright-blue)' : 'var(--gray-text)'"
                       @click="hrmActiveTab = i"
        >{{ tab.name }}
        </rekroo-button>
      </div>
      <div :class="style.hrmSectionTabContent">
        <div :class="style.tabDescription">
          <div :class="style.tabDescriptionTitle">{{ hrmTabs[hrmActiveTab].title }}</div>
          <div :class="style.tabDescriptionText">
            <p v-for="(text, i) in hrmTabs[hrmActiveTab].text" :key="i">{{ text }}</p>
          </div>
          <rekroo-button width="100%" height="50px" :class="style.tabDescriptionButton"
                         icon="arrow-bottom" icon_pos="end" icon_style="transform: rotate(-90deg); margin-left: 15px;"
                         icon_color="#FFF" @click="$store.state.eventBus.$emit('auth-error')"
          >Перейти к HRM-системе
          </rekroo-button>
        </div>
        <img :src="hrmTabs[hrmActiveTab].img" alt="" :class="style.hrmImage">
      </div>
    </section>
    <landing-reviews-section
        :class="$style.hrmReviews"
        :title="`Они уже попробовали работать в HRM,<br>попробуй и ты <span style='color: var(--bright-blue)'>5 дней бесплатно</span>`"/>
    <section :class="style.consultationSection">
      <div :class="style.consultationDescription">
        <h1 :class="style.consultationSectionTitle">
          <span>2. Консультируй и зарабатывай.</span><br>
          Помогай соискателям получить оффер мечты
        </h1>
        <p :class="[style.consultationSectionSubtitle, $style.consultationSectionSubtitle]">Проводи консультации,
          помогай составить грамотное резюме и
          спопроводительное письмо. Помогай улучшить вакансию, скрипт телефонного и очного собеседования. Составь
          алгоритм эффективной адаптации, для устранения текучки кадров внутри компании</p>
        <router-link to="/hr/auction">
          <rekroo-button height="50px" width="100%">Выбрать заявку на консультацию</rekroo-button>
        </router-link>
      </div>
      <img src="/img/hr_landing/consultation_image.png" alt="" :class="style.consultationImage">
    </section>
    <section :class="style.mailingSection">
      <img src="/img/hr_landing/mailing_image.png" alt="" :class="style.mailingImage">
      <div :class="style.mailingDescription">
        <h1 :class="style.mailingSectionTitle">
          <span>3. Рассылка<br>по базе резюме.</span><br>
          Сократи время и усилия, затрачиваемые на поиск кандидатов
        </h1>
        <p :class="[style.mailingSectionSubtitle, $style.mailingSectionSubtitle ]">Отправляйте предложения о вакансиях
          тысячам кандидатов одновременно.
          Вы можете выбрать нужные критерии, такие как профессиональные навыки, опыт работы, уровень образования, чтобы
          предложение о вакансии было доставлено только квалифицированным кандидатам.</p>
        <rekroo-button height="50px" width="100%">Создать рассылку</rekroo-button>
      </div>
    </section>

    <intro-block title="Работа с имеющимися заказчиками" class="candidates_intro"/>
    <landing-candidates-database-section class="candidates_section"
        title_1="Лучшие" title_2="кандидаты уже с Rekroo"
                                         subtitle="Выбирайте лучших кандидатов прямо из&nbsp;нашей актуальной и&nbsp;живой
      базы резюме, без&nbsp;необходимости переходить на&nbsp;сторонние сервисы" title_2_color="var(--bright-blue)"
                                         link="/hr/catalog" :class="$style.candidatesDatabase">
<!--      <template #before>
        <tag text="Работа с текущими заказчиками" color="var(&#45;&#45;green-active)" text_color="var(&#45;&#45;primary)"
             :class="$style.sectionTag"/>
      </template>-->
      <template>
        <router-link to="/hr/catalog" :class="$style.candidatesDatabaseButton">
          <rekroo-button
              height="50px" width="100%">Подберите лучших
          </rekroo-button>
        </router-link>
      </template>
    </landing-candidates-database-section>
    <intro-block title="Работа с новыми заказчиками" class="auction_intro"/>

    <section :class="[style.auctionSection, $style.auctionSection]" class="auction_section">
<!--      <tag text="Работа с новыми заказчиками" color="var(&#45;&#45;green-active)" text_color="var(&#45;&#45;primary)"-->
<!--           :class="$style.sectionTag"/>-->
      <h1 :class="style.auctionSectionTitle"><span>Аукцион с работодателем.</span><br>
        Ищите клиентов прямо на портале</h1>
      <p :class="style.auctionSectionSubtitle">Работодатель добавляет вакансию с оплатой за кандидата, вы находите
        работников за одноразовое вознаграждение от работодателя</p>
      <div :class="style.auctionSteps">
        <img src="/img/hr_landing/dashed_curve.png" alt="" :class="style.dashedCurve">
        <div :class="style.auctionStep" v-for="(step, i) in auctionSteps" :key="i">
          <div :class="style.auctionStepIcon">
            <img :src="step.img" alt="">
          </div>
          <div :class="style.auctionStepLabel" v-html="step.label"/>
        </div>
      </div>
      <router-link to="/hr/auction">
        <rekroo-button
            width="426px" height="50px" :class="style.auctionSectionButton">Найти заказ
        </rekroo-button>
      </router-link>
    </section>
    <section :class="[style.vacanciesSection, $style.vacanciesSection]">
      <h1 :class="style.vacanciesSectionTitle"><span>Актуальные живые вакансии,</span><br>обновляющиеся ежедневно</h1>
      <p :class="style.vacanciesSectionSubtitle">Работодатель добавляет вакансию с оплатой за кандидата, вы находите
        работников за одноразовое вознаграждение от работодателя</p>
      <landing-vacancies :vacancies="vacancies" link="/hr/auction/"/>
      <router-link to="/hr/vacancies" :class="style.moreVacanciesButton">
        <rekroo-button width="100%" height="50px">Больше вакансий</rekroo-button>
      </router-link>
    </section>
    <section :class="style.safetySection">
      <div :class="style.safetySectionHint">
        <div :class="style.safetySectionHintLabel">
          Что мы для этого<br>используем?
        </div>
        <img src="/img/hr_landing/curve_dashed_arrow.svg" alt="" :class="style.safetySectionHintArrow">
      </div>
      <div :class="style.safetySectionHeader">
        <div :class="style.safetyDesc">
          <h1 :class="style.safetySectionTitle"><span>Безопасность и защита.</span><br>Все данные только у вас</h1>
          <p :class="style.safetySectionSubtitle">Мы трепетно относимся к вашим персональным данным — поэтому прилагаем
            все усилия, чтобы обеспечить высокий уровень безопасности и надежности.</p>
        </div>
        <img src="/img/hr_landing/safety_tools_image.png" alt="" :class="style.safetyToolsImage">
      </div>
      <div :class="style.safetyTools">
        <div :class="style.safetyToolOuter" v-for="(tool, i) in safetyTools" :key="i">
          <img src="/img/hr_landing/folder_top.svg" alt="" :class="style.safetyToolTopFolder">
          <div :class="style.safetyTool">
            <div :class="style.safetyToolTitle">{{ tool.title }}</div>
            <div :class="style.safetyToolText">{{ tool.text }}</div>
          </div>
        </div>
      </div>
    </section>
    <landing-reviews-section :title="`Они уже попробовали Rekroo<br>и нашли своих сотрудников`"/>
  </div>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import LandingCandidatesDatabaseSection from "@/views/landing/components/LandingCandidatesDatabaseSection";
import LandingVacancies from "@/views/landing/components/LandingVacancies";
import LandingReviewsSection from "@/views/landing/components/LandingReviewsSection";
import IntroBlock from "@/views/landing/components/IntroBlock";
import Tag from "@/components/Tag";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "HRLanding_v2",
  components: {
    Tag,
    IntroBlock,
    LandingReviewsSection, LandingVacancies, LandingCandidatesDatabaseSection, RekrooIcon, RekrooButton
  },
  data: () => ({
    style,
    hrmActiveTab: 0,
    hrmTabs: [
      {
        name: 'Импорт резюме',
        title: 'Загружайте базы\n' +
            'резюме из папки или со сторонних сервисов с автозаполнением полей',
        text: ['За раз можно загрузить до 500 штук. с ИИ, который автозаполняет поля (имя, фамилию, должность и тд).',
          'Все резюме сохраняются, по ним работает поиск. Вся ваша база резюме в одном месте с возможностью быстро найти прошлого кандидата и посмотреть историю взаимодействия'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_1.png',
      },
      {
        name: 'Аналитика и статистика',
        title: 'Удобная аналитика\n' +
            'и статистика ',
        text: ['Система Rekroo собирает данные по вакансиям, отдельным кандидатам, финансам, эффективности рекрутинга и коэффициентах принятия офферов в удобные графики и диаграммы.',
          'Вам больше не придется сводить их вручную в Excel.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_2.png',
      },
      {
        name: 'Сценарии собеседований',
        title: 'Готовые или авторские скрипты для собеседований',
        text: ['Используйте отдельные шаблоны по всем ключевым вакансиям.',
          'Вам больше не нужно тратить время на составление вопросов перед собеседованием – просто добавьте свои комментарии к готовому сценарию.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_3.png',
      },
      {
        name: 'Воронка вакансий',
        title: 'Воронка вакансий,\n' +
            'где нельзя ничего забыть',
        text: ['Отслеживайте сколько в среднем времени ушло на закрытие вакансии, сколько было кандидатов на каждом этапе.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_4.png',
      },
      {
        name: 'Полная база кандидатов',
        title: 'Кандидаты всегда в поле зрения',
        text: ['Оставляйте оценки и комментарии и возвращайтесь к ним в любой момент.',
          'Подробные профайлы, история взаимодействия, добавление в избранное, поиск по имени и вакансии, контроль выхода на работу – все как на ладони.'],
        img: '/img/hr_landing/hrm_tabs/hrm_tab_5.png',
      }
    ],
    auctionSteps: [
      {
        label: `Работодатели создают заказы&nbsp;—&nbsp;описывают задачу, детали и предлагают цену`,
        img: '/img/hr_landing/icon_colored_briefcase.svg',
      },
      {
        label: 'Вы выбираете интересные заказы, откликаетесь и договариваетесь с работодателем об условиях',
        img: '/img/hr_landing/icon_colored_woman.svg',
      },
      {
        label: 'Выполняете заказ и получаете оплату напрямую от работодателя',
        img: '/img/hr_landing/icon_colored_money.svg',
      },
    ],
    vacancies: [
      {
        company_info: {title: 'Рекламное агенство Нерв'},
        position: 'Менеджер по продажам и аккаунтингу',
        location: 'Санкт-Петербург',
        salary_from: 60000,
      },
      {
        company_info: {title: 'Яндекс Крауд'},
        position: 'Автор текстов в Поиск',
        salary_from: '',
        remote_work: 1
      },
      {
        company_info: {title: 'ИП Максим Андреюк'},
        position: 'Менеджер отдела продаж',
        location: 'Москва',
        salary_from: 40000,
        salary_to: 65000,
      },
    ],
    safetyTools: [
      {
        title: 'SSL-сертификат',
        text: 'Все передаваемые данные между вашим браузером и нашими серверами защищены сертификатом безопасности SSL',
      },
      {
        title: 'HTTPS-соединение',
        text: 'Обмен данных происходит по протоколу HTTPS, что обеспечивает конфиденциальность информации и ее защиту от третьих лиц',
      },
      {
        title: 'Являемся оператором персональных данных',
        text: 'Мы берём на себя ответственность за безопасность ваших данных в соответствии с законодательством',
      },
    ],
  }),
  methods: {
    setGsapIntro(el) {
      gsap.fromTo(`.${el}`,
          {
            scale: 1,
            backgroundColor: '#C7DEDD',
          },
          {
            scale: 0.3,
            backgroundColor: '#39C671',
            duration: 100,
            scrollTrigger: {
              trigger: `.${el}`,
              scrub: 1,
              start: 'top center',
              end: 'bottom center'
            }

          }
      )
    },
    setGsapSection(el) {
      gsap.fromTo(`.${el}`,
          {
            y: 0,
          }, {
            y: el === 'hrm_section' ? -200 : -150,
            scrollTrigger: {
              trigger: `.${el}`,
              scrub: 1,
              start: 'top bottom-=203',
              end: 'top center',
            }
          })
    }
  },

  mounted() {
    let intros = ['hrm_intro', 'candidates_intro', 'auction_intro'];
    let sections = ['hrm_section', 'candidates_section', 'auction_section'];
    for (let i = 0; i <=2; i++) {
      this.setGsapIntro(intros[i]);
      this.setGsapSection(sections[i]);
    }
  }
}
</script>

<style module lang="css">
.hrLandingHeader {
  position: relative;
  margin-bottom: 64px;
  display: flex;
  padding: 92px 0 60px 80px;
  border-radius: 16px;
  background: var(--blue);
  overflow: visible;
}

.hrLandingHeaderDescription {
  max-width: 690px;
  background: transparent;
  position: relative;
}

.hrLandingHeaderTitle {
  margin-bottom: 24px;
  font-size: 64px;
  font-weight: 700;
}

.hrLandingHeaderSubtitle {
  max-width: 520px;
  margin-bottom: 64px;
  font-size: 24px;
  line-height: 140%;
}

.hrLandingHeaderButton {
  max-width: 424px !important;
  margin-bottom: 76px;
}

.hrLandingHeaderArrow {
  position: absolute;
  bottom: 60px;
  right: 96px;
}

.hrLandingHeaderImageContainer {
  min-width: 300px;
  width: 100%;
  position: relative;
}

.hrLandingHeaderImage {
  position: absolute;
  top: 0;
  right: -43px;
  max-width: 580px;
  width: 100%;
}

.hrLandingHeaderImageContainer .hrLandingHeaderNavigateToBottomButton {
  display: none;
}

.sectionTag {
  font-weight: 700;
  height: 54px;
  margin: 0 auto;
}

/*HRM SECTION*/
.hrmSection {
  margin-top: 60px;
  will-change: transform;
}

.hrmSection .sectionTag {
  margin-bottom: 40px;
}

.hrmSection .hrmSectionTitle {
  text-align: center;
}

.hrmReviews {
  transform: translateY(-100px);
  padding-top: 0;
}
.consultationSectionSubtitle, .mailingSectionSubtitle {
  margin-bottom: 36px;
}
.candidatesDatabase,.auctionSection {
  will-change: transform;
}
.candidatesDatabase, .auctionSection {
  padding-bottom: 0;
  margin-bottom: 0;
}

.vacanciesSection {
  padding-top: 0;
}

.candidatesDatabaseButton {
  max-width: 425px;
  width: 100%;
  margin-top: 64px;
}

@media screen and (max-width: 1275px) {
  .hrLandingHeaderTitle {
    font-size: 48px;
  }

  .hrLandingHeaderArrow {
    display: none;
  }
}
</style>
