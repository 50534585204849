<template>
<!--  <ApplicantLanding v-if="show_landing"/>-->
  <transition name="fade">
    <router-view/>
  </transition>
</template>

<script>
import ApplicantLanding from "@/views/landing/ApplicantLanding";
export default {
  name: "ApplicantView",
  components: {ApplicantLanding},
  methods: {
  },
  watch: {

  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    show_landing() {
      return !this.$store.state.user.id;
    }
  },
  mounted() {
  },
  created() {
    this.$eventBus.$on('logout', ()=>{
      this.$router.replace('/applicant').catch(()=>{});
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('logout');
  }
}
</script>

<style module lang="css">
.candidateViewContainer {}
</style>