<template>
  <div class="page" :class="style.catalogPage" :style="`padding-top: ${fromDialog ? 0 : ''}!important`">
    <div :class="style.catalogPageContent">
      <p :class="style.searchedCount" v-if="!fromDialog">{{ countString }}</p>
      <div :class="style.catalogPageContentInner" :style="`padding-top: ${fromDialog ? 0 : ''}!important`"
           ref="pageContent" id="list">
        <div :class="style.sideContent">
          <div v-if="systemParams?.resumeContactsTotal" :class="$style.remainingContainer">
            <items-remaining-block @open-resume-rates="openRatesDialog()"/>
          </div>
          <!--          <div v-if="!fromDialog" :class="style.vacancyPosting">
                      <div :class="style.vacancyPostingHeader">
                        <div class="headline-3" :class="style.vacancyPostingHeaderTitle">Лучшие таланты <br>уже нашли вас!</div>
                        <div :class="style.vacancyPostingHeaderIcon">
                          <img src="/img/icons/icon_heart.svg" alt="icon-heart">
                        </div>
                      </div>
                      <p :class="style.vacancyPostingText">Просмотрите резюме соискателей, которые уже выбрали вашу компанию в
                        качестве компании своей мечты</p>
                      <rekroo-button color="var(&#45;&#45;purple)" width="100%">Перейти</rekroo-button>
                    </div>-->
          <div :class="style.filters">
            <div :class="style.filtersHeader">
              <div :class="style.filtersHeaderTitle">Фильтры</div>
              <rekroo-button type="text" :class="style.filtersResetButton" @click="resetFilters"
                             text_color="var(--gray-text)">Сбросить все
              </rekroo-button>
            </div>
            <catalog-filters-form type="resume"
                                  :filters="filters" @apply="applyFilters"
                                  :without-button="true"
                                  @on-skill-add="val=>filters.skills.push(val)"/>
          </div>
        </div>
        <div :class="style.listContent">
          <div :class="style.searchInputContainer">
            <div :class="style.searchTitle">Кого вы ищете?
              <p :class="style.searchedCount" v-if="!fromDialog">{{ countString }}</p></div>
            <el-autocomplete
                :class="style.searchInput"
                placeholder="Должность" v-model="filters.q"
                :fetch-suggestions="fetchSuggestionsPos"
                :trigger-on-focus="false"
                @select="get"
                @keydown.native="search"
                clearable
            >
              <template #append>
                <rekroo-button icon="search" icon_color="white" width="50px" height="50px" icon_width="20px"
                               @click="search"/>
              </template>
            </el-autocomplete>
            <rekroo-button @click="$refs.resume_filters_dialog.open()"
                           :class="style.filterButton" icon="filter" width="100%" color="var(--gray)"
                           text_color="var(--primary)" icon_style="margin-right: 10px">Фильтры
            </rekroo-button>
            <div :class="style.appliedFilters">
              <tag v-if="filters.location" color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="filters.location = ''; get()" :text="filters.location"/>
              <tag v-if="filters.salary_from || filters.salary_to" color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="filters.salary_from = '';filters.salary_to = ''; get()"
                   :text="$store.state.systemParams.currencies[filters.currency] ? salaryParse(filters.salary_from, filters.salary_to, $store.state.systemParams.currencies[filters.currency].short) : ''"/>
              <tag v-if="filters.experience" color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="filters.experience = ''; get()"
                   :text="`От ${filters.experience} ${getGenitiveYear(filters.experience)}`"/>
              <tag color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="removeSkill(i); get()" v-for="(skill, i) in filters.skills" :key="skill"
                   :text="skill"/>
              <tag v-for="(lang, i) in filters.languages" :key="i"
                   :text="$store.state.systemParams.languageNames ? `${$store.state.systemParams.languageNames[Object.keys(lang)[0]]} - ${lang[Object.keys(lang)[0]]}` : ''"
                   icon="close" icon_pos="end" icon_width="16px"
                   color="var(--blue)" @onIconClick="removeLang(i)"/>
            </div>
          </div>
          <div :class="style.resumeList" v-if="loading">
            <card-list-item-skeleton v-for="i in 5" :key="i" type="candidate"/>
          </div>
          <div :class="style.resumeList" v-else-if="accessError">
            <empty-list-plug title="Доступ к базе резюме ограничен"
                             subtitle="Приобретите доступ, чтобы видеть все резюме"
                             :show-icon="true" button_text="Приобрести доступ" @onClick="openRatesDialog"
                             style="margin: 0 auto"/>
          </div>
          <div :class="style.resumeList" v-else-if="!itemsCount || !items.length">
            <empty-list-plug title="Резюме не найдены" subtitle="Попробуйте изменить параметры фильтров"
                             :show-icon="true"
                             style="margin: 0 auto"/>
          </div>
          <div :class="style.resumeList" v-else>
            <person-card-list-item v-for="item in items" :item="item" :key="item.id" type="candidate"
                                   :link="`/${$route.path.split('/')[1]}/catalog/${item.id.toString()}`"/>
          </div>
          <el-pagination
              hide-on-single-page
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :pager-count="innerWidth > 575 ? 5 : 3"
              :page-size="filters.pageLength"
              :current-page="currentPage"
              :total="itemsCount"/>
        </div>
      </div>
    </div>
    <ResumeFiltersDialog ref="resume_filters_dialog" :filters="filters" @apply="applyFilters" @reset="resetFilters"
                         type="resume"/>
    <ResumeRatesDialog ref="resume_rates_dialog"/>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
import Rates from "@/views/rates/ResumeRates";
import PersonCardListItem from "@/components/PersonCardListItem";
import ResumeFiltersDialog from "@/dialogs/ResumeFiltersDialog";
import CatalogFiltersForm from "@/views/company/catalog/components/CatalogFiltersForm";
import Tag from "@/components/Tag";
import {Resume} from "@/api/resume";
import CardListItemSkeleton from "@/components/skeletons/CardListItemSkeleton";
import EmptyListPlug from "@/components/EmptyListPlug";
import style from "@/views/company/catalog/styles/catalog.module.css"
import ResumeRatesDialog from "@/dialogs/ResumeRatesDialog";
import RekrooIcon from "@/components/Icon";
import ItemsRemainingBlock from "@/components/ItemsRemainingBlock";

export default {
  name: "ResumeList",
  components: {
    ItemsRemainingBlock,
    RekrooIcon,
    ResumeRatesDialog,
    EmptyListPlug,
    CardListItemSkeleton,
    Tag, CatalogFiltersForm, ResumeFiltersDialog, PersonCardListItem, Rates, RekrooButton
  },
  props: ['fromDialog'],
  emits: ['countString-updated'],
  data() {
    return {
      style,
      resumeClass: new Resume({}),
      items: [],
      itemsCount: 0,
      filters: {
        q: '',
        pageLength: 10,
        pageNumber: 0,
        employments: [],
        schedules: [],
        driver_licence: [],
        currency: 'rub',
        skills: [],
        languages: [],
        location: '',
        salary_from: '',
        salary_to: '',
        experience: '',
        educational_levels: [],
        image: 0
      },
      defaultFilters: {
        q: '',
        pageLength: 10,
        employments: [],
        schedules: [],
        driver_licence: [],
        currency: 'rub',
        skills: [],
        languages: [],
        location: '',
        salary_from: '',
        salary_to: '',
        experience: '',
        educational_levels: [],
        image: 0
      },
      appliedFilterLabels: [],
      loading: true,
      accessError: false,
      countString: '',
      innerWidth: window.innerWidth,
      onChangeFilters: this.debounceFn(this.applyFilters, 500),
      onChangeFiltersCount: 0,
    }
  },
  methods: {
    applyFilters(filters = null) {
      if (filters) this.filters = {...filters};
      this.addFiltersToUrl();
      this.get();
    },
    resetFilters() {
      this.filters = {...this.defaultFilters};
      this.defaultFilters = {
        q: '',
        pageLength: 10,
        employments: [],
        schedules: [],
        currency: 'rub',
        skills: [],
        languages: [],
        location: '',
        salary_from: '',
        salary_to: '',
        experience: '',
        driver_licence: [],
        educational_levels: [],
        image: 0
      }
      this.appliedFilterLabels = [];
      this.addFiltersToUrl();
      this.get();
    },
    addFiltersToUrl() {
      let urlFilters = {};
      for (let key in this.filters) {
        if (this.defaultFilters[key] !== this.filters[key] && this.filters[key] && this.filters[key] !== '0')
          if (key === 'languages') urlFilters['languages'] = this.filters.languages.map(el => JSON.stringify(el));
          else if (key === 'image' && this.filters.image) urlFilters['image'] = 1;
          else urlFilters[key] = this.filters[key];
      }
      this.$router.push({query: {...urlFilters}, hash: 'list'}).catch(() => {});
    },
    filtersToRequest() {
      let filters = {...this.filters};
      filters.salary_from = this.filters.salary_from ? this.filters.salary_from.replaceAll(' ', '') : '';
      filters.salary_to = this.filters.salary_to ? this.filters.salary_to.replaceAll(' ', '') : '';
      if (filters.languages.length) {
        let langs = filters.languages;
        filters.languages = {};
        langs.forEach(lang=>{
          Object.assign(filters.languages, lang);
        })
      }
      return filters;
    },
    get() {
      this.loading = true;
      this.resumeClass.search(this.filtersToRequest()).then((data) => {
        this.countString = data.countString
        this.items = data.response;
        this.itemsCount = data.count;
        this.accessError = false;
        this.loading = false;
        this.$emit('countString-updated', data.countString)
      }).catch((data) => {
        if (data.error.type === 'unpayed') this.accessError = true;
        this.itemsCount = data.count;
        this.loading = false;
      })
    },
    fetchSuggestionsPos(q, cb) {
      this.resumeClass.suggestions('position', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    search(e) {
      if (!e || e.code === 'Enter') {
        this.applyFilters();
      }
    },
    removeSkill(i) {
      this.filters.skills.splice(i, 1);
    },
    removeLang(i) {
      this.filters.languages.splice(i, 1);
    },
    changePage(page) {
      this.filters.pageNumber = page - 1;
      this.addFiltersToUrl();
      this.get();

    },
    openRatesDialog(from = '') {
      this.$refs.resume_rates_dialog.open(from);
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    systemParams() {
      return this.$store.state.systemParams;
    },
    pages() {
      let pagesCount = Math.ceil(this.itemsCount / this.filters.pageLength);
      return Array.from(Array(pagesCount).keys());
    },
    currentPage() {
      return parseInt(this.$route.query.pageNumber) + 1 || 1;
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function () {
        if (this.onChangeFiltersCount > 0) this.onChangeFilters();
        this.onChangeFiltersCount++;
      }
    }
  },
  mounted() {
    if (Object.keys(this.$route.query).length) {
      let query = this.$route.query;
      if (typeof query.skills === 'string') query.skills = [query.skills];
      if (query.languages) {
        if (typeof query.languages === 'string') query.languages = [query.languages];
        query.languages = query.languages.map(el => JSON.parse(el));
      }
      if (typeof query.educational_levels === 'string') query.educational_levels = [query.educational_levels];
      Object.assign(this.filters, query);
      this.addFiltersToUrl();
    }
    this.$store.state.eventBus.$emit('get-user');
    this.get();
  }
}
</script>

<style module lang="css">
.remainingContainer {
  margin-bottom: 16px;
}

@media screen and (max-width: 1024px) {
  .remainingContainer {
    margin-bottom: 20px;
  }
}
</style>