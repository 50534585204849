<template>
  <div :class="$style.tag" :style="`background: ${color || 'var(--light-gray)'}; font-size: ${fontSize || 'var(--small-text)'}; height: ${height || 40}px`">
    <rekroo-icon :icon="icon" :color="icon_color" :width="icon_width" v-if="icon && icon_pos === 'start'" style="margin-right: 6px;"
                 @click="iconClick"
    />
    <span v-if="text" :style="`font-size: inherit; color: ${text_color || 'var(--primary)'}`" v-html="text"></span>
    <rekroo-icon :icon="icon" :color="icon_color" :width="icon_width" v-if="icon && icon_pos === 'end'" style="margin-left: 6px;"
                 @click="iconClick"
    />
    <slot name="custom"></slot>
  </div>
</template>

<script>
import RekrooIcon from "@/components/Icon";
export default {
  name: "Tag",
  components: { RekrooIcon },
  props: ['text', 'color', 'fontSize', 'text_color', 'icon', 'icon_color', 'icon_width', 'icon_pos', 'height'],
  emits: ['onIconClick'],
  methods: {
    iconClick() {
      this.$emit('onIconClick');
    }
  }
}
</script>

<style module lang="css">
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  border-radius: 8px;
  font-weight: 500;
  white-space: nowrap;
  width: fit-content;
  line-height: 144%;
}

@media screen and (max-width: 1024px) {
  .tag {
    padding: 8px;
    font-size: var(--tiny-text) !important;
  }
}

</style>