<template>
  <el-dialog :visible.sync="isVisible" width="980px" custom-class="rekroo-dialog payment-dialog" :center="true" @close="onClose">
    <iframe :src="link" frameborder="0" sandbox="allow-top-navigation allow-scripts allow-forms allow-popups allow-same-origin" width="100%" height="100%" id="iframe"/>
  </el-dialog>
</template>

<script>
export default {
  name: "PaymentDialog",
  emits: ['success'],
  data: ()=>({
    isVisible: false,
    link: '',
  }),
  methods: {
    open(link) {
      this.link = '';
      this.link = link;
      this.isVisible = true;
      this.fetchData();
    },
    fetchData() {
      window.addEventListener("message", event=> {
        if (document.getElementById("iframe").contentWindow && event.source === document.getElementById("iframe").contentWindow) {
          this.parseData(JSON.parse(event.data));
        }
      });
    },
    parseData(data) {
      this.isVisible = false;
      if (data.success) {
        this.$emit('success');
        this.$notify({
          type: "success",
          title: "Оплата прошла успешно"
        });
        if (!this.$store.state.isTest) this.gtmEvent('payment-success')
      } else {
        this.$notify({
          type: "warning",
          title: "Проблема с оплатой, повторите попытку"
        })
      }
    },
    onClose() {
      window.removeEventListener('message', ()=>{});
    }
  },
}
</script>

<style module>

</style>
