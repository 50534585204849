<template>
  <table-skeleton v-if="loading" :class="tableStyles.table" :key="'skelet'+name"
                  :header-titles="['Название', 'Город', 'Отклики', 'Дата окончания']"/>
  <el-table
      v-else
      :key="'table'+name"
      :class="['table-container', tableStyles.table, {'table-container-active': loaded}, {'table-with-actions': name !== 'sent'}]"
      :data="data"
      :show-header="data.length > 0"
      header-row-class-name="header-row">
    <el-table-column label="Название" property="position" class-name="table-title-col">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <router-link :to="`/${$route.path.split('/')[1]}/mailing_list/${scope.row.id}`" :class="tableStyles.title">
            {{ scope.row.title }}
          </router-link>
          <div :class="tableStyles.mobileCell">
            <!--            <div :class="$style.vacanciesTableInfoRow">
                          <span>Отклики:</span> {{ scope.row.counts?.total || 0  }}
                        </div>
                        <div :class="$style.vacanciesTableInfoRow" v-if="scope.row.location">
                          <span>Город:</span> {{ scope.row.location }}
                        </div>
                        <div :class="$style.vacanciesTableInfoRow" v-if="scope.row.date_end">
                          <span>Дата окончания:</span> {{ dateParse(scope.row.date_end, 'D MMMM, YYYY') }}
                        </div>-->
          </div>
        </div>
        <!--        <div :class="tableStyles.mobileActions">
                  <el-dropdown :hide-on-click="true" trigger="click">
                    <rekroo-button icon="more" color="transparent" icon_width="30px" style="padding: 0"/>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                          <rekroo-button type="text" text_color="var(&#45;&#45;primary)" icon="pencil" icon_style="margin-right: 6px">
                            Редактировать
                          </rekroo-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <rekroo-button @click="$emit('on-remove-btn-click', scope.row.id)"
                                       type="text" text_color="var(&#45;&#45;error-red)" icon="trash" icon_style="margin-right: 6px">
                          Удалить
                        </rekroo-button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>-->
      </template>
    </el-table-column>
    <el-table-column label="Вакансия">
      <template slot-scope="scope">
        {{scope.row.vacancy?.position}}
      </template>
    </el-table-column>
    <el-table-column v-if="name === 'sent'" label="Статус">
      <template slot-scope="scope">
        {{ $store.state.systemParams?.mailing_list_statuses ? $store.state.systemParams?.mailing_list_statuses[scope.row.status] : ''}}
      </template>
    </el-table-column>
    <el-table-column class-name="table-row__actions">
      <template slot-scope="scope"  v-if="scope.row.status !== 3">
        <div :class="[tableStyles.actions, $style.tableRowActions]">
          <rekroo-button
              width="45px" color="var(--purple)" icon="pencil" icon_color="#FFF"
                         @click="$router.push(`/company/mailing_list/update/${scope.row.id}`)"/>
          <rekroo-button v-if="name !== 'archive'" @click="$emit('on-remove-btn-click', scope.row.id)"
                         width="45px" color="var(--gray-text)" icon="trash" icon_color="#FFF"/>
        </div>
        <div :class="$style.tableRowActionsMobile" v-if="scope.row.status !== 3" >
          <el-dropdown>
            <rekroo-button width="36px" height="36px" color="var(--gray)" icon="more" icon_color="#FFF"
                           icon_width="16px" style="padding: 0"/>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <rekroo-button type="text" icon="pencil" text_color="var(--primary)"
                               icon_style="margin-right: 15px"
                               @click="$router.push(`/company/mailing_list/update/${scope.row.id}`)">Редактировать
                </rekroo-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <rekroo-button v-if="name !== 'archive'" type="text" icon="trash" text_color="var(--error-red)"
                               icon_style="margin-right: 15px"
                               icon_color="var(--error-red)"
                               @click="$emit('on-remove-btn-click', scope.row.id)">Удалить
                </rekroo-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug :title="emptyText[name].title"
      :button_text="emptyText[name].button_text" @onClick="emptyText[name].handler()" :show-icon="name !== 'sent'"/>
    </template>
  </el-table>
</template>

<script>

import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import RekrooButton from "@/components/Button";
import EmptyListPlug from "@/components/EmptyListPlug";

export default {
  name: "MailingListTable",
  components: {EmptyListPlug, RekrooButton, TableSkeleton},
  props: ['loading', 'name', 'data', 'loaded'],
  emits: ['on-create-btn-click', 'on-remove-btn-click'],
  data() {
    return {
      tableStyles,
      emptyText: {
        sent: {
          title: 'Здесь будут находиться e-mail рассылки, которые были отправлены',
          button_text: '+ Создать рассылку',
          handler: () => this.$emit('on-create-btn-click')
        },
        new: {
          title: 'Здесь будут e-mail рассылки, которые были созданы, но не отправлены, либо с отложенной отправкой'
        },
        archive: {
          title: 'Здесь будут удаленные рассылки'
        }
      }
    }
  }
}
</script>

<style module>
.tableRowActionsMobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .tableRowActions {
    display: none;
  }

  .tableRowActionsMobile {
    display: block;
  }
}
</style>