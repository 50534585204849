<template>
  <el-dialog :visible.sync="isVisible" width="427px" custom-class="rekroo-dialog create-consultation-dialog"
             :center="true">
    <div class="dialog-title">Консультация</div>
    <el-form ref="form">
      <el-form-item label="Тема" required>
        <el-input />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "CreateConsultationDialog",
  data: () => ({
    isVisible: false,
    consultation: {}
  }),
  methods: {
    open() {
      this.isVisible = true;
    }
  }
}
</script>

<style module lang="css">

</style>