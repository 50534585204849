<template>
  <table-skeleton v-if="loading" :class="tableStyles.table" :key="'skelet'+name"
                  :header-titles="['Название заказа', 'Бюджет', 'Просмотры', 'Отклики', 'Дата создания']"/>
  <el-table
      v-else
      :class="['table-container', tableStyles.table, {'table-container-active': loaded}, {'table-with-actions': name !== 'work'}]"
      ref="multipleTable" :data="data"
      :show-header="data.length > 0"
      :header-row-style="()=>'height: 50px'">
    <el-table-column label="Название заказа" property="title" class-name="table-title-col">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <router-link :class="tableStyles.title" :to="`/company/auction/${scope.row.id}`">
            {{ scope.row.title }}
          </router-link>
          <div :class="tableStyles.mobileCell">
            <div :class="$style.orderCellMobileStats" v-if="name !== 'work'">
              <div :class="$style.orderValueWithIcon">
                <rekroo-icon icon="eye" color="var(--gray-text)" width="13" style="margin-right: 6px"/>
                {{ scope.row.counts?.views }}
              </div>
              <div :class="$style.orderValueWithIcon">
                <rekroo-icon icon="hand" color="var(--gray-text)" width="13" style="margin-right: 6px"/>
                {{ scope.row.counts?.total }}
                <span> {{ scope.row.counts?.unseen ? `+${scope.row.counts?.unseen}` : '' }}</span>
              </div>
            </div>
            <label-value-row
                v-if="name !== 'work'"
                label="Бюджет"
                             :value="salaryParse(scope.row.price_from, scope.row.price_to) "/>
            <label-value-row v-if="name === 'active'"
                label="Дата создания" :value="dateParse(scope.row.datetime_create, 'DD.MM.YYYY')"/>
          </div>
          <div v-if="name === 'work'" :class="tableStyles.mobileCell">
            <div :class="$style.hr">
              <candidate-avatar :img="scope.row.chosen_user?.image?.url" :circle="true" size="40"/>
              <div :class="$style.hrData">
                <p :class="$style.hrName">{{ scope.row.chosen_user?.name }} {{ scope.row.chosen_user?.surname }}</p>
                <div :class="$style.contactsHr">
                  <a :href="`tel: ${scope.row.chosen_user?.phone}`" v-if="scope.row.chosen_user?.phone">
                    <rekroo-button type="text" text_color="var(--primary)" icon="phone"
                                   icon_color="var(--gray-text)"
                                   icon_style="margin-right: 10px">
                      {{ strPhoneMask(scope.row.chosen_user?.phone) }}
                    </rekroo-button>
                  </a>
                  <a :href="`mailto: ${scope.row.chosen_user?.email}`" v-if="scope.row.chosen_user?.email">
                    <rekroo-button type="text" text_color="var(--primary)" icon="mail"
                                   icon_color="var(--gray-text)"
                                   icon_style="margin-right: 10px">
                      {{ scope.row.chosen_user?.email }}
                    </rekroo-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
    <template v-if="name === 'work'">
      <el-table-column label="HR-исполнитель">
        <template slot-scope="scope">
          <div :class="$style.hr">
            <candidate-avatar :circle="true" size="32" :img="scope.row.chosen_user?.image?.url"/>
            <div :class="$style.hrName">{{ scope.row.chosen_user?.name }} {{ scope.row.chosen_user?.surname }}</div>
          </div>
        </template>
      </el-table-column>
      <template v-if="deviceWidth > 1024">
        <el-table-column label="Телефон">
          <template slot-scope="scope">
            <a :href="`tel:${scope.row.chosen_user?.phone}`">{{ strPhoneMask(scope.row.chosen_user?.phone) }}</a>
          </template>
        </el-table-column>
        <el-table-column label="Email">
          <template slot-scope="scope">
            <a :href="`mailto:${scope.row.chosen_user?.email}`">{{ scope.row.chosen_user?.email }}</a>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column label="Контакты">
          <template slot-scope="scope">
            <div :class="$style.contactsHr">
              <a :href="`tel: ${scope.row.chosen_user?.phone}`" v-if="scope.row.chosen_user?.phone">
                <rekroo-button type="text" text_color="var(--primary)" icon="phone"
                               icon_color="var(--gray-text)"
                               icon_style="margin-right: 10px">
                  {{ strPhoneMask(scope.row.chosen_user?.phone) }}
                </rekroo-button>
              </a>
              <a :href="`mailto: ${scope.row.chosen_user?.email}`" v-if="scope.row.chosen_user?.email">
                <rekroo-button type="text" text_color="var(--primary)" icon="mail"
                               icon_color="var(--gray-text)"
                               icon_style="margin-right: 10px">
                  {{ scope.row.chosen_user?.email }}
                </rekroo-button>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>
    </template>
    <template v-else>
      <el-table-column :label=" 'Бюджет'" property="budget">
        <template slot-scope="scope">
          {{ salaryParse(scope.row.price_from, scope.row.price_to) }}
        </template>
        <!--      <template slot-scope="scope" v-else>
                <div :class="$style.hrName">
                  <candidate-avatar :img="scope.row.hr.img" :circle="true" size="40"/>
                  {{ scope.row.hr.name }}<br>{{ scope.row.hr.surname }}
                </div>
              </template>-->
      </el-table-column>
      <!--    <el-table-column label="Контакты" property="hr" :class-name="$style.contactsCell">
            <template slot-scope="scope">
              <div :class="$style.contacts">
                <rekroo-button type="text" text_color="var(&#45;&#45;primary)" icon="phone" icon_color="var(&#45;&#45;gray-text)"
                               icon_style="margin-right: 10px">{{ strPhoneMask(scope.row.hr.phone) }}
                </rekroo-button>
                <rekroo-button type="text" text_color="var(&#45;&#45;primary)" icon="mail" icon_color="var(&#45;&#45;gray-text)"
                               icon_style="margin-right: 10px">{{ scope.row.hr.email }}
                </rekroo-button>
              </div>
            </template>
          </el-table-column>-->
      <el-table-column label="Просмотры">
        <template slot-scope="scope">
          <div :class="$style.orderValueWithIcon">
            <rekroo-icon icon="eye" color="var(--gray-text)" width="13" style="margin-right: 6px"/>
            {{ scope.row.counts?.views }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Отклики">
        <template slot-scope="scope">
          <div :class="$style.orderValueWithIcon">
            <rekroo-icon icon="hand" color="var(--gray-text)" width="13" style="margin-right: 6px"/>
            {{ scope.row.counts?.total }}
            <span> {{ scope.row.counts?.unseen ? `+${scope.row.counts?.unseen}` : '' }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column property="datetime_create" label="Дата создания">
        <template slot-scope="scope">
          {{ dateParse(scope.row.datetime_create, 'DD.MM.YYYY') }}
        </template>
      </el-table-column>
      <el-table-column class-name="table-row__actions">
        <template slot-scope="scope">
          <div :class="[tableStyles.actions, $style.tableRowActions]">
            <rekroo-button width="45px" color="var(--purple)" icon="pencil" icon_color="#FFF"
                           @click="$emit('open-order-dialog', scope.row.id)"/>
            <rekroo-button v-if="name !== 'archive'"
                @click="$emit('on-remove-btn-click', scope.row.id)"
                           width="45px" color="var(--gray-text)" icon="trash" icon_color="#FFF"/>
          </div>
          <div :class="$style.tableRowActionsMobile">
            <el-dropdown>
              <rekroo-button width="36px" height="36px" color="var(--gray)" icon="more" icon_color="#FFF"
                             icon_width="16px" style="padding: 0"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <rekroo-button type="text" icon="pencil" text_color="var(--primary)"
                                 icon_style="margin-right: 15px"
                                 @click="$emit('open-order-dialog', scope.row.id)">Редактировать
                  </rekroo-button>
                </el-dropdown-item>
                <el-dropdown-item v-if="name !== 'archive'">
                  <rekroo-button type="text" icon="trash" text_color="var(--error-red)"
                                 icon_style="margin-right: 15px"
                                 icon_color="var(--error-red)"
                                 @click="$emit('on-remove-btn-click', scope.row.id)">Удалить
                  </rekroo-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </template>
    <template #empty>
      <empty-list-plug
          :class="$style.emptyOrdersListPlug"
          :title="emptyTexts[name].title"
          :subtitle="emptyTexts[name].subtitle"
          :button_text="emptyTexts[name].button_text" @onClick="emptyTexts[name].handler()"
          :show-icon="name !== 'active'"
      />
    </template>
  </el-table>
</template>

<script>
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import RekrooIcon from "@/components/Icon";
import LabelValueRow from "@/components/LabelValueRow";
import EmptyListPlug from "@/components/EmptyListPlug";
import CandidateAvatar from "@/components/CandidateAvatar";
import RekrooButton from "@/components/Button";

export default {
  name: "AuctionTable",
  components: {RekrooButton, CandidateAvatar, EmptyListPlug, LabelValueRow, RekrooIcon, TableSkeleton},
  props: ['data', 'loading', 'loaded', 'name'],
  emits: ['on-create-order-click', 'on-remove-btn-click'],
  data() {
    return {
      tableStyles,
      emptyTexts: {
        active: {
          title: 'У вас пока нет опубликованных заказов',
          subtitle: 'Самое время создать заказ — опишите задачу, укажите сроки и бюджет. Специалисты сами откликнутся на ваш заказ.',
          button_text: '+ Новый заказ',
          handler: () => this.$emit('open-order-dialog'),
        },
        work: {
          title: 'Здесь будут ваши заказы, для которых нашлись свои исполнители',
          subtitle: '',
          button_text: '',
          handler: () => {
          },
        },
        not_published: {
          title: 'Здесь будут храниться ваши неопубликованные задачи',
          subtitle: '',
          button_text: '',
          handler: () => {
          },
        },
        archive: {
          title: 'Здесь будут храниться ваши завершенные и удаленные задачи',
          subtitle: '',
          button_text: '',
          handler: () => {
          },
        },
      },
      deviceWidth: 0
    }
  },
  mounted() {
    this.deviceWidth = window.innerWidth;
  }
}
</script>
<style module lang="css">
.orderValueWithIcon {
  display: flex;
  align-items: center;
  font-size: var(--small-text);
}

.orderValueWithIcon span {
  color: var(--green-active);
}

.orderCellMobileStats {
  display: flex;
  column-gap: 14px;
}

.emptyOrdersListPlug {
  margin: 120px auto;
}

.hr {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.contactsCell {
  display: none;
}

.tableRowActions {
  display: flex;
}


.tableRowActionsMobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .phoneCell {
    display: none;
  }

  .emailCell {
    display: none;
  }

  .contactsCell {
    display: table-cell;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  .contacts button {
    font-weight: normal;
  }

  .tableRowActions {
    display: none;
  }

  .tableRowActionsMobile {
    display: block;
  }


}

@media screen and (max-width: 575px) {
  .orderCellMobileHr .hr .hrName {
    margin-bottom: 4px;
  }

  .orderCellMobileHr .hr .hrData .contactsHr {
    display: flex;
    gap: 4px 10px;
    flex-wrap: wrap;
  }

  .orderCellMobileHr .hr .hrData .contactsHr button {
    font-weight: normal;
  }
}
</style>