<template>
  <div :class="$style.codeContainer">
    <div class="dialog-title" :class="$style.codeTitle">Введите код</div>
    <div class="dialog-subtitle small" :class="$style.codeSubtitle">
      Код отправлен на: {{ loginData.login }}
      <rekroo-button type="text" text_color="var(--bright-blue)" @click="back"
                     style="margin: 4px auto 0"
      >Изменить</rekroo-button>
    </div>
    <div :class="$style.otpOuter">
      <div :class="[$style.otpContainer, {[$style.otpContainerError]: error}]" ref="otp">
        <el-input v-for="(el, i) in inputs" :key="i"
                  :autofocus="i === 0"
                  autocomplete="one-time-code"
                  placeholder="•"
                  :ref="`otp_input_${i}`"
                  inputmode="numeric"
                  pattern="[0-9]"
                  :class="$style.loginInput" class="no-outline" :maxlength="1" v-model="inputs[i]"
                  @keydown.native="onKeyDown($event, i)"
                  @input="onInput($event, i)"/>
      </div>
      <span :class="$style.otpTextError">{{error}}</span>
    </div>
    <rekroo-button @click="resendCode"
                   :disabled="sec > 0"
                   type="text" text_color="var(--bright-blue)" width="100%">Отправить код повторно&nbsp;
      <span v-if="sec > 0" style="color: var(--bright-blue)">({{ sec }} сек.)</span></rekroo-button>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
import {Auth} from "@/api/auth";

export default {
  name: "LoginCode",
  components: {RekrooButton},
  props: ['loginData', 'role'],
  emits: ['back', 'success-auth'],
  data: () => ({
    inputs: ['', '', '', ''],
    auth: new Auth(),
    sec: 30,
    timer: null,
    error: ''
  }),
  methods: {
    onInput(val, i) {
      this.error = '';
      let inputs = this.$refs.otp.querySelectorAll('input');
      if (val.length && inputs[i + 1]) {
        inputs[i + 1].focus();
      }
      if (i === inputs.length - 1 && val) {
        this.sendCode();
      }
    },
    sendCode() {
      this.auth.checkCode({
        code: this.inputs.join(''),
        secret_code: this.loginData.secretCode
      }).then(() => {
        this.$emit('success-auth');
        this.$store.state.eventBus.$emit('success-auth');
        /*if (window.location.host.indexOf('localhost:')===0) {
          localStorage.setItem('api_token', data.token);
          location.reload();
        }*/
      }).catch((data) => {
        this.error = data.error?.text || data.error;
        this.inputs = ['', '', '', ''];
        let inputs = this.$refs.otp.querySelectorAll('input');
        inputs[0].focus();

      })
    },
    onKeyDown(key, i) {
      let inputs = this.$refs.otp.querySelectorAll('input');
      if (key.code === 'Backspace' && inputs[i - 1]) {
        this.inputs[i] = '';
        inputs[i - 1].focus();
      }
      if (key.code === 'ArrowRight' && inputs[i + 1]) {
        inputs[i + 1].focus();
      }
      if (key.code === 'ArrowLeft' && inputs[i - 1]) {
        inputs[i - 1].focus();
      }
    },
    back() {
      this.$emit('back');
    },
    startTimer() {
      this.sec = 30;
      this.timer = setInterval(() => {
        this.sec--;
        if (this.sec < 0) this.stopTimer();
      }, 1000)
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    resendCode() {
      this.startTimer();
      this.auth.login(this.loginData.type === 'email' ? {email: this.loginData.login} :
          {phone: this.loginData.login}
      ).then((data) => {
        this.loginData.secretCode = data.response;
      })
    },
    detectPaste(e) {
      let pastedText = e.clipboardData?.getData('text');
      if (pastedText && pastedText.length === 4) {
        this.inputs = pastedText.split('');
        this.sendCode();
      }
    }
  },
  mounted() {
    this.startTimer();
    document.addEventListener('paste', this.detectPaste)
  },
  beforeDestroy() {
    this.stopTimer();
    document.removeEventListener('paste', () => {
    });
  }
}
</script>

<style module lang="css">
.codeContainer {
}

.codeTitle {
  margin-bottom: 12px;
}

.codeSubtitle {
  margin-bottom: 32px;
}
.otpOuter {
  margin-bottom: 40px;
  position: relative;
}
.otpContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 12px;
}

.otpContainer.otpContainerError {
  border-bottom: 1px solid var(--error-red);
}

.otpTextError {
  position: absolute;
  bottom: -24px;
  display: block;
  width: 100%;
  text-align: center;
  font-size: var(--small-text);
  color: var(--error-red);
}

.loginInput input {
  border-bottom: 1px solid var(--gray) !important;
  border-radius: 0 !important;
  text-align: center;
  font-size: var(--headline_3) !important;
}

.loginInput input:focus {
  border-bottom: 1px solid var(--purple) !important;
}

/*.otpContainer .loginInput {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.otpContainer .loginInput:after {
  content: ' ';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--gray);
}*/
</style>