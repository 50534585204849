<template>
  <div :class="$style.dot" :style="`background: ${color || '#fff'}; width: ${size || 4}px; height: ${size || 4}px;`"
      @click="$emit('click')"/>
</template>

<script>
export default {
  name: "Dot",
  emits: ['click'],
  props: ['color', 'size']
}
</script>

<style module lang="css">
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

@media screen and (max-width: 1024px) {
  .dot {
    width: 2px;
    height: 2px;
  }
}
</style>