<template>
  <el-dialog :visible.sync="isVisible" width="600px" custom-class="rekroo-dialog complain-dialog" :center="true">
    <div class="dialog-title" :class="$style.title">Пожаловаться на
      {{ params.type === 'vacancy' ? 'вакансию' : 'резюме' }}<br>
<!--      {{ params.complainTarget }}-->
    </div>
    <el-form ref="form" :model="data" :rules="rules">
      <el-form-item prop="message" required>
        <el-input v-model="data.message" type="textarea" :autosize="{ minRows: 4, maxRows: 10}"
                  placeholder="Расскажите, почему вы хотите оставить жалобу"
                  class="gray-bg"/>
      </el-form-item>
      <rekroo-button  @click="complain" :loading="loading" width="100%" height="50px"
      style="margin-top: 32px">Отправить жалобу</rekroo-button>
    </el-form>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import {Item} from "@/api/item";

export default {
  name: "ComplainDialog",
  components: {RekrooButton},
  emits: ['complained'],
  data: () => ({
    isVisible: false,
    data: {
      message: ''
    },
    loading: false,
    itemClass: new Item({}),
    params: {},
    rules: {
      message: [
        {required: true, message: 'Укажите причину', trigger: 'change'},
        {required: true, message: 'Укажите причину', trigger: 'blur'},
      ]
    }
  }),
  methods: {
    open(params) {
      this.params = {};
      this.isVisible = true;
      setTimeout(() => {
        this.$refs.form.resetFields();
      })
      this.params = params;
    },
    complain() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.itemClass.complain(this.params.type, this.params.id, this.data.message).then(() => {
            this.loading = false;
            this.isVisible = false;
            this.$notify({
              type: 'success',
              message: 'Жалоба успешно отправлена'
            })
          }).catch(() => {
            this.loading = false;
          })
        }
      })
    }
  }
}
</script>

<style module lang="css">
.title {
  margin-bottom: 16px;
}
</style>