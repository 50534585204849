<template>
  <div :class="$style.emptyListPlug">
    <div :class="$style.emptyListPlugTitle" class="headline-3">{{title}}</div>
    <div :class="$style.emptyListPlugSubtitle" class="subtitle">{{subtitle}}</div>
    <router-link v-if="link" :to="link">
      <rekroo-button v-if="button_text" @click="$emit('onClick')" width="100%">{{button_text}}</rekroo-button>
    </router-link>
    <rekroo-button v-else-if="button_text" @click="$emit('onClick')" width="100%">{{button_text}}</rekroo-button>
    <img v-if="showIcon" src="/img/icons/icon_empty_list.svg" alt="" :class="$style.icon">
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
export default {
  name: "EmptyListPlug",
  components: {RekrooButton},
  props: ['title', 'subtitle', 'button_text', 'showIcon', 'link'],
  emits: ['onClick']
}
</script>

<style module lang="css">
.emptyListPlug {
  max-width: 425px;
  text-align: center;
}
.emptyListPlug .emptyListPlugTitle {
  margin-bottom: 12px;
  text-align: center;
}

.emptyListPlug .emptyListPlugSubtitle {
  font-size: var(--body);
  margin-bottom: 36px;
  text-align: center;
}

.icon {
  margin: 32px auto 0;
}

@media screen and (max-width: 1024px) {
  .emptyListPlug {
    max-width: 328px;
  }
  .emptyListPlug .emptyListPlugTitle {
    font-size: var(--subheadline);
    margin-bottom: 16px;
  }
  .emptyListPlug .emptyListPlugSubtitle {
    margin-bottom: 32px;
    font-size: var(--small-text);
  }

}

@media screen and (max-width: 575px) {
  .emptyListPlug {
    max-width: 100%;
    padding: 0 20px;
  }
  .emptyListPlug .emptyListPlugTitle {
    margin-bottom: 12px;
  }
  .emptyListPlug .emptyListPlugSubtitle {
    margin-bottom: 24px;
  }
}
</style>