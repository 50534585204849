<template>
  <div :class="[$style.hrLanding_v3, style.landingHrPage]">
    <section :class="$style.hrLanding_v3Header">
      <div :class="$style.hrLanding_v3HeaderDescription">
        <img :class="$style.hrLandingHeaderArrow" src="/img/icons/icon_curve_arrow.svg" alt="">
        <div :class="$style.hrLanding_v3HeaderTitle">
          <span>Актуальная</span> живая <span>база резюме</span>, обновляющаяся ежедневно
        </div>
        <div :class="$style.hrLanding_v3HeaderSubtitle">Лучшие кандидаты. Актуальные обновляемые базы резюме. Все&nbsp;в&nbsp;одном
          месте
        </div>
        <rekroo-button height="72px" width="100%" color="var(--purple)"
                       :class="$style.hrLanding_v3HeaderButton"
                       @click="joinToRekroo">Получить доступ к базе
        </rekroo-button>
        <a href="#candidates_intro" :class="[style.linkToBottom, $style.linkToBottom]">Как тебе поможет Rekroo
          <rekroo-icon icon="arrow-bottom" style="margin-left: 12px" width="14px"/>
        </a>
      </div>
      <div :class="$style.hrLanding_v3HeaderImageContainer">
        <img src="/img/icons/green_play_icon.svg" alt="" :class="$style.hrLanding_v3HeaderImagePlay">
        <img src="/img/hr_landing/hr_landing_v3_header.png" alt="" :class="$style.hrLanding_v3HeaderImage">
        <img src="/img/hr_landing/hr_landing_header_tablet.png" alt="" :class="$style.hrLanding_v3HeaderImageTablet">
      </div>
      <rekroo-button height="72px" width="100%" color="var(--purple)"
                     :class="$style.hrLanding_v3HeaderButtonTablet"
                     @click="joinToRekroo">Получить доступ к базе
      </rekroo-button>
    </section>
    <intro-block title="Работа с имеющимися заказчиками" ref="candidates_intro"
                 id="candidates_intro"/>
    <landing-candidates-database-section ref="candidates_section"
                                         title_1="Лучшие" title_2="кандидаты уже с Rekroo"
                                         subtitle="Выбирай лучших кандидатов прямо из&nbsp;нашей актуальной и&nbsp;живой
      базы резюме, без&nbsp;необходимости переходить на&nbsp;сторонние сервисы" title_2_color="var(--bright-blue)"
                                         link="/hr/catalog" :class="$style.candidatesDatabase">
      <template>
        <router-link to="/hr/catalog" :class="$style.candidatesDatabaseButton">
          <rekroo-button
              height="72px" width="100%" color="var(--purple)">Подберите лучших
          </rekroo-button>
        </router-link>
      </template>
    </landing-candidates-database-section>
    <intro-block title="Работа с новыми заказчиками" ref="auction_intro"/>
    <section :class="[style.auctionSection, $style.auctionSection]" ref="auction_section">
      <h1 :class="style.auctionSectionTitle"><span>Аукцион с работодателем.</span><br>
        Ищите клиентов прямо на портале</h1>
      <p :class="style.auctionSectionSubtitle">Работодатель добавляет вакансию с оплатой за кандидата, вы находите
        работников за одноразовое вознаграждение от работодателя</p>
      <div :class="style.auctionSteps">
        <img src="/img/hr_landing/dashed_curve.png" alt="" :class="[style.dashedCurve, $style.auctionDashedCurve]">
        <div :class="[style.auctionStep, $style.auctionStep]" v-for="(step, i) in auctionSteps" :key="i">
          <img :src="step.img" alt="" :class="$style.auctionStepImage">
          <div :class="style.auctionStepLabel" v-html="step.label"/>
        </div>
      </div>
      <router-link to="/hr/auction" :class="$style.auctionSectionButton">
        <rekroo-button color="var(--purple)" height="72px" width="100%">Найти заказ</rekroo-button>
      </router-link>
    </section>
    <section :class="$style.hrLanding_v3_Vacancies">
      <h1 :class="$style.hrLanding_v3_VacanciesTitle"><span>Актуальные живые вакансии,</span><br>
        обновляющиеся ежедневно</h1>
      <landing-vacancies :vacancies="vacancies" link="/hr/auction/"/>
      <router-link to="/hr/vacancies" :class="$style.hrLanding_v3_VacanciesButton">
        <rekroo-button color="var(--purple)" height="72px" width="100%">Больше вакансий</rekroo-button>
      </router-link>
    </section>
    <landing-reviews-section
        :title="`Они уже попробовали Rekroo<br> <span style='color: var(--bright-blue)'>и нашли своих сотрудников</span>`"/>
    <intro-block ref="consultation_intro" title="Как с нами зарабатывать больше?"/>
    <section ref="consultation_section" :class="$style.hrLanding_v3_Consultation">
      <div :class="$style.hrLanding_v3_ConsultationTitle">
        Консультируй и зарабатывай. <span>Дай экспертное мнение</span>
        <br><span>по конкретной проблеме</span> внутри воронки найма
      </div>
      <div :class="$style.hrLanding_v3_ConsultationInner">
        <div :class="$style.hrLanding_v3_ConsultationDescription">
          <p :class="$style.hrLanding_v3_ConsultationText">Проводи консультации, помогай составить грамотное резюме и
            спопроводительное письмо, для получения
            большего числа офферов. Помогай улучшить вакансию, скрипт телефонного и очного собеседования. Составь
            алгоритм эффективной адаптации, для устранения текучки кадров внутри компании
            Выбрать заявку на консультацию</p>
          <router-link to="/hr/consultation" :class="$style.hrLanding_v3_ConsultationButton">
            <rekroo-button height="72px" width="100%" color="var(--purple)">Выбрать заявку на консультацию
            </rekroo-button>
          </router-link>
        </div>
        <div :class="$style.hrLanding_v3_ConsultationImageContainer">
          <img src="/img/hr_landing/hr_landing_consultation_section.png" alt="">
          <router-link to="/hr/consultation" :class="$style.hrLanding_v3_ConsultationButtonTablet">
            <rekroo-button height="72px" width="100%" color="var(--purple)">Выбрать заявку на консультацию
            </rekroo-button>
          </router-link>
        </div>
      </div>
    </section>
    <intro-block ref="email_intro" title="Инструменты увеличения заработка"/>
    <section ref="email_section" :class="$style.hrLanding_v3_Consultation">
      <div :class="$style.hrLanding_v3_ConsultationTitle">
        Рассылка по базе резюме. <span>Сократи время</span>
        и усилия, затрачиваемые <span>на поиск кандидатов</span>
      </div>
      <div :class="$style.hrLanding_v3_ConsultationInner">
        <div :class="$style.hrLanding_v3_ConsultationDescription">
          <p :class="$style.hrLanding_v3_ConsultationText">Отправляй предложения о вакансиях тысячам кандидатов
            одновременно.
            <br>
            Ты можешь выбрать нужные критерии, такие как профессиональные навыки, опыт работы, уровень образования,
            чтобы предложение о вакансии было доставлено только квалифицированным кандидатам.
            Создать рассылку</p>
          <router-link to="/hr/mailing_list" :class="$style.hrLanding_v3_ConsultationButton">
            <rekroo-button height="72px" width="100%" color="var(--purple)">Создать рассылку
            </rekroo-button>
          </router-link>
        </div>
        <div :class="$style.hrLanding_v3_ConsultationImageContainer">
          <img src="/img/hr_landing/mailing_image.png" alt="">
          <router-link to="/hr/mailing_list" :class="$style.hrLanding_v3_ConsultationButtonTablet">
            <rekroo-button height="72px" width="100%" color="var(--purple)">Создать рассылку
            </rekroo-button>
          </router-link>
        </div>
      </div>
    </section>
    <div :class="$style.hrmSectionOuter">
      <HRMDescriptionSection/>
      <div :class="$style.greenStar">
        <img src="/img/hr_landing/green_star_with_rounded_text.svg" alt="">
        <span>5 дней<br>бесплатно</span>
      </div>
    </div>
    <landing-reviews-section
        :title="`Они уже попробовали работать в HRM,<br>попробуй и ты <span style='color: var(--bright-blue)'>5 дней бесплатно</span>`"/>
    <safety-section :class="$style.hrLanding_v3_safety"/>
  </div>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import IntroBlock from "@/views/landing/components/IntroBlock";
import LandingCandidatesDatabaseSection from "@/views/landing/components/LandingCandidatesDatabaseSection";
import {gsap} from "gsap";
import LandingVacancies from "@/views/landing/components/LandingVacancies";
import LandingReviewsSection from "@/views/landing/components/LandingReviewsSection";
import HRMDescriptionSection from "@/views/landing/components/HRMDescriptionSection";
import SafetySection from "@/views/landing/components/SafetySection";

export default {
  name: "HRLanding_v3",
  components: {
    SafetySection,
    HRMDescriptionSection,
    LandingReviewsSection,
    LandingVacancies, LandingCandidatesDatabaseSection, IntroBlock, RekrooIcon, RekrooButton
  },
  data: () => ({
    style,
    auctionSteps: [
      {
        label: `Работодатели создают заказы&nbsp;—&nbsp;описывают задачу, детали и предлагают цену`,
        img: '/img/hr_landing/hr_landing_auction_1.jpg',
      },
      {
        label: 'Ты выбираешь интересные заказы, откликаешься и договариваешься об условиях',
        img: '/img/hr_landing/hr_landing_auction_2.jpg',
      },
      {
        label: 'Выполняешь заказ и получаешь оплату напрямую от работодателя',
        img: '/img/hr_landing/hr_landing_auction_3.jpg',
      },
    ],
    vacancies: [
      {
        company_info: {title: 'Рекламное агенство Нерв'},
        position: 'Менеджер по продажам и аккаунтингу',
        location: 'Санкт-Петербург',
        salary_from: 60000,
      },
      {
        company_info: {title: 'Яндекс Крауд'},
        position: 'Автор текстов в Поиск',
        salary_from: '',
        remote_work: 1
      },
      {
        company_info: {title: 'ИП Максим Андреюк'},
        position: 'Менеджер отдела продаж',
        location: 'Москва',
        salary_from: 40000,
        salary_to: 65000,
      },
    ],
  }),
  methods: {
    setGsapIntro(el) {
      gsap.fromTo(el,
          {
            scale: 1,
            backgroundColor: '#CED9E3',
          },
          {
            scale: 0.3,
            backgroundColor: '#CED9E3',
            duration: 100,
            scrollTrigger: {
              trigger: el,
              toggleActions: "play none none reverse",
              scrub: 1,
              start: 'top center',
              end: 'bottom center'
            },
          }
      )
    },
    setGsapSection(el, name) {
      let mm = gsap.matchMedia(el);
      mm.add("(min-width: 1025px)",
          () => {
            gsap.fromTo(el,
                {
                  y: 0,
                }, {
                  y:
                      this.getTranslateTop(name),
                  scrollTrigger: {
                    trigger: el,
                    scrub: 1,
                    start: 'top bottom-=203',
                    end: 'top center',
                  }
                })
          })
          .add("(max-width: 1024px)", () => {
            gsap.fromTo(el,
                {
                  y: 0,
                }, {
                  y: ['hrm_section', 'consultation_section', 'email_section'].includes(name) ? -175 : -100,
                  scrollTrigger: {
                    trigger: el,
                    scrub: 1,
                    start: 'top bottom-=203',
                    end: 'top center',
                  }
                });
          })
          .add("(max-width: 575px)", () => {
            gsap.fromTo(el,
                {
                  y: 0,
                }, {
                  y: ['hrm_section', 'consultation_section', 'email_section'].includes(name) ? -120 : -80,
                  scrollTrigger: {
                    trigger: el,
                    scrub: 1,
                    start: 'top bottom-=203',
                    end: 'top center',
                  }
                })
          })
    },
    getTranslateTop(el) {
      switch (el) {
        case 'consultation_section':
          return -200;
        case 'hrm_section':
          return -200;
        case 'email_section':
          return -200;
        default:
          return -150;
      }
    },
    joinToRekroo() {
     this.$router.push('/hr/catalog')
      // else this.$store.state.eventBus.$emit('auth-error');
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    let intros = ['candidates_intro', 'auction_intro', 'consultation_intro', 'email_intro'];
    let sections = ['candidates_section', 'auction_section', 'consultation_section', 'email_section'];
    for (let i = 0; i < intros.length; i++) {
      this.setGsapIntro(this.$refs[intros[i]].$el);
      this.setGsapSection(this.$refs[sections[i]].$el || this.$refs[sections[i]], sections[i]);
    }
  },
  beforeDestroy() {
  }
}
</script>

<style module lang="css">
.hrLanding_v3 {
}

.hrLanding_v3Header {
  margin-bottom: 80px;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: auto 470px;
}

.hrLanding_v3HeaderDescription {
  position: relative;
  padding: 80px 72px 64px 80px;
  background: var(--light-gray);
  border-radius: 16px;
}

.hrLandingHeaderArrow {
  position: absolute;
  left: 525px;
  bottom: 117px;
}

.hrLanding_v3HeaderTitle {
  margin-bottom: 28px;
  font-size: 64px;
  font-weight: 700;
}

.hrLanding_v3HeaderTitle span {
  color: var(--purple);
}

.hrLanding_v3HeaderSubtitle {
  margin-bottom: 40px;
  max-width: 495px;
  font-size: 22px;
  line-height: 140%;
}

.hrLanding_v3HeaderButton {
  max-width: 420px;
  margin-bottom: 62px;
}

.hrLanding_v3HeaderImageContainer {
  position: relative;
  background: var(--blue);
  border-radius: 16px;
}

.hrLanding_v3HeaderImagePlay {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.hrLanding_v3HeaderImage {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 595px;
}

.hrLanding_v3HeaderButtonTablet {
  display: none;
}

.hrLanding_v3HeaderImageTablet {
  display: none;
}

.candidatesDatabase, .auctionSection {
  padding-bottom: 0;
  margin-bottom: 0;
}

.candidatesDatabaseButton, .auctionSectionButton {
  margin-top: 64px;
  max-width: 420px;
  width: 100%;
}

.auctionSection .auctionStep:nth-child(2):before,
.auctionSection .auctionStep:last-child:before {
  top: 74px;
}

.auctionStepImage {
  margin-bottom: 20px;
}

.auctionStepImage {
  width: 148px;
  height: 148px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.hrLanding_v3_Vacancies {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.hrLanding_v3_VacanciesTitle {
  margin-bottom: 32px;
  text-align: center;
}

.hrLanding_v3_VacanciesSubtitle {
  margin-bottom: 40px;
  text-align: center;
  max-width: 575px;
}

.hrLanding_v3_VacanciesTitle span {
  color: var(--bright-blue);
}

.hrLanding_v3_VacanciesButton {
  max-width: 420px;
  width: 100%;
}

/*CONSULTATION SECTION*/
.hrLanding_v3_Consultation {
  margin-top: 40px;
  margin-bottom: 0;
  padding: 140px 80px 70px;
  background: var(--light-gray);
  border-radius: 16px;
}

.hrLanding_v3_ConsultationTitle {
  margin-bottom: 40px;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
}

.hrLanding_v3_ConsultationTitle span {
  display: inline-block;
  color: #FFFFFF;
  background: var(--green-active);
  border-radius: 6px;
  margin-top: 4px;
  padding: 0 4px;
}

.hrLanding_v3_ConsultationInner {
  display: flex;
  align-items: center;
  column-gap: 60px;
}

.hrLanding_v3_ConsultationDescription {
  max-width: 570px;
  width: 100%;
}

.hrLanding_v3_ConsultationText {
  margin-bottom: 40px;
}

.hrLanding_v3_ConsultationImageContainer {
  max-width: 575px;
  width: 100%;
}

.hrLanding_v3_ConsultationImageContainer img {
  width: 100%;
}

.hrLanding_v3_ConsultationImageContainer .hrLanding_v3_ConsultationButtonTablet {
  display: none;
}

.hrLanding_v3_safety {
  margin-top: 80px;
  margin-bottom: 50px;
}

.hrmSectionOuter {
  position: relative;
}

.greenStar {
  position: absolute;
  right: 117px;
  top: -28px;
  width: 232px;
  height: 232px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 110%;
}

.greenStar span {
  position: absolute;
  z-index: 1;
  text-align: center;
}

.greenStar img {
  width: 100%;
  height: 100%;
  animation: rotating 10s linear 0s infinite normal forwards;
  will-change: transform;
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1320px) {
  .hrLandingHeaderArrow {
    display: none;
  }
}

@media screen and (max-width: 1210px) {
  .hrLanding_v3HeaderDescription {
    padding: 80px 40px 64px 40px;
  }

  .hrLanding_v3HeaderTitle {
    font-size: 44px;
  }

  .hrLanding_v3HeaderImage {
    width: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .hrLanding_v3Header {
    margin-bottom: 40px;
    display: block;
    padding: 56px 40px;
    background: var(--light-gray);
    border-radius: 12px;
  }

  .hrLanding_v3HeaderDescription {
    padding: 0;
  }

  .hrLanding_v3HeaderTitle {
    margin-bottom: 24px;
    font-size: 54px;
  }

  .hrLanding_v3HeaderSubtitle {
    margin-bottom: 40px;
    max-width: 100%;
    font-size: 20px;
  }

  .hrLanding_v3HeaderImageContainer {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    border-radius: 8px;
    height: 358px;
  }

  .hrLanding_v3HeaderImage, .linkToBottom {
    display: none;
  }

  .hrLanding_v3HeaderImageTablet {
    display: block;
    position: absolute;
    bottom: -9px;
    max-height: 378px;
  }

  .hrLanding_v3HeaderImagePlay {
    display: none;
  }

  .hrLanding_v3HeaderDescription .hrLanding_v3HeaderButton {
    display: none;
  }

  .hrLanding_v3HeaderButtonTablet {
    display: flex;
  }

  .candidatesDatabase {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .candidatesDatabaseButton, .auctionSectionButton {
    margin-top: 40px;
    max-width: calc(100% - 80px);
  }

  .auctionSection .auctionStep:nth-child(2):before,
  .auctionSection .auctionStep:last-child:before {
    top: 50px;
  }

  .auctionStepImage {
    margin-bottom: 12px;
    width: 100px;
    height: 100px;
  }

  .hrLanding_v3_Vacancies {
    padding-bottom: 64px;
  }

  .hrLanding_v3_VacanciesTitle {
    margin-bottom: 20px;
  }

  .hrLanding_v3_VacanciesSubtitle {
    margin-bottom: 32px;
  }

  .hrLanding_v3_VacanciesButton {
    max-width: calc(100% - 80px);
  }

  /*CONSULTATION SECTION*/
  .hrLanding_v3_Consultation {
    padding: 120px 40px 64px;
  }

  .hrLanding_v3_ConsultationTitle {
    margin-bottom: 20px;
    text-align: left;
    font-size: 30px;
  }

  .hrLanding_v3_ConsultationInner {
    flex-direction: column;
    row-gap: 28px;
  }

  .hrLanding_v3_ConsultationDescription {
    max-width: 100%;
  }

  .hrLanding_v3_ConsultationText {
    margin-bottom: 0;
  }

  .hrLanding_v3_ConsultationImageContainer {
    max-width: 100%;
  }

  .hrLanding_v3_ConsultationButton {
    display: none;
  }

  .hrLanding_v3_ConsultationImageContainer .hrLanding_v3_ConsultationButtonTablet {
    margin-top: 48px;
    display: flex;
  }

  .hrLanding_v3_safety {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .greenStar {
    width: 148px;
    height: 148px;
    top: -60px;
    right: 48px;
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .hrLanding_v3Header {
    margin-bottom: 20px;
    padding: 48px 20px 15px;
  }

  .hrLanding_v3HeaderTitle {
    margin-bottom: 16px;
    font-size: 28px;
  }

  .hrLanding_v3HeaderSubtitle {
    margin-bottom: 24px;
    font-size: 16px;
  }

  .hrLanding_v3HeaderTitle span {
    color: var(--primary);
  }

  .hrLanding_v3HeaderImageContainer {
    margin-bottom: 24px;
    height: 270px;
  }

  .hrLanding_v3HeaderImageTablet {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    bottom: -7px;
  }

  .candidatesDatabase {
    padding-bottom: 0;
  }

  .candidatesDatabaseButton, .auctionSectionButton {
    margin-top: 32px;
    max-width: 100%;
  }

  .auctionStepImage {
    margin-bottom: 10px;
    width: 90px;
    height: 90px;
  }

  .hrLanding_v3 .auctionSection .auctionDashedCurve {
    height: 370px;
  }

  .hrLanding_v3_Vacancies {
    padding-bottom: 60px;
  }

  .hrLanding_v3_VacanciesButton {
    max-width: 100%;
  }

  /*CONSULTATION SECTION*/
  .hrLanding_v3_Consultation {
    padding: 90px 20px 60px;
  }

  .hrLanding_v3_ConsultationTitle {
    margin-bottom: 16px;
    font-size: 22px;
  }

  .hrLanding_v3_ConsultationInner {
    row-gap: 22px;
  }

  .hrLanding_v3_ConsultationImageContainer .hrLanding_v3_ConsultationButtonTablet {
    margin-top: 40px;
  }

  .hrLanding_v3_safety {
    margin-top: 30px;
  }

  .greenStar {
    width: 120px;
    height: 120px;
    top: -42px;
    right: 0;
    font-size: 14px;
  }

}
</style>
