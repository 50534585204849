<template>
  <div :class="style.landingHrPage">
    <section :class="style.heroSection">
      <div :class="style.heroSectionCtaContainer">
        <!--        <img :class="style.arrow" src="/img/icons/icon_curve_arrow.svg" alt="">-->
        <div :class="style.heroTitle">Никогда еще рекрутинг не был таким удобным</div>
        <div :class="style.heroSubtitle">HR мультитул. Управляйте всеми процессами в одном месте.</div>
        <rekroo-button :class="style.ctaButton" width="100%" @click="openAuthDialog">Присоединиться к Rekroo
        </rekroo-button>
        <router-link to="#candidateListSection" :class="style.linkToBottom">Как вам поможет Rekroo
          <rekroo-icon icon="arrow-bottom" style="margin-left: 12px" width="14px"/>
        </router-link>
      </div>
      <div :class="style.heroImageContainer">
        <img src="/img/hr_landing/hr_landing_hero_image.jpg" alt="" :class="style.heroImage">
        <router-link to="#candidateListSection" :class="style.linkToBottom">Как вам поможет Rekroo
          <rekroo-icon icon="arrow-bottom" style="margin-left: 12px" width="14px"/>
        </router-link>
      </div>
    </section>
    <landing-candidates-database-section title_1="Ваши будущие" title_2="кандидаты уже на Rekroo"
    subtitle="Выбирайте лучших кандидатов прямо из&nbsp;нашей актуальной и&nbsp;живой
      базы резюме, без&nbsp;необходимости переходить на&nbsp;сторонние сервисы" title_2_color="var(--bright-blue)"
    link="/hr/catalog"/>

    <HRMDescriptionSection/>

    <section :class="style.auctionSection">
      <h1 :class="style.auctionSectionTitle"><span>Аукцион с работодателем.</span><br>
        Ищите клиентов прямо на портале</h1>
      <p :class="style.auctionSectionSubtitle">Работодатель добавляет вакансию с оплатой за кандидата, вы находите
        работников за одноразовое вознаграждение от работодателя</p>
      <div :class="style.auctionSteps">
        <img src="/img/hr_landing/dashed_curve.png" alt="" :class="style.dashedCurve">
        <div :class="style.auctionStep" v-for="(step, i) in auctionSteps" :key="i">
          <div :class="style.auctionStepIcon">
            <img :src="step.img" alt="">
          </div>
          <div :class="style.auctionStepLabel" v-html="step.label"/>
        </div>
      </div>
      <router-link to="/hr/auction">
      <rekroo-button
          width="426px" height="50px" :class="style.auctionSectionButton">Найти заказ</rekroo-button>
      </router-link>
    </section>
    <section :class="style.consultationSection">
      <div :class="style.consultationDescription">
        <h1 :class="style.consultationSectionTitle">
          <span>Консультируйте и зарабатывайте.</span><br>
          Помогайте соискателям получить оффер мечты
        </h1>
        <p :class="style.consultationSectionSubtitle">Проводите карьерные консультации, помогайте составить грамотное
          резюме и подготовиться к собеседованию, чтобы соискатели быстрее нашли работу с хорошими условиями и классной
          командой</p>
      </div>
      <img src="/img/hr_landing/consultation_image.png" alt="" :class="style.consultationImage">
    </section>
    <section :class="style.mailingSection">
      <img src="/img/hr_landing/mailing_image.png" alt="" :class="style.mailingImage">
      <div :class="style.mailingDescription">
        <h1 :class="style.mailingSectionTitle">
          <span>Рассылка<br>по базе резюме.</span><br>
          Сократите время и усилия, затрачиваемые на поиск кандидатов
        </h1>
        <p :class="style.mailingSectionSubtitle">Отправляйте предложения о вакансиях тысячам кандидатов одновременно.
          Вы можете выбрать нужные критерии, такие как профессиональные навыки, опыт работы, уровень образования, чтобы
          предложение о вакансии было доставлено только квалифицированным кандидатам.</p>
      </div>
    </section>
    <section :class="style.vacanciesSection">
      <h1 :class="style.vacanciesSectionTitle"><span>Актуальные живые вакансии,</span><br>обновляющиеся ежедневно</h1>
      <p :class="style.vacanciesSectionSubtitle">Работодатель добавляет вакансию с оплатой за кандидата, вы находите
        работников за одноразовое вознаграждение от работодателя</p>
      <landing-vacancies :vacancies="vacancies" link="/hr/auction/"/>
      <router-link to="/hr/vacancies">
        <rekroo-button width="100%" :class="style.moreVacanciesButton">Больше вакансий</rekroo-button>
      </router-link>
    </section>
    <safety-section/>
   <landing-reviews-section :title="`Они уже попробовали <br> Rekroo и остались довольны`"/>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";
import RekrooIcon from "@/components/Icon";
import Tag from "@/components/Tag";
import style from "@/views/landing/styles/landingStyles.module.css"
import VacancyCard from "@/components/VacancyCard";
import LandingVacancies from "@/views/landing/components/LandingVacancies";
import LandingReviewsSection from "@/views/landing/components/LandingReviewsSection";
import LandingCandidatesDatabaseSection from "@/views/landing/components/LandingCandidatesDatabaseSection";
import HRMDescriptionSection from "@/views/landing/components/HRMDescriptionSection";
import SafetySection from "@/views/landing/components/SafetySection";
export default {
  name: "HrLanding",
  components: {
    SafetySection,
    HRMDescriptionSection,
    LandingCandidatesDatabaseSection,
    LandingReviewsSection, LandingVacancies, VacancyCard, Tag, RekrooIcon, RekrooButton},
  data: () => ({
    style,
    auctionSteps: [
      {
        label: `Работодатели создают заказы&nbsp;—&nbsp;описывают задачу, детали и предлагают цену`,
        img: '/img/hr_landing/icon_colored_briefcase.svg',
      },
      {
        label: 'Вы выбираете интересные заказы, откликаетесь и договариваетесь с работодателем об условиях',
        img: '/img/hr_landing/icon_colored_woman.svg',
      },
      {
        label: 'Выполняете заказ и получаете оплату напрямую от работодателя',
        img: '/img/hr_landing/icon_colored_money.svg',
      },
    ],
    vacancies: [
      {
        company_info: {title: 'Рекламное агенство Нерв'},
        position: 'Менеджер по продажам и аккаунтингу',
        location: 'Санкт-Петербург',
        salary_from: 60000,
      },
      {
        company_info: {title: 'Яндекс Крауд'},
        position: 'Автор текстов в Поиск',
        salary_from: '',
        remote_work: 1
      },
      {
        company_info: {title: 'ИП Максим Андреюк'},
        position: 'Менеджер отдела продаж',
        location: 'Москва',
        salary_from: 40000,
        salary_to: 65000,
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    openAuthDialog() {
      if (this.user.id) window.open('https://web.rekroo.org', '_blank');
      else this.$eventBus.$emit('open-auth-modal');
    }
  }
}
</script>

<style module lang="css">

</style>
