<template>
  <el-dialog :visible.sync="isVisible" width="820px" custom-class="rekroo-dialog info-dialog" :center="true">
    <div :class="$style.subscriptionContainer">
      <h1 :class="$style.subscriptionTitle">Выберите тарифный план email рассылки</h1>
      <div :class="$style.subscriptionHint">Воспользуйтесь возможностью разослать информацию о вакансии за пару кликов
      </div>
      <div :class="$style.rates">
        <div :class="$style.rate" v-for="(rate, i) in tariffs" :key="i">
          <div :class="$style.title">{{ rate.title }}</div>
          <h1 :class="$style.ratePrice">{{ thousandSeparator(rate.price) }} ₽</h1>
          <div :class="$style.ratePriceHint">Максимальное кол-во почт:
            {{ thousandSeparator(rate.amount) }}
          </div>
          <rekroo-button width="100%" :loading="order.type === rate.key ? loading : false"
                         :disabled="order.type !== rate.key && loading"
                         :class="$style.subscribeButton" @click="createOrder(rate.key)">Выбрать
          </rekroo-button>
        </div>
      </div>
      <SubscribeDialog ref="subscribe_dialog"/>
    </div>
  </el-dialog>
</template>

<script>
import RekrooButton from "@/components/Button";
import {Order} from "@/api/order";
import SubscribeDialog from "@/dialogs/SubscribeDialog";

export default {
  name: "MailingListTariffDialog",
  components: {SubscribeDialog, RekrooButton},
  emits: ['select'],
  props: ['openSchedule'],
  data: () => ({
    isVisible: false,
    orderClass: new Order({}),
    order: {},
    loading: false,
  }),
  computed: {
    tariffs() {
      let rates = [];
      if (this.$store.state.systemParams.productTypes) {
        let keys = Object.keys(this.$store.state.systemParams.productTypes)
            .filter(key => this.$store.state.systemParams.productTypes[key].type === 'mailing');
        keys.forEach(key => {
          this.$store.state.systemParams.productTypes[key].key = key;
          rates.push(this.$store.state.systemParams.productTypes[key]);
        });
      }
      return rates;
    },
    redirect_path() {
      return this.$store.state.redirect_path;
    }
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    createOrder(type) {
      this.$emit('select', type);
      this.order = {type}
      this.loading = true;
      this.orderClass.create({type,
        redirect_url: `${this.redirect_path}${this.$route.path}${this.openSchedule ? '?open_schedule=1' : ''}`}).then((data) => {
        this.loading = false;
        this.order = data.response;
        this.$router.push(`/order/${data.response.id}`);
      }).catch((data) => {
        this.loading = false;
        if (data.error?.type === 'bill_email') this.$refs.subscribe_dialog.open();
      })
    }
  }
}
</script>

<style module lang="css">
.subscriptionContainer {
  padding-top: 56px;
  max-width: 874px;
  width: 100%;
  margin: 0 auto;
}

.subscriptionContainer .subscriptionTitle {
  margin-bottom: 24px;
  text-align: center;
}

.subscriptionContainer .subscriptionHint {
  margin-bottom: 36px;
  font-size: var(--body);
  text-align: center;
  line-height: 144%;
}

.subscriptionContainer .rates {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 20px;
}

.subscriptionContainer .rates .rate {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 37px 32px 32px;
}

.subscriptionContainer .rates .rate .rateHeader {
  height: 42px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.subscriptionContainer .rates .rate .title {
  font-size: var(--subheadline);
  font-weight: 500;
  margin-bottom: 16px;
}

.subscriptionContainer .rates .rate .rateHeader .duration {
  font-size: var(--headline_2);
  font-weight: 600;
}

.rateAmount {
  margin-bottom: 36px;
}

.subscriptionContainer .rates .rate .rateHeader .discount {
  height: 42px;
  padding: 8px 12px;
  font-size: var(--body);
  font-weight: var(--body-medium-weight);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--yellow);
  border-radius: 8px;
}

.subscriptionContainer .rates .rate .ratePrice {
  font-size: 42px;
  margin-bottom: 16px;
}

.subscriptionContainer .rates .rate .ratePriceHint {
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: var(--small-text);
}

.subscriptionContainer .rates .rate .subscribeButton {
  margin-top: auto;
}

@media screen and (max-width: 1024px) {
  .subscriptionContainer {
    padding-top: 60px;
  }

  .subscriptionContainer .subscriptionTitle {
    margin-bottom: 20px;
  }

  .subscriptionContainer .subscriptionHint {
    margin-bottom: 28px;
    font-size: var(--small-text);
  }

  .subscriptionContainer .rates {
    grid-gap: 16px;
  }

  .subscriptionContainer .rates .rate {
    padding: 26px 20px 20px;
  }

  .subscriptionContainer .rates .rate .rateHeader {
    height: 33px;
    margin-bottom: 8px;
  }

  .subscriptionContainer .rates .rate .title {
    margin-bottom: 12px;
  }

  .subscriptionContainer .rates .rate .rateHeader .duration {
    font-size: var(--subheadline);
  }

  .subscriptionContainer .rates .rate .rateHeader .discount {
    height: 33px;
    padding: 6px 10px;
  }
}

@media screen and (max-width: 575px) {
  .subscriptionContainer {
    padding-top: 44px;
  }

  .subscriptionContainer .subscriptionTitle {
    margin-bottom: 16px;
    font-size: 24px;
  }

  .subscriptionContainer .rates {
    grid-template-columns: 1fr;
  }

  .subscriptionContainer .rates .rate {
    padding: 28px;
    height: fit-content;
  }

  .subscriptionContainer .rates .rate .rateHeader {
    height: 46px;
  }

  .subscriptionContainer .rates .rate .rateHeader .duration {
    font-size: 22px;
  }

  .subscriptionContainer .rates .rate .rateHeader .discount {
    height: 46px;
    padding: 10px;
  }

  .subscriptionContainer .rates .rate .ratePrice {
    font-size: 48px;
  }

  .subscriptionContainer .rates .rate .ratePriceHint {
    font-size: var(--headline_3);
    margin-top: 8px;
  }

  .subscriptionContainer .rates .rate .subscribeButton {
    margin-top: 20px;
  }

  .rateAmount {
    margin-bottom: 20px;
  }
}
</style>