<template>
  <div :class="[style.heroImageContainer, $style.heroImageContainer]">
    <img src="/img/applicant_landing/hero_section_chats.svg" alt="" :class="$style.heroSectionChats">
    <img src="/img/applicant_landing/hero_section_image.png" alt="" :class="$style.heroImage">
    <upload-resume-block/>
  </div>
</template>

<script>
import style from "@/views/landing/styles/landingStyles.module.css"
import RekrooIcon from "@/components/Icon";
import Vacancy from "@/api/vacancy";
import UploadResumeBlock from "@/components/UploadResumeBlock";

export default {
  name: "ApplicantLandingHeroImage",
  components: {UploadResumeBlock, RekrooIcon},
  data: ()=>({
    style,
    vacancyClass: new Vacancy({}),
  }),
  methods: {

  }
}
</script>

<style module>

.heroImageContainer {
  position: relative;
  border-radius: 16px;
  background: var(--green);
  overflow: hidden;
}

.heroImageContainer .heroImage {
  max-width: 410px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 20px;
}
.heroSectionChats {
  position: absolute;
  top: 147px;
  left: 28px;
}

@media screen and (max-width: 1024px) {
  .heroImageContainer {
    border-radius: 8px;
    overflow: visible;
  }
  .heroImageContainer .heroImage {
    max-width: 260px;
    right: 16px;
  }
  .heroSectionChats {
    top: 28px;
    left: 94px;
    height: 105px;
  }
}

@media screen and (max-width: 575px ) {
  .heroImageContainer .heroImage {
    max-width: 190px;
    right: 10px;
  }
  .heroSectionChats {
    top: 36px;
    left: 20px;
    height: 84px;
  }
}


</style>