<template>
  <el-dialog :visible.sync="isVisible" width="427px" custom-class="rekroo-dialog profile-edit-dialog" :center="true">
    <el-form :rules="rules" :model="user" ref="form">
      <el-form-item label="Имя">
        <el-input v-model="user.name" class="gray-bg"/>
      </el-form-item>
      <el-form-item label="Фамилия">
        <el-input v-model="user.surname" class="gray-bg"/>
      </el-form-item>
      <el-form-item label="Телефон">
        <el-input v-model="user.phone" class="gray-bg" v-maska="$store.state.phone_mask"
                  :key="random"
                  disabled
                  placeholder="+7 (  )"
                  type="tel"
                  autocomplete="tel"
                  @input="onPhoneInput"/>
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="user.email" class="gray-bg" disabled
                  type="email" autocomplete="email"/>
      </el-form-item>
      <el-form-item label="Дата рождения" prop="birthday">
        <div :class="$style.birthdayContainer">
          <el-input v-model="tempBirthday.day" v-maska="'##'" placeholder="ДД" :class="$style.birthdayInput"
                    @input="v=>{v.length === 2 ? $refs.monthSelect.$el.click() : ''}" class="gray-bg"/>
          <el-select ref="monthSelect" v-model="tempBirthday.month" placeholder="ММ" :class="$style.birthdayInput"
                     @change="onMonthSelect" class="gray-bg">
            <el-option v-for="(month, i) in $store.state.months" :key="i" :value="i+1" :label="month"/>
          </el-select>
          <el-input ref="yearInput" v-model="tempBirthday.year" v-maska="'####'" placeholder="ГГГГ"
                    :class="$style.birthdayInput"
                    class="gray-bg">
            <template #suffix v-if="Object.values(tempBirthday).filter(v=>v).length">
              <div @click="tempBirthday = {}" class="el-icon-circle-close"/>
            </template>
          </el-input>
        </div>
        <!--        <el-date-picker type="date" v-model="user.birthday" value-format="yyyy-MM-dd" format="dd.MM.yyyy"
                                lang="ru" placeholder="ДД.ММ.ГГГГ" class="no-prefix gray-bg" v-maska="'##.##.####'"/>-->
      </el-form-item>
      <rekroo-button width="100%" @click="save" :loading="loading"
                     style="margin-top: 32px">Сохранить</rekroo-button>
    </el-form>
  </el-dialog>
</template>

<script>
import {User} from "@/api/user";
import RekrooButton from "@/components/Button";

export default {
  name: "ProfileEditDialog",
  components: {RekrooButton},
  emits: ['save'],
  data() {
    let birthdayValidator = (_, value, callback) => {
      if ((Object.values(this.tempBirthday).filter(v => v).length && Object.values(this.tempBirthday).filter(v => v).length < 3) ||
          +this.tempBirthday.day > 31 || +this.tempBirthday.day <= 0 || +this.tempBirthday.year < 1900) {
        callback(new Error('Дата указана неверно'));
      } else if (Object.values(this.tempBirthday).filter(v => v).length &&
          this.$moment().diff(this.$moment(`${this.tempBirthday.day}.${this.tempBirthday.month}.${this.tempBirthday.year}`, 'DD.MM.YYYY'), 'years') < 14) {
        callback(new Error('Вам должно быть больше 14 лет'));
      } else callback();
    };
    return {
      isVisible: false,
      user: new User({}),
      userClass: new User({}),
      loading: false,
      random: Math.random(),
      tempBirthday: {
        day: '',
        month: '',
        year: ''
      },
      rules: {
        birthday: [
          {trigger: 'change', validator: birthdayValidator},
        ],
      }
    }
  },
  methods: {
    open() {
      this.isVisible = true;
      this.user = {...this.$store.state.user};
      this.dataFromResponse(this.user);
      this.random = Math.random();
    },
    save() {
      delete this.user.image;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          this.userClass.create(this.dataToRequest()).then((data) => {
            this.loading = false;
            this.$store.commit('setUser', data.response);
            this.$emit('save');
            this.$notify({
              type: 'success',
              title: 'Профиль обновлен успешно'
            })
            this.isVisible = false;
          }).catch(() => {
            this.loading = false;
          })
        }
      })
    },
    dataFromResponse(data) {
      if (data.birthday) {
        this.tempBirthday.year = data.birthday.split('-')[0];
        this.tempBirthday.month = +data.birthday.split('-')[1];
        this.tempBirthday.day = data.birthday.split('-')[2];
      }
    },
    dataToRequest() {
      let data = {...this.user};
      if (Object.values(this.tempBirthday).filter(v=>v).length === 3) {
        if (+this.tempBirthday.month < 10) {
          this.tempBirthday.month = `0${this.tempBirthday.month}`;
        }
        if (+this.tempBirthday.day < 10) {
          this.tempBirthday.day = `0${+this.tempBirthday.day}`;
        }
        data.birthday = Object.values(this.tempBirthday).reverse().join('-');
      }
      return data;
    },
    onMonthSelect() {
      this.$nextTick(() => {
        this.$refs.yearInput.$el.children[0].focus();
      })
    },
    onPhoneInput(v) {
      if (this.ignore8onPhoneInput(v)) this.user.phone = this.ignore8onPhoneInput(v);
    }
  }
}
</script>

<style module>

.birthdayContainer {
  display: flex;
  column-gap: 2px;
}

.birthdayInput {
  width: fit-content !important;
}

.birthdayInput:first-child input {
  width: 70px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.birthdayInput:nth-child(2) input {
  border-radius: 0 !important;
}

.birthdayInput:last-child input {
  width: 90px;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>