<template>
<div :class="$style.introBlock">{{title}}</div>
</template>

<script>
export default {
  name: "IntroBlock",
  props: ['title'],
}
</script>

<style module lang="css">
.introBlock {
  width: 100%;
  height: 174px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--headline_1);
  font-weight: 700;
  border-radius: 16px;
  margin: 0 auto;
  will-change: transform, background-color;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .introBlock {
    height: 156px;
  }
}

@media screen and (max-width: 575px) {
  .introBlock {
    height: 78px;
    font-size: 18px;
  }
}
</style>