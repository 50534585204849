<template>
  <div :class="style.vacancy">
    <div :class="style.companyName">
      <skeleton variant="circle" width="48px" height="48px"/>
      <skeleton variant="p" width="200px"/>
    </div>
    <div :class="style.vacancyTitle">
      <skeleton variant="text" width="250px"/>
    </div>
    <div :class="style.vacancyDesc">
      <skeleton variant="p" width="100%"/>
      <skeleton variant="p" width="100%"/>
      <skeleton variant="p" width="170px"/>
    </div>
    <div :class="style.vacancyLocation">
      <skeleton type="button" width="150px" height="40px"/>
      <skeleton type="button" width="130px" height="40px"/>
    </div>
    <skeleton type="button" width="170px" height="40px"/>
  </div>
</template>

<script>
import style from "@/components/styles/vacancyCardStyles.module.css"
import Skeleton from "@/components/skeletons/Skeleton";
export default {
  name: "VacancyCardSkeleton",
  components: {Skeleton},
  data: ()=>({
    style,
  })
}
</script>

<style scoped>

</style>