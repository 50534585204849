<template>
  <div :class="style.orderOuter">
    <div :class="style.orderInner">
      <div :class="style.orderType">
        <skeleton variant="p" height="var(--headline_3)" width="200px"/>
      </div>
      <div :class="[style.orderParam]">
        <skeleton variant="p" max-width="180px"/>
        <div :class="style.totalPrice">
          <skeleton variant="p" max-width="70px"/>
        </div>
      </div>
      <div :class="style.orderPrice">К оплате:
        <skeleton variant="p" height="var(--headline_3)" width="100px"/>
      </div>
      <rekroo-button width="100%">Оплатить</rekroo-button>
    </div>
  </div>
</template>

<script>
import style from "@/views/rates/styles/OrderStyle.module.css"
import Skeleton from "@/components/skeletons/Skeleton";
import RekrooButton from "@/components/Button";
export default {
  name: "OrderSkeleton",
  components: {RekrooButton, Skeleton},

  data: ()=>({
    style,
  })
}
</script>

<style scoped>

</style>