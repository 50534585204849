<template>
  <el-dialog :visible.sync="isVisible"
             custom-class="rekroo-dialog vacancy-rates-dialog" :center="true">
    <ResumeRates @on-create="onCreate" :redirect_url="redirect_url" :from="from" @close="isVisible = false"/>
  </el-dialog>
</template>

<script>
import ResumeRates from "@/views/rates/ResumeRates";
export default {
  name: "ResumeRatesDialog",
  props: ['redirect_url'],
  components: {ResumeRates},
  data: ()=>({
    isVisible: false,
    from: '',
  }),
  methods: {
    open(from) {
      this.from = '';
      this.isVisible = true;
      this.from = from;
    },
    onCreate() {
      this.isVisible = false;
    }
  }
}
</script>

<style scoped>

</style>