<template>
  <div :class="auctionStyles.orderItemPageContent" class="page-with-navigation">
    <div :class="auctionStyles.orderCreatorContainer">
      <div :class="auctionStyles.orderCreator">
        <div :class="auctionStyles.orderCreatorHeader">
          <rekroo-icon icon="image" width="48px" color="var(--gray-text)"/>
          <skeleton variant="text" :class="auctionStyles.title"/>
        </div>
      </div>
    </div>
    <div :class="auctionStyles.orderItemPageContentInner">
      <div :class="auctionStyles.orderContainer">
        <div :class="auctionStyles.orderInner" ref="order">
          <div :class="auctionStyles.orderHeader">
            <skeleton variant="text" :class="auctionStyles.date" max-width="200px"/>
            <skeleton variant="button" height="46px" width="46px"/>
          </div>
          <skeleton variant="h1" :class="auctionStyles.orderTitle"/>
          <div :class="auctionStyles.orderTags">
            <skeleton variant="button" height="42px" width="140px"/>
            <skeleton variant="button" height="42px" width="150px"/>
            <skeleton variant="button" height="42px" width="110px"/>
          </div>
          <el-skeleton variant="p" :class="auctionStyles.orderDesc"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auctionStyles from '@/components/styles/AuctionItemStyles.module.css'
import RekrooIcon from "@/components/Icon";
import Skeleton from "@/components/skeletons/Skeleton";

export default {
  name: "AuctionItemSkeleton",
  components: {Skeleton, RekrooIcon},
  data: () => ({
    auctionStyles,
  })
}
</script>

<style scoped>

</style>