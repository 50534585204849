<template>
  <el-form>
    <el-form-item label="Город" :class="$style.filtersLocation">
      <el-autocomplete
          :class="{'gray-bg': isGray}"
          placeholder="" v-model="filters.location"
          :fetch-suggestions="fetchSuggestionsLocation"
          :trigger-on-focus="false"
      />
      <el-switch :value="!!filters.remote_work" active-text="Удаленная работа" v-if="type === 'vacancy'"
                 @change="onRemoteWorkChange"/>
    </el-form-item>
    <el-form-item label="Зарплата">
      <div :class="$style.salaryItem">
        <div :class="$style.salary">
          <el-input placeholder="от" v-model="filters.salary_from" :class="[$style.salaryFromInput, {'gray-bg': isGray}]"
                    v-maska="$store.state.maskThousandSeparator"/>
          <el-input v-if="type === 'resume'" placeholder="до" v-model="filters.salary_to" :class="[$style.salaryToInput, {'gray-bg': isGray}]"
                    v-maska="$store.state.maskThousandSeparator"/>
        </div>
        <el-select v-model="filters.currency" placeholder="" style="max-width: 72px;" :class="{'gray-bg': isGray}">
          <el-option
              v-for="(item, key) in $store.state.systemParams.currencies"
              :key="key"
              :label="item.short"
              :value="key">
          </el-option>
        </el-select>
      </div>
    </el-form-item>
    <div :class="$style.employmentAndSchedule" v-if="type === 'vacancy'">
      <el-form-item label="Занятость" :class="$style.employment">
        <el-checkbox v-for="(type, key) in $store.state.systemParams.employment_types" :key="key" :label="type"
                     :class="$style.employmentAndScheduleCheckbox"
                     @change="onEmploymentSelect(key)"
                     :value="filters.employment_type.includes(key)"/>
      </el-form-item>
      <el-form-item label="График" :class="$style.employment">
        <el-checkbox v-for="(type, key) in $store.state.systemParams.schedule_options" :key="key" :label="type"
                     :class="$style.employmentAndScheduleCheckbox"
                     @change="onScheduleSelect(key)"
                     :value="filters.schedule.includes(key)"/>
      </el-form-item>
    </div>
    <el-form-item label="Опыт работы">
      <el-input v-model="filters.experience" placeholder="От 3 лет" v-maska="'##'" :class="{'gray-bg': isGray}"/>
    </el-form-item>
    <el-form-item label="Навыки" v-if="type === 'resume'">
      <div :class="$style.skills">
        <tag :class="$style.skillTag" v-for="(skill, i) in filters.skills" :key="skill" :text="skill"
             icon="close" icon_pos="end" icon_width="16px"
             color="var(--blue)" @onIconClick="removeSkill(i)"/>
      </div>
      <div :class="$style.skillsInputContainer">
        <el-autocomplete
            :class="{'gray-bg': isGray}"
            placeholder="Начните вводить" v-model="skill"
            :fetch-suggestions="fetchSuggestionsSkills"
            :trigger-on-focus="false"
            @select="onSkillSelect"
            @keydown.native="onKeydownSkillInput"
        />
        <rekroo-button :class="$style.addSkillButton" icon="plus" color="var(--purple)" icon_color="#fff"
                       icon_width="24px"
                       width="50px" height="50px" @click="onKeydownSkillInput"/>
      </div>
    </el-form-item>
    <el-form-item label="Язык" v-if="type === 'resume'">
      <div :class="$style.skills">
        <tag :class="$style.skillTag" v-for="(lang, i) in filters.languages" :key="i"
             :text=" $store.state.systemParams.languageNames ? `${$store.state.systemParams.languageNames[Object.keys(lang)[0]]} - ${lang[Object.keys(lang)[0]]}` : ''"
             icon="close" icon_pos="end" icon_width="16px"
             color="var(--blue)" @onIconClick="removeLang(i)"/>
      </div>
      <el-select v-model="langName" placeholder="Выберите из списка" style="margin-bottom: 8px" :class="{'gray-bg': isGray}">
        <el-option v-for="(value, key) in $store.state.systemParams.languageNames"
                   :disabled="filters.languages && filters.languages.map(lang=>Object.keys(lang)[0]).includes(key)"
                   :key="key" :label="value" :value="key"/>
      </el-select>
      <el-select v-model="langLevel" placeholder="Уровень владения" style="margin-bottom: 8px" :class="{'gray-bg': isGray}">
        <el-option v-for="(value, key) in $store.state.systemParams.languageLevels" :key="key" :label="value"
                   :value="key"/>
      </el-select>
      <rekroo-button color="var(--purple)" width="100%" @click="addLang">Добавить</rekroo-button>
    </el-form-item>
    <el-form-item label="Категория водительских прав" class="align-left">
      <el-select v-model="filters.driver_license" placeholder="Категория прав" multiple :class="{'gray-bg': isGray}">
        <el-option v-for="(item, i) in $store.state.systemParams.driver_license_types" :key="i"
                   :label="item" :value="i*1"/>
      </el-select>
    </el-form-item>
    <el-form-item v-if="type === 'resume'" class="align-left">
      <el-checkbox label="Только с фото" v-model="filters.image"/>
    </el-form-item>
<!--    <el-form-item label="Уровень образования" class="align-left">
      <el-select v-model="filters.educational_levels" placeholder="Уровень образования" multiple>
        <el-option v-for="(item, i) in $store.state.systemParams.educational_levels" :key="i"
                   :label="item" :value="i*1"/>
      </el-select>
    </el-form-item>-->
    <rekroo-button width="100%" @click="$emit('apply')" v-if="!withoutButton">Применить</rekroo-button>
  </el-form>
</template>

<script>
import {User} from "@/api/user";
import Tag from "@/components/Tag";
import RekrooButton from "@/components/Button";

export default {
  name: "CatalogFiltersForm",
  components: {RekrooButton, Tag},

  props: ['filters', 'type', 'isGray', 'withoutButton'],
  emits: ['on-employment-select', 'on-schedule-select', 'apply', 'on-skills-change'],
  data: () => ({
    userClass: new User({}),
    employmentTypes: ['Полная занятость', 'Частичная занятость', 'Проектная работа', 'Стажировка', 'Волонтерство'],
    scheduleTypes: ['Полный день', 'Гибкий график', 'Сменный график', 'Вахтовый метод'],
    skill: '',
    langName: '',
    langLevel: ''
  }),

  methods: {
    onEmploymentSelect(type) {
      if (this.filters.employment_type.includes(type)) {
        let typeIndex = this.filters.employment_type.indexOf(type);
        this.filters.employment_type.splice(typeIndex, 1);
      } else {
        this.filters.employment_type.push(type);
      }
    },
    onScheduleSelect(type) {
      if (this.filters.schedule.includes(type)) {
        let typeIndex = this.filters.schedule.indexOf(type);
        this.filters.schedule.splice(typeIndex, 1);
      } else {
        this.filters.schedule.push(type);
      }
    },
    fetchSuggestionsLocation(q, cb) {
      this.userClass.suggestions('location', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    fetchSuggestionsSkills(q, cb) {
      this.userClass.suggestions('skill', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    onSkillSelect(skill) {
      if (!skill.value) return;
      this.$emit('on-skill-add', skill.value);
      // this.filters.skills.push(skill.value);
      this.skill = '';
    },
    onKeydownSkillInput(e) {
      if (!e || e.code === 'Enter') {
        this.onSkillSelect({value: this.skill})
      }
    },
    removeSkill(i) {
      this.filters.skills.splice(i, 1);
    },
    removeLang(i) {
      this.filters.languages.splice(i, 1);
    },
    addLang() {
      let existingLangKeys = this.filters.languages.length ? this.filters.languages.map(lang => Object.keys(lang)[0]) : [];
      if (this.langName && this.langLevel && !existingLangKeys.includes(this.langName)) {
        let lang = {};
        lang[this.langName] = this.langLevel;
        this.filters.languages.push(lang);
        this.langName = '';
        this.langLevel = '';
      }
    },
    onRemoteWorkChange(val) {
      this.filters.remote_work = val ? 1 : 0;
      this.$forceUpdate();
    }
  }
}
</script>

<style module lang="css">

.catalogPage .catalogPageContent .catalogPageContentInner .sideContent .filters .filtersLocation {
  margin-bottom: 20px;
}

.salaryItem {
  display: flex;
  column-gap: 4px;
  margin-bottom: 8px;
}

.salaryItem .salary {
  display: flex;
}

.salaryItem .salary .salaryFromInput {
  margin-right: 2px;
}

.salaryItem .salary .salaryFromInput input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.salaryItem .salary .salaryToInput input {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.employmentAndSchedule {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.employment > * {
  display: flex;
  flex-direction: column;
}

.employment > label {
  margin-bottom: 14px !important;
}

.employmentAndScheduleCheckbox {
  line-height: 100%;
  margin-bottom: 10px;
  margin-right: 0;
}

.employmentAndScheduleCheckbox:last-child {
  margin-bottom: 0;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 8px;
}

.skillsInputContainer {
  display: flex;
  column-gap: 8px;
}

.skills .skillTag {
  font-size: 12px !important;
}

@media screen and (max-width: 1024px) {
  .employmentAndSchedule {
    flex-direction: column;
    row-gap: 20px;
  }
}

@media screen and (max-width: 575px) {
  .addSkillButton {
    width: 45px !important;
    height: 45px !important;
  }
}
</style>