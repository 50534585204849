<template>
  <div class="page doc-page">
    <h1>ПУБЛИЧНАЯ ОФЕРТА</h1>
    <h2>о заключении договора информационно - консультативных услуг</h2>
    <h2>1. Общие положения</h2>
    <p>В настоящей Публичной оферте содержатся условия заключения Договора информационно-консультативных услуг (далее по
      тексту - «Договор информационно- консультативных услуг» и/или «Оферта», «Договор»). Настоящей офертой признается
      предложение, адресованное одному или нескольким конкретным лицам, которое достаточно определенно и выражает
      намерение лица, сделавшего предложение, считать себя заключившим Договор с адресатом, которым будет принято
      предложение.</p>
    <p>Совершение указанных в настоящей Оферте действий является подтверждением согласия обеих Сторон заключить Договор
      информационно-консультативных услуг на условиях, в порядке и объеме, изложенных в настоящей Оферте.</p>
    <p>Нижеизложенный текст Публичной оферты является официальным публичным предложением Исполнителя, адресованный
      заинтересованному кругу лиц заключить Договор информационно-консультативных услуг в соответствии с положениями
      пункта 2 статьи 437 Гражданского кодекса РФ.</p>
    <p>Договор информационно-консультативных услуг считается заключенным и приобретает силу с момента совершения
      Сторонами действий, предусмотренных в настоящей Оферте, и, означающих безоговорочное, а также полное принятие всех
      условий настоящей Оферты без каких-либо изъятий или ограничений на условиях присоединения.</p>
    <p><strong>Термины и определения:</strong></p>
    <p><strong>Договор</strong> – текст настоящей Оферты с Приложениями, являющимися неотъемлемой частью настоящей Оферты, акцептованный
      Заказчиком путем совершения конклюдентных действий, предусмотренных настоящей Офертой.</p>
    <p><strong>Конклюдентные действия</strong> — это поведение, которое выражает согласие с предложением контрагента заключить, изменить или расторгнуть договор. Действия состоят в полном или частичном выполнении условий, которые предложил контрагент.</p>
    <p><strong>Сайт Исполнителя в сети «Интернет»</strong> – совокупность программ для электронных вычислительных машин и иной информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством сети «Интернет» по доменному имени и сетевому адресу: ShopUrl</p>
    <p><strong>Стороны Договора (Стороны)</strong> – Исполнитель и Заказчик.</p>
    <p><strong>Услуга</strong> – информационно-консультативные услуги, оказываемые Исполнителем Заказчику в порядке и на условиях, установленных настоящей Офертой.
    </p>
    <h2>2. Предмет Договора</h2>
    <p>2.1. Исполнитель обязуется оказать Заказчику информационно-консультационные услуги, а Заказчик обязуется оплатить их в размере, порядке и сроки, установленные настоящим Договором.</p>
    <p>2.2. Наименование, количество, порядок и иные условия оказания Услуг определяются на основании сведений Исполнителя при оформлении заявки Заказчиком, либо устанавливаются на сайте Исполнителя в сети «Интернет» ShopUrl.</p>
    <p>2.3. Исполнитель оказывает услуги по настоящему Договору лично, либо с привлечением третьих лиц, при этом за действия третьих лиц Исполнитель отвечает перед Заказчиком как за свои собственные.</p>
    <p>2.4. Акцепт настоящей Оферты выражается в совершении конклюдентных действий, в частности:</p>
    <ul>
      <li>действиях, связанных с регистрацией учетной записи на Сайте Исполнителя в сети «Интернет» при наличии необходимости регистрации учетной записи;</li>
      <li>путем составления и заполнения заявки на оформление заказа по оказанию Услуг;</li>
      <li>путем сообщения требуемых для заключения Договора сведений по телефону, электронной почте, указанными на сайте Исполнителя в сети «Интернет», в том числе, при обратном звонке Исполнителя по заявке Заказчика;</li>
      <li>оплаты Услуг Заказчиком.</li>
    </ul>
    <p>Данный перечень не является исчерпывающим, могут быть и другие действия, которые ясно выражают намерение лица принять предложение контрагента.
    </p>
    <h2>3. Права и обязанности Сторон</h2>
    <p>3.1. <strong>Исполнитель обязан:</strong></p>
    <p>3.1.1.Во исполнение заявки Заказчика:</p>
    <ul>
      <li>проанализировать информацию, документы и иные материалы, предоставленные Заказчиком;</li>
      <li>ответить на вопросы Заказчика, исходя из изученных документов и полученной от Заказчика информации;</li>
      <li>описать потенциальные риски и дать прогноз развития ситуации;</li>
      <li>при необходимости составить проекты документов.</li>
    </ul>
    <p>3.1.2.Оказать информационно-консультативные услуги в сроки, согласно условиям
      настоящего Договора, и с надлежащим качеством.</p>
    <p>3.2. <strong>Заказчик обязан:</strong></p>
    <p>3.2.1. Предоставить Исполнителю документацию и информацию, необходимые последнему для исполнения принятых на себя обязательств</p>
    <p>3.2.2.Оказывать всевозможное содействие Исполнителю в исполнении последним своих обязательств по настоящему Договору.</p>
    <p>3.2.3.Своевременно произвести оплату стоимости услуг Исполнителя в соответствии с условиями настоящей Оферты.</p>
    <p>3.3. <strong>Исполнитель имеет право:</strong></p>
    <p>3.3.1.Получать от Заказчика документы, разъяснения и дополнительные сведения, касающиеся вопроса консультирования и необходимые для качественного оказания</p>
    <p>3.4. <strong>Заказчик имеет право:</strong></p>
    <p>3.4.1.Осуществлять контроль за ходом оказания услуг, не вмешиваясь при этом в деятельность Исполнителя.</p>
    <p>3.4.2.Отказаться от исполнения настоящего Договора при условии оплаты Исполнителю фактически понесенных им расходов.</p>
    <p>3.4.3.Заказчик гарантирует, что все условия Договора ему понятны; Заказчик принимает условия без оговорок, а также в полном объеме.</p>
    <h2>4. Цена и порядок расчетов</h2>
    <p>4.1. Стоимость, а также порядок оказания информационно-консультативных услуг определяется на основании сведений Исполнителя при оформлении заявки Заказчиком, либо устанавливаются на сайте Исполнителя в сети «Интернет»: ShopUrl</p>
    <p>4.2. Все расчеты по Договору производятся в безналичном порядке.</p>
    <h2>5. Конфиденциальность и безопасность</h2>
    <p>5.1. При реализации настоящего Договора Стороны обеспечивают конфиденциальность и безопасность персональных данных в соответствии с актуальной редакцией ФЗ от 27.07.2006 г. No 152-ФЗ «О персональных данных» и ФЗ от 27.07.2006 г. No 149-ФЗ «Об информации, информационных технологиях и о защите информации».</p>
    <p>5.2. Стороны обязуются сохранять конфиденциальность информации, полученной в ходе исполнения настоящего Договора, и принять все возможные меры, чтобы предохранить полученную информацию от разглашения.</p>
    <p>5.3. Под конфиденциальной информацией понимается любая информация, передаваемая Заказчиком и Исполнителем в процессе реализации Договора и подлежащая защите, исключения указаны ниже.</p>
    <p>5.4. Такая информация может содержаться в предоставляемых Исполнителем локальных нормативных актах, договорах, письмах, отчетах, аналитических материалах, результатах исследований, схемах, графиках, спецификациях и других документах, оформленных как на бумажных, так и на электронных носителях.
    </p>
    <h2>6. Форс-мажор</h2>
    <p>6.1. Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору, если надлежащее исполнение оказалось невозможным вследствие непреодолимой силы, то есть чрезвычайных и непредотвратимых при данных условиях обстоятельств, под которыми понимаются: запретные действия властей, эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или другие стихийные бедствия.</p>
    <p>6.2. В случае наступления этих обстоятельств Сторона обязана в течение 30 (Тридцати) рабочих дней уведомить об этом другую Сторону.</p>
    <p>6.3. Документ, выданный уполномоченным государственным органом, является достаточным подтверждением наличия и продолжительности действия непреодолимой силы.</p>
    <p>6.4. Если обстоятельства непреодолимой силы продолжают действовать более 60 (Шестидесяти) рабочих дней, то каждая Сторона вправе отказаться от настоящего Договора в одностороннем порядке.</p>
    <h2>7. Ответственность Сторон</h2>
    <p>7.1. В случае неисполнения и/или ненадлежащего исполнения своих обязательств по Договору, Стороны несут ответственность в соответствии с условиями настоящей Оферты.</p>
    <p>7.2. Сторона, не исполнившая или ненадлежащим образом исполнившая обязательства по Договору, обязана возместить другой Стороне причиненные такими нарушениями убытки.</p>
    <h2>8. Срок действия настоящей Оферты</h2>
    <p>8.1. Оферта вступает в силу с момента ее размещения на Сайте Исполнителя и действует до момента её отзыва Исполнителем.</p>
    <p>8.2. Исполнитель оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. Сведения об изменении или отзыве Оферты доводятся до Заказчика по выбору Исполнителя посредством размещения на сайте Исполнителя в сети «Интернет», в Личном кабинете Заказчика, либо путем направления соответствующего уведомления на электронный или почтовый адрес, указанный Заказчиком при заключении Договора или в ходе его исполнения.</p>
    <p>8.3. Договор вступает в силу с момента Акцепта условий настоящей Оферты Заказчиком и действует до полного исполнения Сторонами обязательств по Договору.</p>
    <p>8.4. Изменения, внесенные Исполнителем в Договор и опубликованные на сайте в форме актуализированной Оферты, считаются принятыми Заказчиком в полном объеме.
    </p>
    <h2>9. Дополнительные условия</h2>
    <p>9.1. Договор, его заключение и исполнение регулируется действующим законодательством Российской Федерации. Все вопросы, не урегулированные настоящей Офертой или урегулированные не полностью, регулируются в соответствии с материальным правом Российской Федерации.</p>
    <p>9.2. В случае возникновения спора, который может возникнуть между Сторонами в ходе исполнения ими своих обязательств по Договору, заключенному на условиях настоящей Оферты, Стороны обязаны урегулировать спор мирным путем до начала судебного разбирательства.</p>
    <p>Судебное разбирательство осуществляется в соответствии с законодательством Российской Федерации.</p>
    <p>Споры или разногласия, по которым Стороны не достигли договоренности, подлежат разрешению в соответствии с законодательством РФ. Досудебный порядок урегулирования спора является обязательным.</p>
    <p>9.3. В качестве языка Договора, заключаемого на условиях настоящей Оферты, а также языка, используемого при любом взаимодействии Сторон (включая ведение переписки, предоставление требований / уведомлений / разъяснений, предоставление документов и т. д.), Стороны определили русский язык.</p>
    <p>9.4. Все документы, подлежащие предоставлению в соответствии с условиями настоящей Оферты, должны быть составлены на русском языке либо иметь перевод на русский язык, удостоверенный в установленном порядке.</p>
    <p>9.5. Бездействие одной из Сторон в случае нарушения условий настоящей Оферты не лишает права заинтересованной Стороны осуществлять защиту своих интересов позднее, а также не означает отказа от своих прав в случае совершения одной из Сторон подобных либо сходных нарушений в будущем.</p>
    <p>9.6. Если на Сайте Исполнителя в сети «Интернет» есть ссылки на другие веб-сайты и материалы третьих лиц, такие ссылки размещены исключительно в целях информирования, и Исполнитель не имеет контроля в отношении содержания таких сайтов или материалов. Исполнитель не несет ответственность за любые убытки или ущерб, которые могут возникнуть в результате использования таких ссылок.</p>
    <h2>10. Реквизиты Исполнителя</h2>
    <p>Полное наименование: ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ТЕХИНФОРМГРУПП"</p>
    <p>ИНН: 9705153891</p>
    <p>ОГРН/ОГРНИП: 1217700136598</p>
    <p>Контактный телефон: <a href="tel: +7 909 669-43-81">+7 909 669-43-81</a></p>
    <p>Контактный e-mail: <a href="mailto: rekroo@khromov.su">rekroo@khromov.su</a></p>
  </div>
</template>

<script>
export default {
  name: "Offer"
}
</script>

<style scoped>

</style>