<template>
<el-dialog :visible.sync="isVisible" width="950px"
           custom-class="rekroo-dialog vacancy-form-dialog" :center="true">
  <UpdateVacancy :hide-hint="true" :from-dialog="true" @create="onVacancyCreate"/>
</el-dialog>
</template>

<script>
import UpdateVacancy from "@/views/company/vacancy/Update";
export default {
  name: "VacancyFormDialog",
  components: {UpdateVacancy},
  emits: ['create'],
  data: ()=>({
    isVisible: false,
  }),
  methods: {
    open() {
      this.isVisible = true;
    },
    onVacancyCreate(vacancy) {
      this.$emit('create', vacancy);
      this.isVisible = false;
    }
  }
}
</script>

<style scoped>

</style>