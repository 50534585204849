<template>
  <div class="page page-with-tabs-table" :class="$style.listVacanciesPage">
    <h1 :class="$style.listVacanciesPageTitle">Вакансии
      <router-link :to="`/${$route.path.split('/')[1]}/vacancies/create`"
                   :class="$style.createVacancyButton"
      >
        <rekroo-button v-if="!authError" height="50px">+ Новая вакансия</rekroo-button>
      </router-link>
    </h1>
    <empty-list-plug v-if="authError" title="Доступ ограничен"
                     subtitle="Авторизуйтесь, чтобы иметь возможность просматривать свои вакансии"
                     button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
                     style="margin: 0 auto" :show-icon="true"/>
    <el-tabs v-else v-model="activeTab" :before-leave="onTabChange">
      <el-tab-pane label="Активные" name="active">
        <vacancies-list-tab :loading="tablesData.active.loading" :loaded="tablesData.active.loaded"
                            :data="tablesData.active.data"
                            name="active"
                            @on-selection-change="val=>handleSelectionChange(val, 'active')"
                            @on-remove-btn-click="id=>$refs.confirm_remove_vacancy_dialog.open({id})"/>
      </el-tab-pane>
      <el-tab-pane label="Неопубликованные" name="not_publish">
        <vacancies-list-tab :loading="tablesData.not_publish.loading" :loaded="tablesData.not_publish.loaded"
                            name="not_publish"
                            :data="tablesData.not_publish.data"
                            @on-selection-change="val=>handleSelectionChange(val, 'not_publish')"
                            @on-remove-btn-click="id=>$refs.confirm_remove_vacancy_dialog.open({id})"/>
      </el-tab-pane>
      <el-tab-pane label="Архив" name="archive">
        <vacancies-list-tab :loading="tablesData.archive.loading" :loaded="tablesData.archive.loaded"
                            name="archive"
                            :data="tablesData.archive.data"
                            @on-selection-change="val=>handleSelectionChange(val, 'archive')"
                            @on-recover-btn-click="id=>recover([id])"/>
      </el-tab-pane>
    </el-tabs>
    <div :class="$style.actions" v-if="currentVacancies.length">
      <div v-if="currentSelectedVacancies.length" :class="$style.listActions">
        <rekroo-button type="outlined"
                       @click="$refs.confirm_remove_vacancy_dialog.open({ids: currentSelectedVacancies})" height="50px">
          Удалить
          ({{ currentSelectedVacancies.length }})
        </rekroo-button>
        <rekroo-button v-if="activeTab === 'archive'" type="outlined"
                       @click="recover(currentSelectedVacancies)" height="50px">
          Восстановить
          ({{ currentSelectedVacancies.length }})
        </rekroo-button>
<!--        <rekroo-button v-if="activeTab !== 'active'"
                       @click="publish"
                       :loading="publishLoading"
                       type="outlined" height="50px">Опубликовать ({{ currentSelectedVacancies.length }})
        </rekroo-button>-->
      </div>
    </div>
    <ConfirmDialog ref="confirm_remove_vacancy_dialog" title="Вы уверены, что хотите удалить&nbsp;вакансию?"
                   subtitle="Вы также можете перенести вакансию в архив, чтобы вернуться к ней позже"
                   success_button_text="Удалить" decline_button_text="Перенести в архив"
                   @success="removeVacancy" @decline="moveToArchive"
    />
  </div>
</template>

<script>
import Vacancy from "@/api/vacancy";
import RekrooButton from "@/components/Button";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import VacanciesListTab from "@/views/company/vacancy/components/VacanciesListTab";
import EmptyListPlug from "@/components/EmptyListPlug";

export default {
  name: "VacanciesList",
  components: {EmptyListPlug, VacanciesListTab, ConfirmDialog, RekrooButton},
  data: () => ({
    activeTab: 'active',
    vacancy: new Vacancy({}),
    tablesData: {
      active: {
        data: [],
        loading: false,
        loaded: false,
        selected: []
      },
      not_publish: {
        data: [],
        loading: false,
        loaded: false,
        selected: []
      },
      archive: {
        data: [],
        loading: false,
        loaded: false,
        selected: []
      },
    },
    authError: false,
    statuses: ['not_publish', 'active', 'archive'],
    publishLoading: false,
  }),
  computed: {
    currentVacancies() {
      return this.tablesData[this.activeTab].data;
    },
    currentSelectedVacancies() {
      return this.tablesData[this.activeTab].selected;
    },
  },
  methods: {
    handleSelectionChange(val, tab) {
      this.tablesData[tab].selected = val.map(e => e.id);
    },
    get(firstLoad = false, status = 'active') {
      if (firstLoad) this.tablesData[status].loading = true;
      this.vacancy.get(null, {status: this.statuses.indexOf(status)}).then((data) => {
        this.tablesData[status].data = data.response;
        this.tablesData[status].loading = false;
        this.authError = false;
        this.tablesData[status].loaded = true;
      }).catch((data) => {
        this.tablesData[status].loading = false;
        this.authError = data.error.type == 401;
      })
    },
    removeVacancy(id) {
      this.vacancy.remove(id).then(() => {
        this.get(false, this.activeTab);
      })
    },
    moveToArchive(id) {
      this.vacancy.moveToArchive(id).then(() => {
        this.get(false, this.activeTab);
      })
    },
    onTabChange(newTab) {
      this.get(false, newTab);
      for (let key in this.tablesData) {
        if (key !== newTab) this.tablesData[key].loaded = false;
      }
      this.$router.push({query: newTab === 'active' ? {} : {t: newTab}}).catch(() => {});
    },
    recover(ids) {
      this.vacancy.create({status: 0}, ids).then(()=>{
        this.get();
        this.activeTab = 'not_publish';
      })
    }
  },
  mounted() {
    if (this.$route.query.t) this.activeTab = this.$route.query.t
    else this.get(true);
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get(true);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
.listVacanciesPage .listVacanciesPageTitle {
  display: flex;
  align-items: center;
}

.createVacancyButton {
  margin-left: auto;
}

.createVacancyButton button {
  font-size: var(--button);
}

.actions {
  display: flex;
}

.listActions {
  display: flex;
  column-gap: 6px;
  margin-left: 20px;
}

@media screen and (max-width: 1024px) {
  .listVacanciesPage {
    padding-top: 52px !important;
  }

  .actions {
    display: flex;
    padding-left: 48px;
  }
}

@media screen and (max-width: 575px) {
  .createVacancyButton button {
    height: 42px !important;
  }

  .actions {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  .actions .listActions {
    order: 1;
    margin-left: 0;
    margin-bottom: 16px;
  }

  .actions button {
    order: 2;
    width: 100% !important;
  }
}
</style>