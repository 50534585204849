<template>
  <div class="page doc-page">
    <div class="doc-header">
      <div></div>
      <div class="doc-header-inner">
        <h2>УТВЕРЖДАЮ</h2>
        <p>Генеральный директор</p>
        <p>ООО «Техинформгрупп»</p>
        <p class="director-name">Иванов О.В.</p>
        <p>«23» мая 2023 г.</p>
      </div>
    </div>
    <h2>Политика общества с ограниченной ответственностью «Техинформгрупп» в отношении обработки персональных
      данных</h2>
    <h2>1. Общие положения</h2>
    <p>1.1. Настоящая Политика общества с ограниченной ответственностью «Техинформгрупп» (ИНН:9705153891; ОГРН
      1217700136598; Дата выдачи ОГРН 25.03.2021; ОКВЭД 62.01; ОКПО 47733313; ОKФС 16; ОКОГУ 4210014; ОКОПФ 12300; адрес
      регистрации: 115035, г. Москва, Набережная Космодамианская, Д. 4/22, К. Б, офис: 529) в отношении обработки
      персональных данных (далее - Политика) разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона
      от 27.07.2006 No 152-ФЗ «О персональных данных» (далее - Закон о персональных данных) в целях обеспечения защиты
      прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на
      неприкосновенность частной жизни, личную и семейную тайну.</p>
    <p>1.2. Политика действует в отношении всех персональных данных, которые обрабатывает общество с ограниченной
      ответственностью «Техинформгрупп» (далее – Оператор). В рамках выполнения своей основной деятельности Оператор
      осуществляет обработку персональных данных различных категорий субъектов персональных данных с использованием
      информационных систем персональных данных, включающих в том числе следующие интернет-сайты Оператора:
      <a href="https://Rekroo.org">https://Rekroo.org</a> и <a href="https://Rekroo.ru">https://Rekroo.ru</a></p>
    <p>1.3. Политика распространяется на отношения в области обработки персональных данных, возникшие у Оператора как
      до, так и после утверждения настоящей Политики.</p>
    <p>1.4. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных настоящая Политика публикуется в
      свободном доступе в информационно-телекоммуникационной сети Интернет на сайте Оператора.</p>
    <p>1.5. Основные понятия, термины и определения Закона о персональных данных, используемые в настоящей Политике.</p>
    <p><strong>Персональные данные</strong> - любая информация, относящаяся к прямо или косвенно определенному или
      определяемому физическому лицу (субъекту персональных данных);</p>
    <p><strong>Оператор персональных данных (оператор)</strong> - государственный орган, муниципальный орган,
      юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие
      обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных
      данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</p>
    <p><strong>Обработка персональных данных</strong> - любое действие (операция) или совокупность действий (операций) с
      персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка
      персональных данных включает в себя в том числе:</p>
    <ul>
      <li>сбор;</li>
      <li>запись;</li>
      <li>систематизацию;</li>
      <li>накопление;</li>
      <li>хранение;</li>
      <li>уточнение (обновление, изменение);</li>
      <li>извлечение;</li>
      <li>использование;</li>
      <li>передачу (распространение, предоставление, доступ);</li>
      <li>обезличивание;</li>
      <li>блокирование;</li>
      <li>удаление;</li>
      <li>уничтожение.</li>
    </ul>
    <p><strong>Автоматизированная обработка персональных данных</strong> - обработка персональных данных с помощью
      средств вычислительной техники;</p>
    <p><strong>Распространение персональных данных</strong> - действия, направленные на раскрытие персональных данных
      неопределенному кругу лиц;</p>
    <p><strong>Предоставление персональных данных</strong> - действия, направленные на раскрытие персональных данных
      определенному лицу или определенному кругу лиц;</p>
    <p><strong>Блокирование персональных данных</strong> - временное прекращение обработки персональных данных (за
      исключением случаев, если обработка необходима для уточнения персональных данных;</p>
    <p><strong>Уничтожение персональных данных</strong> - действия, в результате которых становится невозможным
      восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате
      которых уничтожаются материальные носители персональных данных; Обезличивание персональных данных - действия, в
      результате которых становится невозможным без использования дополнительной информации определить принадлежность
      персональных данных конкретному субъекту персональных данных;</p>
    <p><strong>Информационная система персональных данных</strong> - совокупность содержащихся в базах данных
      персональных данных и обеспечивающих их обработку информационных технологий и технических средств.</p>
    <p>1.6. Основные права и обязанности Оператора.</p>
    <p>1.6.1. Оператор имеет право:</p>
    <ol>
      <li>самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения</li>
      выполнения обязанностей, предусмотренных Законом о персональных данных и принятыми в соответствии с ним
      нормативными правовыми актами, если иное не предусмотрено Законом о персональных данных или другими федеральными
      законами;
      <li>поручитьобработкуперсональныхданныхдругомулицуссогласиясубъектаперсональныхданных, если иное не предусмотрено
        федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку
        персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных,
        предусмотренные Законом о персональных данных, соблюдать конфиденциальность персональных данных, принимать
        необходимые меры, направленные на обеспечение выполнения обязанностей, предусмотренных Законом о персональных
        данных;
      </li>
      <li>в случае отзыва субъектом персональных данных согласия на обработку персональных данных Оператор вправе
        продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований,
        указанных в Законе о персональных данных.
      </li>
    </ol>
    <p>1.6.2. Оператор обязан:</p>
    <ol>
      <li>организовывать обработку персональных данных в соответствии с требованиями Закона о
        персональных данных;
      </li>
      <li>отвечать на обращения и запросы субъектов персональных данных и их законных представителей в
        соответствии с требованиями Закона о персональных данных;
      </li>
      <li>сообщать в уполномоченный орган по защите прав субъектов персональных данных (Федеральную
        службу по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)) по запросу
        этого органа необходимую информацию в течение 10 (десять) рабочих дней с даты получения такого запроса. Данный
        срок может быть продлен, но не более чем на 5 (пять) рабочих дней. Для этого Оператору необходимо направить в
        Роскомнадзор мотивированное уведомление с указанием причин продления срока предоставления запрашиваемой
        информации;
      </li>
      <li>в порядке, определенном федеральным органом исполнительной власти, уполномоченным в области обеспечения
        безопасности, обеспечивать взаимодействие с государственной системой обнаружения, предупреждения и ликвидации
        последствий компьютерных атак на информационные ресурсы РФ, включая информирование его о компьютерных
        инцидентах, которые повлекли неправомерную передачу (предоставление, распространение, доступ) персональных
        данных.
      </li>
    </ol>
    <p>1.7. Основные права субъекта персональных данных. 1.7.1. Субъект персональных данных имеет право:</p>
    <ol>
      <li>получать информацию, касающуюся обработки его персональных данных, за исключением случаев, предусмотренных
        федеральными законами. Сведения предоставляются субъекту персональных данных Оператором в доступной форме, и в
        них не должны содержаться персональные данные, относящиеся к другим субъектам персональных данных, за
        исключением случаев, когда имеются
        Страница 2 из 7
        законные основания для раскрытия таких персональных данных. Перечень информации и порядок
        ее получения установлен Законом о персональных данных;
      </li>
      <li>требовать от оператора уточнения его персональных данных, их блокирования или уничтожения в
        случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не
        являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите
        своих прав;
      </li>
      <li>дать предварительное согласие на обработку персональных данных в целях продвижения на рынке товаров, работ и
        услуг;
      </li>
      <li>обжаловать в Роскомнадзоре или в судебном порядке неправомерные действия или бездействие Оператора при
        обработке его персональных данных.
      </li>
    </ol>
    <p>1.8. Контроль за исполнением требований настоящей Политики осуществляется уполномоченным лицом, ответственным за
      организацию обработки персональных данных у Оператора.</p>
    <p>1.9. Ответственность за нарушение требований законодательства Российской Федерации и нормативных актов ООО
      «Техинформгрупп» в сфере обработки и защиты персональных данных определяется в соответствии с законодательством
      Российской Федерации.</p>
    <h2>2. Цели сбора персональных данных</h2>
    <p>2.1. Обработка персональных данных ограничивается достижением конкретных, заранее определенных и законных целей.
      Не допускается обработка персональных данных, несовместимая с целями сбора персональных данных.</p>
    <p>2.2. Обработке подлежат только персональные данные, которые отвечают целям их обработки.</p>
    <p>2.3. Обработка Оператором персональных данных осуществляется в следующих целях:</p>
    <ul>
      <li>осуществление своей деятельности в соответствии с уставом ООО «Техинформгрупп», в том числе заключение и
        исполнение договоров с контрагентами;
      </li>
      <li>исполнение трудового законодательства в рамках трудовых и иных непосредственно связанных с
        ним отношений, в том числе: содействие работникам в трудоустройстве, продвижении по службе, привлечение и отбор
        кандидатов на работу, повышение квалификации работников, реализацию прав граждан на поиск работы, предоставление
        соискателям сведений о наличии вакансий у работодателей;
      </li>
    </ul>
    <p>2.4. Обработка персональных данных работников может осуществляться исключительно в целях обеспечения соблюдения
      законов и иных нормативных правовых актов.</p>
    <h2>3. Правовые основания обработки персональных данных</h2>
    <p>3.1. Правовым основанием обработки персональных данных является совокупность нормативных правовых актов, во
      исполнение которых и в соответствии с которыми Оператор осуществляет обработку персональных данных, в том
      числе:</p>
    <ul>
      <li>Конституция Российской Федерации;</li>
      <li>Гражданский кодекс Российской Федерации;</li>
      <li>Трудовой кодекс Российской Федерации;</li>
      <li>Налоговый кодекс Российской Федерации;</li>
      <li>Федеральный закон от 08.02.1998 N 14-ФЗ «Об обществах с ограниченной ответственностью»;</li>
      <li>иные нормативные правовые акты, регулирующие отношения, связанные с деятельностью Оператора.</li>
    </ul>
    <p>3.2. Правовым основанием обработки персональных данных также являются:</p>
    <ul>
      <li>устав ООО «Техинформгрупп»;</li>
      <li>договоры, заключаемые между Оператором и субъектами персональных данных;</li>
      <li>согласие субъектов персональных данных на обработку их персональных данных.</li>
    </ul>
    <h2>4. Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных</h2>
    <p>4.1. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным целям обработки,
      предусмотренным в разд. 2 настоящей Политики. Обрабатываемые персональные данные не должны быть избыточными по
      отношению к заявленным целям их обработки.</p>
    <p>4.2. Оператор может обрабатывать персональные данные следующих категорий субъектов персональных данных.</p>
    <p>4.2.1. Кандидаты для приема на работу:</p>
    <ul>
      <li>фамилия, имя, отчество;</li>
      <li>пол;</li>
      <li>гражданство;</li>
      <li>дата и место рождения;</li>
      <li>изображение (фотография);</li>
      <li>паспортные данные;</li>
      <li>адрес регистрации по месту жительства;</li>
      <li>адрес фактического проживания;</li>
      <li>контактные данные;</li>
      <li>индивидуальный номер налогоплательщика;</li>
      <li>страховой номер индивидуального лицевого счета (СНИЛС);</li>
      <li> сведения об образовании, квалификации, профессиональной подготовке и повышении
        квалификации;
      </li>
      <li>семейное положение, наличие детей, родственные связи;</li>
      <li>сведения о трудовой деятельности, в том числе наличие поощрений, награждений и (или)
        дисциплинарных взысканий;
      </li>
      <li>данные о регистрации брака;</li>
      <li>сведения о воинском учете;</li>
      <li>сведения об инвалидности;</li>
      <li> сведения об удержании алиментов;</li>
      <li>сведения о доходе с предыдущего места работы;</li>
      <li>иные персональные данные, предоставляемые работниками в соответствии с требованиями
        трудового законодательства.
      </li>
    </ul>
    <p>4.2.2. Члены семьи работников - для целей исполнения трудового законодательства в рамках трудовых и иных
      непосредственно связанных с ним отношений:</p>
    <ul>
      <li>фамилия, имя, отчество;</li>
      <li>степень родства;</li>
      <li>год рождения;</li>
      <li>иные персональные данные, предоставляемые работниками в соответствии с требованиями
        трудового законодательства.
      </li>
    </ul>
    <p>4.2.3. Клиенты и контрагенты Оператора (физические лица) - для целей осуществления своей деятельности:</p>
    <ul>
      <li>фамилия, имя, отчество;</li>
      <li>дата и место рождения;</li>
      <li>паспортные данные;</li>
      <li>контактные данные;</li>
      <li>замещаемая должность;</li>
      <li>номер расчетного счета;</li>
      <li>иные персональные данные, предоставляемые клиентами и контрагентами (физическими лицами),
        необходимые для заключения и исполнения договоров.
      </li>
    </ul>
    <p>4.3. Обработка Оператором биометрических персональных данных (сведений, которые характеризуют физиологические и
      биологические особенности человека, на основании которых можно установить его личность) осуществляется в
      соответствии с законодательством Российской Федерации.</p>
    <p>4.4. Оператором не осуществляется обработка специальных категорий персональных данных, касающихся расовой,
      национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья,
      интимной жизни, за исключением случаев, предусмотренных законодательством РФ.</p>
    <h2>5. Порядок и условия обработки персональных данных</h2>
    <p>5.1. Обработка персональных данных осуществляется Оператором в соответствии с требованиями законодательства
      Российской Федерации.</p>
    <p>5.2. Обработка персональных данных осуществляется с согласия субъектов персональных данных на обработку их
      персональных данных, а также без такового в случаях, предусмотренных законодательством Российской Федерации.</p>
    <p>5.3. Оператор осуществляет обработку персональных данных для каждой цели их обработки следующими способами:</p>
    <ul>
      <li>неавтоматизированная обработка персональных данных;</li>
      <li>автоматизированная обработка персональных данных с передачей полученной информации по
        информационно-телекоммуникационным сетям или без таковой;
      </li>
      <li>смешанная обработка персональных данных.</li>
    </ul>
    <p>5.4. К обработке персональных данных допускаются работники Оператора, в должностные обязанности которых входит
      обработка персональных данных.</p>
    <p>5.5. Обработка персональных данных для каждой цели обработки, указанной в п. 2.3 Политики, осуществляется
      путем:</p>
    <ul>
      <li>получения персональных данных в устной (по телефону) и письменной форме непосредственно от субъектов
        персональных данных;
      </li>
      <li>внесения персональных данных в журналы, реестры и информационные системы Оператора;</li>
      <li> использования иных способов обработки персональных данных.</li>
    </ul>
    <p>5.6. Не допускается раскрытие третьим лицам и распространение персональных данных без согласия субъекта
      персональных данных, если иное не предусмотрено федеральным законом. Согласие на обработку персональных данных,
      разрешенных субъектом персональных данных для распространения, оформляется отдельно от иных согласий субъекта
      персональных данных на обработку его персональных данных. Требования к содержанию согласия на обработку
      персональных данных, разрешенных субъектом персональных данных для распространения, утверждены Приказом
      Роскомнадзора от 24.02.2021 N 18.</p>
    <p>5.7. Передача персональных данных органам дознания и следствия, в Федеральную налоговую службу, Социальный фонд
      России и другие уполномоченные органы исполнительной власти и организации осуществляется в соответствии с
      требованиями законодательства Российской Федерации.</p>
    <p>5.8. Оператор принимает необходимые правовые, организационные и технические меры для защиты персональных данных
      от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, распространения и других
      несанкционированных действий, в том числе:</p>
    <ul>
      <li>определяет угрозы безопасности персональных данных при их обработке;</li>
      <li> принимает локальные нормативные акты и иные документы, регулирующие отношения в сфере
        обработки и защиты персональных данных;
      </li>
      <li>назначает лиц, ответственных за обеспечение безопасности персональных данных в структурных
        подразделениях и информационных системах Оператора;
      </li>
      <li>создает необходимые условия для работы с персональными данными;</li>
      <li>организует учет документов, содержащих персональные данные;</li>
      <li> организует работу с информационными системами, в которых обрабатываются персональные
        данные;
      </li>
      <li>хранит персональные данные в условиях, при которых обеспечивается их сохранность и
        исключается неправомерный доступ к ним;
      </li>
      <li>организует обучение работников Оператора, осуществляющих обработку персональных данных.</li>
    </ul>
    <p>5.9. Оператор осуществляет хранение персональных данных в форме, позволяющей определить субъекта персональных
      данных, не дольше, чем этого требует каждая цель обработки персональных данных, если срок хранения персональных
      данных не установлен федеральным законом, договором.</p>
    <p>5.9.1. Персональные данные на бумажных носителях хранятся в ООО «Техинформгрупп» в течение сроков хранения
      документов, для которых эти сроки предусмотрены законодательством об архивном деле в РФ (Федеральный закон от
      22.10.2004 N 125-ФЗ «Об архивном деле в Российской Федерации», Перечень типовых управленческих архивных
      документов, образующихся в процессе деятельности государственных органов, органов местного самоуправления и
      организаций, с указанием сроков их хранения (утв. Приказом Росархива от 20.12.2019 N 236)).</p>
    <p>5.9.2. Срок хранения персональных данных, обрабатываемых в информационных системах персональных данных,
      соответствует сроку хранения персональных данных на бумажных носителях.</p>
    <p>5.10. Оператор прекращает обработку персональных данных в следующих случаях:</p>
    <ul>
      <li>выявлен факт их неправомерной обработки. Срок - в течение трех рабочих дней с даты выявления;</li>
      <li>достигнута цель их обработки;</li>
      <li>истек срок действия или отозвано согласие субъекта персональных данных на обработку
        указанных данных, когда по Закону о персональных данных обработка этих данных допускается
        только с согласия.
      </li>
    </ul>
    <p>5.11. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных
      согласия на их обработку Оператор прекращает обработку этих данных, если:</p>
    <ul>
      <li>иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем, по которому является
        субъект персональных данных;
      </li>
      <li>Оператор не вправе осуществлять обработку без согласия субъекта персональных данных на основаниях,
        предусмотренных Законом о персональных данных или иными федеральными законами;
      </li>
      <li>иное не предусмотрено другим соглашением между Оператором и субъектом персональных данных.</li>
    </ul>
    <p>5.12. При обращении субъекта персональных данных к Оператору с требованием о прекращении обработки персональных
      данных в срок, не превышающий 10 рабочих дней с даты получения Оператором соответствующего требования, обработка
      персональных данных прекращается, за исключением случаев, предусмотренных Законом о персональных данных. Указанный
      срок может быть продлен, но не более чем на пять рабочих дней. Для этого Оператору необходимо направить субъекту
      персональных данных мотивированное уведомление с указанием причин продления срока.</p>
    <p>5.13. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети Интернет,
      Оператор обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение
      персональных данных граждан Российской Федерации с использованием баз данных, находящихся на территории Российской
      Федерации, за исключением случаев, указанных в Законе о персональных данных.</p>
    <h2>6. Актуализация, исправление, удаление, уничтожение персональных данных, ответы на запросы субъектов на доступ к
      персональным данным</h2>
    <p>6.1. Подтверждение факта обработки персональных данных Оператором, правовые основания и цели обработки персональных данных, а также иные сведения, указанные в ч. 7 ст. 14 Закона о персональных данных, предоставляются Оператором субъекту персональных данных или его представителю в течение 10 рабочих дней с момента обращения либо получения запроса субъекта персональных данных или его представителя. Данный срок может быть продлен, но не более чем на пять рабочих дней. Для этого Оператору следует направить субъекту персональных данных мотивированное уведомление с указанием причин продления срока предоставления запрашиваемой информации.</p>
    <p>В предоставляемые сведения не включаются персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, когда имеются законные основания для раскрытия таких персональных данных.</p>
    <p>Запрос должен содержать:</p>
    <ul>
      <li>номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе;</li>
      <li>сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором;</li>
      <li>подпись субъекта персональных данных или его представителя.</li>
    </ul>
    <p>Запрос может быть направлен в форме электронного документа и подписан электронной подписью в соответствии с законодательством Российской Федерации.</p>
    <p>Оператор предоставляет сведения, указанные в ч. 7 ст. 14 Закона о персональных данных, субъекту персональных данных или его представителю в той форме, в которой направлены соответствующие обращение либо запрос, если иное не указано в обращении или запросе.</p>
    <p>Если в обращении (запросе) субъекта персональных данных не отражены в соответствии с требованиями Закона о персональных данных все необходимые сведения или субъект не обладает правами доступа к запрашиваемой информации, то ему направляется мотивированный отказ.</p>
    <p>Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с ч. 8 ст. 14 Закона о персональных данных, в том числе если доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.</p>
    <p>6.2. В случае выявления неточных персональных данных при обращении субъекта персональных данных или его представителя либо по их запросу или по запросу Роскомнадзора Оператор осуществляет блокирование персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения указанного запроса на период проверки, если блокирование персональных данных не нарушает права и законные интересы субъекта персональных данных или третьих лиц.</p>
    <p>В случае подтверждения факта неточности персональных данных Оператор на основании сведений, представленных субъектом персональных данных или его представителем либо Роскомнадзором, или иных необходимых документов уточняет персональные данные в течение семи рабочих дней со дня представления таких сведений и снимает блокирование персональных данных.</p>
    <p>6.3. В случае выявления неправомерной обработки персональных данных при обращении (запросе) субъекта персональных данных или его представителя либо Роскомнадзора Оператор осуществляет блокирование неправомерно обрабатываемых персональных данных, относящихся к этому субъекту персональных данных, с момента такого обращения или получения запроса.</p>
    <p>6.4. При выявлении Оператором, Роскомнадзором или иным заинтересованным лицом факта неправомерной или случайной передачи (предоставления, распространения) персональных данных (доступа к персональным данным), повлекшей нарушение прав субъектов персональных данных, Оператор:</p>
    <ul>
      <li>в течение 24 часов - уведомляет Роскомнадзор о произошедшем инциденте, предполагаемых причинах, повлекших нарушение прав субъектов персональных данных, предполагаемом вреде, нанесенном правам субъектов персональных данных, и принятых мерах по устранению последствий инцидента, а также предоставляет сведения о лице, уполномоченном Оператором на взаимодействие с Роскомнадзором по вопросам, связанным с инцидентом;</li>
      <li>в течение 72 часов - уведомляет Роскомнадзор о результатах внутреннего расследования выявленного инцидента и предоставляет сведения о лицах, действия которых стали его причиной (при наличии).</li>
    </ul>
    <p>6.5. Порядок уничтожения персональных данных Оператором.</p>
    <p>6.5.1. Условия и сроки уничтожения персональных данных Оператором:</p>
    <ul>
      <li>достижение цели обработки персональных данных либо утрата необходимости достигать эту цель - в течение 30 дней;</li>
      <li>достижение максимальных сроков хранения документов, содержащих персональные данные, - в течение 30 дней;</li>
      <li>предоставление субъектом персональных данных (его представителем) подтверждения того, что персональные данные получены незаконно или не являются необходимыми для заявленной цели обработки, - в течение семи рабочих дней;</li>
      <li>отзыв субъектом персональных данных согласия на обработку его персональных данных, если их сохранение для цели их обработки более не требуется, - в течение 30 дней.</li>
    </ul>
    <p>6.5.2. При достижении цели обработки персональных данных, а также в случае отзыва субъектом персональных данных согласия на их обработку персональные данные подлежат уничтожению, если:</p>
    <ul>
      <li>иное не предусмотрено договором,стороной которого,выгодоприобретателем или поручителем,по которому является субъект персональных данных;</li>
      <li>оператор не вправе осуществлять обработку без согласия субъекта персональных данных на основаниях, предусмотренных Законом о персональных данных или иными федеральными законами;</li>
      <li>иное не предусмотрено другим соглашением между Оператором и субъектом персональных данных.</li>
    </ul>
    <p>6.5.3. Уничтожение персональных данных осуществляет комиссия, созданная приказом генерального директора ООО «Техинформгрупп».</p>
    <p>6.5.4. Способы уничтожения персональных данных устанавливаются в локальных нормативных актах Оператора.</p>
  </div>
</template>

<script>
export default {
  name: "Policy"
}
</script>

<style scoped>
</style>