<template>
  <el-table header-row-class-name="header-row" class="vacancies-table" :data="skeletonData">
    <el-table-column type="selection" v-if="showSelection"/>
    <el-table-column :label="headerTitles[0]">
      <template #default>
        <el-skeleton-item variant="text"/>
      </template>
    </el-table-column>
    <el-table-column :label="headerTitles[1]">
      <template #default>
        <el-skeleton-item variant="text"/>
      </template>
    </el-table-column>
    <el-table-column :label="headerTitles[2]">
      <template #default>
        <el-skeleton-item variant="text"/>
      </template>
    </el-table-column>
    <el-table-column :label="headerTitles[3]">
      <template #default>
        <el-skeleton-item variant="text"/>
      </template>
    </el-table-column>
    <el-table-column>
      <template #default>
        <div :class="$style.tableActions">
          <el-skeleton-item variant="button"/>
          <el-skeleton-item variant="button"/>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "TableSkeleton",
  props: ['headerTitles', 'showSelection'],
  data: () => ({
    skeletonData: [{}, {}, {}, {}, {}]
  })
}
</script>

<style module lang="css">
.tableActions {
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(2, 45px);
  grid-column-gap: 4px;
}
</style>