<template>
  <el-dialog :visible.sync="isVisible" fullscreen custom-class="rekroo-dialog info-dialog" :center="true" :class="$style.dialog"
  @close="onCLose">
    <ResumeList ref="resume_list" :from-dialog="true" @countString-updated="updateCountString"/>
    <div :class="$style.actionsContainer">
      <div :class="$style.actions">
        <rekroo-button @click="saveFilters" :class="$style.saveButton"
                       height="50px"
                       width="100%">Сохранить фильтры
        </rekroo-button>
        <rekroo-button @click="isVisible = false" :class="$style.saveButton"
                       height="50px"
                       width="100%" color="var(--gray)" text_color="var(--primary)">Закрыть
        </rekroo-button>
        <div :class="$style.itemsCount">{{ countString }}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import ResumeList from "@/views/company/catalog/List";
import RekrooButton from "@/components/Button";

export default {
  name: "ResumeCatalogDialog",
  components: {RekrooButton, ResumeList},
  emits: ['save-filters'],
  data: () => ({
    isVisible: false,
    countString: ''
  }),
  methods: {
    open(resume_filters) {
      this.isVisible = true;
      setTimeout(() => {
        this.$refs.resume_list.resetFilters();
        if (resume_filters) this.$refs.resume_list.filters = {...resume_filters};
      })
    },
    saveFilters() {
      this.$emit('save-filters', {filters: this.$refs.resume_list.filters, counts: this.$refs.resume_list.itemsCount});
      this.isVisible = false;
    },
    updateCountString(v) {
      this.countString = v;
    },
    onCLose() {
      this.$router.push({path: this.$route.path, query: {}}).catch(()=>{});
    }
  },
}
</script>

<style module lang="css">

.actionsContainer {
  position: fixed;
  width: 100%;
  bottom: 20px;
  left: 0;
}

.actions {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  row-gap: 12px;
  align-items: center;
  padding: 16px 32px;
  background: #FFFFFF;
  box-shadow: 1px -6px 12px -5px #DADBDD;
  -webkit-box-shadow: 1px -6px 12px -5px #DADBDD;
}

.itemsCount {
  flex-grow: 1;
  margin-left: auto;
  text-align: right;
}

.saveButton {
  max-width: 320px;
  margin-right: 16px;
}
.saveButton:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1024px) {
  .saveButton {
    max-width: 100%;
    margin-right: 0;
  }
  .actions {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
  .itemsCount {
    margin-left: 0;
    text-align: left;
  }
}

@media screen and (max-width: 575px) {
  .actions {
    grid-template-columns: 1fr;
    width: calc(100% - 40px);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .saveButton {
    height: 42px !important;
  }
}
</style>