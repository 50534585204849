var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:{
    [_vm.$style.outlined]: _vm.type === 'outlined',
    [_vm.$style.primary]: _vm.type === 'primary',
    [_vm.$style.text]: _vm.type === 'text',
    [_vm.$style.active]: _vm.type === 'active',
    [_vm.$style.subheader]: _vm.type === 'subheader',
    [_vm.$style.disabled]: _vm.type === 'disabled'
     },style:(`width:${_vm.width || 'fit-content'}; background: ${_vm.color}; height: ${_vm.height || ''}; color: ${_vm.text_color};
    justify-content: ${_vm.spaceBetween ? 'space-between' : ''}`),attrs:{"disabled":_vm.disabled || _vm.loading,"type":"button"},on:{"click":_vm.click}},[(_vm.loading)?_c('i',{staticClass:"icon el-icon-loading",class:_vm.$style.loading}):[(_vm.icon && _vm.icon_pos==='start')?_c('rekroo-icon',{style:(_vm.icon_style),attrs:{"icon":_vm.icon,"color":_vm.icon_color,"width":_vm.icon_width || 'var(--button)'}}):_vm._e(),_vm._t("default"),(_vm.icon && _vm.icon_pos==='end')?_c('rekroo-icon',{style:(_vm.icon_style),attrs:{"icon":_vm.icon,"color":_vm.icon_color,"width":_vm.icon_width || 'var(--button)'}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }