<template>
  <div class="page" :class="style.vacancyItemPage">
    <link-back text="Вернуться к рассылкам" :path="`/${getValue('role', 'company')}/mailing_list`"/>
    <div :class="style.vacancyItemPageContent">
      <div :class="$style.sideContent">
        <page-hint :title="item.title" :subtitle="item.vacancy?.position" :no-padding-title="true">
          <template #custom>
            <tag v-if="item.deleted"  text="В архиве" :class="$style.mailingListStatus"/>
            <tag v-else :class="$style.mailingListStatus" :text="$store.state.systemParams?.mailing_list_statuses ?
            $store.state.systemParams?.mailing_list_statuses[item.status] : ''"/>
          </template>
        </page-hint>
        <template v-if="item.user_id == user.id && item.status !== 3">
          <div :class="$style.editButtons">
            <router-link :to="`/${$route.path.split('/')[1]}/mailing_list/update/${item.id}`" style="width: 100%">
              <rekroo-button color="var(--purple)" icon="pencil"
                             icon_style="margin-right: 8px" icon_color="#FFF"
                             height="50px" width="100%">Редактировать
              </rekroo-button>
            </router-link>
            <rekroo-button v-if="!item.deleted" icon="trash" icon_color="#FFF" color="var(--gray-text)" height="50px" width="50px"
                           icon_width="20px" @click="openRemoveConfirm"/>
          </div>
          <rekroo-button v-if="![0, 3].includes(item.status) && !item.deleted"
                         color="var(--gray-text)" @click="openCancelConfirmDialog" height="50px" width="100%">Отменить</rekroo-button>
        </template>
      </div>
      <div :class="style.vacancyContainer">
        <div v-if="loading || item.status > 0" :class="style.vacancy">
          <div v-if="item.datetime_schedule && !item.deleted" :class="$style.datetimeSchedule">
            Отправка запланирована на: {{dateParse(item.datetime_schedule, 'D MMMM, YYYY в HH:mm')}}</div>
          <div :class="$style.statsContainer">
            <div :class="$style.labelsContainers">
              <div :class="$style.funnelStepLabel" v-for="(step, i) in funnelSteps" :key="i">
                {{funnelLabels[i]}}
              </div>
            </div>
            <div :class="$style.funnelContainer">
              <div :class="$style.funnelStepItem" v-for="(step, i) in funnelSteps" :key="i">
                <div :class="$style.funnelStep">{{ step }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="style.vacancy">
          <empty-list-plug title="Вы не отправили e-mail рассылку"
                           subtitle="Вы оставляете сотни кандидатов без потенциального оффера мечты"
                           :show-icon="true" button_text="Отправить рассылку"
                           @onClick="openScheduleDialog"
                           style="margin: 0 auto"/>
        </div>
      </div>
    </div>
    <ConfirmDialog ref="confirm_to_archive_dialog" @success="toArchive"
                   :success_btn_loading="removeLoading"
                   title="Вы уверены, что хотите удалить рассылку?"/>
    <ConfirmDialog ref="confirm_cancel_dialog" @success="cancel"
                   :success_btn_loading="cancelLoading"
                   title="Вы уверены, что хотите отменить рассылку?"/>
    <ScheduleSendDialog ref="schedule_send_dialog" @open-tariff="$refs.mailing_list_tariff_dialog.open()" @sent="get"/>
    <MailingListTariffDialog ref="mailing_list_tariff_dialog" @select="tariff=>$refs.schedule_send_dialog.save(tariff)"
    open-schedule="1"/>
  </div>
</template>
<script>
import {Mailing_list_item} from "@/api/mailing_list_item";
import style from "@/views/company/vacancy/styles/Vacancy.module.css"
import LinkBack from "@/components/LinkBack";
import PageHint from "@/components/PageHint";
import RekrooButton from "@/components/Button";
import EmptyListPlug from "@/components/EmptyListPlug";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import Tag from "@/components/Tag";
import ScheduleSendDialog from "@/views/company/mailing_list/components/ScheduleSendDialog";
import MailingListTariffDialog from "@/dialogs/MailingListTariffDialog";

export default {
  name: "MailingListItem",
  components: {
    MailingListTariffDialog,
    ScheduleSendDialog, Tag, ConfirmDialog, EmptyListPlug, RekrooButton, PageHint, LinkBack},
  data: () => ({
    style,
    mailingListClass: new Mailing_list_item({}),
    item: {},
    loading: false,
    removeLoading: false,
    cancelLoading: false,
    funnelLabels: ['Всего', 'Отправлено', 'Просмотрено', 'Отклонено', 'Принято', 'Отвечено']
  }),
  methods: {
    get() {
      this.loading = true;
      this.mailingListClass.get(this.$route.params.id).then(data => {
        this.loading = false;
        this.item = data.response;
        if (this.$route.query.open_schedule) {
          this.openScheduleDialog();
          this.$router.push({path: this.$route.path, query: {}}).catch(()=>{});
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    openScheduleDialog() {
      this.$refs.schedule_send_dialog.open(this.item.id);
    },
    openRemoveConfirm() {
      this.$refs.confirm_to_archive_dialog.open();
    },
    openCancelConfirmDialog() {
      this.$refs.confirm_cancel_dialog.open();
    },
    cancel() {
      this.cancelLoading = true;
      this.mailingListClass.cancel(this.item.id).then(()=>{
        this.cancelLoading = false;
        this.get();
      }).catch(()=>{
        this.cancelLoading = false;
      })
    },
    toArchive() {
      this.removeLoading = true;
      this.mailingListClass.moveToArchive(this.item.id).then(()=>{
        this.removeLoading = false;
        this.get();
      }).catch(()=>{
        this.removeLoading = false;
      })
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    funnelSteps() {
      return this.item.stats ? ['total', 'sent', 'seen', 'denied', 'accepted', 'answered'].map(name => this.item.stats[name]) : [];
    }
  },
  mounted() {
    this.get();
  }
}
</script>

<style module lang="css">
.mailingListStatus {
  margin-top: 16px;
}

.editButtons {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  column-gap: 8px;
}

.sendButton {
  margin-top: 12px;
}
.datetimeSchedule {
  color: var(--gray-text);
  margin-bottom: 16px;
}
.statsContainer {
  display: flex;
}

.labelsContainers {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.funnelStepLabel {
  font-size: var(--small-text);
  font-weight: 500;
  height: 60px;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.funnelStepLabel:before {
  content: ' ';
  position: absolute;
  width: 270px;
  height: 1px;
  background: var(--gray);
}
.funnelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  max-width: 400px;
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 60% 100%, 40% 100%);
}
.funnelStepItem {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
}

.funnelStep {
  background: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 60px;
  width: 100%;
  max-width: 400px;
  min-width: 70px;
  border-left: 1px solid var(--light-gray);
  font-size: var(--body);
  font-weight: 500;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .statsContainer {
    justify-content: space-between;
    /*clip-path: polygon(0 0, 100% 0, 68% 100%, 0% 100%);*/
  }
  .funnelStepLabel:before {
    width: 430px;
  }
}

@media screen and (max-width: 575px) {
  .funnelStepLabel:before {
    width: 200px;
  }
  .funnelContainer {
    max-width: 250px;
  }
  .funnelStep {
    max-width: 250px;
  }
}
</style>