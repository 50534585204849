<template>
  <div :class="$style.hint">
    <div :class="$style.hintInner">
      <div v-if="icon" :class="$style.iconContainer" :style="`background: ${color}`">
        <rekroo-icon v-if="icon" :icon="icon" :color="icon_color" :width="icon_width"/>
      </div>
      <div :class="$style.hintContent">
        <div :class="[$style.hintTitle, {[$style.hintTitleNoPadding]: noPaddingTitle}]" class="headline-3">{{ title }}</div>
        <span :class="$style.hintSubtitle" v-html="subtitle"/>
        <slot name="custom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import RekrooIcon from "@/components/Icon";

export default {
  name: "PageHint",
  components: {RekrooIcon},
  props: ['icon', 'icon_color', 'icon_width', 'color', 'title', 'subtitle', 'noPaddingTitle'],
}
</script>

<style module lang="css">
.hint {
  flex-basis: 33%;
  flex-grow: 1;
}

.hint .hintInner {
  padding: 40px;
  border: 1px solid #111111;
  border-radius: 16px;
}

.hint .hintInner .iconContainer {
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.hint .hintInner .hintTitle {
  margin-bottom: 12px;
}

.hint .hintInner .hintSubtitle {
  font-size: var(--small-text);
  line-height: 144%;
}

@media screen and (max-width: 1024px) {
  .hint {
    flex-basis: 100%;
    order: 1;
    margin-bottom: 20px;
  }
  .hint .hintInner {
    padding: 28px;
    display: flex;
    column-gap: 20px;
  }
  .hint .hintInner .iconContainer {
    margin-bottom: 0;
  }
  .hint .hintInner .hintTitle {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 575px) {
  .hint .hintInner {
    padding: 24px;
    position: relative;
  }
  .hint .hintInner .iconContainer {
    position: absolute;
    top: 24px;
    left: 24px;
    min-width: 40px;
    width: 40px;
    height: 40px;
  }
  .hint .hintInner .hintTitle {
    min-height: 52px;
    padding-left: 52px;
    margin-bottom: 12px;
  }
  .hint .hintInner .hintTitle.hintTitleNoPadding {
    padding-left: 0;
    min-height: auto;
  }
}
</style>