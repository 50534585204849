<template>
  <div class="page" :class="style.vacancyItemPage">
    <link-back text="Вернуться ко всем вакансиям"/>
    <div :class="style.vacancyItemPageContent">
      <div :class="style.company" v-if="company && Object.keys(company).length">
        <div :class="style.companyInner">
          <div :class="style.companyHeader">
            <img v-if="company?.image?.url" :src="company.image.url" alt="" :class="style.logo">
            <rekroo-icon v-else icon="image" width="48px" color="var(--gray-text)"/>
            <div :class="style.title">{{ company.title }}</div>
          </div>
          <div v-if="company.desc" :class="style.companyAboutContainer">
            <div :class="style.companyAbout">{{ companyDesc }}</div>
            <rekroo-button v-if="company.desc.length > 200"
                           @click="openAboutCompanyDialog"
                           type="text" text_color="var(--bright-blue)"
                           icon="chevron-down" icon_color="var(--bright-blue)" icon_pos="end"
                           icon_style="margin-left: 4px">Подробнее</rekroo-button>
          </div>
          <a v-if="company.link" :href="company.link" target="_blank" :class="style.companyLink">{{ company.link }}</a>
        </div>
        <div :class="style.vacancyActions" v-if="['company', 'hr'].includes($route.path.split('/')[1])">
          <rekroo-button @click="$router.push(`/${$route.path.split('/')[1]}/vacancies/update/${vacancy.id}`)"
                         color="var(--purple)" icon="pencil" icon_color="#FFF" width="100%" height="50px"
                         icon_style="margin-right: 10px">Редактировать
          </rekroo-button>
          <rekroo-button v-if="vacancy.status !== 2" @click="$refs.confirm_remove_vacancy_dialog.open()" color="var(--gray-text)" icon="trash"
                         icon_color="#FFF" width="54px" icon_width="18px" height="50px"/>
          <el-tooltip v-else
                      effect="dark" content="Восстановить вакансию из архива" placement="top-start">
            <rekroo-button @click="$refs.confirm_remove_vacancy_dialog.open()" color="var(--gray-text)" icon="undo"
                           icon_color="#FFF" width="54px" icon_width="18px" height="50px"/>
          </el-tooltip>
        </div>
        <tag v-else-if="vacancy.my_response" text="Вы откликнулись на эту вакансию"
             color="var(--blue)"
             style="height: 50px; width: 100%"/>
        <div v-else-if="['applicant'].includes($route.path.split('/')[1]) && user.id+'' !== vacancy.user_id+''" :class="style.vacancyActions">
          <rekroo-button @click="openApplyDialog"
                         color="var(--purple)" icon="hand" icon_color="#FFF" width="100%" height="50px"
                         icon_style="margin-right: 10px">Откликнуться
          </rekroo-button>
        </div>
        <el-tooltip v-if="user.id != vacancy.user_id"
                    effect="dark" content="Пожаловаться на вакансию" placement="top-start">
          <rekroo-button
              @click="complain"
              color="var(--gray)" icon="warning" icon_color="var(--primary)" width="50px" height="50px"
              style="margin-top: 16px"
              text_color="var(--primary)"/>
        </el-tooltip>
        <router-link to="/company/mailing_list" v-if="user.id == vacancy.user_id"
                     style="margin-top: 16px; display: block;">
          <rekroo-button
              type="outlined" width="100%" height="50px">Отправить рассылку соискателям
          </rekroo-button>
        </router-link>
        <router-link :to="`/company/auction?v_id=${vacancy.id}`" v-if="user.id == vacancy.user_id"
                     style="margin-top: 16px; display: block">
          <rekroo-button
              type="outlined" width="100%" height="50px">Найти рекрутера для вакансии
          </rekroo-button>
        </router-link>
      </div>
      <PageHint v-else :title="authError ? 'Доступ ограничен' : notFoundError ? 'Страницы не существует' : 'Информация о компании не заполнена'" icon="error" color="var(--yellow)"/>
      <vacancy-skeleton v-if="loading"/>
      <div v-else :class="style.vacancyContainer">
        <div v-if="authError" :class="style.vacancy">
          <empty-list-plug title="Информация о вакансии будет доступна после авторизации" :show-icon="true"
          button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
          style="margin: 0 auto"/>
        </div>
        <div v-else-if="notFoundError" :class="style.vacancy">
          <empty-list-plug title="Вакансии не существует, либо она была снята с публикации" :show-icon="true"
                           button_text="Вернуться к вакансиям" @onClick="$router.back()"
                           style="margin: 0 auto"/>
        </div>
        <div v-else :class="style.vacancy">
          <div :class="style.titleContainer">
            <h1 :class="style.vacancyTitle">{{ vacancy.position }}</h1>
            <el-dropdown trigger="click"
                         v-if="$store.state.user.id+'' === vacancy.user_id+'' && ['company', 'hr'].includes($route.path.split('/')[1])"
                         :class="style.vacancyStatus">
              <tag :text=" $store.state.vacancyStatuses[vacancy.status]" color="var(--gray)"
                   icon="dot" icon_pos="end" icon_width="16px"
                   :icon_color="$store.state.vacancyStatusColors[vacancy.status]"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(status, i) in $store.state.vacancyStatuses" :key="i">
                  <rekroo-button @click="$refs.confirm_status_vacancy_dialog.open({status: i})"
                                 type="text" text_color="var(--primary)" icon="dot"
                                 :icon_color="$store.state.vacancyStatusColors[i]"
                  >{{ status }}
                  </rekroo-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div :class="style.vacancyHeader">
            <div :class="style.locationContainer">
              <tag v-if="vacancy.location" :text="vacancy.location" color="#FFF" icon_pos="start" icon="geo"
                   icon_color="var(--gray-text)"
                   icon_width="12"/>
              <tag v-if="vacancy.remote_work" text="Удаленно" color="#FFF" icon_pos="start" icon="remote"
                   icon_color="var(--gray-text)" icon_width="12"/>

              <tag :text="salaryParse(vacancy.salary_from, vacancy.salary_to, $store.state.systemParams.currencies[vacancy.currency]?.short)"
                   color="var(--yellow)"/>
            </div>
          </div>
          <div :class="style.subheader">
            <label-value-row v-if="vacancy.employment_type" label="Формат занятости"
                             :value=" $store.state.systemParams.employment_types ? $store.state.systemParams.employment_types[vacancy.employment_type] : ''"/>
            <label-value-row v-if="vacancy.schedule" label="График работы"
                             :value=" $store.state.systemParams.schedule_options ? $store.state.systemParams.schedule_options[vacancy.schedule] : ''"/>
            <label-value-row v-if="$store.state.systemParams.experience_options"
                label="Требуемый опыт"
                             :value="$store.state.systemParams.experience_options[vacancy.experience] || $store.state.systemParams.experience_options[0]"/>
          </div>
          <p :class="style.desc" v-if="vacancy.description">{{ vacancy.description }}</p>
          <div :class="style.section" v-if="vacancy.tasks">
            <div class="headline-3" :class="style.title">Задачи:</div>
            <p :class="style.text" v-html="vacancy.tasks.replaceAll('\n', '<br>')"/>
          </div>
          <div :class="style.section" v-if="vacancy.requirements">
            <div class="headline-3" :class="style.title">Требования:</div>
            <p :class="style.text" v-html="vacancy.requirements.replaceAll('\n', '<br>')"/>
          </div>
          <div :class="style.section" v-if="vacancy.conditions">
            <div class="headline-3" :class="style.title">Условия:</div>
            <p :class="style.text" v-html="vacancy.conditions.replaceAll('\n', '<br>')"/>
          </div>
          <span :class="style.publish_date"
                v-if="vacancy.datetime_publish">Вакансия опубликована {{
              dateParse(vacancy.datetime_publish, 'D MMMM YYYY')
            }}</span>
        </div>
        <template v-if="['company', 'hr'].includes($route.path.split('/')[1])">

          <div :class="style.responsesTitleContainer">
            <h1 :class="style.responsesTitle">Отклики
              <span v-if="vacancy.responses?.filter(e=>!e.seen)?.length"
                    :class="style.responseCountNew">+{{vacancy.responses?.filter(e=>!e.seen)?.length}}</span>
            </h1>
            <span v-if="vacancy.responses?.length" :class="style.responseCount">
              Всего: {{vacancy.responses?.length}}
            </span>
          </div>
          <div :class="style.responses">
            <div :class="style.responsesList" v-if="vacancy.responses && vacancy.responses.length">
              <person-card-list-item  tem v-for="item in vacancy.responses" :key="item.id" type="resume"
                                      :highlight="!item.seen"
                                     :link="`/company/catalog/${item.resume.id}?r=${item.id}`" :item="item.resume" :cover_letter="item.covering_letter"

              />
            </div>
            <empty-list-plug v-else :class="style.emptyResponses" title="На эту вакансию пока нет откликов"
                             subtitle="Не ждите пока кандидаты сами откликнуться на вашу вакансию. Найдите подходящего кандидата в базе резюме"
                             button_text="Перейти к базе" @onClick="$router.push('/company/catalog')"
                             :show-icon="true"/>
          </div>
        </template>
      </div>
    </div>
    <ApplyVacancyDialog ref="apply_vacancy_dialog" @applied="getVacancy"/>
    <ConfirmDialog ref="confirm_status_vacancy_dialog" title="Вы уверены, что хотите изменить статус?"
                   success_button_text="Да" decline_button_text="Нет"
                   @success="changeStatus"
    />
    <ConfirmDialog ref="confirm_remove_vacancy_dialog" title="Вы уверены, что хотите удалить&nbsp;вакансию?"
                   subtitle="Вы также можете перенести вакансию в архив, чтобы вернуться к ней позже"
                   success_button_text="Удалить" decline_button_text="Перенести в архив"
                   @success="remove" @decline="moveToArchive"
    />
    <CompanyAboutDialog ref="company_about_dialog"/>
    <ComplainDialog ref="complain_dialog" @complained="getVacancy"/>
  </div>
</template>

<script>
import LinkBack from "@/components/LinkBack";
import Vacancy from "@/api/vacancy";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import Tag from "@/components/Tag";
import LabelValueRow from "@/components/LabelValueRow";
import VacancySkeleton from "@/components/skeletons/VacancySkeleton";
import style from "./styles/Vacancy.module.css"
import PageHint from "@/components/PageHint";
import PersonCardListItem from "@/components/PersonCardListItem";
import EmptyListPlug from "@/components/EmptyListPlug";
import ApplyVacancyDialog from "@/dialogs/ApplyVacancyDialog";
import CompanyAboutDialog from "@/dialogs/CompanyAboutDialog";
import ComplainDialog from "@/dialogs/ComplainDialog";

export default {
  name: "ItemVacancy",
  components: {
    ComplainDialog,
    CompanyAboutDialog,
    ApplyVacancyDialog,
    EmptyListPlug,
    PersonCardListItem,
    PageHint, VacancySkeleton, LabelValueRow, Tag, ConfirmDialog, RekrooButton, RekrooIcon, LinkBack
  },
  data: () => ({
    style,
    vacancyClass: new Vacancy({}),
    vacancy: new Vacancy({}),
    loading: false,
    authError: false,
    notFoundError: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    company() {
      return this.vacancy.company_info ? this.vacancy.company_info : this.$store.state.user.company_info;
    },
    companyDesc() {
      let str = this.company?.desc || '';
      if (this.company?.desc?.length > 200 ) str = this.company.desc.slice(0, 200) + '...';
      return str;
    }
  },
  methods: {
    getVacancy() {
      this.loading = true;
      this.vacancyClass.get(this.$route.params.id).then((data) => {
        this.vacancy = data.response;
        this.loading = false;
        this.authError = false;
      }).catch((data) => {
        this.loading = false;
        this.authError = data.error?.type === '401';
        this.notFoundError = data.error?.type === '404';

      })
    },
    remove() {
      this.vacancyClass.remove(this.vacancy.id).then(() => {
        this.$router.replace({name: 'VacanciesList'});
      })
    },
    moveToArchive() {
      this.vacancyClass.moveToArchive(this.vacancy.id).then(() => {
        this.$router.replace({name: 'VacanciesList', query: {t: 'archive'}});
      })
    },
    changeStatus(status) {
      this.vacancyClass.create({status, id: this.vacancy.id}).then(() => {
        this.getVacancy();
      })
    },
    openApplyDialog() {
      if (this.user.id)
        this.$refs.apply_vacancy_dialog.open({vacancy_id: this.vacancy.id});
      else this.$store.state.eventBus.$emit('auth-error');
    },
    openAboutCompanyDialog() {
      this.$refs.company_about_dialog.open(this.company);
    },
    complain() {
      this.$refs.complain_dialog.open({type: 'vacancy', complainTarget: this.vacancy.position, id: this.vacancy.id});
    }
  },
  mounted() {
    /*TODO query.to_publish*/
    /*TODO query.mailing_list*/
    this.getVacancy();
  }
}
</script>

<style module lang="css">
</style>