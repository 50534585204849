import Vue from 'vue';
import Router from 'vue-router';
import Landing from "@/views/landing/Landing";
import About from "@/views/company/About";
import List from "@/views/company/vacancy/List";
import CompanyView from "@/views/company/CompanyView";
import Update from "@/views/company/vacancy/Update";
import VacanciesView from "@/views/company/vacancy/VacanciesView";
import Item from "@/views/company/vacancy/Item";
import CatalogView from "@/views/company/catalog/CatalogView";
import ResumeList from "@/views/company/catalog/List";
import ResumeItem from "@/views/company/catalog/Item";
import AuctionView from "@/views/company/auctions/View";
import AuctionList from "@/views/company/auctions/List";
import AuctionItem from "@/views/company/auctions/Item";
import NotFound from "@/views/NotFound";
import UpdateApplicantResume from "@/views/applicant/resume/Update";
import ResumeView from "@/views/applicant/resume/ResumeView";
import CRMView from "@/views/crm/View";
import CRMClients from "@/views/crm/Clients";
// import ApplicantResumeList from "@/views/applicant/resume/List";
import ApplicantResumeList_v2 from "@/views/applicant/resume/List_v2";
import ApplicantView from "@/views/applicant/ApplicantView";
import HrView from "@/views/hr/HrView";
import RatesView from "@/views/rates/RatesView";
import RatesList from "@/views/rates/RatesList";
// import HrLanding from "@/views/landing/HrLanding";
import Policy from "@/views/docs/Policy";
import Offer from "@/views/docs/Offer";
import Order from "@/views/rates/Order";
import ProfileView from "@/views/profile/ProfileView";
import Profile from "@/views/profile/Profile";
import ApplicantVacancyView from "@/views/applicant/vacancy/ApplicantVacancyView";
import ApplicantVacanciesList from "@/views/applicant/vacancy/List";
import ApplicantLanding from "@/views/landing/ApplicantLanding";
import ResponsesView from "@/views/applicant/responses/ResponsesView";
import ResponsesList from "@/views/applicant/responses/ResponsesList";
import ConsultationView from "@/views/applicant/consultation/ConsultationView";
import ApplicantConsultationList from "@/views/applicant/consultation/List"
import HRAuctionView from "@/views/hr/auction/HRAuctionView";
import HROrdersList from "@/views/hr/auction/HROrdersList";
import HRConsultationsView from "@/views/hr/consultations/HRConsultationsView";
import HRConsultationsList from "@/views/hr/consultations/HRConsultationsList";
import EmployerLanding from "@/views/landing/EmployerLanding";
import HRLanding_v2 from "@/views/landing/HRLanding_v2";
import HrLanding from "@/views/landing/HrLanding";
import HRLanding_v3 from "@/views/landing/HRLanding_v3";
import MailingListView from "@/views/company/mailing_list/MailingListView";
import MailingList from "@/views/company/mailing_list/MailingList";
import MailingListForm from "@/views/company/mailing_list/MailingListForm";
import MailingListItem from "@/views/company/mailing_list/MailingListItem";
import HrmLanding from "@/views/landing/HrmLanding";

Vue.use(Router);

function configRoutes() {
    return [
        {
            path: '/',
            name: 'Dashboard',
            component: Landing,
        },
        {
            path: '/company',
            component: CompanyView,
            children: [
                {
                    path: '',
                    name: 'CompanyLanding',
                    component: EmployerLanding,
                },
                {
                    path: 'about',
                    name: 'CompanyAbout',
                    component: About
                },
                {
                    path: 'vacancies',
                    component: VacanciesView,
                    children: [
                        {
                            path: '',
                            name: 'VacanciesList',
                            component: List,
                        },
                        {
                            path: 'create',
                            name: 'CreateVacancy',
                            component: Update
                        },
                        {
                            path: ':id',
                            name: 'VacancyItem',
                            component: Item
                        },
                        {
                            path: 'update/:id',
                            name: 'UpdateVacancy',
                            component: Update
                        },
                    ]
                },
                {
                    path: 'catalog',
                    component: CatalogView,
                    children: [
                        {
                            name: 'CatalogList',
                            path: '',
                            component: ResumeList,
                        },
                        {
                            name: 'CatalogItem',
                            path: ':id',
                            component: ResumeItem,
                        }
                    ]
                },
                {
                    path: 'auction',
                    component: AuctionView,
                    children: [
                        {
                            path: '',
                            name: 'AuctionList',
                            component: AuctionList
                        },
                        {
                            path: ':id',
                            name: 'AuctionItem',
                            component: AuctionItem
                        }
                    ]
                },
                {
                    path: 'mailing_list',
                    component: MailingListView,
                    children: [
                        {
                            path: '',
                            name: 'MailingListCompany',
                            component: MailingList
                        },
                        {
                            path: 'create',
                            name: 'MailingListCreateCompany',
                            component: MailingListForm,
                        },
                        {
                          path: ':id',
                          name: 'MailingListItemCompany',
                          component: MailingListItem
                        },
                        {
                            path: 'update/:id',
                            name: 'MailingListUpdateCompany',
                            component: MailingListForm,
                        }
                    ]
                }
            ],
        },
        {
            path: '/applicant',
            component: ApplicantView,
            children: [
                {
                    path: '',
                    name: 'ApplicantLanding',
                    component: ApplicantLanding,
                },
                {
                    path: 'resumes',
                    component: ResumeView,
                    children: [
                        {
                            path: '',
                            name: 'ApplicantResumeList',
                            component: ApplicantResumeList_v2,
                        },
                        {
                            path: 'create',
                            name: 'CreateResume',
                            component: UpdateApplicantResume,
                        },
                        {
                            path: 'update/:id',
                            name: 'UpdateResume',
                            component: UpdateApplicantResume,
                        },
                        {
                            path: ':id',
                            name: 'ApplicantItem',
                            component: ResumeItem,
                        }
                    ]
                },
                {
                    path: 'responses',
                    component: ResponsesView,
                    children: [
                        {
                            path: '',
                            name: 'ResponsesList',
                            component: ResponsesList
                        }
                    ]
                },
                {
                    path: 'vacancies',
                    component: ApplicantVacancyView,
                    children: [
                        {
                            path: '',
                            name: 'ApplicantVacanciesList',
                            component: ApplicantVacanciesList
                        },
                        {
                            path: ':id',
                            name: 'ApplicantVacancy',
                            component: Item
                        }
                    ]
                },
                {
                    path: 'consultations',
                    component: ConsultationView,
                    children: [
                        {
                            path: '',
                            component: ApplicantConsultationList,
                        }
                    ]
                }
            ]
        },
        {
            path: '/hr',
            component: HrView,
            children: [
                {
                    path: '',
                    name: 'HrLanding',
                    component: HRLanding_v3
                },
                {
                    path: 'v2',
                    component: HRLanding_v2
                },
                {
                    path: 'v3',
                    component: HrLanding
                },
                {
                    path: 'vacancies',
                    component: VacanciesView,
                    children: [
                        {
                            path: '',
                            name: 'HRVacanciesList',
                            component: List,
                        },
                        {
                            path: 'create',
                            name: 'HRCreateVacancy',
                            component: Update
                        },
                        {
                            path: ':id',
                            name: 'HRVacancyItem',
                            component: Item
                        },
                        {
                            path: 'update/:id',
                            name: 'HRUpdateVacancy',
                            component: Update
                        },
                    ]
                },
                {
                    path: 'catalog',
                    component: CatalogView,
                    children: [
                        {
                            name: 'HRCatalogList',
                            path: '',
                            component: ResumeList,
                        },
                        {
                            name: 'HRCatalogItem',
                            path: ':id',
                            component: ResumeItem,
                        }
                    ]
                },
                {
                    path: 'auction',
                    component: HRAuctionView,
                    children: [
                        {
                            path: '',
                            component: HROrdersList,
                            name: 'HROrdersList'
                        },
                        {
                            path: ':id',
                            name: 'HROrderItem',
                            component: AuctionItem
                        }
                    ]
                },
                {
                    path: 'consultations',
                    component: HRConsultationsView,
                    children: [
                        {
                            path: '',
                            component: HRConsultationsList,
                            name: 'HRConsultationsList'
                        }
                    ]
                },
                {
                    path: 'mailing_list',
                    component: MailingListView,
                    children: [
                        {
                            path: '',
                            name: 'MailingListHR',
                            component: MailingList
                        },
                        {
                            path: 'create',
                            name: 'MailingListCreateHR',
                            component: MailingListForm,
                        },
                        {
                            path: ':id',
                            name: 'MailingListItemHR',
                            component: MailingListItem
                        },
                        {
                            path: 'update/:id',
                            name: 'MailingListUpdateHR',
                            component: MailingListForm,
                        }
                    ]
                }
            ]
        },
        {
          path: '/hrm',
          component: HrmLanding,
          name: 'HrmLanding',
        },
        {
            path: '/rates',
            component: RatesView,
            children: [
                {
                    path: '',
                    name: "Rates",
                    component: RatesList
                }
            ]
        },
        {
            path: '/crm',
            component: CRMView,
            children: [
                {
                    path: '',
                    name: "Clients",
                    component: CRMClients
                }
            ]
        },
        {
            path: '/order/:id',
            component: Order,
            name: 'Order',
        },
        {
            path: '/profile',
            component: ProfileView,
            children: [
                {
                    path: '',
                    name: 'Profile',
                    component: Profile
                }
            ]
        },
        {
            path: '/policy',
            component: Policy,
            name: 'Policy'
        },
        {
            path: '/offer',
            component: Offer,
            name: 'Offer'
        },
        {
            path: '*',
            name: 'NotFound',
            component: NotFound
        }
    ]
}

const pos = {x: 0, y: 0, behavior: 'instant'};

export const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: configRoutes(),
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return savedPosition ? savedPosition : to.hash ? {selector: to.hash, behavior: 'smooth'} : pos;
        // if (to.params.id) return pos;
        // return {...savedPosition, behavior: 'instant'} || pos;
    }
});
