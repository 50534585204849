<template>
  <div :class="$style.subheader">
    <el-badge v-for="(btn, i) in current_buttons" :key="i" :value="btn.counts || ''" type="success">
      <router-link :to="btn.path">
        <rekroo-button :type="$route.path.split('/').includes(btn.section) ? 'active' : 'subheader'"
        >{{ btn.label }}
        </rekroo-button>
      </router-link>
    </el-badge>
  </div>
</template>

<script>
import RekrooButton from "@/components/Button";

export default {
  name: "TheSubheader",
  components: {RekrooButton},
  data: () => ({
    candidate_subheader_buttons: [
      {
        path: '/applicant/resumes',
        label: 'Мои резюме',
        section: 'resumes',
      },
      {
        path: '/applicant/responses',
        label: 'Отклики',
        section: 'responses',
      },
      {
        path: '/applicant/vacancies',
        label: 'Вакансии',
        section: 'vacancies',
      },
      {
        path: '/applicant/consultations',
        label: 'Консультации',
        section: 'consultations',
      },
    ],
    hr_subheader_buttons: [
      {
        path: '/hr/vacancies',
        label: 'Мои вакансии',
        section: 'vacancies',
      },
      {
        path: '/hr/catalog',
        label: 'Каталог резюме',
        section: 'catalog',
      },
      {
        path: '/hr/auction',
        label: 'Аукцион',
        section: 'auction',
      },
      {
        path: '/hr/consultations',
        label: 'Консультации',
        section: 'consultations',
      },
      {
        path: '/hr/mailing_list',
        label: 'Рассылки',
        section: 'mailing_list',
      }
    ]
  }),
  computed: {
    userCounts() {
      return this.$store.state.userCounts;
    },
    company_subheader_buttons() {
     return [
        {
          path: '/company/about',
          label: 'О компании',
          section: 'about',
        },
        {
          path: '/company/vacancies',
          label: 'Мои вакансии',
          section: 'vacancies',
          counts: this.userCounts.vacancy_responses
        },
        {
          path: '/company/catalog',
          label: 'Каталог резюме',
          section: 'catalog',
        },
        {
          path: '/company/auction',
          label: 'Аукционы', //заказы, консультации
          section: 'auction',
        },
        {
          path: '/company/mailing_list',
          label: 'Рассылки',
          section: 'mailing_list',
        }
        /*{
          path: '/company/workers',
          label: 'Специалисты',
          section: 'workers'
        }*/
      ]
    },
    current_buttons() {
      return this.$route.path.split('/').includes('company') ? this.company_subheader_buttons :
          this.$route.path.split('/').includes('applicant') ? this.candidate_subheader_buttons : this.hr_subheader_buttons
    }
  }

}
</script>

<style lang="css" module>
.subheader {
  border-top: 1px solid var(--gray);
  padding-top: 16px;
  display: flex;
  column-gap: 12px;
}

@media screen and (max-width: 1024px) {
  .subheader {
    margin-left: -48px;
    margin-right: -48px;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 12px;
    overflow: auto;
  }

  .subheader::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .subheader {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media screen and ( max-width: 575px) {
  .subheader {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

</style>