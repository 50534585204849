<template>
  <table-skeleton v-if="loading" :class="tableStyles.table" :key="'skelet'+name"
                  :header-titles="['Название', 'Город', 'Отклики', 'Дата окончания']"/>
  <el-table
      v-else
      :key="'table'+name"
      :class="['table-container', tableStyles.table, {'table-container-active': loaded}]"
      :data="data"
      :show-header="data.length > 0"
      header-row-class-name="header-row">
    <el-table-column label="Название" property="position" class-name="table-title-col">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <router-link :to="`/hr/auction/${scope.row.id}`" :class="tableStyles.title">
            {{ scope.row.title }}
          </router-link>
          <div :class="tableStyles.mobileCell">
<!--            <div :class="$style.vacanciesTableInfoRow">
              <span>Отклики:</span> {{ scope.row.counts?.total || 0  }}
            </div>
            <div :class="$style.vacanciesTableInfoRow" v-if="scope.row.location">
              <span>Город:</span> {{ scope.row.location }}
            </div>
            <div :class="$style.vacanciesTableInfoRow" v-if="scope.row.date_end">
              <span>Дата окончания:</span> {{ dateParse(scope.row.date_end, 'D MMMM, YYYY') }}
            </div>-->
          </div>
        </div>
<!--        <div :class="tableStyles.mobileActions">
          <el-dropdown :hide-on-click="true" trigger="click">
            <rekroo-button icon="more" color="transparent" icon_width="30px" style="padding: 0"/>
            <el-dropdown-menu>
              <el-dropdown-item>
                  <rekroo-button type="text" text_color="var(&#45;&#45;primary)" icon="pencil" icon_style="margin-right: 6px">
                    Редактировать
                  </rekroo-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <rekroo-button @click="$emit('on-remove-btn-click', scope.row.id)"
                               type="text" text_color="var(&#45;&#45;error-red)" icon="trash" icon_style="margin-right: 6px">
                  Удалить
                </rekroo-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>-->
      </template>
    </el-table-column>
    <el-table-column label="Заказчик">
      <template slot-scope="scope">
        <div :class="$style.company">
          <candidate-avatar :img="scope.row.company_info?.image?.url" :circle="true" size="32"/>
          {{scope.row.company_info?.title}}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Гонорар">
      <template slot-scope="scope">
        {{salaryParse(scope.row.price_from, scope.row.price_to)}}
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug title="В этом разделе нет заказов"/>
    </template>
  </el-table>
</template>

<script>
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import RekrooButton from "@/components/Button";
import EmptyListPlug from "@/components/EmptyListPlug";
import Tag from "@/components/Tag";
import CandidateAvatar from "@/components/CandidateAvatar";

export default {
  name: "HROrdersTable",
  components: {CandidateAvatar, Tag, EmptyListPlug, RekrooButton, TableSkeleton},
  props: ['loading', 'name', 'data', 'loaded'],
  data: ()=>({
    tableStyles,
  })
}
</script>

<style module lang="css">
.company {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
</style>