<template>
  <div class="page page-with-tabs-table" :class="style.catalogPage">
    <div :class="style.catalogPageContent">
      <p :class="style.searchedCount">{{
          activeTab === 'all' ? `Найдено ${itemsCount}
        ${getNoun(itemsCount, 'заказ', 'заказа', 'заказов')}` : ' '
        }}</p>
      <h1 :class="$style.pageTitle">Мои заказы
        <rekroo-button
            v-if="!user.woinfo1?.status && !authError"
            :class="$style.fillAboutInfoButton"
            icon="check"
            icon_style="margin-right: 10px"
            type="outlined"
            @click="fillAboutInfo">Хочу получать заказы от компаний
        </rekroo-button>
      </h1>
      <el-tabs v-model="activeTab" :before-leave="onTabsChange">
        <el-tab-pane label="База заказов" name="all">
          <div :class="style.catalogPageContentInner">
            <div :class="style.sideContent">
              <PageHint
                  icon="error"
                  color="var(--green)"
                  title="Cовет"
                  subtitle="Не ждите, пока заказчики выберут вас в качестве исполнителя, начните откликаться прямо сейчас!"/>
            </div>
            <div :class="style.listContent">
              <div :class="style.searchInputContainer">
                <div :class="style.searchTitle">Какой заказ вы ищете?</div>
                <el-form :class="$style.filtersForm">
                  <el-form-item style="flex-grow: 1">
                    <el-input v-model="filters.q" placeholder="Ключевое слово"/>
                  </el-form-item>
                  <el-form-item>
                    <div :class="salaryItemStyle.salaryItem">
                      <div :class="salaryItemStyle.salary">
                        <el-input placeholder="Ставка от" v-model="filters.price_from"
                                  :class="salaryItemStyle.salaryFromInput"
                                  v-maska="$store.state.maskThousandSeparator"/>
                        <el-input placeholder="Ставка до" v-model="filters.price_to"
                                  :class="salaryItemStyle.salaryToInput"
                                  v-maska="$store.state.maskThousandSeparator"/>
                      </div>
                    </div>
                  </el-form-item>
                  <!--              <el-form-item style="flex-grow: 1">
                                  <el-select v-model="filters.order_from" placeholder="Заказ от">
                                    <el-option v-for="(value, i) in ['Компаний', 'Соискателей']"
                                               :key="i"
                                               :label="value"
                                               :value="i"/>
                                  </el-select>
                                </el-form-item>-->
                  <rekroo-button @click="get" :class="$style.applyButton">Применить</rekroo-button>
                </el-form>
              </div>
              <div :class="style.resumeList" v-if="loading">
                <auction-order-skeleton v-for="i in 5" :key="i"/>
              </div>
              <div :class="style.resumeList" v-else-if="!itemsCount">
                <empty-list-plug title="В базе заказов пусто" subtitle="Попробуйте заглянуть сюда позже"
                                 :show-icon="true"
                                 style="margin: 0 auto"/>
              </div>
              <div :class="style.resumeList" v-else>
                <router-link :to="`/hr/auction/${item.id}`" :class="$style.orderItem" v-for="item in items"
                             :key="item.id">
                  <div :class="$style.orderItemBefore">
                    <candidate-avatar :img="item.company_info?.image?.url || item.image"
                                      :circle="item.order_from === 'applicant'" size="48"/>
                    <template v-if="item.order_from === 'applicant'">
                      {{ item.name }} {{ item.order_from === 'applicant' ? item.surname[0] + '.' : '' }}
                    </template>
                    <template v-else>
                      {{ item.company_info?.title }}
                    </template>
                    <!--                <tag :class="$style.orderItemHeaderTag"
                                         :text="item.order_from === 'applicant' ? 'Соискатель' : 'Компания'"
                                         :color="item.order_from === 'applicant' ? 'var(&#45;&#45;yellow)' : 'var(&#45;&#45;green)'"/>-->
                  </div>
                  <div :class="$style.orderItemBody">
                    <div :class="$style.orderItemHeader">
                      <div class="headline-3">{{ item.title }}</div>
                      <tag :class="$style.priceTag"
                           icon="money" :icon_pos="!item.price_from*1 && !item.price_to*1 ? 'start' : ''"
                           :text="salaryParse(item.price_from, item.price_to)"/>
                    </div>
                    <div :class="$style.orderItemContent">
                      <div :class="$style.orderItemTags">
                        <!--                    <tag
                                                 :text="item.order_from === 'applicant' ? 'Соискатель' : 'Компания'"
                                                 :color="item.order_from === 'applicant' ? 'var(&#45;&#45;yellow)' : 'var(&#45;&#45;green)'"/>-->
                        <tag
                            icon="money" :icon_pos="!item.price_from*1 && !item.price_to*1 ? 'start' : ''"
                            :text="salaryParse(item.price_from, item.price_to)"/>
                      </div>
                      <p v-html="item.description?.replaceAll('\n', '<br>')"/>
                    </div>
                    <div :class="$style.orderItemActions">
                      <tag v-if="item.my_response?.id" text="Откликнулся"/>
                      <rekroo-button v-else-if="item.user_id != user.id" color="var(--purple)"
                                     @click="openAddResponseDialog(item.id)" :prevent="true">Откликнуться
                      </rekroo-button>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Предложения и отклики" name="responses">
          <empty-list-plug v-if="authError" title="Доступ ограничен"
                           subtitle="Авторизуйтесь, чтобы иметь возможность откликаться на предложения"
                           button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
                           style="margin: 32px auto" :show-icon="true"/>
          <HROrdersTable v-else :data="tablesData.responses.data" name="active" :loading="tablesData.responses.loading"
                         :loaded="tablesData.responses.loaded"/>
        </el-tab-pane>
        <el-tab-pane label="В работе" name="work">
          <empty-list-plug v-if="authError" title="Доступ ограничен"
                           subtitle="Авторизуйтесь, чтобы иметь возможность откликаться на предложения"
                           button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
                           style="margin: 32px auto" :show-icon="true"/>
          <HROrdersTable v-else :data="tablesData.work.data" name="work" :loading="tablesData.work.loading"
                         :loaded="tablesData.work.loaded"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <AddResponseDialog ref="add_response_dialog" @success="get"/>
    <AboutHrFormDialog ref="about_hr_form_dialog" @updated="getUser"/>
  </div>
</template>

<script>
import style from '@/views/company/catalog/styles/catalog.module.css';
import salaryItemStyle from "@/assets/repeated_styles/SalaryItemStyle.module.css";
import PageHint from "@/components/PageHint";
import RekrooButton from "@/components/Button";
import CardListItemSkeleton from "@/components/skeletons/CardListItemSkeleton";
import EmptyListPlug from "@/components/EmptyListPlug";
import CandidateAvatar from "@/components/CandidateAvatar";
import Tag from "@/components/Tag";
import {AuctionItem} from "@/api/auction_item";
import AddResponseDialog from "@/views/hr/auction/AddResponseDialog";
import AboutHrFormDialog from "@/dialogs/AboutHrFormDialog";
import {User} from "@/api/user";
import HROrdersTable from "@/views/hr/auction/HROrdersTable";
import AuctionOrderSkeleton from "@/components/skeletons/AuctionOrderSkeleton";


export default {
  name: "HROrdersList",
  components: {
    AuctionOrderSkeleton,
    HROrdersTable,
    AboutHrFormDialog,
    AddResponseDialog, Tag, CandidateAvatar, EmptyListPlug, CardListItemSkeleton, RekrooButton, PageHint
  },
  data: () => ({
    style,
    salaryItemStyle,
    auctionClass: new AuctionItem({}),
    userClass: new User({}),
    itemsCount: 0,
    filters: {},
    items: [],
    loading: false,
    activeTab: 'all',
    tablesData: {
      work: {
        loading: false,
        loaded: false,
        data: []
      },
      responses: {
        loading: false,
        loaded: false,
        data: []
      },
    },
    authError: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    openAddResponseDialog(id) {
      this.$refs.add_response_dialog.open(id);
    },
    get() {
      this.loading = true;
      this.auctionClass.search(this.filtersToRequest()).then((data) => {
        this.loading = false;
        this.items = data.response;
        this.itemsCount = data.count;
      }).catch(() => {
        this.loading = false;
      })
    },
    getOrders(tab) {
      let params = {
        hr: 1,
        status: tab === 'archive' ? 2 : 1,
        chosen: tab === 'work' ? 1 : null,
        worker_chosen: tab === 'responses' ? 0 : null
      };
      this.tablesData[tab].loading = true;
      this.auctionClass.get(null, params).then(data => {
        this.tablesData[tab].data = data.response;
        this.tablesData[tab].loaded = true;
        this.tablesData[tab].loading = false;
        this.authError = false;
      }).catch((data) => {
        this.tablesData[tab].loading = false;
        if (data.error?.type === '401') this.authError = true;
      })
    },
    filtersToRequest() {
      let filters = {...this.filters};
      if (filters.price_from) filters.price_from = filters.price_from?.replaceAll(' ', '');
      if (filters.price_to) filters.price_to = filters.price_to?.replaceAll(' ', '');
      return filters;
    },
    fillAboutInfo() {
      this.$refs.about_hr_form_dialog.open();
    },
    getUser() {
      this.$store.state.eventBus.$emit('get-user');
    },
    onTabsChange(newTab) {
      if (newTab === 'all') this.get();
      else this.getOrders(newTab);
      for (let key in this.tablesData) {
        if (key !== newTab) this.tablesData[key].loaded = false;
      }
      this.$router.push({query: newTab === 'all' ? {} : {t: newTab}}).catch(() => {
      });
    }
  },
  mounted() {
    if (this.$route.query.t) this.activeTab = this.$route.query.t;
    else this.get();
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.getOrders(this.activeTab);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
.pageTitle {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
}

.pageTitle .fillAboutInfoButton {
  margin-left: auto;
  font-size: var(--button);
  white-space: normal;
}

.filtersForm {
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;
}

.applyButton {
  height: 50px !important;
}

.orderItem {
  display: block;
  margin-bottom: 20px;
  padding: 36px;
  background: #FFFFFF;
  border-radius: 16px;
}

.orderItem:last-child {
  margin-bottom: 0;
}

.orderItem .orderItemBefore {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: var(--headline_3);
  font-weight: 500;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light-gray);
}

.orderItem .orderItemBody {
}

.orderItem .orderItemHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.orderItem .priceTag {
  margin-left: auto;
}

.orderItemTags {
  display: none;
}

/*.orderItem .orderItemTags {*/
/*  display: flex;*/
/*  gap: 6px;*/
/*  flex-wrap: wrap;*/
/*  margin-bottom: 10px;*/
/*}*/

.orderItem .orderItemContent {
}

.orderItem .orderItemActions {
  margin-top: 16px;
}

@media screen and (max-width: 1024px) {
  .applyButton {
    width: 100% !important;
    height: 45px !important;
  }
}

@media screen and (max-width: 575px) {
  .pageTitle {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .orderItem .orderItemHeader {
    margin-bottom: 12px;
  }

  .orderItem .orderItemHeader .priceTag {
    display: none
  }

  .orderItem .orderItemHeaderTag {
    display: none;
  }

  .orderItemTags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 12px;
  }
}
</style>