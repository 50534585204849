<template>
  <div class="page" :class="style.catalogPage">
    <div :class="style.catalogPageContent">
      <div :class="$style.bestVacanciesContainer">
        <div :class="$style.bestVacanciesHeader">
          <h1>Лучшие вакансии недели</h1>
          <swiper-arrows :max-forward="bestItems.length - 2" :offset-left="offsetLeft" :item-width="itemWidth()"
                         :current-slide="currentSlide"
                         @on-click="onArrowClick"
          />
        </div>
        <div :class="$style.bestVacanciesItems" ref="best_vacancies">
          <vacancy-card v-for="item in bestItems" :key="item.id" :vacancy="item" type="small"
                        :link="`/applicant/vacancies/${item.id.toString()}`"/>
        </div>
      </div>
      <div :class="style.catalogPageContentInner">
        <div :class="style.sideContent">
          <div :class="style.filters">
            <div :class="style.filtersHeader">
              <div :class="style.filtersHeaderTitle">Фильтры</div>
              <rekroo-button type="text" :class="style.filtersResetButton" @click="resetFilters"
                             text_color="var(--gray-text)">Сбросить все
              </rekroo-button>
            </div>
            <catalog-filters-form :filters="filters" @apply="applyFilters" type="vacancy"/>
          </div>
        </div>
        <div :class="style.listContent">
          <div :class="style.searchInputContainer">
            <div :class="style.searchTitle">Что вы ищете?
              <p :class="style.searchedCount">{{getNoun(itemsCount, 'Найдена', 'Найдены', 'Найдено')}} {{ itemsCount }} {{getNoun(itemsCount, 'вакансия', 'вакансии', 'вакансий')}}</p>
            </div>
            <el-autocomplete
                :class="style.searchInput"
                placeholder="Должность" v-model="filters.q"
                :fetch-suggestions="fetchSuggestionsPos"
                :trigger-on-focus="false"
                @select="get"
                @keydown.native="search"
                clearable>
              <template #append>
                <rekroo-button icon="search" icon_color="white" width="50px" height="50px" icon_width="20px"
                               @click="search"/>
              </template>
            </el-autocomplete>
            <rekroo-button @click="$refs.filters_dialog.open()"
                           :class="style.filterButton" icon="filter" width="100%" color="var(--gray)"
                           text_color="var(--primary)" icon_style="margin-right: 10px">Фильтры
            </rekroo-button>
            <div :class="style.appliedFilters">
              <tag v-if="filters.location" color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="filters.location = ''; get()" :text="filters.location"/>
              <tag v-if="filters.salary_from || filters.salary_to" color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="filters.salary_from = '';filters.salary_to = ''; get()"
                   :text="salaryParse(filters.salary_from, filters.salary_to, $store.state.systemParams.currencies[filters.currency]?.short)"/>
              <tag v-if="filters.experience" color="var(--blue)"
                   icon="close" icon_pos="end" icon_color="var(--primary)" icon_width="16px"
                   @onIconClick="filters.experience = ''; get()"
                   :text="`От ${filters.experience} ${getGenitiveYear(filters.experience)}`"/>
            </div>
          </div>
          <div :class="$style.vacancyList" v-if="loading">
            <vacancy-card-skeleton v-for="i in 6" :key="i"/>
          </div>
          <div :class="style.resumeList" v-else-if="!itemsCount || !items.length">
            <empty-list-plug title="На текущей странице вакансий нет" subtitle="Попробуйте заглянуть сюда позже"
                             :show-icon="true"
                             style="margin: 0 auto"/>
          </div>
          <div :class="$style.vacancyList" v-else>
            <vacancy-card v-for="item in items" :vacancy="item" :key="item.id"
                                   :link="`/applicant/vacancies/${item.id.toString()}`"/>
          </div>
          <el-pagination
              hide-on-single-page
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :pager-count="5"
              :page-size="filters.pageLength"
              :current-page="currentPage"
              :total="itemsCount"/>
        </div>
      </div>
    </div>
    <ResumeFiltersDialog ref="filters_dialog" :filters="filters" @apply="applyFilters" @reset="resetFilters" type="vacancy"/>
  </div>
</template>

<script>
import style from "@/views/company/catalog/styles/catalog.module.css"
import RekrooButton from "@/components/Button";
import CardListItemSkeleton from "@/components/skeletons/CardListItemSkeleton";
import EmptyListPlug from "@/components/EmptyListPlug";
import PersonCardListItem from "@/components/PersonCardListItem";
import CatalogFiltersForm from "@/views/company/catalog/components/CatalogFiltersForm";
import Tag from "@/components/Tag";
import Vacancy from "@/api/vacancy";
import VacancyCard from "@/components/VacancyCard";
import VacancyCardSkeleton from "@/components/skeletons/VacancyCardSkeleton";
import ResumeFiltersDialog from "@/dialogs/ResumeFiltersDialog";
import Swiper_arrows from "@/components/SwiperArrows";
import SwiperArrows from "@/components/SwiperArrows";
import {Resume} from "@/api/resume";

export default {
  name: "ApplicantVacanciesList",
  components: {
    SwiperArrows,
    Swiper_arrows,
    ResumeFiltersDialog,
    VacancyCardSkeleton,
    VacancyCard,
    Tag, CatalogFiltersForm, PersonCardListItem, EmptyListPlug, CardListItemSkeleton, RekrooButton},
  data: () => ({
    style,
    vacancyClass: new Vacancy({}),
    resumeClass: new Resume({}),
    itemsCount: 0,
    loading: false,
    items: [],
    bestItems: [],
    appliedFilterLabels: [],
    filters: {
      pageLength: 10,
      pageNumber: 0,
      employment_type: [],
      schedule: [],
      currency: 'rub',
      location: '',
      salary_from: '',
      salary_to: '',
      experience: '',
      remote_work: 0
    },
    defaultFilters: {
      pageLength: 10,
      employment_type: [],
      schedule: [],
      currency: 'rub',
      location: '',
      salary_from: '',
      salary_to: '',
      experience: '',
      remote_work: 0
    },
    offsetLeft: 0,
    currentSlide: 0,
  }),
  methods: {
    get() {
      this.loading = true;
      this.vacancyClass.search(this.filters).then((data)=>{
        this.loading = false;
        this.items = data.response;
        this.bestItems = data.best;
        this.itemsCount = data.count;
      }).catch(()=>{
        this.loading = false;
      })
    },
    fetchSuggestionsPos(q, cb) {
      this.resumeClass.suggestions('position', q).then((data) => {
        let suggestions = data.response.map(s => ({value: s}));
        cb(suggestions);
      })
    },
    search(e) {
      if (!e || e.code === 'Enter') {
        this.get();
      }
    },
    applyFilters(filters = null) {
      if (filters) this.filters = {...filters};
      this.filters.remote_work = this.filters.remote_work ? 1 : 0;
      this.filters.salary_from = this.filters.salary_from ? this.filters.salary_from.replaceAll(' ', '') : '';
      this.filters.salary_to = this.filters.salary_to ? this.filters.salary_to.replaceAll(' ', '') : '';
      this.addFiltersToUrl();
      this.get();
    },
    resetFilters() {
      this.filters = {...this.defaultFilters};
      this.defaultFilters = {
        pageLength: 10,
        employment_type: [],
        schedule: [],
        currency: 'rub',
        location: '',
        salary_from: '',
        salary_to: '',
        experience: '',
        remote_work: 0
      }
      this.appliedFilterLabels = [];
      this.addFiltersToUrl();
      this.get();
    },
    addFiltersToUrl() {
      let urlFilters = {};
      for (let key in this.filters) {
        if (this.defaultFilters[key] !== this.filters[key] && this.filters[key] && this.filters[key] !== '0')
          urlFilters[key] = this.filters[key];
      }
      this.$router.push({query: {...urlFilters}}).catch(()=>{});
    },
    removeSkill(i) {
      this.filters.skills.splice(i, 1);
    },
    removeLang(i) {
      this.filters.languages.splice(i, 1);
    },
    changePage(page) {
      this.filters.pageNumber = page - 1;
      this.addFiltersToUrl();
      this.get();
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    itemWidth () {
      return this.$refs.best_vacancies?.firstChild?.clientWidth + 16 || 350;
    },
    onArrowClick(params) {
      this.offsetLeft = params.offsetLeft;
      this.currentSlide = params.currentSlide;
      this.$refs.best_vacancies.scrollTo({
            left: this.offsetLeft,
            behavior: 'smooth'
          }
      )
    }
  },
  computed: {
    pages() {
      let pagesCount = Math.ceil(this.itemsCount / this.filters.pageLength);
      return Array.from(Array(pagesCount).keys());
    },
    currentPage() {
      return parseInt(this.$route.query.pageNumber)+1 || 1;
    }
  },
  mounted() {
    if ( Object.keys(this.$route.query).length) {
      let query = this.$route.query;
      if (typeof query.employment_type === 'string') query.employment_type = [query.employment_type];
      if (typeof query.schedule === 'string') query.schedule = [query.schedule];
      Object.assign(this.filters, query);
      this.addFiltersToUrl();
    }
    this.get();
  }
}
</script>

<style module lang="css">
.bestVacanciesContainer {
  padding: 64px 0;
  margin-bottom: 32px;
  border-radius: 16px;
  background: var(--light-gray);
}

.bestVacanciesHeader {
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.bestVacanciesItems {
  padding: 0 48px;
  display: flex;
  overflow: auto;
  column-gap: 16px;
}
.bestVacanciesItems::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bestVacanciesItems {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.vacancyList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1024px) {
  .bestVacanciesContainer {
    padding: 60px 0;
  }
  .bestVacanciesHeader {
    padding: 0 40px;
    margin-bottom: 32px;
  }
  .bestVacanciesItems {
    padding: 0 40px;
  }
}

@media screen and (max-width: 575px) {
  .bestVacanciesContainer {
    padding: 52px 0;
  }
  .bestVacanciesHeader {
    padding: 0 20px;
  }
  .bestVacanciesItems {
    padding: 0 20px;
  }
  .vacancyList {
    margin-bottom: 24px;
  }
}
</style>