<template>
  <div :class="style.resume">
    <img src="/img/icons/icon_paper_clip.svg" alt="" :class="style.resumeClip">
    <div :class="style.resumeHeader">
      <candidate-avatar img="" :size=" windowWidth > 1024 ? '212' : windowWidth > 575 ? '164' : '124'"/>
      <div :class="style.resumeHeaderInfo">
        <skeleton variant="text" :class="style.name" width="200px" height="var(--headline-2)"/>
        <skeleton variant="text" :class="style.position" max-width="300px" width="100%"/>
        <div :class="style.age">
          <skeleton variant="text" max-width="250px" width="100%"/>
        </div>
        <div :class="style.location">
          <skeleton variant="text" max-width="260px" width="100%"/>
        </div>
        <div :class="style.citizen">Гражданство:  <skeleton variant="text" width="170px"/></div>
        <tag>
          <template #custom>
            <div :class="style.contactsHiddenIcon">
              <rekroo-icon icon="error" color="#FFF" width="9"/>
            </div>
            <rekroo-button type="text" text_color="var(--bright-blue)"
                           :class="style.subscribeTextButton">Открыть имя и контакты
            </rekroo-button>
          </template>
        </tag>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Обо мне</div>
      <div :class="style.resumeSectionAbout">
        <el-skeleton>
          <skeleton variant="p"/>
        </el-skeleton>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Пожелания к работе</div>
      <div :class="style.resumeSectionWishes">
        <div :class="style.resumeSectionWishesInfo">
          <div :class="style.row">
            <span :class="style.label">График: </span><skeleton width="100px"/>
          </div>
          <div :class="style.row">
            <span :class="style.label">Занятость: </span><skeleton width="120px"/>
          </div>
          <div :class="style.row">
            <span :class="style.label">Командировки: </span><skeleton width="110px"/>
          </div>
        </div>
        <tag color="var(--green)" icon="money" icon_pos="start" text="По договоренности"/>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Образование</div>
      <div :class="style.resumeSectionEducation">
        <div :class="style.resumeSectionRowInfo" v-for="i in 2" :key="i">
          <div :class="style.resumeSectionRowInfoTitle">
            <skeleton variant="text" width="100%" max-width="400px"/>
            <div :class="style.year">
              <skeleton variant="text" width="100px"/>
            </div>
          </div>
          <skeleton variant="text" width="100%" max-width="300px"/>
        </div>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Опыт работы</div>
      <div :class="style.resumeSectionRowInfo" v-for="i in 3" :key="i">
        <div :class="style.resumeSectionRowInfoTitle">
          <skeleton variant="text" width="100%" max-width="400px"/>
          <div :class="style.year">
            <skeleton variant="text" width="100px"/> - <skeleton variant="text" width="100px"/>
          </div>
        </div>
        <div :class="style.jobPosition">
          <skeleton variant="text" width="100%" max-width="400px"/>
        </div>
        <div :class="style.resumeSectionRowInfoSubtitle">
          <el-skeleton>
            <skeleton variant="p"/>
          </el-skeleton>
        </div>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Навыки</div>
      <div :class="style.skills">
        <skeleton variant="button" height="40px" width="120px" v-for="i in 8" :key="i"/>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Языки</div>
      <div :class="style.languages">
        <skeleton variant="button" height="40px" width="120px" v-for="i in 2" :key="i"/>
      </div>
    </div>
    <div :class="style.resumeSection">
      <div :class="style.resumeSectionTitle">Курсы и сертификаты</div>
      <div :class="style.resumeSectionRowInfo" v-for="i in 2" :key="i">
        <div :class="style.resumeSectionRowInfoTitle">
          <skeleton variant="text" width="100%" max-width="400px"/>
          <div :class="style.year">
            <skeleton variant="text" width="100px"/>
          </div>
        </div>
        <skeleton variant="text" width="100%" max-width="300px"/>
      </div>
    </div>
  </div>
</template>

<script>
import style from "@/components/styles/ResumeStyles.module.css"
import CandidateAvatar from "@/components/CandidateAvatar";
import Skeleton from "@/components/skeletons/Skeleton";
import Tag from "@/components/Tag";
import RekrooIcon from "@/components/Icon";
import RekrooButton from "@/components/Button";

export default {
  name: "ResumeSkeleton",
  props: ['windowWidth'],
  components: {RekrooButton, RekrooIcon, Tag, Skeleton, CandidateAvatar},
  data: () => ({
    style,
  })
}
</script>

<style module lang="css">

</style>