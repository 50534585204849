<template>
  <div :class="$style.companyAboutPage" class="page" ref="page">
    <h1 :class="$style.companyAboutPageTitle">Информация о работодателе</h1>
    <company-form ref="about_company_form" :loading="loading"
                  :update-in-vacancies="updateInVacanciesLoading"
                  role="company" :company="company" :info-hint-subtitle="infoHintSubtitle"
                  :is-full-info="isFullInfo" @onUploadImageSuccess="onUploadImageSuccess" @onRemoveImage="onRemoveImage"
    @submit="submit" @keydown.native="onKeydown" @update-in-vacancies="updateInVacancies"/>
  </div>
</template>

<script>
import PageHint from "@/components/PageHint";
import RekrooButton from "@/components/Button";
import {User} from "@/api/user";
import ImageUpload from "@/components/ImageUpload";
import CompanyForm from "@/components/CompanyForm";
import Vacancy from "@/api/vacancy";
export default {
  name: "AboutVacancy",
  components: {CompanyForm, ImageUpload, RekrooButton, PageHint},
  data: ()=>({
    userClass: new User({}),
    loading: false,
    vacancyClass: new Vacancy({}),
    updateInVacanciesLoading: false,
  }),
  methods: {
    submit() {
      this.$refs.about_company_form.$refs.about_company_form.validate((valid)=>{
        if (valid) {
          this.loading = true;
          this.userClass.create({company_info: this.dataToRequest(), id: this.$store.state.user.id}).then((data)=>{
            if (data.response.company_info && data.response.company_info.link) {
              data.response.company_info.link = data.response.company_info.link.replace('https://', '');
            }
            this.$store.commit('setUser', data.response);
            this.$notify({
              type: 'success',
              title: 'Информация о компании успешно сохранена'
            })
            this.loading = false;
          }).catch(()=>{
            this.loading = false;
          })
        } else {
          this.scrollToError(this.$refs.page);
        }
      })
    },
    onKeydown(key) {
      if (key.code === 'Enter') this.submit();
    },
    dataToRequest() {
      let data = {...this.company};
      if (data.image.id) data.image = data.image.id;
      if (data.link && !data.link.includes('http')) data.link = 'https://'+data.link;
      return data;
    },
    onUploadImageSuccess(image) {
      this.company.image = image;
    },
    onRemoveImage() {
      this.$nextTick(()=>{
        this.company.image = null;
      })
      this.$forceUpdate();
    },
    updateInVacancies() {
      this.updateInVacanciesLoading = true;
      this.vacancyClass.get().then((data)=>{
        if (data.response.length) {
          let ids = data.response.map(e=>e.id);
          this.vacancyClass.create({company_info: this.user.company_info}, ids).then(()=>{
            this.updateInVacanciesLoading = false;
          }).catch(()=>{
            this.updateInVacanciesLoading = false;
          })
        }
      }).catch(()=>{
        this.updateInVacanciesLoading = false;
      })
    }
  },
  computed: {
    isButtonDisabled() {
      return !this.company.title;
    },
    user() {
      return this.$store.state.user;
    },
    company() {
      return this.user.company_info || {};
    },
    isFullInfo() {
      return Object.values(this.company).filter(e=>e).length === 6
    },
    infoHintSubtitle() {
      if (!this.company.image) return 'Добавьте логотип — кандидаты больше доверяют вакансиям с максимально заполненными страницами компаний';
      else return 'Заполните как можно больше полей. Чем больше информации будет о вашей компании, тем лучше'
    }
  },
}
</script>

<style lang="css" module>
.companyAboutPage .companyAboutPageTitle {
  margin-bottom: 32px;
}

@media screen and (max-width: 1024px) {
  .companyAboutPage .companyAboutPageTitle {
    display: none;
  }
}
</style>
