<template>
  <div :class="$style.rate">
    <div :class="$style.title">
      <skeleton variant="text" height="var(--subheadline)" max-width="240px" width="100%"/>
    </div>
    <h1 :class="$style.ratePrice">
      <skeleton variant="h1" width="140px"/>
    </h1>
    <skeleton variant="button" height="50px" width="100%"/>
  </div>
</template>

<script>
import Skeleton from "@/components/skeletons/Skeleton";
export default {
  name: "RateSkeleton",
  components: {Skeleton}

}
</script>

<style module lang="css">

.rate {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary);
  border-radius: 8px;
  padding: 37px 32px 32px;
}

.rate .rateHeader {
  height: 42px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}

.rate .title {
  font-size: var(--subheadline);
  font-weight: 500;
  margin-bottom: 16px;
}

.rate .rateHeader .duration {
  font-size: var(--headline_2);
  font-weight: 600;
}

.rateAmount {
  margin-bottom: 36px;
}

.rate .rateHeader .discount {
  height: 42px;
  padding: 8px 12px;
  font-size: var(--body);
  font-weight: var(--body-medium-weight);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--yellow);
  border-radius: 8px;
}

.rate .ratePrice {
  font-size: 42px;
  margin-bottom: 16px;
}

.rate .ratePriceHint {
  margin-top: 4px;
  font-size: var(--small-text);
}

.rate .subscribeButton {
  margin-top: auto;
}

@media screen and (max-width: 1024px) {

  .rate {
    padding: 26px 20px 20px;
  }

  .rate .rateHeader {
    height: 33px;
    margin-bottom: 8px;
  }

  .rate .title {
    margin-bottom: 12px;
  }

  .rate .rateHeader .duration {
    font-size: var(--subheadline);
  }

  .rate .rateHeader .discount {
    height: 33px;
    padding: 6px 10px;
  }
}

@media screen and (max-width: 575px) {
  .rate {
    padding: 28px;
    height: fit-content;
  }

  .rate .rateHeader {
    height: 46px;
  }

  .rate .rateHeader .duration {
    font-size: 22px;
  }

  .rate .rateHeader .discount {
    height: 46px;
    padding: 10px;
  }

  .rate .ratePrice {
    font-size: 48px;
  }

  .rate .ratePriceHint {
    font-size: var(--headline_3);
    margin-top: 8px;
  }

  .rate .subscribeButton {
    margin-top: 20px;
  }
}
</style>