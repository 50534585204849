<template>
  <div class="page page-with-tabs-table" :class="$style.candidateResumeListPage">
    <h1 :class="$style.resumeListTitle">Мои резюме
      <router-link v-if="resumes.length" to="/applicant/resumes/create" :class="$style.createResumeButton">
        <rekroo-button width="315px" height="50px">+ Новое резюме</rekroo-button>
      </router-link>
    </h1>
    <div v-if="loading" :class="$style.resumesList">
      <card-list-item-skeleton type="candidate" v-for="i in 4" :key="i"/>
    </div>
    <div v-else-if="authError" :class="$style.resumesList">
      <empty-list-plug title="Доступ ограничен" subtitle="Авторизуйтесь, чтобы иметь возможность размещать резюме"
                       button_text="Войти" @onClick="$store.state.eventBus.$emit('auth-error')"
                       style="margin: 0 auto" :show-icon="true"/>
    </div>
    <div v-else-if="resumes.length" :class="$style.resumesList">
      <div :class="$style.resumeListItemContainer" v-for="resume in resumes" :key="resume.id">
        <person-card-list-item :item="resume"
                               :class="$style.resumeListItem"
                               type="candidate"
                               :link="`/applicant/resumes/${resume.id}`"/>
        <PageHint :class="$style.resumeHint"
            :icon="checkResumeFullness(resume).isFullness ? 'check' : 'warning'"
                  :color="checkResumeFullness(resume).isFullness ? 'var(--green)' : 'var(--yellow)'"
                  :title="checkResumeFullness(resume).isFullness ? 'Резюме полностью заполнено!' : 'Резюме заполнено не полностью'"
        :subtitle="checkResumeFullness(resume).text"/>
      </div>
    </div>
    <div v-else :class="$style.resumesList">
      <empty-list-plug title="Вы еще не разместили ни одного резюме" :show-icon="true"
                       button_text="+ Новое резюме"
                       link="/applicant/resumes/create"
                       style="margin: 0 auto"/>
    </div>
    <ConfirmDialog ref="confirm_remove_resume_dialog" title="Вы уверены, что хотите удалить&nbsp;резюме?"
                   subtitle="Вы также можете перенести резюме в архив, чтобы вернуться к нему позже"
                   success_button_text="Удалить" decline_button_text="Перенести в архив"
                   @success="remove" @decline="moveToArchive"
    />
  </div>
</template>

<script>
import {Resume} from "@/api/resume";
import RekrooButton from "@/components/Button";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import ResumeListTab from "@/views/applicant/resume/components/ResumeListTab";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";
import ResumeItem from "@/views/company/catalog/Item";
import PersonCardListItem from "@/components/PersonCardListItem";
import CardListItemSkeleton from "@/components/skeletons/CardListItemSkeleton";
import EmptyListPlug from "@/components/EmptyListPlug";
import PageHint from "@/components/PageHint";

export default {
  name: "CandidateResumeList_v2",
  components: {
    PageHint,
    EmptyListPlug,
    CardListItemSkeleton, PersonCardListItem, ResumeItem, ResumeListTab, ConfirmDialog, RekrooButton
  },
  data: () => ({
    tableStyles,
    resumes: [],
    loading: false,
    resumeClass: new Resume({}),
    authError: false
  }),
  methods: {
    get(firstLoad = false) {
      if (firstLoad) this.loading = true;
      this.resumeClass.get().then((data) => {
        let statuses = [1, 0, 2];
        this.resumes = data.response.sort((a, b) => statuses.indexOf(a.status) - statuses.indexOf(b.status));
        this.loading = false;
        this.authError = false;
      }).catch((data) => {
        this.loading = false;
        this.authError = data?.error?.type === '401';
      })
    },
    moveToArchive(id) {
      this.resumeClass.create({status: 0, id}).then(() => {
        this.$notify({
          type: 'success',
          title: 'Резюме успешно перемещено в архив',
          duration: 3000
        });
        if (this.activeTab === 'active') this.get();
        else this.getArchive();
      })
    },
    onRemoveBtnClick(id) {
      this.$refs.confirm_remove_resume_dialog.open({id});
    },
    remove(id) {
      this.resumeClass.moveToArchive(id).then(() => {
        this.$notify({
          type: 'success',
          title: 'Резюме успешно удалено',
          duration: 3000
        });
        if (this.activeTab === 'active') this.get();
        else this.getArchive();
      })
    },

    onTabChange(newTab) {
      if (newTab === 'active') {
        this.get();
        this.$router.push({query: {}}).catch(() => {
        });
        this.loadedArchive = false;
      } else {
        this.getArchive();
        this.$router.push({query: {t: 'archive'}}).catch(() => {
        });
        this.loaded = false;
      }
    },
    checkResumeFullness(resume) {
      if (!resume.about) return {text: 'Не заполнен раздел "О себе"', isFullness: false}
      else if (!resume.location) return {text: 'Не указан город проживания',  isFullness: false}
      else if (!resume.birthday) return {text: 'Не указана дата рождения',  isFullness: false}
      else if (!resume.education.length) return {text: 'Не заполнена информация об образовании',  isFullness: false}
      else if (!resume.work.length && !resume.experience_text) return {text: 'Не заполнена информация об опыте работы',  isFullness: false}
      else if (!resume.skills.length) return {text: 'Не указаны навыки',  isFullness: false}
      else if (!resume.languages.length) return {text: 'Не указаны языки',  isFullness: false}
      return {text: 'Полностью заполненные резюме обычно привлекают рекрутеров гораздо чаще. Удачи!',  isFullness: true};
    }
  },
  mounted() {
    if (this.$route.query.t) this.activeTab = this.$route.query.t
    else this.get(true);
  },
  created() {
    this.$store.state.eventBus.$on('user-received', () => {
      this.get(true);
    });
  },
  beforeDestroy() {
    this.$store.state.eventBus.$off('user-received');
  }
}
</script>

<style module lang="css">
.candidateResumeListPage {
}

.resumeListTitle {
  width: 100%;
  display: flex;
  align-items: center;
}

.resumesList {
  padding: 48px;
  background: var(--light-gray);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.resumeListItemContainer {
  display: grid;
  grid-template-columns: minmax(400px, 1fr) auto;
  grid-gap: 20px;
}
.resumeListItem {
  max-width: 760px;
  margin-bottom: 0;
}
.createResumeButton {
  margin-left: auto;
  font-size: var(--button);
}

@media screen and (max-width: 1024px) {
  .resumeListTitle {
    max-width: 100%;
  }
  .resumeListItemContainer {
    grid-template-columns: 1fr;
  }
  .resumesList {
    max-width: 100%;
    border-radius: 0;
  }
  .resumeHint {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .createResumeButton button {
    width: fit-content !important;
    height: 46px !important;
  }

  .resumesList {
    padding: 24px 16px;
  }
}


</style>