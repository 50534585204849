<template>
  <router-link :to="link || '/'" :class="[style.vacancy, {[style.vacancyCardSmall]: type === 'small'}]">
    <div :class="style.companyName" v-if="vacancy.company_info">
      <candidate-avatar v-if="type !== 'small'" :img="vacancy.company_info.image?.url" :circle="true" size="48"/>
      {{ vacancy.company_info.title }}
    </div>
    <div :class="style.vacancyTitle">{{ vacancy.position }}</div>
    <div :class="style.vacancyDesc" v-if="vacancy.description && type !== 'small'">{{ vacancy.description }}</div>
    <div :class="style.vacancyLocation">
      <tag :clss="style.vacancyLocationTag" v-if="vacancy.location" icon="geo" icon_pos="start"
           icon_color="var(--gray-text)" :text="vacancy.location"
           font-size="16px"/>
      <tag v-if="vacancy.remote_work" :clss="style.vacancyLocationTag" text="Удаленно" icon="remote" icon_pos="start"
           icon_color="var(--gray-text)" font-size="16px"/>
    </div>
    <tag
        :text="salaryParse(vacancy.salary_from, vacancy.salary_to, $store.state.systemParams.currencies[vacancy.currency]?.short)"
        color="var(--yellow)" font-size="16px"/>
    <tag :class="style.responseTag" text="Откликнулся" v-if="vacancy.my_response && type !== 'small'" color="var(--green)"/>
  </router-link>
</template>

<script>
import Tag from "@/components/Tag";
import CandidateAvatar from "@/components/CandidateAvatar";
import style from "@/components/styles/vacancyCardStyles.module.css"

export default {
  name: "VacancyCard",
  components: {CandidateAvatar, Tag},
  props: ['vacancy', 'link', 'type'],
  data: () => ({
    style,
  })
}
</script>

<style module>

</style>