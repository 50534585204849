<template>
  <table-skeleton v-if="loading" :class="tableStyles.table" :key="'skelet'+name"
                  :header-titles="['Название', 'Город', 'Отклики', 'Дата окончания']"/>
  <el-table
      v-else
      :key="'table'+name"
      @selection-change="onSelectionChange"
      :class="['table-container', tableStyles.table, {'table-container-active': loaded}, 'table-with-selections', 'table-with-actions']"
      ref="multipleTable" :data="data"
      :show-header="data.length > 0"
      header-row-class-name="header-row">
    <el-table-column type="selection"/>
    <el-table-column label="Название" property="position" class-name="table-title-col">
      <template slot-scope="scope">
        <div :class="tableStyles.titleContainer">
          <router-link :to="`/${root}/vacancies/${scope.row.id}`" :class="tableStyles.title">
            {{ scope.row.position }}
          </router-link>
          <div :class="tableStyles.mobileCell">
            <div :class="$style.vacanciesTableInfoRow">
              <span>Отклики:</span> {{ scope.row.counts?.total || 0  }}
            </div>
            <div :class="$style.vacanciesTableInfoRow" v-if="scope.row.location">
              <span>Город:</span> {{ scope.row.location }}
            </div>
            <div :class="$style.vacanciesTableInfoRow" v-if="scope.row.date_end">
              <span>Дата окончания:</span> {{ dateParse(scope.row.date_end, 'D MMMM, YYYY') }}
            </div>
          </div>
        </div>
        <div :class="tableStyles.mobileActions">
          <el-dropdown :hide-on-click="true" trigger="click">
            <rekroo-button icon="more" color="transparent" icon_width="30px" style="padding: 0"/>
            <el-dropdown-menu>
              <el-dropdown-item>
                <router-link :to="`/${root}/vacancies/update/${scope.row.id}`">
                  <rekroo-button type="text" text_color="var(--primary)" icon="pencil" icon_style="margin-right: 6px">
                    Редактировать
                  </rekroo-button>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <rekroo-button @click="$emit('on-remove-btn-click', scope.row.id)"
                               type="text" text_color="var(--error-red)" icon="trash" icon_style="margin-right: 6px">
                  Удалить
                </rekroo-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Город" property="location" class-name="city-col"/>
    <el-table-column label="Зарплата" class-name="response-col">
      <template slot-scope="scope">
        {{ salaryParse(scope.row.salary_from, scope.row.salary_to, $store.state.systemParams.currencies[scope.row.currency] ? $store.state.systemParams.currencies[scope.row.currency].short : scope.row.currency) }}
      </template>
    </el-table-column>
    <el-table-column property="date_end" label="Отклики" class-name="date-col">
      <template slot-scope="scope">
        <rekroo-icon icon="hand" color="var(--gray-text)" style="margin-right: 10px"/>
        {{scope.row.counts?.total || 0  }}
      </template>
    </el-table-column>
    <el-table-column class-name="table-row__actions">
      <template slot-scope="scope">
        <div :class="[tableStyles.actions, $style.tableRowActions]">
          <rekroo-button width="45px" color="var(--purple)" icon="pencil" icon_color="#FFF"
                         @click="$router.push(`/${root}/vacancies/update/${scope.row.id}`)"/>
          <rekroo-button v-if="name !== 'archive'" @click="$emit('on-remove-btn-click', scope.row.id)"
                         width="45px" color="var(--gray-text)" icon="trash" icon_color="#FFF"/>
          <el-tooltip v-if="name === 'archive'"
                      effect="dark" content="Восстановить вакансию из архива" placement="top-start">
            <rekroo-button @click="$emit('on-recover-btn-click', scope.row.id)"
                           width="45px" color="var(--gray-text)" icon="undo" icon_color="#FFF"/>
          </el-tooltip>
        </div>
        <div :class="$style.tableRowActionsMobile">
          <el-dropdown>
            <rekroo-button width="36px" height="36px" color="var(--gray)" icon="more" icon_color="#FFF"
                           icon_width="16px" style="padding: 0"/>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <rekroo-button type="text" icon="pencil" text_color="var(--primary)"
                               icon_style="margin-right: 15px"
                               @click="$router.push(`/${root}/vacancies/update/${scope.row.id}`)">Редактировать
                </rekroo-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <rekroo-button v-if="name !== 'archive'" type="text" icon="trash" text_color="var(--error-red)"
                               icon_style="margin-right: 15px"
                               icon_color="var(--error-red)"
                               @click="$emit('on-remove-btn-click', scope.row.id)">Удалить
                </rekroo-button>
                <el-tooltip v-if="name === 'archive'"
                    effect="dark" content="Восстановить вакансию из архива" placement="top-start">
                  <rekroo-button  type="text" icon="undo" text_color="var(--error-red)"
                                  icon_style="margin-right: 15px"
                                  icon_color="var(--error-red)"
                                  @click="$emit('on-recover-btn-click', scope.row.id)">Восстановить
                  </rekroo-button>
                </el-tooltip>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-table-column>
    <template #empty>
      <empty-list-plug :class="tableStyles.emptyTablePlug"
                       title="У вас пока нет опубликованных вакансий"
                       subtitle="Опишите преимущества работы в вашей компании за 3 минуты и привлеките лучших специалистов"
                       button_text="+ Новая вакансия" @onClick="goToVacancyCreate"
      />
    </template>
  </el-table>
</template>

<script>
import TableSkeleton from "@/components/skeletons/TableSkeleton";
import RekrooButton from "@/components/Button";
import EmptyListPlug from "@/components/EmptyListPlug";
import RekrooIcon from "@/components/Icon";
import tableStyles from "@/assets/repeated_styles/table_styles/Table.module.css";

export default {
  name: "VacanciesListTab",
  // eslint-disable-next-line vue/no-unused-components
  components: {RekrooIcon, EmptyListPlug, RekrooButton, TableSkeleton},
  props: ['data', 'loading', 'loaded', 'name'],
  emits: ['on-selection-change', 'on-remove-btn-click', 'on-recover-btn-click'],
  data: ()=>({
    tableStyles,
  }),
  methods: {
    goToVacancyCreate() {
      this.$router.push(`/${this.root}/vacancies/create`);
    },
    onSelectionChange(val) {
      this.$emit('on-selection-change', val);
    }
  },
  computed: {
    root() {
      return this.$route.path.split('/')[1];
    }
  },
}
</script>

<style module lang="css">

.tableRowActions {
  display: flex;
}

.tableRowActionsMobile {
  display: none;
}

@media screen and (max-width: 1024px) {

  .tableRowActions {
    display: none;
  }

  .tableRowActionsMobile {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .vacanciesTable .vacanciesTableInfo .vacanciesTableInfoRow {
    margin-bottom: 2px;
    font-size: var(--tiny-text);
  }

  .vacanciesTable .vacanciesTableInfo .vacanciesTableInfoRow span {
    color: var(--gray-text);
  }
}


</style>