import {Item} from "@/api/item";

export class AuctionItem extends Item {
    title;
    position;
    description;
    files;
    salary_from;
    salary_to;
    currency;
    order_from;
    name;
    surname;
    gender;
    image;
    datetime_create;
    user_id;
    views;
    responses;
    type;

    method = 'workerOrder';
    constructor(props) {
        super(props);
        this.currency = props.currency || 'rub';
    }

    workerSearch(filters) {
      return this.request(filters, `${this.method}/workerSearch`);
    }
    addResponse(id, params) {
        return this.request(params, `${this.method}/addResponse/${id}`);
    }
    addWorker(id, worker_id) {
        return this.request({worker_id}, `${this.method}/addWorker/${id}`);
    }
    choose(id,worker_id) {
        return this.request({worker_id}, `${this.method}/choose/${id}`);
    }
}