import moment from "moment";
import store from "@/store";

moment.locale('ru');
moment.relativeTimeThreshold('ss', 0);
moment.relativeTimeThreshold('s', 60);

export default {
    methods: {
        thousandSeparator(sum) {
            return String(sum)
                .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '); // 1 000 000 000
        },
        updateArray(arr, val) {
            if (arr.includes(val)) {
                let valIndex = arr.indexOf(val);
                arr.splice(valIndex, 1);
                return arr;
            } else {
                arr.push(val);
                return arr;
            }
        },
        gtmEvent(event) {
            try {
                window.dataLayer.push({event})
            } catch (e) {
                console.error('gtmEvent exception', e);
            }
        },
        scrollToError(page) {
            setTimeout(() => {
                let errorPos = page.querySelector('.el-form-item.is-error').offsetTop;
                window.scrollTo({
                    top: errorPos - 32,
                    behavior: "smooth"
                });
            }, 5)
        },
        dateParse(date, format) {
            if (!moment(date)) return '';
            if (typeof date === "number") return moment.unix(date).format(format);
            return moment(date).format(format) === 'Invalid date' ? '' : moment(date).format(format);
        },
        salaryParse(from, to, currency = '₽') {
            if (from && to) return `${this.thousandSeparator(from)} - ${this.thousandSeparator(to)} ${currency}`
            else if (from && !to) return `от ${this.thousandSeparator(from)} ${currency}`
            else if (!from && to) return `до ${this.thousandSeparator(to)} ${currency}`
            else return 'По договоренности';
        },
        getGenitiveYear(number) {
            let n = Math.abs(number);
            n %= 10;
            if (n === 1) return 'года';
            else return 'лет';
        },
        getNoun(number, one, two, five) {
            let n = Math.abs(number);
            n %= 100;
            if (n >= 5 && n <= 20) {
                return five;
            }
            n %= 10;
            if (n === 1) {
                return one;
            }
            if (n >= 2 && n <= 4) {
                return two;
            }
            return five;
        },
        getAgeByDate(date) {
            if (!moment(date)._isValid) return '';
            return moment().diff(date, 'years');
        },
        strPhoneMask(phone) {
            if (!phone) return '';
            let i = 1;
            return store.state.phone_mask.replace(/#/g, () => phone[i++] || '');
        },
        ignore8onPhoneInput: v => +v === 8 ? '+7' : '',
        setValue(name, value) {
            localStorage.setItem(name, JSON.stringify(value));
        },
        getValue(name, defaultValue) {
            let value = localStorage.getItem(name);
            return value === null ? defaultValue : JSON.parse(value);
        },
        debounceFn(fn, wait) {
            let timer;
            return function (...args) {
                if (timer) {
                    clearTimeout(timer); // clear any pre-existing timer
                }
                const context = this;
                timer = setTimeout(() => {
                    fn.apply(context, args); // call the function if time expires
                }, wait);
            }
        },
        checkFileSize: (size, maxSize = 1024 * 1024 * 5) => size > maxSize,
    }
}
